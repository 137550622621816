<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: General Report -->
        <div class="col-span-12 mt-5">
          <div class="grid grid-cols-12 gap-6 mt-5">
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex items-center justify-between">
                    <img src="@/assets/images/diamond.gif" class="w-14 h-14 mb-2 text-theme-9" alt="">
                    <div class="text-3xl font-medium leading-8 mt-6">{{ pagedata.headings.total_packets }}</div>
                  </div>
                  <div class="flex items-center justify-between">
                    <div class="text-base text-gray-600 mt-1">Total Packets</div>
                    <div class="text-base text-gray-600 mt-1">{{ parseFloat(pagedata.headings.total_packets_weight).toFixed(2) }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex items-center justify-between">
                    <img src="@/assets/images/processing.gif" class="w-14 h-14 mb-2 text-theme-10" alt="">
                    <div class="text-3xl font-medium leading-8 mt-6">{{ pagedata.headings.processing }}</div>
                  </div>
                  <div class="flex items-center justify-between">
                    <div class="text-base text-gray-600 mt-1">In Process</div>
                    <div class="text-base text-gray-600 mt-1">{{ parseFloat(pagedata.headings.processing_weight).toFixed(2) }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex items-center justify-between">
                    <img src="@/assets/images/shoppingcart.gif" class="w-16 h-16 text-theme-12" alt="">
                    <div class="text-3xl font-medium leading-8 mt-6">{{ pagedata.headings.external }}</div>
                  </div>
                  <div class="flex items-center justify-between">
                    <div class="text-base text-gray-600 mt-1">In External</div>
                    <div class="text-base text-gray-600 mt-1">{{ parseFloat(pagedata.headings.external_weight).toFixed(2) }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex items-center justify-between">
                    <img src="@/assets/images/process.gif" class="w-16 h-16 text-theme-12" alt="">
                    <div class="text-3xl font-medium leading-8 mt-6">{{ pagedata.headings.outward }}</div>
                  </div>
                  <div class="flex items-center justify-between">
                    <div class="text-base text-gray-600 mt-1">In Delivery</div>
                    <div class="text-base text-gray-600 mt-1">{{ parseFloat(pagedata.headings.outward_weight).toFixed(2) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: General Report -->
        <div class="col-span-12 2xl:col-span-9">
          <div class="intro-y flex items-center h-10">
            <h2 class="text-lg font-bold truncate mr-5">
              Employee Stock
            </h2>
          </div>
        </div>
        <div class="col-span-12 2xl:col-span-9">
          <div class="grid grid-cols-12 gap-5">
              <div
                  v-for="(emp, index) in pagedata.employee_packets"
                  :key="index"
                  class="col-span-12 sm:col-span-4 2xl:col-span-3 box cursor-pointer zoom-in"
                >
                <div class="box px-4 py-4 mb-3 flex items-center zoom-in">
                  <div
                    class="text-lg font-medium truncate mr-3"
                  >{{ emp.total_packets }}
                  </div>
                  <div class="ml-4 mr-auto">
                    <div class="font-medium">
                      {{ emp.employee ? emp.employee : emp.rough_employee }}
                    </div>
                  </div>
                  <div
                    class="py-1 px-2 rounded-full text-xs bg-theme-9 text-white cursor-pointer font-medium"
                  >
                    {{ parseFloat(emp.total_issue_weight).toFixed(2) }} ct.
                  </div>
                </div>
              </div>
          </div>
        </div>
        <!-- BEGIN: Weekly Top Products -->
        <div class="col-span-12 mt-3">
          <div class="intro-y block sm:flex items-center h-10">
            <div class="flex items-center justify-between w-full">
              <h2 class="text-lg font-medium truncate mr-5">
              Party Packets
            </h2>
            <div class="input-group m-1 my-2">
            <div id="input-group-3" class="input-group-text text-center w-20">Date</div>
            <Litepicker
              v-model="pagedata.date"
              :options="{
                autoApply: false,
                singleMode: false,
                numberOfColumns: 2,
                numberOfMonths: 2,
                showWeekNumbers: true,
                dropdowns: {
                  minYear: 1990,
                  maxYear: null,
                  months: true,
                  years: true
                }
              }"
              class="form-control w-48 block mx-auto"
            />
          </div>
            </div>
          </div>
          <div class="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0">
            <table class="table-report w-full" v-if="pagedata.employee_processes.length > 0">
              <thead>
                <tr>
                  <th class="text-center whitespace-nowrap">#</th>
                  <th class="text-center whitespace-nowrap">Name</th>
                  <th class="text-center whitespace-nowrap">Packets</th>
                  <th class="text-center whitespace-nowrap">Issue Weight ( ct. )</th>
                  <th class="text-center whitespace-nowrap">Receive Weight ( ct. )</th>
                  <th class="text-center whitespace-nowrap">Weight Loss ( ct. )</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(emp, index) in pagedata.employee_processes"
                  :key="index"
                  class="intro-x"
                >
                  <td class="py-2 text-center whitespace-nowrap">{{ index + 1 }}</td>
                  <td class="py-2 text-center whitespace-nowrap">{{ emp.name }}</td>
                  <td class="py-2 text-center whitespace-nowrap">{{ emp.total_packets }}</td>
                  <td class="py-2 text-center whitespace-nowrap">{{ emp.total_issue_weight }}</td>
                  <td class="py-2 text-center whitespace-nowrap">{{ emp.total_receive_weight }}</td>
                  <td class="py-2 text-center whitespace-nowrap">{{ parseFloat(emp.total_loss_weight).toFixed(2) }}</td>
                </tr>
              </tbody>
            </table>
            <div class="intro-y box py-4 text-center" v-else>
                    No Employee Packets Yet !
                </div>
          </div>
          <!-- BEGIN: Pagination -->
          <div
            class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center mt-3 mb-7"
          >
            <ul class="pagination">
              <li>
                <button class="pagination__link" :disabled="pagedata.current_page == 1" @click="AdminDashboardUrl(pagedata.first_page_url)">
                  <ChevronsLeftIcon class="w-4 h-4" />
                </button>
              </li>
              <li>
                <button class="pagination__link" :disabled="pagedata.prev_page_url == null" @click="AdminDashboardUrl(pagedata.prev_page_url)">
                  <ChevronLeftIcon class="w-4 h-4" />
                </button>
              </li>
              <li v-for="(pagelink,index) in pagelinks" :key="index">
                <button class="pagination__link" :class="{'pagination__link--active' : pagelink.active == true}" v-if="pagelinks[0] != pagelink && pagelinks[pagelinks.length - 1] != pagelink" @click='AdminDashboardUrl(pagelink.url)'>{{ pagelink.label }}</button>
              </li>
              <li>
                <button class="pagination__link" :disabled="pagedata.next_page_url == null" @click="AdminDashboardUrl(pagedata.next_page_url)">
                  <ChevronRightIcon class="w-4 h-4" />
                </button>
              </li>
              <li>
                <button class="pagination__link" :disabled="pagedata.current_page == pagedata.last_page" @click="AdminDashboardUrl(pagedata.last_page_url)">
                  <ChevronsRightIcon class="w-4 h-4" />
                </button>
              </li>
            </ul>
            <select class="w-20 form-select box mt-3 sm:mt-0" v-model="pagedata.size" @change="AdminDashboard">
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
            </select>
          </div>
          <!-- END: Pagination -->
        </div>
        <!-- END: Weekly Top Products -->
      </div>
    </div>
    <div class="col-span-12">
      <div class="2xl:border-l border-theme-5 -mb-10 pb-10">
        <div class="2xl:pl-6 grid grid-cols-12 gap-6">
          <!-- BEGIN: Important Notes -->
          <div
            class="col-span-12 md:col-span-6 xl:col-span-12 xl:col-start-1 xl:row-start-1 2xl:col-start-auto 2xl:row-start-auto"
          >
            <div class="intro-x flex items-center h-10">
              <h2 class="text-lg font-medium truncate mr-auto">
                Blogs
              </h2>
              <button
                data-carousel="important-notes"
                data-target="prev"
                class="tiny-slider-navigator btn px-2 border-gray-400 text-gray-700 dark:text-gray-300 mr-2"
                @click="prevImportantNotes"
              >
                <ChevronLeftIcon class="w-4 h-4" />
              </button>
              <button
                data-carousel="important-notes"
                data-target="next"
                class="tiny-slider-navigator btn px-2 border-gray-400 text-gray-700 dark:text-gray-300 mr-2"
                @click="nextImportantNotes"
              >
                <ChevronRightIcon class="w-4 h-4" />
              </button>
            </div>
            <div class="mt-5 intro-x">
              <div class="box zoom-in">
                <TinySlider ref-key="importantNotesRef">
                  <div class="p-5">
                    <div class="text-base font-medium truncate">
                      Lorem Ipsum is simply dummy text
                    </div>
                    <div class="text-gray-500 mt-1">20 Hours ago</div>
                    <div class="text-gray-600 text-justify mt-1">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s.
                    </div>
                    <div class="font-medium flex mt-5">
                      <button type="button" class="btn btn-secondary py-1 px-2">
                        View Notes
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-secondary py-1 px-2 ml-auto ml-auto"
                      >
                        Dismiss
                      </button>
                    </div>
                  </div>
                  <div class="p-5">
                    <div class="text-base font-medium truncate">
                      Lorem Ipsum is simply dummy text
                    </div>
                    <div class="text-gray-500 mt-1">20 Hours ago</div>
                    <div class="text-gray-600 text-justify mt-1">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s.
                    </div>
                    <div class="font-medium flex mt-5">
                      <button type="button" class="btn btn-secondary py-1 px-2">
                        View Notes
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-secondary py-1 px-2 ml-auto ml-auto"
                      >
                        Dismiss
                      </button>
                    </div>
                  </div>
                  <div class="p-5">
                    <div class="text-base font-medium truncate">
                      Lorem Ipsum is simply dummy text
                    </div>
                    <div class="text-gray-500 mt-1">20 Hours ago</div>
                    <div class="text-gray-600 text-justify mt-1">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s.
                    </div>
                    <div class="font-medium flex mt-5">
                      <button type="button" class="btn btn-secondary py-1 px-2">
                        View Notes
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-secondary py-1 px-2 ml-auto ml-auto"
                      >
                        Dismiss
                      </button>
                    </div>
                  </div>
                </TinySlider>
              </div>
            </div>
          </div>
          <!-- END: Important Notes -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { defineComponent, ref, provide, onMounted, reactive, toRef, watch } from 'vue'
import ReportLineChart from '@/components/line-chart/Main.vue'
import ReportDonutChart from '@/components/report-donut-chart/Main.vue'
import ReportPieChart from '@/components/report-pie-chart/Main.vue'
import axios from 'axios'
import { BASE_URL } from '@/constants/config'
import moment from 'moment'

export default defineComponent({
  components: {
    ReportLineChart,
    ReportDonutChart,
    ReportPieChart
  },
  setup() {
    const salesReportFilter = ref()
    const importantNotesRef = ref()

    provide('bind[importantNotesRef]', el => {
      importantNotesRef.value = el
    })

    const prevImportantNotes = () => {
      const el = importantNotesRef.value
      el.tns.goTo('prev')
    }
    const nextImportantNotes = () => {
      const el = importantNotesRef.value
      el.tns.goTo('next')
    }
    const pagedata = reactive({
      headings: [],
      date: '',
      start_date: '',
      end_date: '',
      size: 5,
      employee_processes: [],
      employee_packets: [],
      pagelinks: [],
      prev_page_url: null,
      next_page_url: null,
      first_page_url: null,
      last_page_url: null,
      current_page: 1,
      last_page: '',
    })
    const pagelinks = toRef(pagedata, 'pagelinks')
    onMounted(() => {
      let start = moment().startOf('month').format('DD MMM, YYYY')
      let end = moment().endOf('month').format('DD MMM, YYYY')
      pagedata.date = start + '-' + end
    })
    function AdminDashboardUrl() {
        axios({
          url: url + `&size${pagedata.size}&s_date=${pagedata.start_date}&e_date=${pagedata.end_date}`,
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.headings = res.data.headings[0]
          pagedata.employee_packets = res.data.employee_packets
          pagedata.employee_processes = res.data.employee_processes.data
          pagedata.pagelinks = res.data.employee_processes.links
          pagedata.prev_page_url = res.data.employee_processes.prev_page_url
          pagedata.next_page_url = res.data.employee_processes.next_page_url
          pagedata.first_page_url = res.data.employee_processes.first_page_url
          pagedata.last_page_url = res.data.employee_processes.last_page_url
          pagedata.current_page = res.data.employee_processes.current_page
          pagedata.last_page = res.data.employee_processes.last_page
        })
    }
    function AdminDashboard() {
        axios({
          url: `/dashboard/manager?size=${pagedata.size}&s_date=${pagedata.start_date}&e_date=${pagedata.end_date}`,
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.headings = res.data.headings[0]
          pagedata.employee_packets = res.data.employee_packets
          pagedata.employee_processes = res.data.employee_processes.data
          pagedata.pagelinks = res.data.employee_processes.links
          pagedata.prev_page_url = res.data.employee_processes.prev_page_url
          pagedata.next_page_url = res.data.employee_processes.next_page_url
          pagedata.first_page_url = res.data.employee_processes.first_page_url
          pagedata.last_page_url = res.data.employee_processes.last_page_url
          pagedata.current_page = res.data.employee_processes.current_page
          pagedata.last_page = res.data.employee_processes.last_page
        })
    }
    watch(() => pagedata.date, (currentDate) => {
        if(currentDate) {
          let divide = pagedata.date.split("-")
          pagedata.start_date = moment(divide[0]).format('YYYY-MM-DD');
          pagedata.end_date = moment(divide[1]).format('YYYY-MM-DD');
          AdminDashboard()
        }
      })
    return {
      pagedata,
      pagelinks,
      salesReportFilter,
      prevImportantNotes,
      nextImportantNotes,
      AdminDashboard,
      AdminDashboardUrl
    }
  }
})
</script>

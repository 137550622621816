<template>
  <div>
    <div class="pos intro-y grid">
      <h2 class="text-lg font-medium mr-auto">Department Report</h2>
    </div>
    <!-- BEgin: Process Report Start-->
    <div class="intro-y box p-5 mt-5">
      <div class="overflow-x-auto scrollbar-hidden">
        <div class="intro-y col-span-12 overflow-auto">
          <table class="mt-2 w-full border-2">
            <thead>
              <tr class="bg-theme-1 text-white">
                <th class="py-2 text-xs whitespace-nowrap">#</th>
                <th class="py-2 text-xs whitespace-nowrap">Process</th>
                <th class="py-2 text-xs whitespace-nowrap">Packets</th>
                <th class="py-2 text-xs whitespace-nowrap">Weight</th>
              </tr>
            </thead>
            <tbody>
              <tr class="bg-gray-200 text-gray-700">
                <td class="py-2 border-b text-xs text-center"></td>
                <td class="py-2 border-b text-xs text-center">{{ pagedata.total_processes }}</td>
                <td class="py-2 border-b text-xs text-center">{{ pagedata.total_packets }}</td>
                <td class="py-2 border-b text-xs text-center">{{ parseFloat(pagedata.total_weight).toFixed(2) }}</td>
              </tr>
              <tr v-for="(packet, index) in pagedata.packets" :key="index" @click="getEmployeePkt(packet.id)" class="cursor-pointer">
                <td class="py-2 border-b text-xs text-center">{{ index + 1 }}</td>
                <td class="py-2 border-b text-xs text-center">{{ packet.department }}</td>
                <td class="py-2 border-b text-xs text-center">{{ packet.total_packets }}</td>
                <td class="py-2 border-b text-xs text-center">{{ parseFloat(packet.total_weight == null ? 0 : packet.total_weight).toFixed(2) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config';
import axios from 'axios';
import cash from 'cash-dom';
import { defineComponent, onMounted, reactive, toRef, watch } from 'vue';

export default defineComponent({
  setup() {
    const pagedata = reactive({
      packets: [],
      total_weight: null,
      total_packets: null,
      employee_total_weight: null,
      manager_total_weight: null,
    })
    onMounted(() => {
      getAvailPkt()
    })
    function getAvailPkt() {
       axios({
        url: `/report/department_report`,
        method: 'get',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.packets = res.data.departments
        pagedata.total_packets = res.data.total[0].total_packets
        pagedata.total_weight = res.data.total[0].total_weight
      })
    }
    return {
      pagedata
    }
  }
})
</script>

<template>
  <div>
    <!-- BEGIN: Account Menu -->
    <div class="intro-x dropdown">
      <div
        class="dropdown-toggle"
        role="button"
        aria-expanded="false"
      >
        {{ name }} <ChevronDownIcon class="w-3 h-3" />
      </div>
      <div class="dropdown-menu">
        <div
          class="dropdown-menu__content box"
        >
        <!-- <select class="select" v-model="pagedata.selected" multiple>
          <option :value="option.id" v-for="(option, index) in options" :key="index">{{ name == 'color' ? option.color_name : (name == 'cut' ? option.cut_name : (name == 'purity' ? option.purity_name : (name == 'polish' ? option.polish_name : (name == 'shape' ? option.shape_name : (name == 'symmetry' ? option.symmetry_name : (name == 'fluerocent' ? option.fluerocent_name : (name == 'tension' ? option.tension_name : option.stone_name))))))) }}</option>
        </select>
        <div v-for="(option, index) in options" :key="index" class="p-2 border-b border-theme-2 dark:border-dark-3">
          <input type="checkbox" :id="index" :value="option.id" v-model="pagedata.selected">
          <label :for="index">{{ name == 'color' ? option.color_name : (name == 'cut' ? option.cut_name : (name == 'purity' ? option.purity_name : (name == 'polish' ? option.polish_name : (name == 'shape' ? option.shape_name : (name == 'symmetry' ? option.symmetry_name : (name == 'fluerocent' ? option.fluerocent_name : (name == 'tension' ? option.tension_name : option.stone_name))))))) }}</label>
        </div> -->
          <ul class="p-2">
            <li v-for="(option, index) in options" :key="index" class="flex items-center justify-around w-16">
              <input type="checkbox" :id="index" :value="option.id" v-model="pagedata.selected">
              <label :for="index">{{ name == 'color' ? option.color_name : (name == 'cut' ? option.cut_name : (name == 'purity' ? option.purity_name : (name == 'polish' ? option.polish_name : (name == 'shape' ? option.shape_name : (name == 'symmetry' ? option.symmetry_name : (name == 'fluerocent' ? option.fluerocent_name : (name == 'tension' ? option.tension_name : (name == 'stone' ? option.stone_name : '')))))))) }}</label>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- END: Account Menu -->
    <!-- <div class="dropdown" @click="showDropdown">
      {{ name }} <ChevronDownIcon class="w-4 h-4"></ChevronDownIcon>
    </div>
    <div class="multiselect" v-if="pagedata.show">
      <ul>
        <li v-for="(option, index) in options" :key="index" class="flex items-center justify-around">
          <input type="checkbox" :id="index" :value="option.id" v-model="pagedata.selected">
          <label :for="index">{{ name == 'color' ? option.color_name : (name == 'cut' ? option.cut_name : (name == 'purity' ? option.purity_name : (name == 'polish' ? option.polish_name : (name == 'shape' ? option.shape_name : (name == 'symmetry' ? option.symmetry_name : (name == 'fluerocent' ? option.fluerocent_name : (name == 'tension' ? option.tension_name : option.stone_name))))))) }}</label>
        </li>
      </ul>
    </div> -->
  </div>
</template>
<script>
/* eslint-disable */
import { defineComponent, reactive, watch } from 'vue'
  export default defineComponent({
  props: ['options','name'],
  emits: ['getData','updatParameters','showNotification'],
  setup(props, {emit}) {
    const pagedata = reactive({
      show: false,
      selected: []
    })
    function showDropdown() {
      pagedata.show = !pagedata.show
    }
    watch(() => pagedata.selected, (currentValue) => {
      emit('onChecked', currentValue)
    })
    return {
      showDropdown,
      pagedata
    }
  }
  })
</script>
<style scoped>
.dropdown {
  cursor: pointer;
}

.multiselect {
  position: relative;

}
.multiselect ul {
    background: #fff;
    color: black;
    border: 1px solid #ddd;
    border-top: 0;
    border-radius: 0 0 3px 3px;
    left: 0px;
    padding: 4px 4px;
    position: absolute;
    top: 1rem;
    /* width: 50px; */
    list-style: none;
    max-height: 150px;
    overflow: auto;
  }
.overselect {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>

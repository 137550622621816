<template>
  <tr :class="{'text-theme-6' : packet.blocked == 1 }">
    <td class="text-center text-sm py-2">{{ index + 1 }}</td>
    <td class="text-center text-sm py-2">{{ packet.t_id }}</td>
    <td class="text-center text-sm py-2">{{ packet.process }}</td>
    <td class="text-center text-sm py-2">{{ packet.department }}</td>
    <td class="text-center text-sm py-2">{{ packet.manager }}</td>
    <td class="text-center text-sm py-2">{{ packet.employee }}</td>
    <td class="text-center text-sm py-2">{{ packet.issue_weight }}</td>
    <td class="text-center text-sm py-2"><input v-if="pagedata.editable" type="number" class="form-control w-16 text-center" v-model="changes.weight"><div v-else>{{ packet.receive_weight }}</div></td>
    <td class="text-center text-sm py-2 w-6">{{ packet.issue_date }}</td>
    <td class="text-center text-sm py-2 w-6">{{ packet.receive_date }}</td>
    <td class="text-center text-sm py-2">
      <div v-if="packet.shape">
        <div class="input-group mt-2 sm:mt-0" v-if="pagedata.editable">
          <select
            class="form-select w-14"
            v-model="changes.shape"
            aria-label="Default select example"
          >
            <option v-for="col in diamond.shapes" :key="col.id" :value="col.id">{{ col.name }}</option>
          </select>
        </div>
        <div v-else>
          {{ packet.shape.name }}
        </div>
      </div>
    </td>
    <td class="text-center text-sm py-2">
      <div class="input-group mt-2 sm:mt-0" v-if="pagedata.editable">
        <select
          class="form-select w-14"
          v-model="changes.color"
          aria-label="Default select example"
        >
          <option v-for="col in diamond.colors" :key="col.id" :value="col.id">{{ col.name }}</option>
        </select>
      </div>
      <div v-else>
        {{ packet.color.name }}
      </div>
    </td>
    <td class="text-center text-sm py-2">
      <div class="input-group mt-2 sm:mt-0" v-if="pagedata.editable">
        <select
          class="form-select w-14"
          v-model="changes.purity"
          aria-label="Default select example"
        >
          <option v-for="col in diamond.purities" :key="col.id" :value="col.id">{{ col.name }}</option>
        </select>
      </div>
      <div v-else>
        {{ packet.purity.name }}
      </div>
    </td>
    <td class="text-center text-sm py-2">
      <div v-if="packet.cut">
        <div class="input-group mt-2 sm:mt-0" v-if="pagedata.editable">
          <select
            class="form-select w-14"
            v-model="changes.cut"
            aria-label="Default select example"
          >
            <option v-for="col in diamond.cuts" :key="col.id" :value="col.id">{{ col.name }}</option>
          </select>
        </div>
        <div v-else>
          {{ packet.cut.name }}
        </div>
      </div>
    </td>
    <td class="text-center text-sm py-2">
      <div v-if="packet.polish">
        <div class="input-group mt-2 sm:mt-0" v-if="pagedata.editable">
          <select
            class="form-select w-14"
            v-model="changes.polish"
            aria-label="Default select example"
          >
            <option v-for="col in diamond.polish" :key="col.id" :value="col.id">{{ col.name }}</option>
          </select>
        </div>
        <div v-else>
          {{ packet.polish.name }}
        </div>
      </div>
    </td>
    <td class="text-center text-sm py-2">
      <div v-if="packet.symmetry">
        <div class="input-group mt-2 sm:mt-0" v-if="pagedata.editable">
          <select
            class="form-select w-14"
            v-model="changes.symmetry"
            aria-label="Default select example"
          >
            <option v-for="col in diamond.symmetry" :key="col.id" :value="col.id">{{ col.name }}</option>
          </select>
        </div>
        <div v-else>
          {{ packet.symmetry.name }}
        </div>
      </div>
    </td>
    <td class="text-center text-sm py-2" v-if="packet.fluerocent">
      <div class="input-group mt-2 sm:mt-0" v-if="pagedata.editable">
        <select
          class="form-select w-14"
          v-model="changes.fluerocent"
          aria-label="Default select example"
        >
          <option v-for="col in diamond.fluerocents" :key="col.id" :value="col.id">{{ col.name }}</option>
        </select>
      </div>
      <div v-else>
        {{ packet.fluerocent.name }}
      </div>
    </td>
    <td class="text-center text-sm py-2">
      <div v-if="packet.tension">
        <div class="input-group mt-2 sm:mt-0" v-if="pagedata.editable">
          <select
            class="form-select w-14"
            v-model="changes.tension"
            aria-label="Default select example"
          >
            <option v-for="col in diamond.tensions" :key="col.id" :value="col.id">{{ col.name }}</option>
          </select>
        </div>
        <div v-else>
          {{ packet.tension.name }}
        </div>
      </div>
    </td>
    <td class="text-center text-sm py-2">
      <div class="input-group mt-2 sm:mt-0" v-if="pagedata.editable">
        <select
          class="form-select w-14"
          v-model="changes.stone"
          aria-label="Default select example"
        >
          <option v-for="col in diamond.stones" :key="col.id" :value="col.id">{{ col.name }}</option>
        </select>
      </div>
      <div v-else>
        {{ packet.stone.name }}
      </div>
    </td>
    <td class="text-center text-sm py-2">
      <div class="flex justify-center items-center" v-if="packet.status == 0 && packet.employee == user_id && packet.type != 'rough'">
        <button title="Edit" class="btn btn-success mr-1" :disabled="packet.change_status == 0 || packet.rate_status == 0" @click="$emit('getDiamondDetail',packet.id)" v-if="packetDet.status == 2">
          R
        </button>
        <button
          class="btn btn-danger mr-1" :disabled="packet.change_status == 0 || packet.rate_status == 0" @click="$emit('cancelProcess')"  v-if="packetDet.status == 2"
        >
          X
        </button>
      </div>
      <div v-else-if="packet.external == 1 && packet.employee == user_id && packet.type != 'rough'">
        <button
          class="btn btn-primary mr-1 p-2" @click="pagedata.editable = !pagedata.editable" v-if="pagedata.editable == false" :disabled="packet.status == 3"
        >
          <ExternalLinkIcon class="h-4 w-4" />
        </button>
        <div v-else>
          <button class="btn bg-primary-9 text-white mr-1 p-2"><SaveIcon class="w-4 h-4" @click="updateParameters"/></button>
          <button class="btn btn-danger mr-1 p-2" @click="pagedata.editable = !pagedata.editable" v-if="pagedata.editable == true" :disabled="packet.status == 3"><XIcon class="h-4 w-4" /></button>
        </div>
      </div>
    </td>
  </tr>
  <div id="basic-non-sticky-notification-packet-update" class="toastify-content hidden flex flex-col sm:flex-row">
      <div class="flex items-center justify-around">
        <CheckCircleIcon class="w-6 h-6 text-theme-9 mx-auto" />
        <div class="font-medium text-black" v-if="pagedata.success">{{ pagedata.success }}</div>
      </div>
  </div>
</template>

<script>
/* eslint-disable */
import { computed, defineComponent, onMounted, reactive, watch} from 'vue'
import Toastify from 'toastify-js'
import axios from 'axios'
import { BASE_URL } from '@/constants/config'
import store from '@/store'
export default defineComponent({
  props: ['packet', 'index', 'diamond','packetDet'],
  emits: ['getDiamondDetail','updatParameters','getData','cancelProcess'],
  setup(props, {emit}) {
    const changes = reactive({
      id: props.packet.id,
      weight: props.packet.receive_weight,
      color: props.packet.color.id,
      cut: props.packet.cut.id,
      purity: props.packet.purity.id,
      polish: props.packet.polish.id,
      shape: props.packet.shape.id,
      symmetry: props.packet.symmetry.id,
      fluerocent: props.packet.fluerocent.id,
      stone: props.packet.stone.id,
      tension: props.packet.tension.id,
    })
    const user_id = store.state.user.data.id
    const issue = computed(() => props.packet.issue_weight)
    const receive = computed(() => props.packet.receive_weight)
    const pagedata = reactive({
      editable: props.packet.issue_weight < props.packet.receive_weight ? true:false,
      error: '',
      success: ''
    })
    function updateParameters() {
      if(changes.color == props.packet.color.id) {
        if(changes.cut == props.packet.cut.id) {
          if(changes.purity == props.packet.purity.id) {
            if(changes.polish == props.packet.polish.id) {
              if(changes.shape == props.packet.shape.id) {
                if(changes.symmetry == props.packet.symmetry.id) {
                  if(changes.fluerocent == props.packet.fluerocent.id) {
                    if(changes.stone == props.packet.stone.id) {
                      if(changes.tension == props.packet.tension.id) {
                        if(parseFloat(changes.weight) == parseFloat(props.packet.receive_weight)) {
                          UpdateWeight()
                              return
                        } else if(parseFloat(changes.weight) < parseFloat(props.packet.issue_weight)) {
                          if(props.packetDet.admin_pwt > 0) {
                            if(props.packetDet.admin_pwt > changes.weight) {
                              emit('updatParameters',changes)
                              pagedata.editable = false
                              return
                            } else {
                              UpdateWeight()
                              return
                            }
                          } else {
                            if(parseFloat(props.packetDet.pwt) > parseFloat(changes.weight)) {
                              emit('updatParameters',changes)
                              pagedata.editable = false
                              return
                            } else {
                              UpdateWeight()
                              return
                            }
                          }
                        } else {
                          UpdateWeight()
                              return
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      emit('updatParameters',changes)
      pagedata.editable = false
    }
    function UpdateWeight() {
      axios({
        url: `/process/update-middle-proc-weight/${changes.id}`,
        method: 'post',
        baseURL: BASE_URL,
        data: { weight: changes.weight},
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      }).then((res) => {
        pagedata.success = res.data.success
        setTimeout(() => {
          basicNonStickyNotificationPacketUpdate()
        }, 200);
          pagedata.editable = false
        emit('getData')
      }).catch(() => {
      })
    }
    const basicNonStickyNotificationPacketUpdate = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification-packet-update')
          .clone()
          .removeClass('hidden')[0],
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    watch(() => issue.value, (newValue,OldValue) => {
      if(receive.value > issue.value) {
        pagedata.editable = true
      }
    })
    return {
      changes,
      pagedata,
      user_id,
      updateParameters,
      basicNonStickyNotificationPacketUpdate
    }
  }
})
</script>

<style scoped>
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type=number] {
            -moz-appearance: textfield;
        }

        .input-group .input-group-text-custom {
            border-width: 1px;
            --tw-border-opacity: 1;
            border-color: rgba(226, 232, 240, var(--tw-border-opacity));
            --tw-bg-opacity: 1;
            background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
            padding-top: 0.1rem;
            padding-bottom: 0.1rem;
            padding-left: 1rem;
            padding-right: 1rem;
            --tw-text-opacity: 1;
            color: rgba(113, 128, 150, var(--tw-text-opacity));
            --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
            box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
        }
    </style>

<template>
    <div>
      <div id="basic-non-sticky-notification-packet" class="toastify-content hidden flex flex-col sm:flex-row">
        <div class="font-medium text-primary-3" v-if="pagedata.errors.error">{{ pagedata.errors.error }}</div>
        <div class="flex items-center justify-around" v-if="pagedata.success">
            <CheckCircleIcon class="w-6 h-6 text-theme-9 mx-auto" />
            <div class="font-medium text-black">{{ pagedata.success }}</div>
          </div>
      </div>
      <div class="intro-y flex items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Create Jober Rate</h2>
      </div>
      <div class="grid grid-cols-12 gap-6 mt-5">
        <div class="intro-y col-span-12 lg:col-span-8">
          <!-- BEGIN: Input -->
          <div class="intro-y box">
            <div id="input" class="p-5">
              <div class="preview">
                <div class="sm:grid grid-cols-2 gap-2">
                  <div>
                    <select
                      class="form-select"
                      v-model="rateData.process_type"
                      aria-label="Default select example"
                      @change="getProcessData"
                    >
                      <option value="makeable">Makeable</option>
                      <option value="rough">Rough</option>
                      <option value="video">Video</option>
                      <option value="lab">LAB</option>
                    </select>
                  </div>
                  <div>
                    <div>
                      <div v-if="pagedata.endis">
                        <TomSelect
                          v-model="rateData.process"
                              class="w-full" disabled
                            >
                          <option value="0">Select Process</option>
                          <option v-for="process in pagedata.processes" :key="process.id" :value="process.id">{{ process.process_name }}</option>
                        </TomSelect>
                      </div>
                      <div v-else>
                        <TomSelect @change="getProcessType"
                          v-model="rateData.process" :disabled="pagedata.endis"
                              class="w-full"
                            >
                          <option value="0">Select Process</option>
                          <option v-for="process in pagedata.processes" :key="process.id" :value="process.id">{{ process.process_name }}</option>
                        </TomSelect>
                      </div>
                    </div>
                    <div class="text-primary-3" v-if="pagedata.errors.process">{{ pagedata.errors.process[0] }}</div>
                  </div>
                </div>
                <div class="flex items-center mt-3">
                  <div class="form-check">
                    <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer" value="1" type="radio" name="flexRadioDefault" id="flexRadioDefault1" v-model="rateData.type">
                    <label class="form-check-label inline-block text-lg" for="flexRadioDefault1">
                      Standard Rates
                    </label>
                  </div>
                  <div class="form-check ml-6">
                    <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer" value="2" type="radio" name="flexRadioDefault" id="flexRadioDefault2" v-model="rateData.type">
                    <label class="form-check-label inline-block text-lg" for="flexRadioDefault2">
                      Fixed Per Piece
                    </label>
                  </div>
                </div>
                <div class="mt-3">
                  <div class="sm:grid grid-cols-2 gap-2">
                    <div>
                      <div class="input-group mt-2 sm:mt-0">
                        <div id="input-group-3" class="input-group-text w-36 text-center">From</div>
                        <input
                          type="number"
                          class="form-control"
                          v-model="rateData.from"
                          placeholder="From"
                          aria-describedby="input-group-3"
                        />
                        <div class="flex-col">
                          <button class="btn input-group-text-custom px-3" @click="IncrementWeight('increment','From')"><ChevronUpIcon class="w-4 h-4" /></button>
                          <button class="btn input-group-text-custom px-3" :disabled="pagedata.fromdis" @click="IncrementWeight('decrement','From')"><ChevronDownIcon class="w-4 h-4" /></button>
                        </div>
                      </div>
                      <div class="text-primary-3" v-if="pagedata.errors.from">{{ pagedata.errors.from[0] }}</div>
                    </div>
                    <div>
                      <div class="input-group">
                        <div id="input-group-3" class="input-group-text w-36 text-center">To</div>
                          <input
                            type="number"
                            class="form-control"
                            v-model="rateData.to"
                            placeholder="To"
                            aria-describedby="input-group-3"
                          />
                          <div class="flex-col">
                          <button class="btn input-group-text-custom px-3" @click="IncrementWeight('increment','To')"><ChevronUpIcon class="w-4 h-4" /></button>
                          <button class="btn input-group-text-custom px-3" :disabled="pagedata.todis" @click="IncrementWeight('decrement','To')"><ChevronDownIcon class="w-4 h-4" /></button>
                        </div>
                      </div>
                      <div class="text-primary-3" v-if="pagedata.errors.to">{{ pagedata.errors.to[0] }}</div>
                    </div>
                  </div>
                </div>
                <div class="mt-3">
                  <div class="sm:grid grid-cols-2 gap-2">
                    <div>
                      <div class="input-group mt-2 sm:mt-0">
                        <div id="input-group-3" class="input-group-text w-32 text-center">Type</div>
                        <TomSelect
                          v-model="rateData.rate_type"
                          id="crud-form-2" disabled
                          :options="{
                            placeholder: 'Select your Labour'
                          }"
                          class="w-full"
                        >
                          <option value="1">Issue Weight</option>
                          <option value="2">Fixed Piece</option>
                          <option value="3">Weight + Weight</option>
                          <option value="4">Piece + Piece</option>
                          <option value="5">Weight Loss</option>
                        </TomSelect>
                      </div>
                      <div class="text-primary-3" v-if="pagedata.errors.rate_type">{{ pagedata.errors.rate_type[0] }}</div>
                    </div>
                    <div>
                      <div class="input-group">
                        <div id="input-group-3" class="input-group-text w-36 text-center">Rate</div>
                        <input
                          type="number"
                          class="form-control"
                          v-model="rateData.rate"
                          placeholder="Rate"
                          aria-describedby="input-group-4"
                        />
                      </div>
                      <div class="text-primary-3" v-if="pagedata.errors.rate">{{ pagedata.errors.rate[0] }}</div>
                    </div>
                  </div>
                </div>
                <div class="mt-3">
                  <label class="form-label">Joining Date & End Date</label>
                  <div class="sm:grid grid-cols-2 gap-2">
                    <div>
                      <div class="input-group">
                        <div id="input-group-3" class="input-group-text w-36">Joining</div>
                        <Litepicker
                            v-model="rateData.s_date"
                            :options="{
                              autoApply: false,
                              showWeekNumbers: true,
                              dropdowns: {
                                minYear: 1990,
                                maxYear: 2025,
                                months: true,
                                years: true,
                              },
                            }"
                            class="form-control block mx-auto"
                          />
                      </div>
                      <div class="text-primary-3" v-if="pagedata.errors.s_date">{{ pagedata.errors.s_date[0] }}</div>
                    </div>
                    <div>
                      <div class="input-group mt-2 sm:mt-0">
                        <div id="input-group-3" class="input-group-text w-36">End</div>
                        <Litepicker
                            v-model="rateData.e_date"
                            :options="{
                              autoApply: false,
                              showWeekNumbers: true,
                              dropdowns: {
                                minYear: 1990,
                                maxYear: 2025,
                                months: true,
                                years: true,
                              },
                            }"
                            class="form-control block mx-auto"
                          />
                      </div>
                      <div class="text-primary-3" v-if="pagedata.errors.e_date">{{ pagedata.errors.e_date[0] }}</div>
                    </div>
                  </div>
                </div>
                <div class="grid grid-cols-12 gap-6 mt-5">
                <div class="intro-y col-span-12 md:col-span-6">
                  <div class="mt-3" v-if="rateData.process_type != 'rough'">
                    <div class="input-group mt-2 sm:mt-0">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Color</div>
                      <TomSelect
                        v-model="rateData.color"
                            :options="{
                              placeholder: 'Select Colors'
                            }"
                            class="w-full"
                          multiple
                          >
                        <option value="All">All</option>
                        <option v-for="col in pagedata.colors" :key="col.id" :value="col.id">{{ col.color_name }}</option>
                      </TomSelect>
                    </div>
                    <div class="text-primary-3" v-if="pagedata.errors.color">{{ pagedata.errors.color[0] }}</div>
                  </div>
                  <div class="mt-3" v-if="rateData.process_type != 'rough'">
                    <div class="input-group">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Purity</div>
                        <TomSelect
                          v-model="rateData.purity"
                            :options="{
                              placeholder: 'Select Purity'
                            }"
                            class="w-full"
                          multiple
                          >
                        <option value="All">All</option>
                        <option v-for="col in pagedata.purities" :key="col.id" :value="col.id">{{ col.purity_name }}</option>
                      </TomSelect>
                    </div>
                  <div class="text-primary-3" v-if="pagedata.errors.purity">{{ pagedata.errors.purity[0] }}</div>
                  </div>
                  <div class="mt-3" v-if="rateData.process_type != 'rough'">
                    <div class="input-group mt-2 sm:mt-0">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Shape</div>
                      <TomSelect
                        v-model="rateData.shape"
                            :options="{
                              placeholder: 'Select your favorite actors'
                            }"
                            class="w-full"
                          multiple
                          >
                        <option value="All">All</option>
                        <option v-for="col in pagedata.shapes" :key="col.id" :value="col.id">{{ col.shape_name }}</option>
                      </TomSelect>
                    </div>
                    <div class="text-primary-3" v-if="pagedata.errors.shape">{{ pagedata.errors.shape[0] }}</div>
                  </div>
                  <div class="mt-3" v-if="rateData.process_type != 'rough'">
                    <div class="input-group mt-2 sm:mt-0">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Flurocent</div>
                      <TomSelect
                        v-model="rateData.fluerocent"
                            :options="{
                              placeholder: 'Select your favorite actors'
                            }"
                            class="w-full"
                          multiple
                          >
                        <option value="All">All</option>
                        <option v-for="col in pagedata.fluerocents" :key="col.id" :value="col.id">{{ col.fluerocent_name }}</option>
                      </TomSelect>
                    </div>
                     <div class="text-primary-3" v-if="pagedata.errors.fluerocent">{{ pagedata.errors.fluerocent[0] }}</div>
                  </div>
                  <div class="mt-3">
                    <div class="input-group mt-2 sm:mt-0">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Stone</div>
                      <TomSelect
                        v-model="rateData.stone"
                            :options="{
                              placeholder: 'Select your favorite actors'
                            }"
                            class="w-full"
                          multiple
                          >
                        <option value="All">All</option>
                        <option v-for="col in pagedata.stones" :key="col.id" :value="col.id">{{ col.stone_name }}</option>
                      </TomSelect>
                    </div>
                    <div class="text-primary-3" v-if="pagedata.errors.stone">{{ pagedata.errors.stone[0] }}</div>
                  </div>
                </div>
                <div class="intro-y col-span-12 md:col-span-6">
                  <div class="mt-3" v-if="rateData.process_type != 'rough'">
                    <div class="input-group">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Cut</div>
                      <TomSelect
                        v-model="rateData.cut"
                            :options="{
                              placeholder: 'Select Cut'
                            }"
                            class="w-full"
                          multiple
                          >
                        <option value="All">All</option>
                        <option v-for="col in pagedata.cuts" :key="col.id" :value="col.id">{{ col.cut_name }}</option>
                      </TomSelect>
                    </div>
                    <div class="text-primary-3" v-if="pagedata.errors.cut">{{ pagedata.errors.cut[0] }}</div>
                  </div>
                  <div class="mt-3" v-if="rateData.process_type != 'rough'">
                    <div class="input-group mt-2 sm:mt-0">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Polish</div>
                      <TomSelect
                        v-model="rateData.polish"
                            :options="{
                              placeholder: 'Select your favorite actors'
                            }"
                            class="w-full"
                          multiple
                          >
                        <option value="All">All</option>
                        <option v-for="col in pagedata.polish" :key="col.id" :value="col.id">{{ col.polish_name }}</option>
                      </TomSelect>
                    </div>
                    <div class="text-primary-3" v-if="pagedata.errors.polish">{{ pagedata.errors.polish[0] }}</div>
                  </div>
                  <div class="mt-3" v-if="rateData.process_type != 'rough'">
                    <div class="input-group">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Symmerty</div>
                      <TomSelect
                        v-model="rateData.symmetry"
                            :options="{
                              placeholder: 'Select Symmetry'
                            }"
                            class="w-full"
                          multiple
                          >
                        <option value="All">All</option>
                        <option v-for="col in pagedata.symmetry" :key="col.id" :value="col.id">{{ col.symmetry_name }}</option>
                      </TomSelect>
                    </div>
                    <div class="text-primary-3" v-if="pagedata.errors.symmetry">{{ pagedata.errors.symmetry[0] }}</div>
                  </div>
                  <div class="mt-3" v-if="rateData.process_type == 'makeable'">
                    <div class="input-group">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Tension</div>
                      <TomSelect
                        v-model="rateData.tension"
                            :options="{
                              placeholder: 'Select your favorite actors'
                            }"
                            class="w-full"
                          multiple
                          >
                        <option value="All">All</option>
                        <option v-for="col in pagedata.tensions" :key="col.id" :value="col.id">{{ col.tension_name }}</option>
                      </TomSelect>
                    </div>
                     <div class="text-primary-3" v-if="pagedata.errors.tension">{{ pagedata.errors.tension[0] }}</div>
                  </div>
                  <div class="mt-3">
                    <div class="input-group mt-2 sm:mt-0">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Processing</div>
                      <select
                        class="form-select"
                        v-model="rateData.processing"
                        aria-label="Default select example"
                      >
                        <option value="all">All</option>
                        <option value="normal">Normal</option>
                        <option value="special">Special</option>
                        <option value="lab">LAB</option>
                      </select>
                    </div>
                  </div>
                </div>
                </div>
                <div class="text-right mt-3">
                      <router-link :to="{ name: 'notification-employee' }" class="btn btn-outline-secondary w-24 mr-1">
                        Cancel
                      </router-link>
                      <button type="button" @click="SaveJoberRate" :disabled="pagedata.submit" class="btn btn-primary w-24">Save
                        <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
                      </button>
                    </div>
              </div>
            </div>
          </div>
          <!-- END: Input -->
        </div>
      </div>
    </div>
  </template>

  <script>
  /* eslint-disable */
  import { BASE_URL } from '@/constants/config'
  import router from '@/router'
  import { useRoute } from 'vue-router'
  import axios from 'axios'
  import Toastify from 'toastify-js'
  import { defineComponent, onMounted, reactive, toRef, watch } from 'vue'
  import moment from 'moment'
  import cash from 'cash-dom'
  import { stringify } from 'json5'
  export default defineComponent({
    setup() {
      const pagedata = reactive({
        submit: false,
        endis: false,
        processes: [],
        errors: [],
        success: '',
        colors: [],
        cuts: [],
        purities: [],
        polish: [],
        shapes: [],
        stones: [],
        tensions: [],
        fluerocents: [],
        symmetry: [],
        fromdis: true,
        todis: true
      })
      const route = useRoute()
      const rateData = reactive({
        id: '',
        process: '0',
        user_type: 'Jober',
        from: 0,
        to: 0.01,
        rate: '',
        rate_type: '1',
        color: '',
        cut: '',
        purity: '',
        polish: '',
        shape: '',
        symmetry: '',
        stone: '',
        tension: '',
        fluerocent: '',
        s_date: '',
        e_date: '',
        processing: 'all',
        process_type: 'makeable',
        type: '1',
        jober_id: ''
      })
      onMounted(() => {
        getProcessRates()
        getColorDetails()
        if(route.params.id) {
          rateData.id = route.params.id
        }
      })
      function getColorDetails() {
        axios({
          url: 'api/master/get_all_parameters',
          method: 'post',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.cuts = res.data.cut
          pagedata.colors = res.data.color
          pagedata.purities = res.data.purity
          pagedata.polish = res.data.polish
          pagedata.shapes = res.data.shape
          pagedata.symmetry = res.data.symmetry
          pagedata.fluerocents = res.data.fluerocent
          pagedata.tensions = res.data.tension
          pagedata.stones = res.data.stone
        })
      }
      function getProcessData() {
        if(rateData.process_type == 'video') {
        rateData.tension = []
      } else if(rateData.process_type == 'rough') {
        rateData.color = []
        rateData.cut = []
        rateData.purity = []
        rateData.polish = []
        rateData.shape = []
        rateData.symmetry = []
        rateData.tension = []
        rateData.fluerocent = []
      }
        const params = {
          process_type : rateData.process_type
        }
        rateData.process = '0'
        axios({
          url: 'api/master/get_process_by_type',
          method: 'post',
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.processes = res.data.items
        })
      }
      function SaveJoberRate() {
        if(rateData.process == '0') {

        } else if(rateData.process_type) {

          rateData.s_date = moment(rateData.s_date).format('YYYY-MM-DD');
          rateData.e_date = moment(rateData.e_date).format('YYYY-MM-DD');
          pagedata.submit = true
          axios({
            url: 'api/master/add_update_new_process_rate_jober',
            method: 'post',
            baseURL: BASE_URL,
            data: rateData,
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
            config: { headers: { 'Accept':'application/json' } }
          }).then((res) => {
            pagedata.submit = false
            pagedata.success = res.data.success
            setTimeout(() => {
              basicNonStickyNotificationPacket()
            }, 20);
            UnBlockPacket()
            router.push({ name: 'notification-employee' })
          }).catch((err) => {
            pagedata.errors = err.response.data.errors
            setTimeout(() => {
              pagedata.errors = []
            }, 5000);
            pagedata.submit = false
          })

        }
      }
      function UnBlockPacket() {
      axios({
        url: 'api/request/unlock_packet_item',
        method: 'post',
        baseURL: BASE_URL,
        data: {id : route.params.id},
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      })
    }
      const basicNonStickyNotificationPacket = () => {
        Toastify({
          node: cash('#basic-non-sticky-notification-packet')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
      }
      function IncrementWeight(type,name) {
        if(name == 'From') {
          if(type == 'increment') {
            rateData.from = parseFloat((rateData.from + 0.01).toFixed(2))
           }else {
             rateData.from = parseFloat((rateData.from - 0.01).toFixed(2))
           }
        } else if(name == 'To') {
          if(type == 'increment') {
            rateData.to = parseFloat((rateData.to + 0.01).toFixed(2))
           }else {
             rateData.to = parseFloat((rateData.to - 0.01).toFixed(2))
           }
        }
      }
      function getProcessRates() {
        axios({
            url: 'api/request/get_employee_params',
            method: 'post',
            baseURL: BASE_URL,
            data: {id : route.params.id},
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
            config: { headers: { 'Accept':'application/json' } }
          }).then((res) => {
             rateData.process_type = res.data.rates.process_type
            getProcessData()

            rateData.process = stringify(res.data.rates.process)
            rateData.from = parseFloat(res.data.rates.weight)
            rateData.to = parseFloat(res.data.rates.weight)
            rateData.jober_id = window.btoa(res.data.rates.jober_id)
            rateData.id = window.btoa(res.data.rates.jober_id)

            getProcessType()

            setTimeout(() => {
            if(rateData.process_type == 'makeable') {
              rateData.tension = [res.data.rates.tension]
            }
            if(rateData.process_type != 'rough') {
              rateData.color = [res.data.rates.color]
              rateData.cut = [res.data.rates.cut]
              rateData.purity = [res.data.rates.purity]
              rateData.polish = [res.data.rates.polish]
              rateData.shape = [res.data.rates.shape]
              rateData.symmetry = [res.data.rates.symmetry]
              rateData.fluerocent = [res.data.rates.fluerocent]
            }
            rateData.stone = [res.data.rates.stone]

            })
          })
      }
      function getProcessType() {
        const params = {
          process_id : rateData.process
        }
        if(rateData.process != '0') {

          axios({
            url: 'api/master/get_process_type',
            method: 'post',
            baseURL: BASE_URL,
            data: params,
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
            config: { headers: { 'Accept':'application/json' } }
          }).then((res) => {
            rateData.rate_type = stringify(res.data.process_type)
          })
        }
      }
      watch(() => rateData.color , (CurrentValue, OldValue) => {
        if(rateData.color.includes('All')) {
          rateData.color.splice(0,rateData.color.length - 1)
          rateData.color[0] = 'All'
        }
      })
      watch(() => rateData.cut , (CurrentValue, OldValue) => {
        if(rateData.cut.includes('All')) {
          rateData.cut.splice(0,rateData.cut.length - 1)
          rateData.cut[0] = 'All'
        }
      })
      watch(() => rateData.purity , (CurrentValue, OldValue) => {
        if(rateData.purity.includes('All')) {
          rateData.purity.splice(0,rateData.purity.length - 1)
          rateData.purity[0] = 'All'
        }
      })
      watch(() => rateData.polish , (CurrentValue, OldValue) => {
        if(rateData.polish.includes('All')) {
          rateData.polish.splice(0,rateData.polish.length - 1)
          rateData.polish[0] = 'All'
        }
      })
      watch(() => rateData.shape , (CurrentValue, OldValue) => {
        if(rateData.shape.includes('All')) {
          rateData.shape.splice(0,rateData.shape.length - 1)
          rateData.shape[0] = 'All'
        }
      })
      watch(() => rateData.symmetry , (CurrentValue, OldValue) => {
        if(rateData.symmetry.includes('All')) {
          rateData.symmetry.splice(0,rateData.symmetry.length - 1)
          rateData.symmetry[0] = 'All'
        }
      })
      watch(() => rateData.stone , (CurrentValue, OldValue) => {
        if(rateData.stone.includes('All')) {
          rateData.stone.splice(0,rateData.stone.length - 1)
          rateData.stone[0] = 'All'
        }
      })
      watch(() => rateData.tension , (CurrentValue, OldValue) => {
        if(rateData.tension.includes('All')) {
          rateData.tension.splice(0,rateData.tension.length - 1)
          rateData.tension[0] = 'All'
        }
      })
      watch(() => rateData.fluerocent , (CurrentValue, OldValue) => {
        if(rateData.fluerocent.includes('All')) {
          rateData.fluerocent.splice(0,rateData.fluerocent.length - 1)
          rateData.fluerocent[0] = 'All'
        }
      })
      watch(() => rateData.from , (CurrentValue, OldValue) => {
        if(rateData.from != null) {
          if(rateData.from <= 0) {
            pagedata.fromdis = true
          } else {
            pagedata.fromdis = false
          }
          if(rateData.to <= rateData.from) {
            rateData.to = parseFloat((rateData.from).toFixed(2))
          }
        }
      })
      watch(() => rateData.to , (CurrentValue, OldValue) => {
        if(rateData.to != null) {
          if(rateData.to <= rateData.from) {
            rateData.to = parseFloat((rateData.from).toFixed(2))
            pagedata.todis = true
          } else {
            pagedata.todis = false
          }
        }
      })
      return {
        pagedata,
        rateData,
        getProcessData,
        basicNonStickyNotificationPacket,
        SaveJoberRate,
        IncrementWeight,
        getProcessType
      }
    }
  })
  </script>
  <style scoped>
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
          }

          input[type=number] {
              -moz-appearance: textfield;
          }

          .input-group .input-group-text-custom {
              border-width: 1px;
              --tw-border-opacity: 1;
              border-color: rgba(226, 232, 240, var(--tw-border-opacity));
              --tw-bg-opacity: 1;
              background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
              padding-top: 0.1rem;
              padding-bottom: 0.1rem;
              padding-left: 1rem;
              padding-right: 1rem;
              --tw-text-opacity: 1;
              color: rgba(113, 128, 150, var(--tw-text-opacity));
              --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
              box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
          }
      </style>

<template>
<div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Packet Issue Transactions</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div class="flex items-center mt-2 ml-2">
                <div class="mr-2 flex items-center">
                    <input id="remember-me" type="checkbox" v-model="page_data.check" class="form-check-input border mr-2 bg-white" />
                    <label for="remember-me" class="text-md font-medium">Manual</label>
                </div>
            </div>
            <div class="w-full flex sm:w-auto mt-3 ml-2">
                <div class="w-56 relative text-gray-700 dark:text-gray-300 mr-4">
                    <input type="text" v-model="page_data.scan_lot_no" @change="get_packet_scan" autofocus ref="focusMe" :disabled="page_data.scan_disable == true" class="form-control w-56 box pr-10 placeholder-theme-13" placeholder="Scan Packet" />
                    <SearchIcon class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0" />
                </div>
                <button class="btn btn-primary shadow-md mr-2" @click="scan_stock_no">Issue</button>
            </div>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
        <div class="grid grid-cols-12 gap-x-2">
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div class="">
                    <label for="update-profile-form-6" class="form-label py-1">Packet / Stock</label>
                    <input id="tabulator-html-filter-value" v-model="filters.packet_no" type="text" class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0" placeholder="Search Packet / Stock" />
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div>
                  <label for="update-profile-form-6" class="form-label py-1">Select Kapan</label>
                    <TomSelect v-model="filters.kapan_id" :options="{
                  placeholder: 'Select Kapan',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Kapan'
                    }
                  }
                }" class="w-full">
                        <option value="0">Select Kapan</option>
                        <option v-for="item in page_data.purchase_list" :key="item.id" :value="item.id">{{item.lot_no}} ( {{ item.voucher_no ? item.voucher_no : '-' }} )</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div>
                  <label for="update-profile-form-6" class="form-label py-1">Select Status</label>
                    <TomSelect v-model="filters.status" :options="{
                  placeholder: 'Select Status',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Status'
                    }
                  }
                }" class="w-full">
                        <option value="0">Select Status</option>
                        <option value="1">On Hold</option>
                        <option value="4">Active</option>
                        <option value="2">Returned</option>
                        <option value="3">Cancelled</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-2 md:col-span-2 xl:col-span-1">
                <div class="mt-7 flex pt-2">

                    <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2" @click="reset_filters">
                        Reset
                    </button>
                </div>
            </div>
            <div class="col-span-2 md:col-span-2 xl:col-span-1">
                <div class="mt-7 flex pt-2">

                    <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full ml-2" tabindex="5" @click="initTabulator()">
                        Generate
                    </button>
                </div>
            </div>
        </div>
        <div class="overflow-x-auto scrollbar-hidden">
            <div id="tabulator" ref="tableRef" class="mt-5 table-report table-report--tabulator"></div>
        </div>
    </div>
    <!-- END: HTML Table Data -->

    <!-- BEGIN: new Process Modal -->
<div id="discuss-issue-modal" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
                <a data-dismiss="modal" href="javascript:;">
                    <XIcon class="w-8 h-8 text-gray-500" />
                </a>
                <div class="modal-body p-10">
                    <!-- <div id="vertical-form"> -->
                    <div class="flex flex-col justify-between mt-5">
                        <!-- BEGIN: Data List -->
                        <div class="intro-y overflow-auto">
                            <div class="flex items-center">
                                <h2 class="intro-y text-lg font-medium mr-5">Discuss Issue</h2>
                            </div>
                        </div>
                        <div class="grid grid-cols-12 gap-4">
                            <div class="intro-y col-span-12">
                                <!-- BEGIN: Input -->
                                <div class="">
                                    <div id="input" class="py-5">
                                        <div class="preview">
                                            <div>
                                                <!-- <label class="form-label">Kapan No & Stone Type</label> -->
                                                <div class="sm:grid grid-cols-2 gap-2">
                                                    <div>
                                                        <div class="">
                                                            <div id="input-group-4">Packet NO</div>

                                                            <input id="pos-form-1" v-model="page_data.packet_details.number" name="number" type="text" disabled class="form-control flex-1" placeholder="Packet No" />
                                                        </div>
                                                    </div>
                                                    <div class="mt-2 sm:mt-0">
                                                        <div class="">
                                                            <div id="input-group-4">Stock NO</div>

                                                            <input id="pos-form-1" v-model="page_data.packet_details.vepari_no" name="vepari_no" type="text" disabled class="form-control flex-1" placeholder="Stock No" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-3">
                                                <div class="sm:grid grid-cols-2 gap-2">
                                                    <div>
                                                        <div>

                                                            <div class="">
                                                                <div id="input-group-3">Issue Weight</div>
                                                                <input type="text" class="form-control" name="weight" disabled v-model="page_data.packet_details.cr_weight" aria-describedby="input-group-3" />
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="mt-2 sm:mt-0">
                                                        <div class="">
                                                            <div id="input-group-4">Process</div>
                                                            <input type="text" class="form-control" name="process_name" disabled v-model="page_data.packet_details.process_name" aria-describedby="input-group-4" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-3">
                                                <div class="sm:grid grid-cols-1 gap-2">

                                                      <div class="">
                                                          <div id="input-group-3">Process Party</div>
                                                          <input type="text" class="form-control w-full" name="process_party_name" disabled v-model="page_data.packet_details.process_party_name" aria-describedby="input-group-3" />
                                                      </div>
                                                  </div>
                                            </div>
                                            <div class="mt-3">
                                                <div class="sm:grid grid-cols-1 gap-2">

                                                      <div class="">
                                                          <div id="input-group-3">Select Manager</div>
                                                          <TomSelect v-model="discuss_form.manager_id" :options="{
                                                              placeholder: 'Select Manager',
                                                              loadingClass: 'loading',
                                                              plugins: {
                                                                dropdown_header: {
                                                                  title: 'Manager'
                                                                }
                                                              }
                                                            }" class="w-full">
                                                                    <!-- <option value="0">Select Process</option> -->
                                                                    <option v-for="item in page_data.user_details" :key="item.id" :value="item.id">{{item.first_name}} {{ item.last_name ? item.last_name : '' }} ({{ item.user_role == 1 ? 'Admin' : (item.user_role == 4 ? 'Process Party' : 'Manager') }})</option>
                                                                </TomSelect>
                                                      </div>
                                                  </div>
                                            </div>
                                            <div class="mt-3">
                                                <div class="sm:grid grid-cols-1 gap-2">

                                                      <div class="">
                                                          <div id="input-group-3">Remark</div>
                                                          <textarea id="message" rows="4" class="form-control" placeholder="Write your statement here..." name="remark" v-model="discuss_form.remark"></textarea>
                                                      </div>
                                                  </div>
                                            </div>
                                            <div class="mt-3">
                                                <!-- <label class="form-label">Stone && Est Value</label> -->
                                                <div class="sm:grid grid-cols-2 gap-2">
                                                    <div>

                                                        <div class="">

                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="mt-2 sm:mt-0">
                                                            <div class="flex justify-end items-center mt-5">
                                                                <button type="button" class="btn btn-secondary w-24 m-auto mr-3" data-dismiss="modal" href="javascript:;">Cancel</button>
                                                                <button type="button" @click="discuss_issue" :disabled="page_data.submit == true" class="btn btn-primary w-24 m-auto">Issue
                            <LoadingIcon v-if="page_data.submit == true" icon="oval" color="white" class="w-4 h-4 ml-2" />
                        </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- END: Input -->
                            </div>
                        </div>
                        <!-- END: Data List -->
                    </div>
                    <!-- </div> -->
                </div>
            </div>
    </div>
</div>
<!-- END: new Process Modal -->
</div>
</template>

<script>
import {
    defineComponent,
    computed,
    ref,
    reactive,
    onMounted,
    inject
} from 'vue'
import xlsx from 'xlsx'
import {
    useStore
} from '@/store'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import VueRouter from 'vue-router'
import {
    useRouter,
    useRoute
} from 'vue-router'
import {
    BASE_URL
} from '@/constants/config.js'
import moment from 'moment'
import { helper as  $h } from '@/utils/helper'

export default defineComponent({
    setup() {
        const swal = inject('$swal')
        const user_details = computed(() => store.state.user.currentUser)
        const router = useRouter()
        const focusMe = ref()
        const store = useStore()
        const route = useRoute()
        const tableRef = ref()
        const tabulator = ref()

        const page_data = reactive({
            scan_lot_no: '',
            check: false,
            form_submit: false,
            user_list: [],
            packet_details: [],
            process_list: [],
            manager_list: [],
            purchase_list: [],
            employee_list: [],
            scan_disable: false
        })

        const discuss_form = reactive({
            packet_id: '',
            manager_id: '',
            remark: ''
        })

        const filters = reactive({
            kapan_id: '0',
            packet_no: '',
            status: '0',
        })

        const initTabulator = () => {

            const params = {
                kapan_id: filters.kapan_id == '0' ? '' : window.btoa(filters.kapan_id),
                packet_no: filters.packet_no == '0' ? '' : window.btoa(filters.packet_no),
                status: filters.status == '' ? '' : window.btoa(filters.status)
            }
            let _this = this;
            tabulator.value = new Tabulator(tableRef.value, {
                ajaxURL: BASE_URL + 'api/discuss/d_gdt',
                ajaxConfig: {
                    method: "GET", //set request type to Position
                    headers: {
                        "Content-type": 'application/json; charset=utf-8', //set specific content type
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                },
                ajaxParams: params,
                ajaxFiltering: true,
                ajaxSorting: true,
                printAsHtml: true,
                printStyled: true,
                pagination: 'remote',
                paginationSize: 20,
                paginationSizeSelector: [20, 30, 40],
                layout: 'fitColumns',
                responsiveLayout: false,
                tooltipsHeader: true,
                headerSort: false,
                placeholder: 'No matching records found',
                columns: [

                    // For HTML table
                    {
                        title: 'Transaction No',
                        minWidth: 150,
                        responsive: 0,
                        field: 'transaction_code',
                        vertAlign: 'middle',
                        print: false,
                        download: true,

                        formatter(cell) {
                            // const a = cash(`<div class="w-full m-0">
                            //    <div class="whitespace-nowrap">${
                            //      cell.getData().due
                            //    }</div>
                            //  </div>`)
                            //  cash(a).on('click', function () {
                            //    Packet(cell.getData().id, cell.getData().lot_no, cell.getData().type)
                            //  })
                            //  return a[0]
                            if (cell.getData().issue_by == user_details.value.id) {
                                const a = cash(`<div><i data-feather="arrow-up-right" class="w-6 h-6 font-bold text-theme-9 mr-1"></i>
                 ${
                  cell.getData().transaction_code
                 }
                </div>`)
                                cash(a).on('click', function () {
                                    // router.push({
                                    //     'path': '/packets/transaction/' + window.btoa(cell.getData().id)
                                    // })
                                })
                                return a[0]
                            } else {
                                const a = cash(`<div><i data-feather="arrow-down-left" class="w-6 h-6 font-bold text-theme-6 mr-1"></i>
                 ${
                  cell.getData().transaction_code
                 }
                </div>`)
                                cash(a).on('click', function () {
                                    // router.push({
                                    //     'path': '/packets/transaction/' + window.btoa(cell.getData().id)
                                    // })
                                })
                                return a[0]
                            }
                        }
                    },
                    {
                        title: 'Stock',
                        field: 'number',
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        minWidth: 100,
                        print: false,
                        download: true,
                        formatter(cell) {
                          return `<div class="text-center">` + cell.getData().number + `
                      <p class="font-12 font-bold"> ( ` + cell.getData().vepari_no + ` )</p>
                    </div>`;
                            return `<div class="text-md">
                <p class="font-12 text-md">` + (cell.getData().number) + `</p>
              </div>`;
                        }
                    },
              //       {
              //           title: 'Stock No',
              //           field: 'number',
              //           hozAlign: 'center',
              //           vertAlign: 'middle',
              //           print: false,
              //           minWidth: 100,
              //           download: true,
              //           formatter(cell) {
              //               return `<div class="text-md">
              //   <p class="font-12 text-md">` + (cell.getData().vepari_no) + `</p>
              // </div>`;
              //           }
              //       },
                    {
                        title: 'Weight',
                        field: 'issue_weight',
                        hozAlign: 'center',
                        vertAlign: 'middle',
                        print: false,
                        minWidth: 100,
                        download: true,
                        formatter(cell) {
                            return `<div class="text-md">
                <p class="font-12 font-bold text-md">` + $h.myFloat(cell.getData().weight) + ` ct </p>
              </div>`;
                        }
                    },
                    {

                        title: 'Issue By <br> Issue To',
                        field: 'employee_first_name',
                        hozAlign: 'center',
                        vertAlign: 'middle',
                        minWidth: 150,
                        print: false,
                        download: true,
                        formatter(cell) {



                          if (cell.getData().issue_by == user_details.value.id) {

                            let user_role = cell.getData().receive_manager_role == 1 ? 'Admin' : (cell.getData().receive_manager_role == 1 ? 'Out Party' : 'Manager')

                            return `<div class="text-center">` + cell.getData().receive_first_name + ' ' + (cell.getData().receive_last_name ? cell.getData().receive_last_name : '') + `
                      <p class="text-theme-9 font-12 font-bold">` + user_role + `</p>
                    </div>`;
                  } else {

                    let user_role = cell.getData().issue_manager_role == 1 ? 'Admin' : (cell.getData().issue_manager_role == 1 ? 'Out Party' : 'Manager')

                    return `<div class="text-center">` + cell.getData().issue_first_name + ' ' + (cell.getData().issue_last_name ? cell.getData().issue_last_name : '') + `
              <p class="text-theme-9 font-12 font-bold">` + user_role + `</p>
            </div>`;
                          }
                        }
                    },
                    {
                        title: 'Remark',
                        field: 'remark',
                        minWidth: 150,
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                        formatter: 'textarea'
                    },
                    {
                        title: 'IssueDate',
                        field: 'created_at',
                        hozAlign: 'left',
                        minWidth: 130,
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                        formatter: 'textarea'
                    },
                    {
                        title: 'Status',
                        field: 'status',
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {

                          if(cell.getData().issue_by == user_details.value.id) {
                            if (cell.getData().status == 1 && cell.getData().transaction_status == 1) {
                                    return `<div class="flex items-center lg:justify-center text-theme-1">
                    On Hold
                  </div>`
                                } else if (cell.getData().status == 1 && cell.getData().transaction_status == 2) {
                                    return `<div class="flex items-center lg:justify-center text-theme-1">
                    Accepted
                  </div>`
                                } else if (cell.getData().status == 2) {
                                    return `<div class="flex items-center lg:justify-center text-theme-9">
                  Returned
                  </div>`
                                } else if (cell.getData().status == 3) {
                                    return `<div class="flex items-center lg:justify-center text-theme-6">
                    Cancelled
                  </div>`
                                }
                          } else {

                            if (cell.getData().status == 1 && cell.getData().transaction_status == 1) {
                                    return `<div class="flex items-center lg:justify-center text-theme-1">
                    On Hold
                  </div>`
                                } else if (cell.getData().status == 1 && cell.getData().transaction_status == 2) {
                                    return `<div class="flex items-center lg:justify-center text-theme-1">
                    Active
                  </div>`
                                } else if (cell.getData().status == 2) {
                                    return `<div class="flex items-center lg:justify-center text-theme-9">
                  Transferred
                  </div>`
                                } else if (cell.getData().status == 3) {
                                    return `<div class="flex items-center lg:justify-center text-theme-6">
                    Cancelled
                  </div>`
                                }

                          }
                        }
                    },
                    {
                        title: ' ',
                        minWidth: 80,
                        field: '',
                        responsive: 1,
                        hozAlign: 'center',
                        vertAlign: 'middle',
                        headerSort: false,
                        print: false,
                        download: false,
                        formatter(cell) {
                            const data_id = cell.getData().id;
                              if (cell.getData().issue_by == user_details.value.id) {
                                  if (cell.getData().transaction_status == 1) {
                                      const a = cash(`<div class="flex lg:justify-center items-center">
                    <button class="btn btn-primary btn-sm flex items-center mr-3">
                      <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Withdraw
                    </button>
                  </div>`)
                                      cash(a).on('click', function () {
                                          editTransactionStatus(cell.getData().id, 'withdraw');
                                      })
                                      return a[0]
                                  }
                              } else if (cell.getData().issue_by != user_details.value.id) {

                                if (cell.getData().transaction_status == 1) {

                                  const a = cash(`<div class="flex lg:justify-center items-center">
                    <button class="btn btn-primary btn-sm flex items-center mr-3">
                      <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Accept
                    </button>
                  </div>`)
                                  cash(a).on('click', function () {
                                      editTransactionStatus(cell.getData().id, 'accept');
                                  })

                                  return a[0]
                                }

                              }
                        }
                    },

                ],
                renderComplete() {
                    feather.replace({
                        'stroke-width': 1.5
                    })
                }
            })
        }

        const scan_stock_no = async () => {

            if (page_data.scan_lot_no == '') {
                return;
            }

            const params = {
                packet_no: window.btoa(page_data.scan_lot_no),
            }

            page_data.scan_disable = true

            let link_url = 'api/discuss/d_sdp'

            let promise = axios({
                url: link_url,
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    page_data.scan_disable = false

                    if (data.status == 'error') {
                        page_data.loading = false
                        swal('Warning', data.message, 'warning');
                    } else {

                        page_data.packet_details = data.data
                        discuss_form.packet_id = page_data.packet_details.id

                        // Manager Details
                        page_data.user_details = data.user_list

                        var i = page_data.user_details.length;
                        while (i--) {
                            if (page_data.user_details[i]['id'] == user_details.value.id) {
                                page_data.user_details.splice(i, 1);
                            }
                        }
                        discuss_form.manager_id = String(page_data.user_details[0].id)

                        cash('#discuss-issue-modal').modal('show')

                        page_data.scan_lot_no = ''
                    }
                })
                .catch(_error => {
                    page_data.scan_disable = false
                    swal('Error', 'Something went wrong.', 'error');
                });
        }

        const discuss_issue = async () => {
            let params = {
                packet_id: (discuss_form.packet_id ? window.btoa(discuss_form.packet_id) : ''),
                manager_id: (discuss_form.manager_id ? window.btoa(discuss_form.manager_id) : ''),
                remark: (discuss_form.remark ? window.btoa(discuss_form.remark) : '')
            }

            page_data.submit = true

            let link_url = 'api/discuss/d_td'

            let promise = axios({
                url: link_url,
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    page_data.submit = false

                    if (data.status == 'error') {
                        page_data.loading = false
                        swal('Warning', data.message, 'warning');
                    } else {
                        // Manager Details
                        page_data.packet_details = []
                        page_data.user_details = []
                        discuss_form.manager_id = ''
                        discuss_form.remark = ''
                        discuss_form.packet_id = ''

                        swal('Success', data.message, 'success');
                        cash('#discuss-issue-modal').modal('hide')

                        initTabulator()
                    }
                })
                .catch(_error => {
                    page_data.submit = false
                    swal('Error', 'Something went wrong.', 'error');
                });
        }

        const get_kapan_details = async () => {

            const params = {}

            let promise_url = 'api/master/dp_lot_details'

            let promise = axios({
                url: promise_url,
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    page_data.purchase_list = data.items
                    filters.kapan_id = '0'

                })
                .catch(_error => {

                    page_data.purchase_list = []
                    filters.kapan_id = '0'
                });
        }

        const get_packet_scan = () => {
            if (!page_data.check && page_data.scan_lot_no) {
                scan_stock_no();
            }
        }

        const reset_filters = () => {

            filters.packet_no = ''
            filters.kapan_id = '0'
        }

        const editTransactionStatus = (id,action) => {
      let trans_id = id;

      const params = {
      "trans_id":  trans_id,
      'action':action
      };

      swal({
        title: "Are you sure?",
        text: "You are about to " + action + " transaction!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm & Accept",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {

          let promise = axios({
            url: "api/discuss/d_da",
            method: "post",
            data: params,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data)
            .then(data => {
              console.log(data.message);
              if(data.status == "error"){
                swal("Error!", data.message, "error");
              }else{
                swal("Success!", data.message, "success");
                initTabulator()
              }
            })
            .catch(_error => {
              return [];
          });
        },
        allowOutsideClick: () => !swal.isLoading()
      }).then(result => {
        if (result.value) {
          //swal("Success!", "User Password Updated.", "success");
        }
      });
        }

        onMounted(() => {
            initTabulator()
            get_kapan_details()
            focusMe.value.focus()
        })

        return {
            page_data,
            focusMe,
            filters,
            user_details,
            initTabulator,
            tableRef,
            scan_stock_no,
            discuss_issue,
            get_packet_scan,
            reset_filters,
            discuss_form
        }

    }
})
</script>

<template>
<div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-3">
        <h2 class="text-lg font-medium mr-auto">Lab Packet Receive</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="grid grid-cols-12 mt-5 hidden">
        <div class="intro-y col-span-12 lg:col-span-12">
            <!-- BEGIN: Input -->
            <div class="intro-y box">

                <div class="p-5">
                    <div class="grid grid-cols-12 gap-x-5">
                        <div class="col-span-6 md:col-span-4">

                            <div>
                                <label for="update-profile-form-6" class="form-label">Scan Lot No</label>
                                <div class="input-group mt-2">

                                    <input id="scan_lot_no" type="text" tabindex="1" placeholder="Scan Barcode" v-model="screenData.scan_lot_no" :class="{'form-control':true,'loading':screenData.scan_load}" :disabled="screenData.scan_load" autocomplete="off" @change="scanAndAddPack" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- END: Input -->
        </div>
    </div>
    <!-- END: HTML Table Data -->

    <div class="hidden">
        <input type="file" name="newExcelFile" id="newExcelFile" @change="getExcelData" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
    </div>

    <div class="grid grid-cols-12 mt-5">
        <div class="intro-y col-span-12 lg:col-span-12">
            <!-- BEGIN: Input -->
            <div class="intro-y box">
                <div class="flex flex-col sm:flex-row items-center p-5 py-2 border-b border-gray-200 dark:border-dark-5">
                    <h2 class="font-medium text-base mr-auto">Update Stone Certy Results</h2>
                    <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                    </div>
                    <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
                      <button class="btn btn-sm btn-primary shadow-md mr-2 mb-2" @click="inputFileClick" :disabled="screenData.excel_loading == true">
                          Excel/ CSV Upload
                          <LoadingIcon v-if="screenData.excel_loading == true" icon="oval" color="white" class="w-4 h-4 ml-2" />
                      </button>
                        <button class="btn btn-sm btn-primary shadow-md mr-2 mb-2" @click="takeMultiReturn" :disabled="screenData.return_progress">
                            Update Result
                            <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" :hidden="!screenData.return_progress" />
                        </button>
                        <button class="btn btn-sm btn-primary shadow-md mr-2 mb-2" @click="resetData">
                            Reset Form
                        </button>
                    </div>
                </div>
                <div class="p-5">
                    <div class="grid grid-cols-12 gap-x-5">
                        <div class="col-span-4 xl:col-span-4">
                            <div>
                                <label for="update-profile-form-6" class="form-label font-bold">
                                    Total Scanned Pcs. :&nbsp;</label>
                                <label for="update-profile-form-6" class="form-label">{{screenData.total_scan_pack}} </label>
                            </div>
                        </div>
                        <div class="col-span-4 xl:col-span-4">
                            <div>
                                <label for="update-profile-form-6" class="form-label font-bold">
                                    Total Pcs Weight. :&nbsp;</label>
                                <label for="update-profile-form-6" class="form-label">{{$h.myFloat(screenData.total_scan_pack_wt)}} </label>
                            </div>
                        </div>
                        <div class="col-span-4 xl:col-span-4">
                            <div>
                                <label for="update-profile-form-6" class="form-label font-bold">
                                    Total New Weight. :&nbsp;</label>
                                <label for="update-profile-form-6" class="form-label">{{$h.myFloat(screenData.total_scan_new_wt)}} </label>
                            </div>
                        </div>
                        <div class="col-span-12 ">
                          <div id="generate-pdf">
                            <div>
                              <!-- BEgin: Process Report Start-->
                              <div id="responsive-table" class="p-5" v-if="screenData.excel_loading">
                                <div class="preview">
                                  <div class="overflow-x-auto">
                                    <div
                                      class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
                                    >
                                      <LoadingIcon icon="bars" class="w-8 h-8" />
                                      <div class="text-center text-xs mt-2">Getting Records</div>
                                  </div>
                                  </div>
                                </div>
                              </div>

                              <div v-else>
                                <div class="scrollbar-hidden" v-if="page_data.record_details.length > 0">
                                  <div class="intro-y col-span-12 overflow-auto">
                                    <table class="mt-3 w-full border-2 text-center">
                                      <thead>
                                        <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                          <th class="p-2 text-xs lg:text-md whitespace-nowrap border-b border-r">NO.</th>
                                          <th class="p-2 text-xs lg:text-md whitespace-nowrap border-b border-r-2">Packet No</th>
                                          <th class="p-2 text-xs lg:text-md whitespace-nowrap border-b border-r">LAB</th>
                                          <th class="p-2 text-xs lg:text-md whitespace-nowrap border-b border-r">I WEIGHT (CT)</th>
                                          <th class="p-2 text-xs lg:text-md whitespace-nowrap border-b border-r">WEIGHT (CT)</th>
                                          <th class="p-2 text-xs lg:text-md whitespace-nowrap border-b border-r">I SHAPE</th>
                                          <th class="p-2 text-xs lg:text-md whitespace-nowrap border-b border-r">SHAPE</th>
                                          <th class="p-2 text-xs lg:text-md whitespace-nowrap border-b border-r">I COLOR</th>
                                          <th class="p-2 text-xs lg:text-md whitespace-nowrap border-b border-r">COLOR</th>
                                          <th class="p-2 text-xs lg:text-md whitespace-nowrap border-b border-r">I CLARITY</th>
                                          <th class="p-2 text-xs lg:text-md whitespace-nowrap border-b border-r">CLARITY</th>
                                          <th class="p-2 text-xs lg:text-md whitespace-nowrap border-b border-r">I CUT</th>
                                          <th class="p-2 text-xs lg:text-md whitespace-nowrap border-b border-r">CUT</th>
                                          <th class="p-2 text-xs lg:text-md whitespace-nowrap border-b border-r">I POLISH</th>
                                          <th class="p-2 text-xs lg:text-md whitespace-nowrap border-b border-r">POLISH</th>
                                          <th class="p-2 text-xs lg:text-md whitespace-nowrap border-b border-r">I SYMM</th>
                                          <th class="p-2 text-xs lg:text-md whitespace-nowrap border-b border-r">SYMM</th>
                                          <th class="p-2 text-xs lg:text-md whitespace-nowrap border-b border-r">Status</th>
                                        </tr>
                                      </thead>
                                      <tbody v-for="(item,index) in page_data.record_details" :key="index">
                                        <tr class="hover:bg-gray-200 p-0" :class="{'bg-gray-100': (index % 2) == 0}">
                                          <td class="py-2 text-xs lg:text-md text-center border-r border-b">{{ index + 1 }}</td>
                                          <td class="py-2 text-xs lg:text-md text-center border-r border-b">{{ item.pack_code }} <br> ({{ item.vepari_no }})</td>
                                          <td class="py-2 text-xs lg:text-md text-center border-r border-b w-24">{{ item.laser_scribe }}</td>
                                          <td class="py-2 text-xs lg:text-md text-center border-r border-b">{{ item.cr_wt }}</td>
                                          <td class="py-2 text-xs lg:text-md text-center border-r border-b" :class="{'text-theme-6' : ((item.cr_wt < item.new_wt) && item.new_wt > 0),'text-theme-9' : ((item.cr_wt > item.new_wt) && item.new_wt > 0)}">{{ item.new_wt }}</td>
                                          <td class="py-2 text-xs lg:text-md text-center border-r border-b">{{ item.issue_shape_name }}</td>
                                          <td class="py-2 text-xs lg:text-md text-center border-r border-b">{{ item.shape_name }}</td>
                                          <td class="py-2 text-xs lg:text-md text-center border-r border-b">{{ item.issue_color_name }}</td>
                                          <td class="py-2 text-xs lg:text-md text-center border-r border-b" :class="{'text-theme-6' : ((item.issue_color_id < item.receive_color_id) && item.receive_color_id > 0),'text-theme-9' : ((item.issue_color_id > item.receive_color_id) && item.receive_color_id > 0)}">{{ item.color_short }}</td>
                                          <td class="py-2 text-xs lg:text-md text-center border-r border-b">{{ item.issue_purity_name }}</td>
                                          <td class="py-2 text-xs lg:text-md text-center border-r border-b" :class="{'text-theme-6' : ((item.issue_purity_id < item.receive_purity_id) && item.receive_purity_id > 0),'text-theme-9' : ((item.issue_purity_id > item.receive_purity_id) && item.receive_purity_id > 0)}">{{ item.clarity }}</td>
                                          <td class="py-2 text-xs lg:text-md text-center border-r border-b">{{ item.issue_cut_name }}</td>
                                          <td class="py-2 text-xs lg:text-md text-center border-r border-b" :class="{'text-theme-6' : ((item.issue_cut_id < item.receive_cut_id) && item.receive_cut_id > 0),'text-theme-9' : ((item.issue_cut_id > item.receive_cut_id) && item.receive_cut_id > 0)}">{{ item.cut_prop }}</td>
                                          <td class="py-2 text-xs lg:text-md text-center border-r border-b">{{ item.issue_polish_name }}</td>
                                          <td class="py-2 text-xs lg:text-md text-center border-r border-b" :class="{'text-theme-6' : ((item.issue_polish_id < item.receive_polish_id) && item.receive_polish_id > 0),'text-theme-9' : ((item.issue_polish_id > item.receive_polish_id) && item.receive_polish_id > 0)}">{{ item.polish }}</td>
                                          <td class="py-2 text-xs lg:text-md text-center border-r border-b">{{ item.issue_symmetry_name }}</td>
                                          <td class="py-2 text-xs lg:text-md text-center border-r border-b" :class="{'text-theme-6' : ((item.issue_symmetry_id < item.receive_symmetry_id) && item.receive_symmetry_id > 0),'text-theme-9' : ((item.issue_symmetry_id > item.receive_symmetry_id) && item.receive_symmetry_id > 0)}">{{ item.sym }}</td>
                                          <td class="py-2 text-xs lg:text-md text-center border-r border-b">

                                            <div class="flex items-center mb-1">
                                              <div class="flex items-center mb-1 mx-1">
                                                <input id="default-radio-1" v-model="item.recheck" type="radio" value="1" :name="item.pack_code" class="w-3 h-3 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                                <label for="default-radio-1" class="mx-2 text-xs font-medium text-gray-900 dark:text-gray-300">Accept</label>
                                              </div>
                                              <div class="flex items-center">
                                                  <input id="default-radio-2" v-model="item.recheck" type="radio" value="2" :name="item.pack_code" class="w-3 h-3 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                                  <label for="default-radio-2" class="ml-2 text-xs font-medium text-gray-900 dark:text-gray-300">Recheck</label>
                                              </div>
                                            </div>

                                          </td>
                                        </tr>

                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                    </div>
                </div>
            </div>
            <!-- END: Input -->
        </div>
    </div>
</div>
</template>

<script>
import {
    defineComponent,
    ref,
    reactive,
    onMounted,
    inject
} from 'vue'
import feather from 'feather-icons'
import VueRouter from 'vue-router'
import {
    AgGridVue
} from "ag-grid-vue3";
import {
    useRouter,
    useRoute
} from 'vue-router'
import {
    BASE_URL
} from '@/constants/config.js'
import ShapeRenderer from '@/custom-renderer/ShapeRenderer.js'
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import json5 from 'json5';
import VueBarcode from '@chenfengyuan/vue-barcode';

export default defineComponent({
    components: {
        AgGridVue,
        ShapeRenderer,
        VueBarcode
    },
    setup() {
        const gridApi = ref(null);
        const router = useRouter()
        const route = useRoute()
        const swal = inject('$swal')
        const filter = reactive({
            field: 'lot_no',
            type: 'like',
            value: ''
        })

        const page_data = reactive({
          record_details: []
        })

        class DatePicker {
            // gets called once before the renderer is used
            init(params) {
                // create the cell
                this.eInput = document.createElement('input');
                this.eInput.value = params.value;
                this.eInput.classList.add('ag-input');
                this.eInput.style.height = 'var(--ag-row-height)';
                this.eInput.style.fontSize = 'calc(var(--ag-font-size) + 1px)';

                // https://jqueryui.com/datepicker/
                $(this.eInput).datepicker({
                    dateFormat: 'dd/mm/yy',
                    onSelect: () => {
                        this.eInput.focus();
                    },
                });
            }

            // gets called once when grid ready to insert the element
            getGui() {
                return this.eInput;
            }

            // focus and select can be done after the gui is attached
            afterGuiAttached() {
                this.eInput.focus();
                this.eInput.select();
            }

            // returns the new value after editing
            getValue() {
                return this.eInput.value;
            }

            // any cleanup we need to be done here
            destroy() {
                // but this example is simple, no cleanup, we could
                // even leave this method out as it's optional
            }

            // if true, then this editor will appear in a popup
            isPopup() {
                // and we could leave this method out also, false is the default
                return false;
            }
        }

        const getRowId = (params) => {
            params.data.sr_no
            console.log(params.data.sr_no);
        }
        const screenData = reactive({
            scan_load: false,
            return_progress: false,
            delete_row: true,
            excel_loading: false,
            scan_lot_no: '',
            total_scan_pack: 0,
            total_scan_pack_wt: 0,
            total_scan_new_wt: 0,
            shapes: [],
            colors: [],
            purity: [],
            lab_file: '',
            form_type: 'create'

        })

        const onGridReady = (params) => {
            gridApi.value = params.api;
        };

        const rowData = reactive({
            value: []
        });

        const overlayNoRowsTemplate = 'Scan packets for process receive!'

        const columnDefs = reactive({
            value: [{
                    headerName: "#",
                    field: 'sr_no',
                    maxWidth: 60,
                },
                {
                    headerName: "Packet No",
                    field: 'pack_no',
                    minWidth: 120
                },
                {
                    headerName: "Stock No",
                    field: 'vepari_no',
                    minWidth: 120
                },
                {
                    headerName: "Issue Weight",
                    field: 'cr_wt'
                },
                {
                    headerName: "FInal Weight",
                    field: 'new_wt',
                    editable: true,
                    minWidth: 120,
                    valueGetter: (params) => {
                        if (params.data.new_wt) {
                            return params.data.new_wt;
                        } else {
                            return 0;
                        }
                    },
                    valueSetter: (params) => {
                        if (params.newValue == '') {
                            alert('Value cannot be empty');
                            return false;
                        }
                        var newValInt = parseFloat(params.newValue);

                        if (isNaN(newValInt)) {
                            alert('Not a Valid Number');
                            params.data.new_wt = params.data.new_wt;
                            return false;

                        } else if (newValInt > params.data.cr_wt) {
                            alert('Cr Wt can not greater than original issue weight');
                            params.data.new_wt = params.data.cr_wt;
                            return params.data.cr_wt;
                        } else {
                            var valueChanged = params.data.new_wt !== newValInt;
                            if (valueChanged) {
                                params.data.new_wt = newValInt;
                            }
                            return valueChanged;
                        }
                    },
                },
                {
                    headerName: "Document No",
                    field: 'document_no',
                    editable: true,
                    valueSetter: (params) => {
                        var newValInt = params.newValue;

                        var valueChanged = (params.data.crown_height !== newValInt);
                        if (valueChanged) {
                            params.data.document_no = newValInt;
                        }
                        return valueChanged;
                    },
                },
                {
                    headerName: "Report Date",
                    field: 'report_date',
                    editable: true,
                    valueSetter: (params) => {
                        var newValInt = params.newValue;

                        var valueChanged = (params.data.crown_height !== newValInt);
                        if (valueChanged) {
                            params.data.report_date = newValInt;
                        }
                        return valueChanged;
                    },
                },
                {
                    headerName: "Report Number",
                    field: 'report_number',
                    editable: true,
                    valueSetter: (params) => {
                        var newValInt = params.newValue;

                        var valueChanged = (params.data.report_number !== newValInt);
                        if (valueChanged) {
                            params.data.report_number = newValInt;
                        }
                        return valueChanged;
                    },
                },
                {
                    headerName: "Prefix",
                    field: 'prefix',
                    editable: true,
                    valueSetter: (params) => {
                        var newValInt = params.newValue;

                        var valueChanged = (params.data.prefix !== newValInt);
                        if (valueChanged) {
                            params.data.prefix = newValInt;
                        }
                        return valueChanged;
                    },
                },
                {
                    headerName: "Other Report Number",
                    field: 'other_report_number',
                    editable: true,
                    valueSetter: (params) => {
                        var newValInt = params.newValue;

                        var valueChanged = (params.data.other_report_number !== newValInt);
                        if (valueChanged) {
                            params.data.other_report_number = newValInt;
                        }
                        return valueChanged;
                    },
                },
                {
                    headerName: "Shape Name",
                    field: 'shape_name',
                    editable: true,
                    valueSetter: (params) => {
                        var newValInt = params.newValue;

                        var valueChanged = (params.data.shape_name !== newValInt);
                        if (valueChanged) {
                            params.data.shape_name = newValInt;
                        }
                        return valueChanged;
                    },
                },
                {
                    headerName: "Number Stones",
                    field: 'number_stones',
                    editable: true,
                    valueSetter: (params) => {
                        var newValInt = params.newValue;

                        var valueChanged = (params.data.number_stones !== newValInt);
                        if (valueChanged) {
                            params.data.number_stones = newValInt;
                        }
                        return valueChanged;
                    },
                },
                {
                    headerName: "Description",
                    field: 'description',
                    editable: true,
                    valueSetter: (params) => {
                        var newValInt = params.newValue;

                        var valueChanged = (params.data.description !== newValInt);
                        if (valueChanged) {
                            params.data.description = newValInt;
                        }
                        return valueChanged;
                    },
                },
                {
                    headerName: "Measurement 1",
                    field: 'measurement_1',
                    editable: true,
                    valueSetter: (params) => {
                        var newValInt = params.newValue;

                        var valueChanged = (params.data.measurement_1 !== newValInt);
                        if (valueChanged) {
                            params.data.measurement_1 = newValInt;
                        }
                        return valueChanged;
                    },
                },
                {
                    headerName: "Measurement 2",
                    field: 'measurement_2',
                    editable: true,
                    valueSetter: (params) => {
                        var newValInt = params.newValue;

                        var valueChanged = (params.data.measurement_2 !== newValInt);
                        if (valueChanged) {
                            params.data.measurement_2 = newValInt;
                        }
                        return valueChanged;
                    },
                },
                {
                    headerName: "Measurement 3",
                    field: 'measurement_3',
                    editable: true,
                    valueSetter: (params) => {
                        var newValInt = params.newValue;

                        var valueChanged = (params.data.measurement_3 !== newValInt);
                        if (valueChanged) {
                            params.data.measurement_3 = newValInt;
                        }
                        return valueChanged;
                    },
                },
                {
                    headerName: "Table",
                    field: 'table',
                    editable: true,
                    valueSetter: (params) => {
                        var newValInt = params.newValue;

                        var valueChanged = (params.data.table !== newValInt);
                        if (valueChanged) {
                            params.data.table = newValInt;
                        }
                        return valueChanged;
                    },
                },
                {
                    headerName: "Crown Height",
                    field: 'crown_height',
                    editable: true,
                    valueSetter: (params) => {
                        var newValInt = params.newValue;

                        var valueChanged = (params.data.crown_height !== newValInt);
                        if (valueChanged) {
                            params.data.crown_height = newValInt;
                        }
                        return valueChanged;
                    },
                },
                {
                    headerName: "Pavilion Depth",
                    field: 'pavilion_depth',
                    editable: true,
                    valueSetter: (params) => {
                        var newValInt = params.newValue;

                        var valueChanged = (params.data.pavilion_depth !== newValInt);
                        if (valueChanged) {
                            params.data.pavilion_depth = newValInt;
                        }
                        return valueChanged;
                    },
                },
                {
                    headerName: "Total Depth",
                    field: 'total_depth',
                    editable: true,
                    valueSetter: (params) => {
                        var newValInt = params.newValue;

                        var valueChanged = (params.data.total_depth !== newValInt);
                        if (valueChanged) {
                            params.data.total_depth = newValInt;
                        }
                        return valueChanged;
                    },
                },
                {
                    headerName: "Crown Angle",
                    field: 'crown_angle',
                    editable: true,
                    valueSetter: (params) => {
                        var newValInt = params.newValue;

                        var valueChanged = (params.data.crown_angle !== newValInt);
                        if (valueChanged) {
                            params.data.crown_angle = newValInt;
                        }
                        return valueChanged;
                    },
                },
                {
                    headerName: "Pavilion Angle",
                    field: 'pavilion_angle',
                    editable: true,
                    valueSetter: (params) => {
                        var newValInt = params.newValue;

                        var valueChanged = (params.data.pavilion_angle !== newValInt);
                        if (valueChanged) {
                            params.data.pavilion_angle = newValInt;
                        }
                        return valueChanged;
                    },
                },
                {
                    headerName: "Culet Size",
                    field: 'culet_size',
                    editable: true,
                    valueSetter: (params) => {
                        var newValInt = params.newValue;

                        var valueChanged = (params.data.culet_size !== newValInt);
                        if (valueChanged) {
                            params.data.culet_size = newValInt;
                        }
                        return valueChanged;
                    },
                },
                {
                    headerName: "Girdle Percent",
                    field: 'girdle_percent',
                    editable: true,
                    valueSetter: (params) => {
                        var newValInt = params.newValue;

                        var valueChanged = (params.data.girdle_percent !== newValInt);
                        if (valueChanged) {
                            params.data.girdle_percent = newValInt;
                        }
                        return valueChanged;
                    },
                },
                {
                    headerName: "Girdle Name",
                    field: 'girdle_name',
                    editable: true,
                    valueSetter: (params) => {
                        var newValInt = params.newValue;

                        var valueChanged = (params.data.girdle_name !== newValInt);
                        if (valueChanged) {
                            params.data.girdle_name = newValInt;
                        }
                        return valueChanged;
                    },
                },
                {
                    headerName: "Polish",
                    field: 'polish',
                    editable: true,
                    valueSetter: (params) => {
                        var newValInt = params.newValue;

                        var valueChanged = (params.data.polish !== newValInt);
                        if (valueChanged) {
                            params.data.polish = newValInt;
                        }
                        return valueChanged;
                    },
                },
                {
                    headerName: "Crown Height",
                    field: 'sym',
                    editable: true,
                    valueSetter: (params) => {
                        var newValInt = params.newValue;

                        var valueChanged = (params.data.sym !== newValInt);
                        if (valueChanged) {
                            params.data.sym = newValInt;
                        }
                        return valueChanged;
                    },
                },
                {
                    headerName: "Crown Height",
                    field: 'cut_prop',
                    editable: true,
                    valueSetter: (params) => {
                        var newValInt = params.newValue;

                        var valueChanged = (params.data.cut_prop !== newValInt);
                        if (valueChanged) {
                            params.data.cut_prop = newValInt;
                        }
                        return valueChanged;
                    },
                },
                {
                    headerName: "CUT-PROP",
                    field: 'clarity',
                    editable: true,
                    valueSetter: (params) => {
                        var newValInt = params.newValue;

                        var valueChanged = (params.data.clarity !== newValInt);
                        if (valueChanged) {
                            params.data.clarity = newValInt;
                        }
                        return valueChanged;
                    },
                },
                {
                    headerName: "Color (Long)",
                    field: 'color_long',
                    editable: true,
                    valueSetter: (params) => {
                        var newValInt = params.newValue;

                        var valueChanged = (params.data.color_long !== newValInt);
                        if (valueChanged) {
                            params.data.color_long = newValInt;
                        }
                        return valueChanged;
                    },
                },
                {
                    headerName: "Report Type I",
                    field: 'report_type_1',
                    editable: true,
                    valueSetter: (params) => {
                        var newValInt = params.newValue;

                        var valueChanged = (params.data.report_type_1 !== newValInt);
                        if (valueChanged) {
                            params.data.report_type_1 = newValInt;
                        }
                        return valueChanged;
                    },
                },
                {
                    headerName: "Report Format I",
                    field: 'report_format_1',
                    editable: true,
                    valueSetter: (params) => {
                        var newValInt = params.newValue;

                        var valueChanged = (params.data.report_format_1 !== newValInt);
                        if (valueChanged) {
                            params.data.report_format_1 = newValInt;
                        }
                        return valueChanged;
                    },
                },
                {
                    headerName: "Color (Short)",
                    field: 'color_short',
                    editable: true,
                    valueSetter: (params) => {
                        var newValInt = params.newValue;

                        var valueChanged = (params.data.color_short !== newValInt);
                        if (valueChanged) {
                            params.data.color_short = newValInt;
                        }
                        return valueChanged;
                    },
                },
                {
                    headerName: "Report Type II",
                    field: 'report_type_2',
                    editable: true,
                    valueSetter: (params) => {
                        var newValInt = params.newValue;

                        var valueChanged = (params.data.report_type_2 !== newValInt);
                        if (valueChanged) {
                            params.data.report_type_2 = newValInt;
                        }
                        return valueChanged;
                    },
                },
                {
                    headerName: "Report Format II",
                    field: 'report_format_2',
                    editable: true,
                    valueSetter: (params) => {
                        var newValInt = params.newValue;

                        var valueChanged = (params.data.report_format_2 !== newValInt);
                        if (valueChanged) {
                            params.data.report_format_2 = newValInt;
                        }
                        return valueChanged;
                    },
                },
                {
                    headerName: "Fluorescence",
                    field: 'fluorescence',
                    editable: true,
                    valueSetter: (params) => {
                        var newValInt = params.newValue;

                        var valueChanged = (params.data.fluorescence !== newValInt);
                        if (valueChanged) {
                            params.data.fluorescence = newValInt;
                        }
                        return valueChanged;
                    },
                },
                {
                    headerName: "Comments Consultation",
                    field: 'comments_consultation',
                    editable: true,
                    valueSetter: (params) => {
                        var newValInt = params.newValue;

                        var valueChanged = (params.data.comments_consultation !== newValInt);
                        if (valueChanged) {
                            params.data.comments_consultation = newValInt;
                        }
                        return valueChanged;
                    },
                },
                {
                    headerName: "Special Comments",
                    field: 'special_comments',
                    editable: true,
                    valueSetter: (params) => {
                        var newValInt = params.newValue;

                        var valueChanged = (params.data.special_comments !== newValInt);
                        if (valueChanged) {
                            params.data.special_comments = newValInt;
                        }
                        return valueChanged;
                    },
                },
                {
                    headerName: "Report Comment",
                    field: 'report_comment',
                    editable: true,
                    valueSetter: (params) => {
                        var newValInt = params.newValue;

                        var valueChanged = (params.data.report_comment !== newValInt);
                        if (valueChanged) {
                            params.data.report_comment = newValInt;
                        }
                        return valueChanged;
                    },
                },
                {
                    headerName: "Customer Ref No",
                    field: 'customer_ref_no',
                    editable: true,
                    valueSetter: (params) => {
                        var newValInt = params.newValue;

                        var valueChanged = (params.data.customer_ref_no !== newValInt);
                        if (valueChanged) {
                            params.data.customer_ref_no = newValInt;
                        }
                        return valueChanged;
                    },
                },
                {
                    headerName: "Memo No",
                    field: 'memo_no',
                    editable: true,
                    valueSetter: (params) => {
                        var newValInt = params.newValue;

                        var valueChanged = (params.data.memo_no !== newValInt);
                        if (valueChanged) {
                            params.data.memo_no = newValInt;
                        }
                        return valueChanged;
                    },
                },
                {
                    headerName: "Fax Comments",
                    field: 'fax_comments',
                    editable: true,
                    valueSetter: (params) => {
                        var newValInt = params.newValue;

                        var valueChanged = (params.data.fax_comments !== newValInt);
                        if (valueChanged) {
                            params.data.fax_comments = newValInt;
                        }
                        return valueChanged;
                    },
                },
                {
                    headerName: "Laserscribe",
                    field: 'laser_scribe',
                    editable: true,
                    valueSetter: (params) => {
                        var newValInt = params.newValue;

                        var valueChanged = (params.data.laser_scribe !== newValInt);
                        if (valueChanged) {
                            params.data.laser_scribe = newValInt;
                        }
                        return valueChanged;
                    },
                },
                {
                    headerName: "Recheck",
                    field: 'recheck',
                    editable: true,
                    valueSetter: (params) => {
                        var newValInt = params.newValue;

                        var valueChanged = (params.data.recheck !== newValInt);
                        if (valueChanged) {
                            params.data.recheck = newValInt;
                        }
                        return valueChanged;
                    },
                },
                {
                    headerName: "Remark",
                    field: 'remark',
                    editable: true,
                    valueSetter: (params) => {
                        var newValInt = params.newValue;

                        var valueChanged = (params.data.remark !== newValInt);
                        if (valueChanged) {
                            params.data.remark = newValInt;
                        }
                        return valueChanged;
                    },
                }
            ],
        });

        const defaultColDef = {
            sortable: true,
            filter: true,
            minWidth: 150,
            //  floatingFilter: true,
            flex: 1
        };

        const focusScan = () => {

            document.querySelector('[id="scan_lot_no"]').focus();
        }
        //Edit user
        const scanAndAddPack = () => {

            if (screenData.form_type == 'update') {
                swal("Warning!", "Please Reset The Data!", "warning");
                return;

            }
            let codelength = screenData.scan_lot_no.trim().length;
            if (codelength > 4) {
                screenData.scan_load = true;
                //Check already in array
                var i = page_data.record_details.length;
                while (i--) {
                    if (page_data.record_details[i]['barcode'] == screenData.scan_lot_no) {
                        screenData.scan_lot_no = "";
                        swal("Warning!", "Already in list, Scan other packet!", "warning");
                        screenData.scan_load = false;
                        return;
                    }
                }

                //get lot details
                const data = {
                    barcode: screenData.scan_lot_no,
                };
                let url_post = '';
                if (screenData.scan_lot_no != '') {
                    url_post = "api/full-process/get_scanned_packet_details_for_lab_return"
                } else {
                    url_post = "api/full-process/get_scanned_packet_details_for_lab_return"
                }
                let promise = axios({
                    url: url_post,
                    method: "post",
                    data: data,
                    baseURL: BASE_URL,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token")
                    },
                    config: {
                        headers: {
                            Accept: "application/json"
                        }
                    },
                });
                return promise
                    .then((result) => result.data)
                    .then((data) => {

                        if (data.status == "success") {

                            let lab_packet = data.lab_packet;

                            page_data.record_details.push({
                                "sr_no": screenData.total_scan_pack + 1,
                                "id": lab_packet.id,
                                "transaction_id": lab_packet.transaction_id,
                                "packet_id": lab_packet.packet_id,
                                "pack_no": lab_packet.pack_code,
                                "vepari_no": lab_packet.vepari_no,
                                "barnum": lab_packet.barnum,
                                "document_no": lab_packet.document_no,
                                "report_date": lab_packet.report_date,
                                "report_number": lab_packet.report_number,
                                "prefix": lab_packet.prefix,
                                "other_report_number": lab_packet.other_report_number,
                                "shape_name": lab_packet.shape_name,
                                "weight": lab_packet.cr_wt,
                                "cr_wt": lab_packet.cr_wt,
                                "new_wt": lab_packet.cr_wt,
                                "number_stones": lab_packet.number_stones,
                                "description": lab_packet.description,
                                "measurement_1": lab_packet.measurement_1,
                                "measurement_2": lab_packet.measurement_2,
                                "measurement_3": lab_packet.measurement_3,
                                "table": lab_packet.table,
                                "crown_height": lab_packet.crown_height,
                                "pavilion_depth": lab_packet.pavilion_depth,
                                "total_depth": lab_packet.total_depth,
                                "crown_angle": lab_packet.crown_angle,
                                "pavilion_angle": lab_packet.pavilion_angle,
                                "culet_size": lab_packet.culet_size,
                                "girdle_percent": lab_packet.girdle_percent,
                                "girdle_name": lab_packet.girdle_name,
                                "polish": lab_packet.polish,
                                "sym": lab_packet.sym,
                                "cut_prop": lab_packet.cut_prop,
                                "clarity": lab_packet.clarity,
                                "color_long": lab_packet.color_long,
                                "report_type_1": lab_packet.report_type_1,
                                "report_format_1": lab_packet.report_format_1,
                                "color_short": lab_packet.color_short,
                                "report_type_2": lab_packet.report_type_2,
                                "report_format_2": lab_packet.report_format_2,
                                "fluorescence": lab_packet.fluorescence,
                                "comments_consultation": lab_packet.comments_consultation,
                                "special_comments": lab_packet.special_comments,
                                "report_comment": lab_packet.report_comment,
                                "customer_ref_no": lab_packet.customer_ref_no,
                                "memo_no": lab_packet.memo_no,
                                "fax_comments": lab_packet.fax_comments,
                                "lab": lab_packet.lab,
                                "laser_scribe": lab_packet.laser_scribe,
                                "recheck": lab_packet.recheck,
                                "remark": lab_packet.remark,
                            });
                            //gridApi.value.setRowData([]);

                            // gridApi.value.setRowData(page_data.record_details);
                            screenData.scan_lot_no = "";
                            //screenData.total_scan_pack_wt = parseFloat(screenData.total_scan_pack_wt) + parseFloat(pack_details.cr_weight);

                            reCalculateTotal();
                            screenData.scanload = false;
                            //console.log(gridApi.value.refreshCells());
                            if (page_data.record_details.length == 1) {
                                setTimeout(focusLstRow, 50);
                            } else {
                                focusLstRow();
                            }
                        } else {
                            swal("Error!", data.message, "error");
                        }

                    })
                    .catch((_error) => {
                        screenData.scan_load = false;
                        return [];
                    });
            }
        }

        const focusLstRow = () => {
            gridApi.value.ensureIndexVisible(page_data.record_details.length - 1);
            gridApi.value.setFocusedCell(page_data.record_details.length - 1, 'new_wt');
            gridApi.value.startEditingCell({
                rowIndex: page_data.record_details.length - 1,
                colKey: 'new_wt',
            });
        }

        const inputFileClick = () => {
            document.getElementById("newExcelFile").click()
        }

        const getExcelData = (e) => {
            console.log(e.target.files[0]);
            screenData.lab_file = e.target.files[0];

            e.preventDefault();

            if (screenData.lab_file) {

                screenData.excel_loading = true

                let formData = new FormData();
                formData.append('scanned_data', JSON.stringify(page_data.record_details));
                formData.append('lab_data', screenData.lab_file);

                let promise = axios({
                    url: "api/full-process/get_lab_excel_read_data",
                    method: "POST",
                    data: formData,
                    baseURL: BASE_URL,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token")
                    },
                    config: {
                        headers: {
                            Accept: "application/json"
                        }
                    },
                    'content-type': 'multipart/form-data'
                });
                return promise
                    .then(result => result.data)
                    .then(data => {
                        if (data.error == "YES") {
                            swal('Error!', data.message, "error");
                            screenData.excel_loading = false
                            return
                        }

                        page_data.record_details = data.items
                        reCalculateTotal();

                        if (data.message != '') {

                            swal('Warning!', data.message, "warning");
                        }

                        screenData.lab_file = ''
                        document.querySelector('[id="newExcelFile"]').value = '';
                        screenData.excel_loading = false
                        focusScan()

                    })
                    .catch(_error => {

                        screenData.excel_loading = false
                    });
            }

        }

        const reCalculateTotal = () => {
            var j = page_data.record_details.length;
            var total_pc = 0;
            var new_ct = 0;
            var total_ct = 0;
            var sr = 1;
            while (j--) {
                total_pc++;
                total_ct = total_ct + parseFloat(page_data.record_details[j]['new_wt']);
                new_ct = new_ct + parseFloat(page_data.record_details[j]['new_wt']);
                page_data.record_details[j]['sr_no'] = j + 1;
                sr++;
            }

            screenData.total_scan_pack_wt = total_ct;
            screenData.total_scan_new_wt = new_ct;
            screenData.total_scan_pack = total_pc;

        }

        const onGridRowSelect = () => {
            console.log(gridApi.value.getSelectedRows().length);
            if (gridApi.value.getSelectedRows().length == 0) {
                screenData.delete_row = true;
            } else {
                screenData.delete_row = false;
            }
        }

        const removeGridRowSelect = () => {
            console.log(gridApi.value.getSelectedRows());
            const sel = gridApi.value.getSelectedRows();
            console.log(sel);

            let remove_rows = [];
            var i = sel.length;
            while (i--) {
                remove_rows.push(sel[i]['barcode']);
            }
            gridApi.value.applyTransaction({
                remove: sel
            });
            console.log('Removeing row');
            console.log(remove_rows);
            var j = page_data.record_details.length;
            while (j--) {
                if (remove_rows.includes(page_data.record_details[j]['barcode'])) {
                  page_data.record_details.splice(j, 1);
                }
            }

            if (page_data.record_details.length == 0) {
                screenData.form_type == 'create'
            }

            setTimeout(reCalculateTotal, 50);
        }

        const takeMultiReturn = () => {
            // gridApi.value.stopEditing();
            if (page_data.record_details.length == 0) {
                swal("Error!", "Kindly select packets for return, Packet count is zero", "error");
                return;
            }

            let total_pcs = page_data.record_details.length;
            let i = 0;
            while (i < total_pcs) {
                console.log(page_data.record_details[i].cr_wt);
                if (page_data.record_details[i].cr_wt == '' || page_data.record_details[i].cr_wt == undefined) {
                    swal("Error!", "Kindly Enter Return Weight to all packet and retry!", "error");
                    return;
                }
                i++;
            }

            let api_url = "api/full-process/return_lab_packets"
            //screenData.return_progress = true;
            swal({
                title: "Are you sure?",
                text: "You are about to take return entry!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Return",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                preConfirm: login => {

                    const data = {
                        packet_details: page_data.record_details,
                    };

                    let promise = axios({
                        url: api_url,
                        method: "post",
                        data: data,
                        baseURL: BASE_URL,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        }
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {

                            if (data.status == "success") {
                                if (data.type == "success") {

                                    swal.fire({
                                        icon: 'success',
                                        title: "Success!",
                                        text: data.message,
                                        showConfirmButton: false,
                                        timer: 1500
                                    })
                                    // swal("Success!", data.message, "success");
                                } else {
                                    swal("Warning!", data.message, "warning");
                                    return;
                                }
                                document.querySelector('[id="scan_lot_no"]').focus();

                                let new_items = data.items

                                page_data.record_details = []

                            } else if (data.status == "error") {
                                swal("Error!", data.message, "error");
                                screenData.return_progress = false;
                                return;
                            }
                        })
                        .catch(_error => {
                            screenData.return_progress = false;
                            return [];
                        });
                },
                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.value) {
                    screenData.return_progress = false;
                }
            });
        }

        const resetData = () => {
            screenData.form_type = 'create'
            page_data.record_details = []
            screenData.total_scan_pack_wt = 0;
            screenData.total_scan_new_wt = 0;
            screenData.total_scan_pack = 0;
        }

        onMounted(() => {
          page_data.record_details = []
            focusScan()

        })

        return {
            filter,
            screenData,
            defaultColDef,
            rowData,
            columnDefs,
            overlayNoRowsTemplate,
            inputFileClick,
            scanAndAddPack,
            onGridReady,
            resetData,
            takeMultiReturn,
            getRowId,
            focusScan,
            onGridRowSelect,
            removeGridRowSelect,
            getExcelData,
            page_data
        }

    }
})
</script>

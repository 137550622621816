<template>
  <!-- BEGIN: Profile Info -->
  <div id="basic-non-sticky-notification-packet-found" class="toastify-content hidden flex flex-col sm:flex-row">
      <div class="font-medium text-primary-3" v-if="errors.error">{{ errors.error }}</div>
  </div>
  <div id="basic-non-sticky-notification-packet-assign" class="toastify-content hidden flex flex-col sm:flex-row">
      <div class="flex items-center justify-around">
        <CheckCircleIcon class="w-6 h-6 text-theme-9 mx-auto" />
        <div class="font-medium text-black" v-if="pagedata.messages.success">{{ pagedata.messages.success }}</div>
      </div>
  </div>
  <div>
    <div class="xl:grid block xl:grid-cols-2 gap-6">
      <div>
        <h2 class="intro-y text-lg font-medium mt-5 ml-2">Scan Packet</h2>
        <div class="flex items-center mt-2 ml-2">
          <div class="mr-2 flex items-center">
            <input
              id="remember-me"
              type="checkbox" v-model="pagedata.check"
              class="form-check-input border mr-2 bg-white"
            />
            <label for="remember-me" class="text-md font-medium">Menual</label>
          </div>
        </div>
        <div class="w-full flex sm:w-auto mt-3 ml-2">
          <div class="w-56 relative text-gray-700 dark:text-gray-300 mr-4">
            <input
              type="text"
              v-model="pagedata.search"
              autofocus
              ref="focusMe"
              class="form-control w-56 box pr-10 placeholder-theme-13"
              placeholder="Search Packet"
            />
            <SearchIcon
              class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
            />
          </div>
          <button class="btn btn-primary shadow-md mr-2" @click="AddtoList">Add</button>
          <button class="btn btn-primary shadow-md mr-2" @click="acceptPackets" v-if="addlist.length > 0">Accept</button>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-5 mt-5">
      <div
        class="col-span-6 xl:col-span-2 md:col-span-3 sm:col-span-4 box p-5 cursor-pointer zoom-in" v-for="(add,index) in addlist" :key="index"
      >
        <div class="flex justify-between">
          <div class="font-medium text-base">{{ add }}</div>
          <button class="btn btn-outline-danger p-1 pl-2 hover:text-white" @click="removeItem(index)"><Trash2Icon class="w-4 h-4 mr-1" /></button>
        </div>
      </div>
    </div>
    <!-- BEGIN: Data List -->
    <h2 class="intro-y text-lg font-medium mt-5 ml-2">Transfered Packets</h2>
    <div v-if="pagedata.packets.length > 0">
      <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
        <table class="table-report mt-3 w-full">
          <thead>
            <tr>
              <th class="py-2 text-center">Pkt No</th>
              <th class="py-2 text-center whitespace-nowrap">Vepari No</th>
              <th class="py-2 text-center">Party</th>
              <th class="py-2 text-center whitespace-nowrap">Old Manager</th>
              <th class="py-2 text-center">Weight</th>
              <th class="py-2 text-center">Shape</th>
              <th class="py-2 text-center">Color</th>
              <th class="py-2 text-center">Purity</th>
              <th class="py-2 text-center">Cut</th>
              <th class="py-2 text-center">Polish</th>
              <th class="py-2 text-center">Symm</th>
              <th class="py-2 text-center">Fluc</th>
              <th class="py-2 text-center">Tension</th>
              <th class="py-2 text-center">Stone</th>
              <th class="py-2 text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(packet, index) in pagedata.packets"
              :key="index"
              class="intro-x"
            >
              <td class="text-center" :class="{ 'text-primary-3' : packet.urgent == 1 }">
                <div class="py-2 font-medium whitespace-nowrap">{{
                  packet.number
                }}</div>
              </td>
              <td :class="{ 'text-primary-3' : packet.urgent == 1 }" class="py-2 font-medium text-center">{{ packet.vepari_no }}</td>
              <td :class="{ 'text-primary-3' : packet.urgent == 1 }" class="py-2 font-medium text-center">{{ packet.party }}</td>
              <td :class="{ 'text-primary-3' : packet.urgent == 1 }" class="py-2 font-medium text-center">{{ packet.manager_trans }}</td>
              <td :class="{ 'text-primary-3' : packet.urgent == 1 }" class="py-2 font-medium text-center">{{ packet.weight }}</td>
              <td :class="{ 'text-primary-3' : packet.urgent == 1 }" class="py-2 font-medium text-center">{{ packet.shape }}</td>
              <td :class="{ 'text-primary-3' : packet.urgent == 1 }" class="py-2 font-medium text-center">{{ packet.color }}</td>
              <td :class="{ 'text-primary-3' : packet.urgent == 1 }" class="py-2 font-medium text-center">{{ packet.purity }}</td>
              <td :class="{ 'text-primary-3' : packet.urgent == 1 }" class="py-2 font-medium text-center">{{ packet.cut }}</td>
              <td :class="{ 'text-primary-3' : packet.urgent == 1 }" class="py-2 font-medium text-center">{{ packet.polish }}</td>
              <td :class="{ 'text-primary-3' : packet.urgent == 1 }" class="py-2 font-medium text-center">{{ packet.symmetry }}</td>
              <td :class="{ 'text-primary-3' : packet.urgent == 1 }" class="py-2 font-medium text-center">{{ packet.fluerocent }}</td>
              <td :class="{ 'text-primary-3' : packet.urgent == 1 }" class="py-2 font-medium text-center">{{ packet.tension }}</td>
              <td :class="{ 'text-primary-3' : packet.urgent == 1 }" class="py-2 font-medium text-center">{{ packet.stone }}</td>
              <td class="table-report__action w-40">
                <div class="flex justify-center items-center">
                  <a class="flex items-center mr-3" href="javascript:;" data-target="#button-modal-preview"
                    data-toggle="modal" @click="pagedata.delId = packet.transferId">
                    <CheckSquareIcon class="w-4 h-4 mr-1" />
                    Accept
                  </a>
                  <a
                    class="flex items-center text-theme-6"
                    href="javascript:;"
                    data-toggle="modal"
                    @click="pagedata.delId = packet.transferId"
                    data-target="#delete-confirmation-modal"
                  >
                    <Trash2Icon class="w-4 h-4 mr-1" /> Cancel
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
    </div>
    <div v-else class="text-center">No Packet Found</div>
  </div>
  <!-- END: Profile Info -->
  <!-- BEGIN: Delete Confirmation Modal -->
    <div
      id="delete-confirmation-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
              <div class="text-3xl mt-5">Are you sure?</div>
              <div class="text-gray-600 mt-2">
                Do you really want to Cancel these Request? <br />This process
                cannot be undone.
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-24 mr-1"
              >
                Cancel
              </button>
              <button type="button" class="btn btn-danger w-24" @click="cancelRequest">Confirm</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Delete Confirmation Modal -->
    <!-- BEGIN: Modal Content -->
              <div
                id="button-modal-preview"
                class="modal"
                tabindex="-1"
                aria-hidden="true"
              >
                <div class="modal-dialog">
                  <div class="modal-content">
                    <a data-dismiss="modal" href="javascript:;">
                      <XIcon class="w-8 h-8 text-gray-500" />
                    </a>
                    <div class="modal-body p-0">
                      <div class="p-5 text-center">
                        <CheckCircleIcon
                          class="w-16 h-16 text-theme-9 mx-auto mt-3"
                        />
                        <div class="text-3xl mt-5">Accept Request</div>
                        <div class="text-gray-600 mt-2">
                          Are you Sure?
                        </div>
                      </div>
                      <div class="px-5 pb-8 text-center">
                        <button
                          type="button" @click="acceptPacket"
                          class="btn btn-primary w-24"
                        >
                          Accept
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- END: Modal Content -->
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import axios from 'axios'
import cash from 'cash-dom'
import { computed, defineComponent, onMounted, reactive, ref, toRef, watch } from 'vue'
import print from 'vue3-print-nb'
import Toastify from 'toastify-js'
export default defineComponent({
  directives: {
    print
  },
  setup() {
    const pagedata = reactive({
      packets: [],
      errors: [],
      addlist: [],
      search: '',
      check: false,
      waiting: false,
      addornot: false,
      delId: '',
      messages: []
    })
    const errors = toRef(pagedata,'errors')
    const addlist = toRef(pagedata,'addlist')
    onMounted(() => {
      getPacketDetails('receive')
    })
    function getPacketDetails(trans) {
      axios({
          url: `/transfer/transfered-packets?type=${trans}`,
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.packets = res.data.packets
        }).catch((err) => {
          pagedata.errors = err.response.data
          setTimeout(() => {
            basicNonStickyNotificationPacketFound()
          },100)
          setTimeout(() => {
            pagedata.errors = []
          }, 5000);
        })
    }
    watch(() => pagedata.search, (currentValue) => {
      if(!pagedata.check) {
        if (!pagedata.waiting) {
          setTimeout(() => {
            AddtoList()
            pagedata.waiting = false;
          }, 200); // 1 sec delay
        }
        pagedata.waiting = true;
      }
    })
    function AddtoList() {
      if(pagedata.search != '') {
        if(pagedata.addlist.length <= 0) {
          checkadd()
        } else {
          for (let i = 0; i < pagedata.addlist.length; i++) {
            if(pagedata.addlist[i] == pagedata.search) {
              pagedata.addlist.splice(i,1)
              pagedata.addornot = true
              pagedata.search = ''
              break
            } else {
              pagedata.addornot = false
            }

          }
          if(pagedata.addornot == false) {
              checkadd()
            }
        }
      }
    }
    function cancelRequest() {
      axios({
        url: `/transfer/transfered-cancel-request/${pagedata.delId}`,
        method: 'get',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.messages.success = res.data.success
        cash('#delete-confirmation-modal').modal('hide')
        setTimeout(() => {
          basicNonStickyNotificationPacketAssign()
        }, 100);
        getPacketDetails('receive')
      }).catch((err) => {
        pagedata.errors = err.response.data
        setTimeout(() => {
          basicNonStickyNotificationPacketFound()
        },100)
        setTimeout(() => {
          pagedata.errors = []
        }, 5000);
      })
    }
    function removeItem(id) {
      console.warn(id)
      pagedata.addlist.splice(id, 1);
    }
    function checkadd() {
      axios({
        url: `/transfer/transfered-packets-scan/${pagedata.search}`,
        method: 'get',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.addlist[pagedata.addlist.length] = res.data.packet.number
        pagedata.search = ''
      }).catch((err) => {
        pagedata.errors = err.response.data
        console.warn(pagedata.errors)
        setTimeout(() => {
          basicNonStickyNotificationPacketFound()
        },100)
        setTimeout(() => {
          pagedata.errors = []
        }, 5000);

      })
    }
    function acceptPackets() {
      axios({
        url: '/transfer/transfered-accept-all-request',
        method: 'post',
        baseURL: BASE_URL,
        data: {packets: pagedata.addlist},
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.messages.success = res.data.success
        setTimeout(() => {
          basicNonStickyNotificationPacketAssign()
        }, 100);
        pagedata.addlist = []
        getPacketDetails('receive')
      }).catch((err) => {
        pagedata.errors = err.response.data
        setTimeout(() => {
          basicNonStickyNotificationPacketFound()
        },100)
        setTimeout(() => {
          pagedata.errors = []
        }, 5000);

      })
    }
    function acceptPacket() {
      axios({
        url: `/transfer/transfered-accept-request/${pagedata.delId}`,
        method: 'get',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.messages.success = res.data.success
        setTimeout(() => {
          basicNonStickyNotificationPacketAssign()
        }, 100);
        cash('#button-modal-preview').modal('hide')
        for (let i = 0; i < pagedata.addlist.length; i++) {
            if(pagedata.addlist[i] == res.data.number) {
              pagedata.addlist.splice(i,1)
              break
          }
        }
        getPacketDetails('receive')
      }).catch((err) => {
        pagedata.errors = err.response.data

        console.warn(pagedata.errors)
        setTimeout(() => {
          basicNonStickyNotificationPacketFound()
        },100)
        setTimeout(() => {
          pagedata.errors = []
        }, 5000);

      })
    }
    const basicNonStickyNotificationPacketAssign = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification-packet-assign')
          .clone()
          .removeClass('hidden')[0],
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    const basicNonStickyNotificationPacketFound = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification-packet-found')
          .clone()
          .removeClass('hidden')[0],
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    return {
      pagedata,
      errors,
      addlist,
      basicNonStickyNotificationPacketAssign,
      basicNonStickyNotificationPacketFound,
      cancelRequest,
      acceptPacket,
      acceptPackets,
      AddtoList,
      removeItem
    }
  },
  mounted() {
    this.$refs.focusMe.focus()
  }
})
</script>

export default {
    template: `<select v-model="cellValue">
    <option v-for="shape_item in shape" :key="shape_item.id" :value="shape_item.id" >{{shape_item.name}}</option>
    </select>`,
    setup(props) {
        console.log(props.params.value);
        const cellValue = props.params.value
        const shape = [{
            id:1,
            name:"English"
        },{
            id:2,
            name:"Gujrati"
        }]
        
      return {
        shape,
        cellValue,
      };
    },
  };
<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Transaction Details</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
            <button @click="print_jangad" class="btn btn-primary btn-sm shadow-md mr-2"><PrinterIcon class="w-4 h-4" /></button>
        </div>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
            <button @click="export_stock" class="btn btn-primary btn-sm shadow-md mr-2">Export</button>
        </div>
    </div>
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">

        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5" v-if="transaction_details.transaction_status == 1 && (transaction_details.transaction_type == 1 || transaction_details.transaction_type == 4 || transaction_details.transaction_type == 3 || transaction_details.process_type == 3 || transaction_details.process_type == 4)">
            <h2 class="font-medium text-base mr-auto">Transaction Details</h2>
            <div class="flex -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0" v-if="transaction_details.issue_by == user_details.id">
              <button
              class="btn btn-primary shadow-md mr-2"
              @click="editTransactionStatus(transaction_details.id,transaction_details.issue_by,'withdraw');">
              Withdraw
              </button>
            </div>
            <div class="flex -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0" v-else>
              <button
              class="btn btn-primary shadow-md mr-2"
              v-if="cr_status != 2 && issue_type == 1 && process_id != 18 && process_id != 19 && process_id != 20 && process_id != 32"
              @click="editTransactionStatus(transaction_details.id,transaction_details.issue_by,'accept');">
              Accept
              </button>
            </div>
          </div>
          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-4 xl:col-span-4">

                <div>
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Transaction No. :&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ transaction_details.transaction_code }}</label
                  >
                </div>

                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label font-bold"
                   >Issue Pieces : &nbsp;</label
                 ><label for="update-profile-form-6" class="form-label"> {{ transaction_details.pieces }}</label
                 >
               </div>
                <div class="mt-3" v-if="transaction_details.receive_pieces">
                  <label for="update-profile-form-6" class="form-label font-bold"
                   >Receive Pieces : &nbsp;</label
                 ><label for="update-profile-form-6" class="form-label"> {{ transaction_details.receive_pieces }}</label
                 >
               </div>
                 <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Issue Weight : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(transaction_details.issue_weight) }} (Ct)</label
                  >
                </div>
                 <div class="mt-3" v-if="transaction_details.receive_weight">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Receive Weight : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(transaction_details.receive_weight) }} (Ct)</label
                  >
                </div>
                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Issue For : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label">
                    <span v-if="transaction_details.transaction_type == 1">Manager Transfer</span>
                    <span v-if="transaction_details.transaction_type == 4">Job Done Transfer</span>
                    <span v-if="transaction_details.transaction_type == 2">Process</span>
                    <span v-if="transaction_details.transaction_type == 3">Process Done Transfer</span>
                  </label>
                </div>

              </div>
              <div class="col-span-4 xl:col-span-4">

                 <div class="" v-if="transaction_details.transaction_type == 2">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Process Name : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ transaction_details.process_name }}</label
                  >
                </div>
                 <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Worker Name : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label">
                    <span v-if="user_details.id == transaction_details.issue_by">
                      <span v-if="transaction_details.transaction_type == 1 || transaction_details.transaction_type == 3 || transaction_details.transaction_type == 4">{{ transaction_details.issue_manager_first_name+ ' ' + transaction_details.issue_manager_last_name }}</span>
                      <span v-else-if="transaction_details.transaction_type == 2">
                        <span v-if="transaction_details.process_type == 1 || transaction_details.process_type == 2 || transaction_details.process_type == 5 || transaction_details.process_type == 6 || transaction_details.process_type == 7">
                          <span v-if="transaction_details.employee_role == 7">{{ (transaction_details.karigar_first_name ? transaction_details.karigar_first_name : '') + ' ' + (transaction_details.karigar_last_name ? transaction_details.karigar_last_name : '') }}</span>
                          <span v-if="transaction_details.employee_role == 4">{{ (transaction_details.pp_first_name ? transaction_details.pp_first_name : '') + ' ' + (transaction_details.pp_last_name ? transaction_details.pp_last_name : '') }}</span>
                        </span>
                        <span v-if="transaction_details.process_type == 1 || transaction_details.process_type == 2">
                          <span>{{ (transaction_details.planner_first_name ? transaction_details.planner_first_name : '') + ' ' + (transaction_details.planner_last_name ? transaction_details.planner_last_name : '') }}</span>
                        </span>
                      </span>
                    </span>
                    <span v-else>
                      <span>{{ (transaction_details.manager_first_name ? transaction_details.manager_first_name : '') + ' ' + (transaction_details.manager_last_name ? transaction_details.manager_last_name : '') }}</span>
                    </span>
                  </label>
                </div>
                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Manager Name : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label">

                   <span v-if="user_details.id != transaction_details.issue_by && (user_details.user_role == 2 || user_details.user_role == 8 || user_details.user_role == 1)"> {{ transaction_details.manager_first_name }} {{ transaction_details.manager_last_name }} </span>
                   <span v-else> {{ (transaction_details.issue_manager_first_name ? transaction_details.issue_manager_first_name : '')+ ' ' + (transaction_details.issue_manager_last_name ? transaction_details.issue_manager_last_name : '') }} </span>
                  </label>
                </div>
                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Issue Date Time : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ moment(transaction_details.created_at).format('DD/MM/YYYY hh:mm:ss') }}</label
                  >
                </div>
                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Receive Date Time : &nbsp;</label
                  ><label for="update-profile-form-6" class="form-label"> {{ transaction_details.receive_date ? moment(transaction_details.receive_date).format('DD/MM/YYYY hh:mm:ss') : '-' }}</label
                  >
                </div>
                 <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Status : &nbsp;</label>
                    <label for="update-profile-form-6" class="form-label">
                      <span v-if="transaction_details.transaction_type == 1 || transaction_details.transaction_type == 4 || transaction_details.transaction_type == 3 || (transaction_details.transaction_type == 2 && (transaction_details.process_type  == 5 || transaction_details.process_type  == 6))">
                        <span v-if="transaction_details.status == 1 && transaction_details.transaction_status == 1" class="text-theme-11 font-bold">Hold</span>
                        <span v-if="transaction_details.status == 1 && transaction_details.transaction_status == 2" class="text-theme-9 font-bold">Accepted</span>
                        <span v-if="transaction_details.status == 3 || transaction_details.transaction_status == 3" class="text-theme-6 font-bold">Cancelled</span>

                      </span>
                      <span v-else>
                        <span v-if="transaction_details.status == 1 && transaction_details.transaction_status == 2" class="text-theme-9 font-bold">In Process</span>
                        <span v-if="transaction_details.status == 2 && transaction_details.transaction_status == 2" class="text-theme-6 font-bold">Received</span>
                        <span v-if="transaction_details.status == 3 || transaction_details.transaction_status == 3" class="text-theme-6 font-bold">Cancelled</span>
                      </span>
                    </label>
                </div>


              </div>
          </div>
          </div>
        </div>
        <div class="intro-y box mt-10">
          <div class="col-span-12 xl:col-span-12">
              <div
                class="flex flex-col sm:flex-row items-center pt-5 pb-5 border-b border-gray-200 dark:border-dark-5"
              >
              <h2 class="font-medium text-base mr-auto pl-5">Selected Packets</h2>
              </div>
              <div class="p-5">
              <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12">
                <div class="border">
                  <div class="grid grid-cols-12 sticky top-0 pl-5 pr-5 pb-2 mt-3" style="background-color:#fff;">

                    <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1 text-left">
                      <p>Sr. No</p>
                    </div>
                    <div class="col-span-6 md:col-span-2 font-bold xl:col-span-2 text-center">
                      <p>Transaction</p>
                    </div>
                    <div class="col-span-6 md:col-span-2 font-bold xl:col-span-2 text-center">
                      <p>Packet</p>
                    </div>
                    <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1 text-center">
                      <p>Stock No</p>
                    </div>
                    <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1 text-center">
                      <p>Type</p>
                    </div>
                    <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1 text-center">
                      <p>Issue WT(Ct)</p>
                    </div>
                    <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1 text-center">
                      <p>Polish WT(Ct)</p>
                    </div>
                    <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1 text-center" v-if="transaction_details.process_type == 6">
                      <p>Polish Party</p>
                    </div>
                    <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1 text-center">
                      <p>Return Date</p>
                    </div>
                    <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1 text-center">
                      <p>Status</p>
                    </div>
                  </div>

                  <div class="border" v-for="(field,index) in transaction_packets" :key="index">
                    <div class="grid grid-cols-12">
                      <div class="col-span-6 md:col-span-1 xl:col-span-1 p-1 text-left">
                        <label class="p-2 mt-2">{{index+1}}</label>
                      </div>
                      <div class="col-span-6 md:col-span-2 xl:col-span-2 p-2 text-center">
                        <label class="p-2 mt-3">{{field.transaction_code}}</label>
                      </div>
                      <div class="col-span-6 md:col-span-2 xl:col-span-2 p-2 text-center">
                        <label class="p-2 mt-3">{{field.number}}</label>
                      </div>
                      <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2 text-center">
                        <label class="p-2 mt-3">{{field.vepari_no}}</label>
                      </div>
                      <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2 text-center">
                        <label class="p-2 mt-3">{{field.packet_type}}</label>
                      </div>
                      <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2 text-center">
                        <label class="p-2 mt-3">{{field.issue_weight ? $h.myFloat(field.issue_weight) : '0'}} Ct</label>
                      </div>
                      <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2 text-center">
                        <label class="p-2 mt-3">{{field.cr_polish_weight ? $h.myFloat(field.cr_polish_weight) : '0'}} Ct</label>
                      </div>
                      <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2 text-center" v-if="transaction_details.process_type == 6">
                        <label class="p-2 mt-3">{{field.pp_company_name ? field.pp_company_name : (field.pp_first_name + ' ' + field.pp_last_name)}} </label>
                      </div>
                      <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2 text-center">
                        <label class="p-2 mt-3">{{(field.receive_date ? moment(field.receive_date).format('D/M/YYYY hh:mm:ss') : '-')}}</label>
                      </div>
                      <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2 text-left">
                        <label class="p-2 mt-3">
                          <span v-if="field.transaction_type == 1 || field.transaction_type == 4 || field.transaction_type == 3">
                            <span v-if="field.status == 1 && field.transaction_status == 1" class="text-theme-11 font-bold">Hold</span>
                            <span v-if="field.status == 1 && field.transaction_status == 2" class="text-theme-9 font-bold">Accepted</span>
                            <span v-if="field.status == 3 || field.transaction_status == 3" class="text-theme-6 font-bold">Cancelled</span>

                          </span>
                          <span v-else>
                            <span v-if="field.status == 1 && field.transaction_status == 1" class="text-theme-11 font-bold">Hold</span>
                            <span v-if="field.status == 1 && field.transaction_status == 2" class="text-theme-9 font-bold">In Process</span>
                            <span v-if="field.status == 2 && field.transaction_status == 2" class="text-theme-6 font-bold">Received</span>
                            <span v-if="field.status == 3 || field.transaction_status == 3" class="text-theme-6 font-bold">Cancelled</span>
                          </span></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
      </div>
    </div>

  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import { mapGetters, mapActions } from 'vuex'

import { BASE_URL } from '@/constants/config.js'
import axios from 'axios'
import datetime from 'vuejs-datetimepicker';
import { helper as $h } from '@/utils/helper'
import moment from 'moment';
import FileDownload from "js-file-download";
// const router = useRouter();
// const route = useRoute();

export default defineComponent({
  components: {
    datetime
  },
  data() {
    return {
      transaction_details: [],
      user_details: this.$store.state.user.currentUser,
      transaction_packets: [],
      req: axios.create({
        baseUrl: BASE_URL
      }),
      moment : moment
    }
  },
  mounted(){
  this.getRoughDetails(this.$route.params.id);
  },

  methods: {
    async getRoughDetails(id) {
      let _this = this;
      const params = {
        trans_id: id
      };
      let promise = axios({
        url: "api/process/get_transaction_details",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          this.transaction_details = data.transaction_details;
          this.transaction_packets = data.transaction_packets;

        })
        .catch(_error => {

        });
    },
    editTransactionStatus(id,user_id,action) {
      console.log(id);
      console.log(user_id);
      let trans_id = id;
      let sender_type='';
      if(user_id == this.user_details.id){
        sender_type = "sender";
      }else{
        sender_type = "receiver";
      }

      const params = {
      "trans_id":  trans_id,
      'sender_type' :sender_type,
      'action':action
      };

      this.$swal({
        title: "Are you sure?",
        text: "You want to" + action +"Request !",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm & Accept",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {

          let promise = axios({
            url: "api/purchase/hold_action_packet_trans",
            method: "post",
            data: params,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data)
            .then(data => {
              console.log(data.message);
              if(data.status == "error"){
                swal("Error!", data.message, "error");
              }else{
                this.getRoughDetails(this.$route.params.id)
                // router.go();
                // this.$router.push({path: '/packets/transaction'});
              }
            })
            .catch(_error => {
              return [];
          });
        },
        allowOutsideClick: () => !swal.isLoading()
      }).then(result => {
        if (result.value) {
          //swal("Success!", "User Password Updated.", "success");
        }
      });
    },
    print_jangad() {
      let url = BASE_URL + 'jangad' + '?id=' + this.$route.params.id
              window.open(url, '_blank');
    },
    export_stock() {

    const today = new Date();
    const date = today.getDate() + '' + (today.getMonth() + 1) + '' + today.getFullYear();
    // const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    const dateTime = date;
    let filename = dateTime + "_stock.xlsx";
    let params = {
        id: this.$route.params.id
    };
    let promise = axios({
            url: "api/export/pjc_ex",
            method: "post",
            data: params,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: { headers: { Accept: "application/json" } },
            responseType: "blob",
          });
      return promise.then(({
            data
        }) => {
            // console.warn(data)
            FileDownload(data, filename);
        })
        .catch(({
            response
        }) => {
            Swal.fire({
                text: "Something went wrong.",
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Try again!",
                heightAuto: false,
                customClass: {
                    confirmButton: "btn fw-semibold btn-light-danger btn-sm",
                },
            }).then(() => {
                return;
            });

        });

    }

  }

})
</script>

<template>
  <div>
    <div id="basic-non-sticky-notification-packet" class="toastify-content hidden flex flex-col sm:flex-row">
      <div class="font-medium text-primary-3" v-if="pagedata.error">{{ pagedata.error }}</div>
      <div class="font-medium text-primary-4" v-if="pagedata.success">{{ pagedata.success }}</div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex items-center justify-between">
        <h2 class="text-lg font-bold">Processing Packets</h2>
        <div class="flex items-center">
          <button @click="newPlan" :disabled="!pagedata.editable" class="btn btn-primary"> New Plan</button>
          <button class="btn btn-danger ml-2" @click="planDoneShow" :disabled="!pagedata.editable"> Plan Done >> </button>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <!-- BEGIN: Transfer to Manager Process -->
    <div id="transfer-confirmation-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-3">
            <div class="p-5 text-center">
              <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
              <div class="text-3xl mt-5">Are you sure?</div>
              <div class="text-gray-600 mt-2">
                Do you really want to Send Request?
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-24 mr-1"
              >
                Cancel
              </button>
              <button type="button" class="btn btn-danger w-24" :disabled="pagedata.submit" @click="sendPacketManager">Send
              <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!-- END: Transfer to Manager Process -->
  <!-- BEGIN: Packet Plan Details Modal -->
  <div
    id="packet-plans-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <a data-dismiss="modal" href="javascript:;">
          <XIcon class="w-8 h-8 text-gray-500" />
        </a>
        <div class="modal-body py-5 text-center">
          <!-- BEGIN: HTML Table Data -->
          <div class="intro-y box p-5 mt-5">
            <div class="overflow-x-auto scrollbar-hidden">
              <table class="w-full">
                <thead>
                  <tr class="bg-gray-700 dark:bg-dark-1 text-white">
                    <th class="whitespace-nowrap p-1">#</th>
                    <th class="whitespace-nowrap p-1">Number</th>
                    <th class="whitespace-nowrap p-1">PWT</th>
                    <th class="whitespace-nowrap p-1">Shape</th>
                    <th class="whitespace-nowrap p-1">Color</th>
                    <th class="whitespace-nowrap p-1">Purity</th>
                    <th class="whitespace-nowrap p-1">Cut</th>
                    <th class="whitespace-nowrap p-1">Polish</th>
                    <th class="whitespace-nowrap p-1">Symm</th>
                    <th class="whitespace-nowrap p-1">Fluc</th>
                    <th class="whitespace-nowrap p-1">Tension</th>
                    <th class="whitespace-nowrap p-1">Stone</th>
                    <th class="whitespace-nowrap p-1">R Rate</th>
                    <th class="whitespace-nowrap p-1">S Rate</th>
                    <th class="whitespace-nowrap p-1">Tabl</th>
                    <th class="whitespace-nowrap p-1">Height</th>
                    <th class="whitespace-nowrap p-1">Discount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(plandet, i) in pagedata.plans"
                    :key="i">
                    <td class="border-b dark:border-dark-5 p-1">{{ i + 1 }}</td>
                    <td class="border-b dark:border-dark-5 p-1">{{ plandet.number }}</td>
                    <td class="border-b dark:border-dark-5 p-1">{{ plandet.pwt }}</td>
                    <td class="border-b dark:border-dark-5 p-1">{{ plandet.shape }}</td>
                    <td class="border-b dark:border-dark-5 p-1">{{ plandet.color }}</td>
                    <td class="border-b dark:border-dark-5 p-1">{{ plandet.purity }}</td>
                    <td class="border-b dark:border-dark-5 p-1">{{ plandet.cut }}</td>
                    <td class="border-b dark:border-dark-5 p-1">{{ plandet.polish }}</td>
                    <td class="border-b dark:border-dark-5 p-1">{{ plandet.symmetry }}</td>
                    <td class="border-b dark:border-dark-5 p-1">{{ plandet.fluerocent }}</td>
                    <td class="border-b dark:border-dark-5 p-1">{{ plandet.tension }}</td>
                    <td class="border-b dark:border-dark-5 p-1">{{ plandet.stone }}</td>
                    <td class="border-b dark:border-dark-5 p-1">{{ plandet.r_rate }}</td>
                    <td class="border-b dark:border-dark-5 p-1">{{ plandet.s_rate }}</td>
                    <td class="border-b dark:border-dark-5 p-1">{{ plandet.tabl }}</td>
                    <td class="border-b dark:border-dark-5 p-1">{{ plandet.height }}</td>
                    <td class="border-b dark:border-dark-5 p-1">{{ plandet.discount }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- END: HTML Table Data -->
        </div>
      </div>
    </div>
  </div>
  <!-- END: Packet Plan Details Modal -->
  </div>
  <!-- END: Profile Info -->
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import axios from 'axios'
import cash from 'cash-dom'
import { defineComponent, onMounted, computed, reactive, ref, watch } from 'vue'
import print from 'vue3-print-nb'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import Toastify from 'toastify-js'
import router from '@/router'
import { useRoute } from 'vue-router'
import store from '@/store'
export default defineComponent({
  directives: {
    print
  },
  setup() {
    const pagedata = reactive({
      search: '',
      check: false,
      submit: false,
      waiting: false,
      editable: false,
      error: '',
      success: '',
      plans: []
    })
    const route = useRoute()
    const tableRef = ref()
    const tabulator = ref()
    const accesses = computed(() => store.state.user.actions)
    onMounted(() => {
      initTabulator()
      Editable()
    })
    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + `/rough/plans/${route.params.id}`,
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [
          {
            formatter: 'responsiveCollapse',
            width: 40,
            minWidth: 30,
            hozAlign: 'center',
            vertAlign: 'middle',
            resizable: false,
            headerSort: false
          },

          // For HTML table
          {
            title: 'Packet No',
            responsive: 0,

            field: 'number',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().number
                }</div>
              </div>`
            }
          },
          {
            title: 'Total Weight',
            responsive: 0,
            field: 'total_weight',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().total_weight
                }</div>
              </div>`
            }
          },
          {
            title: 'Total Pwt',
            responsive: 0,
            field: 'total_pwt',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().total_pwt
                }</div>
              </div>`
            }
          },
          {
            title: 'Rates',
            responsive: 0,
            field: 'price',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().price
                }</div>
              </div>`
            }
          },
          {
            title: 'Pieces',
            field: 'pieces',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Status',
            field: 'status',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().status == 1 ? '<div class="text-primary-4">Active</div>' : ''
                }</div>
              </div>`
            }
          },
          {
            title: 'Action',
            field: 'action',
            minWidth: 100,
            vertAlign: 'middle',
            print: false,
            download: false,
            columns:[
            {
              field:"action",
              width:60,
              formatter(cell) {
                const a = cash(`<div class="flex lg:justify-center items-center"><button class="btn btn-primary p-1 px-2 mr-1"> View </button></div>`)
                cash(a).on('click', function() {
                    showPacketPlans(cell.getData().id)
                })

                return a[0]
              }
            },
            {
              field:"action",
              width:100,
              formatter(cell) {
                const a = cash(`<div class="flex lg:justify-center items-center">
                  ${
                    accesses.value.includes(246) && cell.getData().editable == 1 ? '<button class="btn btn-primary p-1 px-2 mr-1"> <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Edit Plan </button>':accesses.value.includes(246) && cell.getData().editable == 0 ? '<button disabled class="btn btn-primary p-1 px-2 mr-1"> <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Edit Plan </button>':''
                  }
                </div>`)
                cash(a).on('click', function() {
                  if(cell.getData().editable == 1 &&  accesses.value.includes(246)) {
                    router.push({ name: 'update-packet-plan', params: { id: cell.getData().id}})
                  }
                })

                return a[0]
              }
            }
            ],
          },
        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }
    function Editable() {
      axios({
          url: `/rough/plans-editable/${route.params.id}`,
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.editable = res.data.editable
        })
    }
    function PacketScan() {
      if(pagedata.search != '') {
        axios({
          url: `/rough/alltoAssign/${pagedata.search}`,
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          let number = res.data.packet
          router.push({ name: 'packet-plans', params: { id: number } })
        }).catch((err) => {
          pagedata.error = err.response.data.error
          setTimeout(() => {
            basicNonStickyNotificationPacket()
          },100)
          setTimeout(() => {
            pagedata.search = ''
          }, 1000);
          setTimeout(() => {
            pagedata.error = ''
          }, 5000);

        })
      }
    }
    function showPacketPlans(id) {
      axios({
        url: `/rough/show-packet-plans/${id}`,
        method: 'get',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.plans = res.data.data
        cash('#packet-plans-modal').modal('show')
      })
    }
    function sendPacketManager() {
      pagedata.submit = true
      axios({
        url: `/rough/plan-done-request/${route.params.id}`,
        method: 'get',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      }).then((res) => {
        pagedata.submit = false
        pagedata.success = res.data.success
        initTabulator()
        cash('#transfer-confirmation-modal').modal('hide')
        setTimeout(() => {
          basicNonStickyNotificationPacket()
        }, 100);
          router.push({ name: 'plan-packets'})
        setTimeout(() => {
          pagedata.success = ''
        }, 3000);
      }).catch((err) => {
        pagedata.submit = false
        pagedata.error = res.data.error
        cash('#transfer-confirmation-modal').modal('hide')
        setTimeout(() => {
          basicNonStickyNotificationPacket()
        }, 100);
        setTimeout(() => {
          pagedata.error = ''
        }, 3000);
      })
    }
    function newPlan() {
      router.push({ name: 'create-packet-plan', params: { id: this.$route.params.id}})
    }
    function planDoneShow() {
      cash('#transfer-confirmation-modal').modal('show')
    }
    watch(() => pagedata.search, (currentValue) => {
      if(!pagedata.check) {
        if (!pagedata.waiting) {
          setTimeout(() => {
            PacketScan()
            pagedata.waiting = false;
          }, 100); // 100 ms delay
        }
        pagedata.waiting = true;
      }
    })
    const basicNonStickyNotificationPacket = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification-packet')
          .clone()
          .removeClass('hidden')[0],
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    return {
      pagedata,
      tableRef,
      initTabulator,
      sendPacketManager,
      showPacketPlans,
      newPlan,
      planDoneShow
    }
  }
})
</script>


<template>
<div>
    <!-- Headings -->
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Final Polish Report</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

        </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

        <div class="grid grid-cols-12 gap-x-2">
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div class="mt-2">
                  <div class="flex items-center"><label for="update-profile-form-6" class="form-label"
                    >Report Type</label
                  ><input
                    class="show-code form-check-switch mr-0 ml-3 h-6"
                    type="checkbox" v-model="filter.pending_packet"
                  /></div>
                    <TomSelect v-model="filter.report_type" :options="{
                  placeholder: 'Report Type',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Report'
                    }
                  }
                }" class="w-full">
                        <option value="1">PolishSummary</option>
                        <option value="2">Polish Lot Wise</option>
                        <option value="3">Pending Stock</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div class="mt-2">
                    <label for="update-profile-form-6" class="form-label">Packet / Lot</label>
                    <input id="tabulator-html-filter-value" v-model="filter.packet_no" type="text" class="form-control w-full mt-2 sm:mt-0" placeholder="Search Packet / Kapan" />
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div class="mt-2">
                    <label for="update-profile-form-6" class="form-label">Select Kapan</label>
                    <TomSelect v-model="filter.kapan_id" :options="{
                  placeholder: 'Select Kapan',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Process'
                    }
                  }
                }" class="w-full">
                        <option value="0">Select Kapan</option>
                        <option v-for="item in page_data.lot_items" :key="item.id" :value="item.id">{{item.lot_no}} ( {{ item.voucher_no ? item.voucher_no : '-' }} )</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2" v-if="[1,2,8].includes(user_details.user_role)">
                <div class="mt-2">
                    <label for="update-profile-form-6" class="form-label">Select Worker</label>
                    <TomSelect v-model="filter.worker_id" :options="{
                  placeholder: 'Select Worker',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Worker'
                    }
                  }
                }" class="w-full">
                        <option value="0">Select Worker</option>
                        <option v-for="item in page_data.employee_items" :key="item.id" :value="item.id">{{item.company_name}} ( {{ item.process_short_code ? item.short_code : '-' }} )</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div class="mt-2">
              <div class="flex items-center"><label for="update-profile-form-6" class="form-label"
                >Select Start Date</label
              ><input
                class="show-code form-check-switch mr-0 ml-3 h-6"
                type="checkbox" v-model="filter.issue_pending"
              /></div>
              <input
                type= "date"
                id="update-profile-form-13"
                :disabled="filter.issue_pending"
                class="form-control"
                placeholder=""
                v-model="filter.issue_date"
              />
            </div>

        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div class="mt-2">
            <label for="update-profile-form-6" class="form-label"
              >Select End Date</label
            >
            <input
              type= "date"
              id="update-profile-form-13"

              class="form-control"
              placeholder=""
              :disabled="filter.issue_pending"
              v-model="filter.receive_date"
            />
          </div>
        </div>
            <div class="col-span-2 md:col-span-2 xl:col-span-1">
                <div class="mt-7 xl:mt-2 flex pt-2">

                    <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2" @click="onResetFilter">
                        Reset
                    </button>
                </div>
            </div>
            <div class="col-span-2 md:col-span-2 xl:col-span-1">
                <div class="mt-7 xl:mt-2 flex pt-2">

                    <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full ml-2" tabindex="5" @click="getReportDetails()">
                        Generate
                    </button>
                </div>
            </div>
        </div>

        <div>
            <!-- BEgin: Process Report Start-->
            <div id="responsive-table" class="p-5" v-if="page_data.loading">
                <div class="preview">
                    <div class="overflow-x-auto">
                        <div class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center">
                            <LoadingIcon icon="bars" class="w-8 h-8" />
                            <div class="text-center text-xs mt-2">Getting Latest Price</div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else>
                <div class="scrollbar-hidden">
                    <div class="intro-y col-span-12 overflow-auto tableFixHead mt-3 border-2">
                        <table class="w-full border-0" v-if="api_filter.report_type == 1">
                            <thead>
                                <tr class="border-b bg-gray-300 dark:bg-dark-1">
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap border-b">#</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Packet No</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Stock No</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap" v-if="[1,2,8].includes(user_details.user_role)">Worker</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Issue Date</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Process Wight</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Planning PCS</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Planning WT</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Planning Polish WT</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Polish PCS</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Final Polish Pending PCS</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Final Polish PCS</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Final Polish Weight</th>
                                  </tr>
                                  <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.total_packets }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="[1,2,8].includes(user_details.user_role)"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_issue_weight ? $h.myFloat(page_data.total.total_issue_weight) : 0) }} Ct</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_ls_packets ? page_data.total.total_ls_packets : 0) }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_ls_weight ? $h.myFloat(page_data.total.total_ls_weight) : 0) }} Ct</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_ls_polish_weight ? $h.myFloat(page_data.total.total_ls_polish_weight) : 0) }} Ct</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_ls_packets ? page_data.total.total_ls_packets : 0) }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_pending_packets ? page_data.total.total_pending_packets : 0) }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_received_packets ? page_data.total.total_received_packets : 0) }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_received_weight ? $h.myFloat(page_data.total.total_received_weight) : 0) }} Ct</td>
                                  </tr>
                            </thead>
                                <tbody v-for="(item,index) in page_data.items" :key="index">

                                  <tr class="border-b hover:bg-gray-200 p-0" :class="{'bg-gray-100': (ind % 2) == 0}">
                                      <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ index + 1 }}</td>
                                      <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.number }}</td>
                                      <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.vepari_no }}</td>
                                      <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="[1,2,8].includes(user_details.user_role)">{{ item.company_name ? item.company_name : ((item.first_name ? item.first_name : '') + ' ' + (item.last_name ? item.last_name : '')) }}</td>
                                      <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.issue_date }}</td>
                                      <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.issue_weight ? $h.myFloat(item.issue_weight) : 0) }} Ct</td>
                                      <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.total_ls_packets ? item.total_ls_packets : 0) }}</td>
                                      <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.total_ls_weight ? $h.myFloat(item.total_ls_weight) : 0) }} Ct</td>
                                      <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.total_ls_polish_weight ? $h.myFloat(item.total_ls_polish_weight) : 0) }} Ct</td>
                                      <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.total_ls_packets ? item.total_ls_packets : 0) }}</td>
                                      <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.total_pending_packets ? item.total_pending_packets : 0) }}</td>
                                      <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.total_received_packets ? item.total_received_packets : 0) }}</td>
                                      <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.total_received_weight ? $h.myFloat(item.total_received_weight) : 0) }} Ct</td>
                                  </tr>
                              </tbody>

                        </table>
                        <table class="w-full border-0" v-if="api_filter.report_type == 2">
                            <thead>
                                <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">#</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Packet No</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Stock No</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Stone</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="[1,2,8].includes(user_details.user_role)">Worker</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Issue Date</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Process Wight</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Planning PCS</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Planning WT</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Planning Polish WT</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Polish PCS</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Polish Pending PCS</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Polish Received PCS</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Polish Received Weight</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r" colspan="5">Stocks</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r" colspan="8">Planning</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r" colspan="7" v-if="api_filter.pending_packet == false">Final Polish</th>
                                  </tr>
                                  <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="[1,2,8].includes(user_details.user_role)"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border">Packet No</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border">Stock No</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border">Cr Manager</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border">Cr Process</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border">Cr Employee</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border">Weight</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border">Polish Weight</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border">Shape</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border">Color</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border">Purity</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border">Cut</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border">Polish</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border">Symmetry</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="api_filter.pending_packet == false">Final WT</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="api_filter.pending_packet == false">Shape</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="api_filter.pending_packet == false">Color</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="api_filter.pending_packet == false">Purity</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="api_filter.pending_packet == false">Cut</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="api_filter.pending_packet == false">Polish</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="api_filter.pending_packet == false">Symmetry</th>
                                  </tr>
                                  <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.total_packets }}</td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r" v-if="[1,2,8].includes(user_details.user_role)"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_issue_weight ? $h.myFloat(page_data.total.total_issue_weight) : 0) }} Ct</td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_ls_packets ? page_data.total.total_ls_packets : 0) }}</td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_ls_weight ? $h.myFloat(page_data.total.total_ls_weight) : 0) }} Ct</td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_ls_polish_weight ? $h.myFloat(page_data.total.total_ls_polish_weight) : 0) }} Ct</td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_ls_packets ? page_data.total.total_ls_packets : 0) }}</td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_pending_packets ? page_data.total.total_pending_packets : 0) }}</td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_received_packets ? page_data.total.total_received_packets : 0) }}</td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_received_weight ? $h.myFloat(page_data.total.total_received_weight) : 0) }} Ct</td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.pending_packet == false"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.pending_packet == false"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.pending_packet == false"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.pending_packet == false"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.pending_packet == false"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.pending_packet == false"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.pending_packet == false"></td>
                                    </tr>
                            </thead>
                                <tbody>

                                  <template v-for="(main_pack,index) in page_data.items" :key="index">
                                    <template v-for="(item,inx) in main_pack.packet_details" :key="inx">

                                      <tr class="border-b hover:bg-gray-200 p-0" :class="{'bg-gray-100': (ind % 2) == 0}">
                                          <template v-if="inx == 0">
                                            <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ index + 1 }}</td>
                                            <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ main_pack.number }}</td>
                                            <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ main_pack.vepari_no }}</td>
                                            <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ main_pack.stone_name }}</td>
                                            <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="[1,2,8].includes(user_details.user_role)">{{ main_pack.company_name ? main_pack.company_name : ((main_pack.first_name ? main_pack.first_name : '') + ' ' + (main_pack.last_name ? main_pack.last_name : '')) }}</td>
                                            <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.issue_date }}</td>
                                            <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (main_pack.issue_weight ? main_pack.issue_weight : 0) }} Ct</td>
                                            <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (main_pack.total_ls_packets ? main_pack.total_ls_packets : 0) }}</td>
                                            <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (main_pack.total_ls_weight ? $h.myFloat(main_pack.total_ls_weight) : 0) }} Ct</td>
                                            <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (main_pack.total_ls_polish_weight ? $h.myFloat(main_pack.total_ls_polish_weight) : 0) }} Ct</td>
                                            <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (main_pack.total_ls_packets ? main_pack.total_ls_packets : 0) }}</td>
                                            <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (main_pack.total_pending_packets ? main_pack.total_pending_packets : 0) }}</td>
                                            <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (main_pack.total_received_packets ? main_pack.total_received_packets : 0) }}</td>
                                            <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (main_pack.total_received_weight ? $h.myFloat(main_pack.total_received_weight) : 0) }} Ct</td>
                                          </template>
                                          <th class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.sub_number ? item.sub_number : '-') }}</th>
                                          <th class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.sub_vepari_no ? item.sub_vepari_no : '-') }}</th>
                                          <th class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.manager_short_name ? item.manager_short_name : '-') }}</th>
                                          <th class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.process_short_code ? item.process_short_code : '-') }}</th>
                                          <th class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.employee_short_name ? item.employee_short_name : '-') }}</th>
                                          <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.sub_mk_weight ? item.sub_mk_weight : '-') }}</td>
                                          <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.sub_polish_weight ? item.sub_polish_weight : '-') }}</td>
                                          <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.ls_shape_name ? item.ls_shape_name : '-') }}</td>
                                          <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.ls_color_name ? item.ls_color_name : '-') }}</td>
                                          <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.ls_purity_name ? item.ls_purity_name : '-') }}</td>
                                          <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.ls_cut_name ? item.ls_cut_name : '-') }}</td>
                                          <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.ls_polish_name ? item.ls_polish_name : '-') }}</td>
                                          <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.ls_symmetry_name ? item.ls_symmetry_name : '-') }}</td>
                                          <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="api_filter.pending_packet == false">{{ (item.issue_weight ? item.issue_weight : '-') }}</td>
                                          <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="api_filter.pending_packet == false">{{ (item.final_shape_name ? item.final_shape_name : '-') }}</td>
                                          <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="api_filter.pending_packet == false">{{ (item.final_color_name ? item.final_color_name : '-') }}</td>
                                          <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="api_filter.pending_packet == false">{{ (item.final_purity_name ? item.final_purity_name : '-') }}</td>
                                          <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="api_filter.pending_packet == false">{{ (item.final_cut_name ? item.final_cut_name : '-') }}</td>
                                          <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="api_filter.pending_packet == false">{{ (item.final_polish_name ? item.final_polish_name : '-') }}</td>
                                          <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="api_filter.pending_packet == false">{{ (item.final_symmetry_name ? item.final_symmetry_name : '-') }}</td>
                                      </tr>
                                    </template>
                                  </template>

                                  <tr class="border-b bg-gray-100 dark:bg-dark-1">
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.total_packets }}</td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r" v-if="[1,2,8].includes(user_details.user_role)"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_issue_weight ? $h.myFloat(page_data.total.total_issue_weight) : 0) }} Ct</td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_ls_packets ? page_data.total.total_ls_packets : 0) }}</td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_ls_weight ? $h.myFloat(page_data.total.total_ls_weight) : 0) }} Ct</td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_ls_polish_weight ? $h.myFloat(page_data.total.total_ls_polish_weight) : 0) }} Ct</td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_ls_packets ? page_data.total.total_ls_packets : 0) }}</td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_pending_packets ? page_data.total.total_pending_packets : 0) }}</td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_received_packets ? page_data.total.total_received_packets : 0) }}</td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_received_weight ? $h.myFloat(page_data.total.total_received_weight) : 0) }} Ct</td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.pending_packet == false"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.pending_packet == false"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.pending_packet == false"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.pending_packet == false"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.pending_packet == false"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.pending_packet == false"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.pending_packet == false"></td>
                                    </tr>

                              </tbody>

                        </table>

                        <table class="w-full border-0" v-if="api_filter.report_type == 3">
                            <thead>
                                <tr class="border-b bg-gray-300 dark:bg-dark-1">
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">#</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Packet No</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Stock No</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap" v-if="[1,2,8].includes(user_details.user_role)">Worker</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Issue Date</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Process Wight</th>
                                  </tr>
                                  <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.total_packets }}</td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r" v-if="[1,2,8].includes(user_details.user_role)"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_issue_weight ? $h.myFloat(page_data.total.total_issue_weight) : 0) }} Ct</td>
                                  </tr>
                            </thead>
                                <tbody>

                                  <tr v-for="(item,index) in page_data.items" :key="index" class="border-b hover:bg-gray-200 p-0" :class="{'bg-gray-100': (index % 2) == 0}">
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ index + 1 }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.number }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.vepari_no }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="[1,2,8].includes(user_details.user_role)">{{ item.company_name ? item.company_name : ((item.first_name ? item.first_name : '') + ' ' + (item.last_name ? item.last_name : '')) }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.issue_date }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.issue_weight ? $h.myFloat(item.issue_weight) : 0) }} Ct</td>
                                      <!-- <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.total_ls_packets ? item.total_ls_packets : 0) }}</td>
                                      <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.total_ls_weight ? $h.myFloat(item.total_ls_weight) : 0) }} Ct</td>
                                      <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.total_ls_polish_weight ? $h.myFloat(item.total_ls_polish_weight) : 0) }} Ct</td>
                                      <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.total_ls_packets ? item.total_ls_packets : 0) }}</td>
                                      <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.total_received_packets ? item.total_received_packets : 0) }}</td>
                                      <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.total_pending_packets ? item.total_pending_packets : 0) }}</td>
                                      <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.total_received_weight ? $h.myFloat(item.total_received_weight) : 0) }} Ct</td> -->
                                  </tr>
                              </tbody>

                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END: HTML Table Data -->

</div>
</template>

<script>
/* eslint-disable */
import {
    BASE_URL
} from '@/constants/config';
import axios from 'axios';
import moment from 'moment';
import {
    defineComponent,
    onMounted,
    reactive,
    toRef,
    watch,
    computed
} from 'vue';
import store from '@/store'
import { helper as $h } from '@/utils/helper'

export default defineComponent({
    setup() {
        const user_details = computed(() => store.state.user.currentUser)
        const page_data = reactive({
            total: [],
            items: [],
            manager_items: [],
            process_items: [],
            lot_items: [],
            employee_items: [],
            loading: false,
        })

        const filter = reactive({
            packet_no: '',
            report_type: '1',
            kapan_id: '0',
            worker_id: '0',
            issue_pending : false,
            pending_packet : false,
            issue_date: moment().startOf('month').format('YYYY-MM-DD'),
            receive_date:moment().format('YYYY-MM-DD'),
        })

        const api_filter = reactive({
            packet_no: '',
            report_type: '1',
            kapan_id: '0',
            worker_id: '0',
            issue_pending : false,
            pending_packet : false,
            issue_date: moment().startOf('month').format('YYYY-MM-DD'),
            receive_date:moment().format('YYYY-MM-DD'),
        })

        const getReportDetails = async () => {

            api_filter.packet_no = filter.packet_no
            api_filter.report_type = filter.report_type
            api_filter.kapan_id = filter.kapan_id
            api_filter.worker_id = filter.worker_id
            api_filter.issue_pending = filter.issue_pending
            api_filter.pending_packet = filter.pending_packet
            api_filter.issue_date = filter.issue_date
            api_filter.receive_date = filter.receive_date

            const params = {
                packet_no: window.btoa(api_filter.packet_no),
                report_type: api_filter.report_type == '0' ? '' : window.btoa(api_filter.report_type),
                kapan_id: api_filter.kapan_id == '0' ? '' : window.btoa(api_filter.kapan_id),
                worker_id: api_filter.worker_id == '0' ? '' : window.btoa(api_filter.worker_id),
                issue_pending : api_filter.issue_pending,
                pending_packet : api_filter.pending_packet,
                start_date : window.btoa(api_filter.issue_date),
                end_date : window.btoa(api_filter.receive_date),
            }
            page_data.loading = true

            let promise = axios({
                url: 'api/report/rtp_details',
                method: "post",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    page_data.items = data.items
                    page_data.total = data.total
                    page_data.loading = false
                })
                .catch(_error => {
                    page_data.items = []
                    page_data.total = []
                    page_data.loading = false
                });
        }

        const getLotDetails = async () => {
            let params = {
            }
            let api_url = 'api/master/dp_lot_all_details'

            let promise = axios({
                url: api_url,
                method: "post",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    filter.kapan_id = '0'
                    page_data.lot_items = data.items
                })
                .catch(_error => {
                    filter.kapan_id = '0'
                    page_data.lot_items = []
                });
        }

        const getWorkerDetails = async () => {

            let params = {
                manager_id: user_details.value.user_role == 1 ? '' : user_details.value.account_id,
                employee_type: window.btoa('3'),
            }
            let api_url = ''

            if (filter.department_id == '0') {

                api_url = 'api/master/get_employees_by_manager_employee_type'
            } else {
                api_url = 'api/master/get_employees_by_manager_employee_type'
            }
            let promise = axios({
                url: api_url,
                method: "post",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    filter.worker_id = '0'
                    page_data.employee_items = data.items
                })
                .catch(_error => {
                    filter.worker_id = '0'
                    page_data.employee_items = []
                });
        }

        function onResetFilter() {
            filter.packet_no = ''
            filter.report_type = '1'
            filter.kapan_id = '0'
            filter.worker_id = '0'
            filter.issue_pending = false
            filter.pending_packet = false
            filter.issue_date = moment().startOf('month').format('YYYY-MM-DD')
            filter.receive_date =moment().format('YYYY-MM-DD')
        }
        onMounted(() => {
            getLotDetails()
            getReportDetails()
            getWorkerDetails()
        })
        return {
            page_data,
            filter,
            api_filter,
            user_details,
            getReportDetails,
            onResetFilter,
            getWorkerDetails,
        }
    }
})
</script>
<style scoped>
.tableFixHead {
        overflow-y: auto; /* make the table scrollable if height is more than 200 px  */
        max-height: 650px; /* gives an initial height of 200px to the table */
      }
      .tableFixHead thead {
        position: sticky; /* make the table heads sticky */
        top: 0px; /* table head will be placed from the top of the table and sticks to it */
      }
      </style>

<template>
  <!-- BEGIN: Profile Info -->
  <div id="basic-non-sticky-notification-packet" class="toastify-content hidden flex flex-col sm:flex-row">
      <div class="font-medium text-primary-3" v-if="pagedata.error">{{ pagedata.error }}</div>
  </div>
  <div id="basic-non-sticky-notification-packet-assign" class="toastify-content hidden flex flex-col sm:flex-row">
      <div class="flex items-center justify-around">
        <CheckCircleIcon class="w-6 h-6 text-theme-9 mx-auto" />
        <div class="font-medium text-black">{{ pagedata.message }}</div>
      </div>
  </div>
  <div>
    <div class="xl:grid block xl:grid-cols-2 gap-6">
      <div>
        <h2 class="intro-y text-lg font-medium mt-5 ml-2">Scan Packet</h2>
        <div class="flex items-center mt-2 ml-2">
          <div class="mr-2 flex items-center">
            <input
              id="remember-me"
              type="checkbox" v-model="pagedata.check"
              class="form-check-input border mr-2 bg-white"
            />
            <label for="remember-me" class="text-md font-medium">Menual</label>
          </div>
        </div>
        <div class="w-full flex sm:w-auto mt-3 ml-2">
          <div class="w-56 relative text-gray-700 dark:text-gray-300 mr-4">
            <input
              type="text"
              v-model="pagedata.search"
              autofocus
              @change="getPacketDetailsScan"
              ref="focusMe"
              class="form-control w-56 box pr-10 placeholder-theme-13"
              placeholder="Search Packet"
            />
            <SearchIcon
              class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
            />
          </div>
          <button class="btn btn-primary shadow-md mr-2" @click="AddtoList">Add</button>
          <a class="btn btn-primary shadow-md mr-2" href="javascript:;" data-toggle="modal" @click="getProcessData" data-target="#new-process-modal" v-if="pagedata.addlist.length > 0">Assign Packets</a>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-5 mt-5">
      <div
        class="col-span-6 xl:col-span-2 md:col-span-3 sm:col-span-4 box p-5 cursor-pointer zoom-in" v-for="(add,index) in pagedata.addlist" :key="index"
      >
        <div class="flex justify-between">
          <div class="font-medium text-base">{{ add }}</div>
          <button class="btn btn-outline-danger p-1 pl-2 hover:text-white" @click="removeItem(index)"><Trash2Icon class="w-4 h-4 mr-1" /></button>
        </div>
      </div>
    </div>
    <!-- BEGIN: Data List -->
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex items-center">
        <h2 class="text-lg font-bold">Plan Done Packets</h2>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
  <!-- BEGIN: new Process Modal -->
  <div
    id="new-process-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <a data-dismiss="modal" href="javascript:;">
          <XIcon class="w-8 h-8 text-gray-500" />
        </a>
        <div class="modal-body p-10 text-center">
          <div class="intro-y box">
            <!-- <div id="vertical-form"> -->
            <div class="flex flex-col justify-between mt-5">
              <!-- BEGIN: Data List -->
              <div class="intro-y overflow-auto">
                <div class="flex">
                </div>
              </div>
              <div class="m-auto mt-3">
                  <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label"
                      >Transaction Type</label
                    >
                    <TomSelect
                      v-model="process.transaction_type"
                        :options="{
                          placeholder: 'Select Transaction Type'
                        }"
                        class="w-60" :disabled="user_details.user_role == 6"
                    >
                    <option value="0">Process Assign</option>
                    <option value="1">Manager Transfer</option>
                    </TomSelect>
                  </div>
                  <div class="mt-3" v-if="process.transaction_type == '0'">
                    <label for="update-profile-form-6" class="form-label"
                      >Select Checker</label
                    >
                      <TomSelect
                        v-model="process.checker_id"
                          :options="{
                            placeholder: 'Select Checker'
                          }"
                          class="w-60"
                      >
                        <option value="0">Select Checker</option>
                        <option v-for="(emp,ind) in pagedata.planners" :key="ind" :value="emp.id">{{ emp.first_name + ' ' + emp.last_name}}</option>
                      </TomSelect>
                      <!-- <span class="text-primary-3 mb-2" v-if="errors.employee_id">{{ errors.employee[0] }}</span> -->
                  </div>
                </div>
              <button type="button" @click="AssignProcess" :disabled="pagedata.submit" class="btn btn-primary w-24 m-auto mt-5">Submit
                <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: new Process Modal -->
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import axios from 'axios'
import cash from 'cash-dom'
import { computed, defineComponent, inject, onMounted, reactive, ref, toRef, watch } from 'vue'
import print from 'vue3-print-nb'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import Toastify from 'toastify-js'
import store from '@/store'
import router from '@/router'
export default defineComponent({
  directives: {
    print
  },
  setup() {
    const user_details = computed(() => store.state.user.currentUser)
    const pagedata = reactive({
      search: '',
      check: false,
      addlist: [],
      addornot: false,
      planners: [],
      submit: false,
      message: '',
      error: '',
    })
    const focusMe = ref()
    const process = reactive({
      checker_id: '0',
      transaction_type: user_details.value.user_role == 5 ? '0' : '1',
    })
    const tableRef = ref()
    const tabulator = ref()
    onMounted(() => {
      initTabulator()
      getEmployees()
      focusMe.value.focus()
    })
    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + 'api/purchase/get_plan_done_packets',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        headerSort: false,
        pagination: 'remote',
        paginationSize: 10,
        paginationSizeSelector: [10, 20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [
          {
            formatter: 'responsiveCollapse',
            width: 40,
            minWidth: 30,
            hozAlign: 'center',
            resizable: false,
            headerSort: false
          },

          // For HTML table
          {
            title: 'Packet No',
            responsive: 0,

            field: 'number',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().urgent == 1 ? '<span class="text-theme-6">'+ cell.getData().number +'</span>' : cell.getData().number
                }</div>
              </div>`
            }
          },
          {
            title: 'Stock No',
            responsive: 0,
            field: 'vepari_no',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().vepari_no
                }</div>
              </div>`
            }
          },
          {
            title: 'Type',
            responsive: 0,
            field: 'type',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().type
                }</div>
              </div>`
            }
          },
          {
            title: 'Weight',
            responsive: 0,
            field: 'cr_weight',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().weight
                }</div>
              </div>`
            }
          },
          {
            title: 'Stn',
            field: 'm_stone_stone',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          // {
          //   title: 'Action',
          //   minWidth: 80,
          //   field: '',
          //   responsive: 1,
          //   hozAlign: 'center',
          //   vertAlign: 'middle',
          //   headerSort:false,
          //   print: false,
          //   download: false,
          //   formatter(cell) {
          //     if(cell.getData().last_planner == user_details.value.account_id) {
          //       const a = cash(`<div class="flex lg:justify-center items-center">
          //             <button class="btn btn-primary btn-sm flex items-center mr-3">
          //               <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Edit Plan
          //             </button>
          //           </div>`)
          //           cash(a).on('click', function() {
          //             cash('#new-manual-planning').modal('show')
          //           })
          //           return a[0]
          //         } else {
          //           const a = cash(`<div class="flex lg:justify-center items-center">
          //             <button class="btn btn-primary btn-sm flex items-center mr-3">
          //               <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Add Plan
          //               </button>
          //               </div>`)
          //               cash(a).on('click', function() {
          //             cash('#new-manual-planning').modal('show')
          //           })
          //           return a[0]
          //     }
          //   }
          // },
        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })

    }
    function AddtoList() {
      if(pagedata.search != '') {
        if(pagedata.addlist.length <= 0) {
          checkadd()
        } else {
          for (let i = 0; i < pagedata.addlist.length; i++) {
            if(pagedata.addlist[i] == pagedata.search) {
              // pagedata.checklist.splice(pagedata.addlist[i].id,1)
              pagedata.addlist.splice(i, 1);
              pagedata.addornot = true
              pagedata.search = ''
              break
            } else {
              pagedata.addornot = false
            }

          }
          if(pagedata.addornot == false) {
              checkadd()
            }
        }
      }
    }
    function checkadd() {
      const params = {
        number : window.btoa(pagedata.search),
      }
      axios({
        url: 'api/purchase/scan_plan_done_packets',
        method: 'post',
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        const index = ref(pagedata.addlist.length)
        pagedata.addlist[index.value] = res.data.packet.number
        pagedata.search = ''
        focusMe.value.focus()
        // pagedata.checklist[pagedata.addlist[index.value].id] = true
      }).catch((err) => {
        pagedata.error = err.response.data.error
        pagedata.search = ''
        focusMe.value.focus()
        console.warn(pagedata.error)
        setTimeout(() => {
          basicNonStickyNotificationPacket()
        },100)
        // setTimeout(() => {
        //   pagedata.search = ''
        // }, 1000);
        setTimeout(() => {
          pagedata.error = ''
        }, 3000);

      })
    }
    function removeItem(id) {
      console.warn(id)
      pagedata.addlist.splice(id, 1);
    }
    function AssignProcess() {
      const params = {
        number: pagedata.addlist,
        checker_id : process.checker_id == '0' ? '' : window.btoa(process.checker_id),
        transaction_type : window.btoa(process.transaction_type),
      }
      pagedata.submit = true
      axios({
        url: 'api/process/planner_checker_transfer_packet',
        method: 'post',
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.message = res.data.success
        pagedata.submit = false
        initTabulator()
        setTimeout(() => {
          basicNonStickyNotificationPacketAssign()
        }, 100);
        cash('#new-process-modal').modal('hide')
        pagedata.addlist = []
        process.checker_id = '0'
        process.transaction_type = '0'
        setTimeout(() => {
          pagedata.message = ''
        },3000)
      }).catch((err) => {
        pagedata.submit = false
        pagedata.error = err.response.data.error
        setTimeout(() => {
          pagedata.error = ''
        }, 3000);
      })
    }
    function getEmployees() {

      axios({
        url: 'api/master/dp_checker',
        method: 'post',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.planners = res.data.items
      })
     }
    function getPacketDetailsScan() {
        if(!pagedata.check && pagedata.search) {
          AddtoList();
        }
      }
    const basicNonStickyNotificationPacket = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification-packet')
          .clone()
          .removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    const basicNonStickyNotificationPacketAssign = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification-packet-assign')
          .clone()
          .removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    return {
      pagedata,
      focusMe,
      user_details,
      process,
      tableRef,
      getPacketDetailsScan,
      AddtoList,
      removeItem,
      AssignProcess,
      initTabulator,
      basicNonStickyNotificationPacketAssign,
      basicNonStickyNotificationPacket,
    }
  }
  // ,
  // mounted() {
  //   this.$refs.focusMe.focus()
  // }
})
</script>


<template>
  <div>
    <div class="pos intro-y grid">
      <h2 class="text-lg font-medium mr-auto">Employee Report</h2>
    </div>
    <!-- BEgin: Process Report Start-->
    <div class="intro-y box p-5 mt-5">
      <div class="flex sm:flex-wrap md:flex-row flex-col items-start">
        <div class="input-group m-1 my-2">
          <div id="input-group-3" class="input-group-text text-center w-20">Type</div>
          <TomSelect
          v-model="filters.type"
          @change="getChangesTypeWise"
            :options="{
              placeholder: 'Select your favorite actors'
            }"
            class="w-36"
          >
          <option value="0">Packet Type</option>
            <option value="makeable">Makeable</option>
            <option value="rough">Rough</option>
            <option value="video">Video</option>
            <option value="lab">Lab</option>
          </TomSelect>
        </div>
          <div class="input-group m-1 my-2">
            <div id="input-group-3" class="input-group-text text-center w-20">Dept</div>
            <TomSelect
            @change="getManager"
            v-model="filters.department"
              :options="{
                placeholder: 'Select your favorite actors'
              }"
              class="w-48"
            >
            <option value="0">Select Dept</option>
              <option v-for="(department,index) in pagedata.departments" :key="index" :value="department.id">{{ department.name }}</option>
            </TomSelect>
          </div>
          <div class="input-group m-1 my-2" v-if="filters.department != 0">
            <div id="input-group-3" class="input-group-text text-center w-20">Manager</div>
            <TomSelect
            @change="getEmployee"
            v-model="filters.manager"
              :options="{
                placeholder: 'Select your favorite actors'
              }"
              class="w-48"
            >
            <option value="0">Select Manager</option>
              <option v-for="(manager,index) in pagedata.managers" :key="index" :value="manager.id">{{ manager.name }}</option>
            </TomSelect>
          </div>
          <div class="input-group m-1 my-2" v-if="filters.manager != 0 && (filters.type == '0' || filters.type == 'makeable' || filters.type == 'rough')">
            <div id="input-group-3" class="input-group-text text-center w-12">Emp</div>
            <TomSelect
            v-model="filters.employee"
            @change="getAvailPkt"
              :options="{
                placeholder: 'Select your favorite actors'
              }"
              class="w-40"
            >
            <option value="0">Select Employee</option>
              <option v-for="(employee,index) in pagedata.femployees" :key="index" :value="employee.id">{{ employee.name }}</option>
            </TomSelect>
          </div>
        </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div class="intro-y col-span-12 overflow-auto">
          <table class="mt-2 w-full border-2">
            <thead>
              <tr class="bg-theme-1 text-white">
                <th class="py-2 text-xs whitespace-nowrap">#</th>
                <th class="py-2 text-xs whitespace-nowrap">Employee</th>
                <th class="py-2 text-xs whitespace-nowrap">Packets</th>
                <th class="py-2 text-xs whitespace-nowrap">Weight</th>
              </tr>
            </thead>
            <tbody>
              <tr class="bg-gray-200 text-gray-700">
                <td class="py-2 border-b text-xs text-center"></td>
                <td class="py-2 border-b text-xs text-center"></td>
                <td class="py-2 border-b text-xs text-center">{{ pagedata.total_packets }}</td>
                <td class="py-2 border-b text-xs text-center">{{ parseFloat(pagedata.total_weight).toFixed(2) }}</td>
              </tr>
              <tr v-for="(packet, index) in pagedata.employees" :key="index" @click="getProcessPkt(packet.id)" class="cursor-pointer">
                <td class="py-2 border-b text-xs text-center">{{ index + 1 }}</td>
                <td class="py-2 border-b text-xs text-center">{{ packet.emp_name }}</td>
                <td class="py-2 border-b text-xs text-center">{{ packet.total_packets }}</td>
                <td class="py-2 border-b text-xs text-center">{{ parseFloat(packet.total_weight).toFixed(2) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- BEGIN: Pagination -->
          <div
            class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center mt-3 mb-7"
          >
            <ul class="pagination">
              <li>
                <button class="pagination__link" :disabled="pagedata.current_page == 1" @click="getAvailablePkt(pagedata.first_page_url)">
                  <ChevronsLeftIcon class="w-4 h-4" />
                </button>
              </li>
              <li>
                <button class="pagination__link" :disabled="pagedata.prev_page_url == null" @click="getAvailablePkt(pagedata.prev_page_url)">
                  <ChevronLeftIcon class="w-4 h-4" />
                </button>
              </li>
              <li v-for="(pagelink,index) in pagelinks" :key="index">
                <button class="pagination__link" :class="{'pagination__link--active' : pagelink.active == true}" v-if="pagelinks[0] != pagelink && pagelinks[pagelinks.length - 1] != pagelink" @click='getAvailablePkt(pagelink.url)'>{{ pagelink.label }}</button>
              </li>
              <li>
                <button class="pagination__link" :disabled="pagedata.next_page_url == null" @click="getAvailablePkt(pagedata.next_page_url)">
                  <ChevronRightIcon class="w-4 h-4" />
                </button>
              </li>
              <li>
                <button class="pagination__link" :disabled="pagedata.current_page == pagedata.last_page" @click="getAvailablePkt(pagedata.last_page_url)">
                  <ChevronsRightIcon class="w-4 h-4" />
                </button>
              </li>
            </ul>
            <select class="w-20 form-select box mt-3 sm:mt-0" v-model="filters.size" @change="getAvailPkt">
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
            </select>
          </div>
          <!-- END: Pagination -->
      </div>
    </div>
    <!-- BEGIN: Compare Packet Plan Details Modal -->
    <div
      id="process_process_modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <a data-dismiss="modal" href="javascript:;">
            <XIcon class="w-8 h-8 text-gray-500" />
          </a>
          <div class="modal-body py-5 text-center">
            <!-- BEGIN: HTML Table Data -->
            <!-- BEGIN: Data List -->
            <h2 class="text-lg font-medium mr-auto">Employee Packets</h2>
              <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
                <table class="mt-2 w-full border-2">
                  <thead>
                    <tr class="bg-theme-1 text-white">
                      <th class="py-2 text-xs whitespace-nowrap">#</th>
                      <th class="py-2 text-xs whitespace-nowrap">Employee</th>
                      <th class="py-2 text-xs whitespace-nowrap">Packets</th>
                      <th class="py-2 text-xs whitespace-nowrap">Weight</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="bg-gray-200 text-gray-700">
                      <td class="py-2 border-b text-xs text-center"></td>
                      <td class="py-2 border-b text-xs text-center"></td>
                      <td class="py-2 border-b text-xs text-center">{{ pagedata.process_total_packets }}</td>
                      <td class="py-2 border-b text-xs text-center">{{ parseFloat(pagedata.process_total_weight).toFixed(2) }}</td>
                    </tr>
                    <tr v-for="(process, index) in pagedata.processes" :key="index" class="cursor-pointer">
                      <td class="py-2 border-b text-xs text-center">{{ index + 1 }}</td>
                      <td class="py-2 border-b text-xs text-center">{{ process.process }}</td>
                      <td class="py-2 border-b text-xs text-center">{{ process.total_packets }}</td>
                      <td class="py-2 border-b text-xs text-center">{{ parseFloat(process.total_weight).toFixed(2) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- END: Data List -->
            <!-- END: HTML Table Data -->
          </div>
        </div>
      </div>
    </div>
    <!-- END: Compare Packet Plan Details Modal -->
  </div>
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config';
import axios from 'axios';
import cash from 'cash-dom';
import { defineComponent, onMounted, reactive, toRef, watch } from 'vue';

export default defineComponent({
  setup() {
    const pagedata = reactive({
      employees: [],
      processes: [],
      total_weight: null,
      total_packets: null,
      process_total_weight: null,
      process_total_packets: null,
      pagelinks: [],
      prev_page_url: null,
      next_page_url: null,
      first_page_url: null,
      last_page_url: null,
      current_page: 1,
      last_page: '',
      departments: [],
      managers: [],
      femployees: [],
    })
    const pagelinks = toRef(pagedata, 'pagelinks')
    const filters = reactive({
      size : 10,
      type: '0',
      department: '0',
      manager: '0',
      employee: '0'
    })
    onMounted(() => {
      getAvailPkt()
      getDepartment()
    })
    function getAvailablePkt(url) {
      axios({
        url: url,
        method: 'post',
        data: filters,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.employees = res.data.employees.data
        pagedata.pagelinks = res.data.employees.links
        pagedata.prev_page_url = res.data.employees.prev_page_url
        pagedata.next_page_url = res.data.employees.next_page_url
        pagedata.first_page_url = res.data.employees.first_page_url
        pagedata.last_page_url = res.data.employees.last_page_url
        pagedata.current_page = res.data.employees.current_page
        pagedata.last_page = res.data.employees.last_page
        pagedata.total_packets = res.data.total[0].total_packets
        pagedata.total_weight = res.data.total[0].total_weight
    })
    }
    function getAvailPkt() {
       axios({
        url: `/report/employee_report`,
        method: 'post',
        baseURL: BASE_URL,
        data: filters,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.employees = res.data.employees.data
        pagedata.pagelinks = res.data.employees.links
        pagedata.prev_page_url = res.data.employees.prev_page_url
        pagedata.next_page_url = res.data.employees.next_page_url
        pagedata.first_page_url = res.data.employees.first_page_url
        pagedata.last_page_url = res.data.employees.last_page_url
        pagedata.current_page = res.data.employees.current_page
        pagedata.last_page = res.data.employees.last_page
        pagedata.total_packets = res.data.total[0].total_packets
        pagedata.total_weight = res.data.total[0].total_weight
      })
    }
    function getProcessPkt(id) {
       axios({
        url: `/report/employee_report_process_packets/${id}`,
        method: 'get',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.processes = res.data.processes
        pagedata.process_total_packets = res.data.total[0].total_packets
        pagedata.process_total_weight = res.data.total[0].total_weight
        cash('#process_process_modal').modal('show')
      })
    }
    function getDepartment() {
      axios({
        url: '/allDepartments',
        method: 'get',
        baseURL: BASE_URL,
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.departments = res.data.departments
      })
    }
    function getManager() {
      filters.manager = '0'
      filters.employee = '0'
      axios({
        url: `/user/allManager?dept_id=${filters.department}`,
        method: 'get',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.managers = res.data.managers
        getAvailPkt()
      })
    }
    function getEmployee() {
      filters.employee = '0'
      if(filters.type == 'makeable' || filters.type == '0') {
        axios({
          url: `/user/manager-employee/${filters.manager}`,
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.femployees = res.data.employees
          getAvailPkt()
        })
      } else if(filters.type == 'rough') {
        axios({
          url: '/user/planner_checker_ls',
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.femployees = res.data.users
          getAvailPkt()
        })
      }
    }
    function getChangesTypeWise() {
      if(filters.type == 'makeable' || filters.type== 'rough' || filters.type == '0') {
        getEmployee()
      } else {
        pagedata.employees = []
        filters.employee = '0'
      }

    }
    return {
      pagedata,
      filters,
      getChangesTypeWise,
      pagelinks,
      getProcessPkt,
      getAvailablePkt,
      getEmployee,
      getManager,
      getAvailPkt
    }
  }
})
</script>

<template>
  <!-- BEGIN: Profile Info -->
  <div id="basic-non-sticky-notification-packet" class="toastify-content hidden flex flex-col sm:flex-row">
      <div class="font-medium text-primary-3" v-if="errors.error">{{ errors.error }}</div>
  </div>
  <div id="basic-non-sticky-notification-packet-assign" class="toastify-content hidden flex flex-col sm:flex-row">
      <div class="flex items-center justify-around">
        <CheckCircleIcon class="w-6 h-6 text-theme-9 mx-auto" />
        <div class="font-medium text-black">Packet Cancelled</div>
      </div>
  </div>
  <div>
    <div class="xl:grid block xl:grid-cols-2 gap-6">
      <div>
        <h2 class="intro-y text-lg font-medium mt-5 ml-2">Transfered Packets</h2>
        <div class="w-full flex sm:w-auto mt-3 ml-2">
          <div class="w-56 relative text-gray-700 dark:text-gray-300 mr-4">
            <input
              type="text"
              v-model="pagedata.search"
              autofocus
              ref="focusMe"
              class="form-control w-56 box pr-10 placeholder-theme-13"
              placeholder="Search Packet"
            />
            <SearchIcon
              class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: Data List -->
    <div v-if="paketFilter.length > 0">
      <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
        <table class="table-report mt-3 w-full">
          <thead>
            <tr>
              <th class="py-2 text-center">Pkt No</th>
              <th class="py-2 text-center whitespace-nowrap">Vepari No</th>
              <th class="py-2 text-center">Party</th>
              <th class="py-2 text-center whitespace-nowrap">New Manager</th>
              <th class="py-2 text-center">Weight</th>
              <th class="py-2 text-center">Shape</th>
              <th class="py-2 text-center">Color</th>
              <th class="py-2 text-center">Purity</th>
              <th class="py-2 text-center">Cut</th>
              <th class="py-2 text-center">Polish</th>
              <th class="py-2 text-center">Symm</th>
              <th class="py-2 text-center">Fluc</th>
              <th class="py-2 text-center">Tension</th>
              <th class="py-2 text-center">Stone</th>
              <th class="py-2 text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(packet, index) in paketFilter"
              :key="index"
              class="intro-x"
            >
              <td class="text-center" :class="{ 'text-primary-3' : packet.urgent == 1 }">
                <div class="py-2 font-medium whitespace-nowrap">{{
                  packet.number
                }}</div>
              </td>
              <td :class="{ 'text-primary-3' : packet.urgent == 1 }" class="py-2 font-medium text-center">{{ packet.vepari_no }}</td>
              <td :class="{ 'text-primary-3' : packet.urgent == 1 }" class="py-2 font-medium text-center">{{ packet.party }}</td>
              <td :class="{ 'text-primary-3' : packet.urgent == 1 }" class="py-2 font-medium text-center">{{ packet.manager_trans }}</td>
              <td :class="{ 'text-primary-3' : packet.urgent == 1 }" class="py-2 font-medium text-center">{{ packet.weight }}</td>
              <td :class="{ 'text-primary-3' : packet.urgent == 1 }" class="py-2 font-medium text-center">{{ packet.shape }}</td>
              <td :class="{ 'text-primary-3' : packet.urgent == 1 }" class="py-2 font-medium text-center">{{ packet.color }}</td>
              <td :class="{ 'text-primary-3' : packet.urgent == 1 }" class="py-2 font-medium text-center">{{ packet.purity }}</td>
              <td :class="{ 'text-primary-3' : packet.urgent == 1 }" class="py-2 font-medium text-center">{{ packet.cut }}</td>
              <td :class="{ 'text-primary-3' : packet.urgent == 1 }" class="py-2 font-medium text-center">{{ packet.polish }}</td>
              <td :class="{ 'text-primary-3' : packet.urgent == 1 }" class="py-2 font-medium text-center">{{ packet.symmetry }}</td>
              <td :class="{ 'text-primary-3' : packet.urgent == 1 }" class="py-2 font-medium text-center">{{ packet.fluerocent }}</td>
              <td :class="{ 'text-primary-3' : packet.urgent == 1 }" class="py-2 font-medium text-center">{{ packet.tension }}</td>
              <td :class="{ 'text-primary-3' : packet.urgent == 1 }" class="py-2 font-medium text-center">{{ packet.stone }}</td>
              <td class="table-report__action w-32">
                <div class="flex justify-center items-center">
                  <a
                    class="flex items-center text-theme-6"
                    href="javascript:;"
                    data-toggle="modal"
                    @click="pagedata.delId = packet.transferId"
                    data-target="#delete-confirmation-modal"
                  >
                    <Trash2Icon class="w-4 h-4 mr-1" /> Cancel
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
    </div>
    <div v-else class="text-center">No Request Found</div>
  </div>
  <!-- END: Profile Info -->
  <!-- BEGIN: Delete Confirmation Modal -->
    <div
      id="delete-confirmation-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
              <div class="text-3xl mt-5">Are you sure?</div>
              <div class="text-gray-600 mt-2">
                Do you really want to Cancel these Request? <br />This process
                cannot be undone.
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-24 mr-1"
              >
                Cancel
              </button>
              <button type="button" class="btn btn-danger w-24" @click="cancelRequest">Confirm</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Delete Confirmation Modal -->
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import axios from 'axios'
import cash from 'cash-dom'
import { computed, defineComponent, onMounted, reactive, ref, toRef, watch } from 'vue'
import print from 'vue3-print-nb'
import Toastify from 'toastify-js'
export default defineComponent({
  directives: {
    print
  },
  setup() {
    const pagedata = reactive({
      packets: [],
      errors: [],
      search: '',
      check: false,
      waiting: false,
      addornot: false,
      delId: ''
    })
    const paketFilter = computed(() => pagedata.packets.filter(purc => {
      return purc.number.toString().includes(pagedata.search)
    }))
    const errors = toRef(pagedata,'errors')
    onMounted(() => {
      getPacketDetails('transfer')
    })
    function getPacketDetails(trans) {
      axios({
          url: `/transfer/transfered-packets?type=${trans}`,
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.packets = res.data.packets
        }).catch((err) => {
          pagedata.errors = err.response.data
          setTimeout(() => {
            basicNonStickyNotificationPacketFound()
          },100)
          setTimeout(() => {
            pagedata.errors = []
          }, 5000);
        })
    }
    watch(() => pagedata.search, (currentValue) => {
      if(!pagedata.check) {
        if (!pagedata.waiting) {
          setTimeout(() => {
            getScannedPacket();
            pagedata.waiting = false;
          }, 500); // 1 sec delay
        }
        pagedata.waiting = true;
      }
    })
    function cancelRequest() {
      axios({
          url: `/transfer/transfered-cancel-request/${pagedata.delId}`,
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then(() => {
          getPacketDetails('transfer')
          basicNonStickyNotificationPacketAssign()
          cash('#delete-confirmation-modal').modal('hide')
        }).catch((err) => {
          pagedata.errors = err.response.data
          setTimeout(() => {
            basicNonStickyNotificationPacketFound()
          },100)
          setTimeout(() => {
            pagedata.errors = []
          }, 5000);
        })
    }
    const basicNonStickyNotificationPacketAssign = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification-packet-assign')
          .clone()
          .removeClass('hidden')[0],
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    const basicNonStickyNotificationPacketFound = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification-packet-found')
          .clone()
          .removeClass('hidden')[0],
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    return {
      pagedata,
      errors,
      paketFilter,
      basicNonStickyNotificationPacketAssign,
      basicNonStickyNotificationPacketFound,
      cancelRequest
    }
  },
  mounted() {
    this.$refs.focusMe.focus()
  }
})
</script>

<template>
  <div>
    <div class="intro-y box mt-2">
      <h2 class="text-lg font-medium mr-auto ml-2 pt-2">Stock Report</h2>
      <div class="flex flex-col sm:items-start relative">
        <div class="flex sm:flex-wrap md:flex-row flex-col items-start " v-if="pagedata.filters == true">
          <div class="input-group m-1 my-2">
            <div id="input-group-3" class="input-group-text text-center w-20">Party</div>
            <TomSelect
            @change="getAvailPkt"
            v-model="filter.party"
              :options="{
                placeholder: 'Select your favorite actors'
              }"
              class="w-48"
            >
            <option value="0">Select Party</option>
              <option v-for="(party,index) in pagedata.parties" :key="index" :value="party.id">{{ party.name }}({{ index + 1 }})</option>
            </TomSelect>
          </div>
          <div class="input-group m-1 my-2">
            <div id="input-group-3" class="input-group-text text-center w-20">Process</div>
            <TomSelect
            v-model="filter.process"
            @change="getAvailPkt"
              :options="{
                placeholder: 'Select your favorite actors'
              }"
              class="w-48"
            >
            <option value="0">Select Process</option>
              <option v-for="(process,index) in pagedata.processes" :key="index" :value="process.id">{{ process.name }}</option>
            </TomSelect>
          </div>
          <div class="input-group m-1 my-2">
            <div id="input-group-3" class="input-group-text text-center w-20">Cancel</div>
            <select
            @change="getAvailPkt"
              class="form-select w-32"
              v-model="filter.cancel_type"
              aria-label="Default select example"
            >
              <option value="">All</option>
              <option value="1">Not Cancelled</option>
              <option value="2">Cancelled</option>
            </select>
          </div>
          <div class="input-group m-1 my-2">
            <div id="input-group-3" class="input-group-text text-center w-20">Type</div>
            <TomSelect
            v-model="filter.type"
            @change="getChangesTypeWise"
              :options="{
                placeholder: 'Select your favorite actors'
              }"
              class="w-36"
            >
            <option value="0">Packet Type</option>
              <option value="makeable">Makeable</option>
              <option value="rough">Rough</option>
              <option value="video">Video</option>
              <option value="lab">Lab</option>
            </TomSelect>
          </div>
        </div>
        <div class="flex sm:flex-wrap md:flex-row flex-col items-start " v-if="pagedata.filters == true">
          <div class="input-group m-1 my-2">
            <div id="input-group-3" class="input-group-text text-center w-20">Dept</div>
            <TomSelect
            @change="getManager"
            v-model="filter.department"
              :options="{
                placeholder: 'Select your favorite actors'
              }"
              class="w-48"
            >
            <option value="0">Select Dept</option>
              <option v-for="(department,index) in pagedata.departments" :key="index" :value="department.id">{{ department.name }}</option>
            </TomSelect>
          </div>
          <div class="input-group m-1 my-2" v-if="filter.department != 0">
            <div id="input-group-3" class="input-group-text text-center w-20">Manager</div>
            <TomSelect
            @change="getEmployee"
            v-model="filter.manager"
              :options="{
                placeholder: 'Select your favorite actors'
              }"
              class="w-48"
            >
            <option value="0">Select Manager</option>
              <option v-for="(manager,index) in pagedata.managers" :key="index" :value="manager.id">{{ manager.name }}</option>
            </TomSelect>
          </div>
          <div class="input-group m-1 my-2" v-if="filter.manager != 0 && (filter.type == '0' || filter.type == 'makeable' || filter.type == 'rough')">
            <div id="input-group-3" class="input-group-text text-center w-12">Emp</div>
            <TomSelect
            v-model="filter.employee"
            @change="getAvailPkt"
              :options="{
                placeholder: 'Select your favorite actors'
              }"
              class="w-40"
            >
            <option value="0">Select Employee</option>
              <option v-for="(employee,index) in pagedata.employees" :key="index" :value="employee.id">{{ employee.name }}</option>
            </TomSelect>
          </div>
        </div>
        <div class="flex sm:flex-wrap md:flex-row flex-col items-start " v-if="pagedata.filters == true">
          <div class="input-group m-1 my-2">
            <div id="input-group-3" class="input-group-text text-center mt-1 w-20">Search</div>
            <div class="w-48 mt-1 relative text-gray-700 dark:text-gray-300">
              <input
                type="text"
                v-model="filter.number"
                autofocus
                ref="focusMe"
                class="form-control w-48 pr-10"
                placeholder="Search Packet"
              />
              <SearchIcon
                class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
              />
            </div>
          </div>
          <div class="mt-1">
            <div class="input-group m-1 my-2">
              <div id="input-group-3" class="input-group-text text-center w-16">days</div>
              <input
                type="number"
                v-model="filter.days"
                autofocus
                class="form-control w-20"
                placeholder="Days"
              />
            </div>
          </div>
          <div class="mt-1">
            <div class="input-group m-1 my-2">
              <div id="input-group-3" class="input-group-text text-center w-16">Late</div>
              <input
                type="number"
                v-model="filter.late"
                autofocus
                class="form-control w-20"
                placeholder="Late"
              />
            </div>
          </div>
          <div class="input-group mr-2 my-2">
            <button class="btn btn-primary p-2 w-24 mt-1 shadow-md mr-2" @click="getAvailPkt">Search</button>
          </div>
        </div>
        <div class="bottom-0 right-0 absolute">
          <div @click="pagedata.filters = !pagedata.filters" class="btn btn-primary p-0 mr-3"><ChevronsUpIcon class="h-5 w-5" v-if="pagedata.filters == true" /><ChevronsDownIcon class="h-5 w-5" v-if="pagedata.filters == false" /></div>
        </div>
      </div>
      <!-- BEGIN: Data List -->
      <div class="overflow-x-auto scrollbar-hidden">
          <div class="intro-y col-span-12 overflow-auto">
            <table class="mt-2 w-full border-2">
                  <thead>
                    <tr class="bg-theme-1 text-white">
                      <th class="py-2 text-xs whitespace-nowrap">#</th>
                      <th class="py-2 text-xs whitespace-nowrap">Number</th>
                      <th class="py-2 text-xs whitespace-nowrap">Party</th>
                      <th class="py-2 text-xs whitespace-nowrap">Dept.</th>
                      <th class="py-2 text-xs whitespace-nowrap">Mgr.</th>
                      <th class="py-2 text-xs whitespace-nowrap">Emp.</th>
                      <th class="py-2 text-xs whitespace-nowrap">Proc.</th>
                      <th class="py-2 text-xs whitespace-nowrap">R Wt</th>
                      <th class="py-2 text-xs whitespace-nowrap">P Wt</th>
                      <th class="py-2 text-xs whitespace-nowrap">C Wt</th>
                      <th class="py-2 border-b text-xs text-center">
                        <MultipleSelect :options="pagedata.parameters.shape" :name="'Shp'" @onChecked="onShapeCheck"></MultipleSelect>
                      </th>
                      <th class="py-2 border-b text-xs text-center">
                        <MultipleSelect :options="pagedata.parameters.color" :name="'Clr'" @onChecked="onColorCheck"></MultipleSelect>
                      </th>
                      <th class="py-2 border-b text-xs text-center">
                        <MultipleSelect :options="pagedata.parameters.purity" :name="'Prt'" @onChecked="onPurityCheck"></MultipleSelect>
                      </th>
                      <th class="py-2 border-b text-xs text-center">
                        <MultipleSelect :options="pagedata.parameters.cut" :name="'Cut'" @onChecked="onCutCheck"></MultipleSelect>
                      </th>
                      <th class="py-2 border-b text-xs text-center">
                        <MultipleSelect :options="pagedata.parameters.polish" :name="'Pol'" @onChecked="onPolishCheck"></MultipleSelect>
                      </th>
                      <th class="py-2 border-b text-xs text-center">
                        <MultipleSelect :options="pagedata.parameters.symmetry" :name="'Sym'" @onChecked="onSymmetryCheck"></MultipleSelect>
                      </th>
                      <th class="py-2 border-b text-xs text-center">
                        <MultipleSelect :options="pagedata.parameters.fluerocent" :name="'TN'" @onChecked="onFluerocentCheck"></MultipleSelect>
                      </th>
                      <th class="py-2 border-b text-xs text-center">
                        <MultipleSelect :options="pagedata.parameters.tension" :name="'FL'" @onChecked="onTensionCheck"></MultipleSelect>
                      </th>
                      <th class="py-2 border-b text-xs text-center">
                        <MultipleSelect :options="pagedata.parameters.stone" :name="'Stn'" @onChecked="onStoneCheck"></MultipleSelect>
                      </th>
                      <th class="py-2 text-xs whitespace-nowrap">Days</th>
                      <th class="py-2 text-xs whitespace-nowrap">Late</th>
                      <th class="py-2 text-xs whitespace-nowrap">I Date</th>
                      <th class="py-2 text-xs whitespace-nowrap">
                        <MultipleSelect :options="pagedata.parameters.IR" :name="'IR'" @onChecked="onIRCheck"></MultipleSelect>
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="pagedata.packets.length > 0">
                    <tr class="bg-gray-200 text-gray-700">
                      <td class="py-2 border-b text-xs text-center"></td>
                      <td class="py-2 border-b text-xs text-center"></td>
                      <td class="py-2 border-b text-xs text-center"></td>
                      <td class="py-2 border-b text-xs text-center">{{ pagedata.heading.processes }}</td>
                      <td class="py-2 border-b text-xs text-center"></td>
                      <td class="py-2 border-b text-xs text-center"></td>
                      <td class="py-2 border-b text-xs text-center"></td>
                      <td class="py-2 border-b text-xs text-center">{{ parseFloat(pagedata.heading.total_weight).toFixed(2) }}</td>
                      <td class="py-2 border-b text-xs text-center">{{ parseFloat(pagedata.heading.total_polish_weight).toFixed(2) }}</td>
                      <td class="py-2 border-b text-xs text-center">{{ parseFloat(pagedata.heading.total_current_weight).toFixed(2) }}</td>
                      <td class="py-2 border-b text-xs text-center"></td>
                      <td class="py-2 border-b text-xs text-center"></td>
                      <td class="py-2 border-b text-xs text-center"></td>
                      <td class="py-2 border-b text-xs text-center"></td>
                      <td class="py-2 border-b text-xs text-center"></td>
                      <td class="py-2 border-b text-xs text-center"></td>
                      <td class="py-2 border-b text-xs text-center"></td>
                      <td class="py-2 border-b text-xs text-center"></td>
                      <td class="py-2 border-b text-xs text-center"></td>
                      <td class="py-2 border-b text-xs text-center"></td>
                      <td class="py-2 border-b text-xs text-center"></td>
                      <td class="py-2 border-b text-xs text-center"></td>
                      <td class="py-2 border-b text-xs text-center"></td>

                    </tr>
                    <tr v-for="(packet, index) in pagedata.packets" :key="index">
                      <td class="py-2 border-b text-xs text-center">{{ index + 1 }}</td>
                      <td class="py-2 border-b text-xs text-center">{{ packet.number }}</td>
                      <td class="py-2 border-b text-xs text-center">{{ packet.party }}</td>
                      <td class="py-2 border-b text-xs text-center">{{ packet.department }}</td>
                      <td class="py-2 border-b text-xs text-center">{{ packet.manager }}</td>
                      <td class="py-2 border-b text-xs text-center">{{ packet.employee }}</td>
                      <td class="py-2 border-b text-xs text-center">{{ packet.process }}</td>
                      <td class="py-2 border-b text-xs text-center">{{ packet.weight }}</td>
                      <td class="py-2 border-b text-xs text-center">{{ packet.admin_pwt > 0 ? packet.admin_pwt : packet.pwt }}</td>
                      <td class="py-2 border-b text-xs text-center">{{ packet.receive_weight == null ? packet.issue_weight : packet.receive_weight }}</td>
                      <td class="py-2 border-b text-xs text-center">{{ packet.shape }}</td>
                      <td class="py-2 border-b text-xs text-center">{{ packet.color }}</td>
                      <td class="py-2 border-b text-xs text-center">{{ packet.purity }}</td>
                      <td class="py-2 border-b text-xs text-center">{{ packet.cut }}</td>
                      <td class="py-2 border-b text-xs text-center">{{ packet.polish }}</td>
                      <td class="py-2 border-b text-xs text-center">{{ packet.symmetry }}</td>
                      <td class="py-2 border-b text-xs text-center">{{ packet.fluerocent }}</td>
                      <td class="py-2 border-b text-xs text-center">{{ packet.tension }}</td>
                      <td class="py-2 border-b text-xs text-center">{{ packet.stone }}</td>
                      <td class="py-2 border-b text-xs text-center"><div  :class="{'bg-primary-3 text-white rounded-full': packet.due < packet.days}">{{ packet.days }}</div></td>
                      <td class="py-2 border-b text-xs text-center"><div  :class="{'bg-primary-3 text-white rounded-full': packet.process_limit < packet.late}">{{ packet.late }}</div></td>
                      <td class="py-2 border-b text-xs text-center">{{ packet.issue_date }}</td>
                      <td class="py-2 border-b text-xs text-center">{{ packet.packet_detail_status == 0 ? 'I' : 'R' }}</td>
                    </tr>
                  </tbody>
                </table>
                <div class="intro-y box mt-40 py-4 text-center" v-if="pagedata.packets.length <= 0">No Packet Found</div>
          </div>
          <!-- END: Data List -->
          <!-- BEGIN: Pagination -->
          <div v-if="pagedata.packets.length > 0"
            class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center mt-3 mb-7"
          >
            <ul class="pagination">
              <li>
                <button class="pagination__link" :disabled="pagedata.current_page == 1" @click="getAvailablePkt(pagedata.first_page_url)">
                  <ChevronsLeftIcon class="w-4 h-4" />
                </button>
              </li>
              <li>
                <button class="pagination__link" :disabled="pagedata.prev_page_url == null" @click="getAvailablePkt(pagedata.prev_page_url)">
                  <ChevronLeftIcon class="w-4 h-4" />
                </button>
              </li>
              <li v-for="(pagelink,index) in pagelinks" :key="index">
                <button class="pagination__link" :class="{'pagination__link--active' : pagelink.active == true}" v-if="pagelinks[0] != pagelink && pagelinks[pagelinks.length - 1] != pagelink" @click='getAvailablePkt(pagelink.url)'>{{ pagelink.label }}</button>
              </li>
              <li>
                <button class="pagination__link" :disabled="pagedata.next_page_url == null" @click="getAvailablePkt(pagedata.next_page_url)">
                  <ChevronRightIcon class="w-4 h-4" />
                </button>
              </li>
              <li>
                <button class="pagination__link" :disabled="pagedata.current_page == pagedata.last_page" @click="getAvailablePkt(pagedata.last_page_url)">
                  <ChevronsRightIcon class="w-4 h-4" />
                </button>
              </li>
            </ul>
            <select class="w-20 form-select box mt-3 sm:mt-0" v-model="filter.size" @change="getAvailPkt">
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
            </select>
          </div>
          <!-- END: Pagination -->
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config';
import axios from 'axios';
import MultipleSelect from '@/components/MultipleSelect/Main.vue'
import { defineComponent, onMounted, reactive, toRef, watch } from 'vue';

export default defineComponent({
  components:{
    MultipleSelect
  },
  setup() {
    const pagedata = reactive({
      packets: [],
      // selected: [],
      // show: false,
      filters: true,
      pagelinks: [],
      prev_page_url: null,
      next_page_url: null,
      first_page_url: null,
      last_page_url: null,
      current_page: 1,
      last_page: '',
      processes: [],
      parties: [],
      departments: [],
      managers: [],
      employees: [],
      heading: {
        total_weight : '',
        total_polish_weight : '',
        total_current_weight : '',
        processes : ''
      },
      parameters: {
        color: [],
        cut: [],
        purity: [],
        polish: [],
        shape: [],
        symmetry: [],
        fluerocent: [],
        tension: [],
        stone: [],
        IR: [
          {
            id: 0,
            name: 'I'
          },
          {
            id: 1,
            name: 'R'
          }
        ]
      }
    })
    const pagelinks = toRef(pagedata, 'pagelinks')
    const filter = reactive({
      size : 10,
      type : '0',
      party : '',
      process : '',
      department : '0',
      manager : '0',
      employee : '0',
      number : '',
      cancel_type : '',
      color: [],
      cut: [],
      purity: [],
      polish: [],
      shape: [],
      symmetry: [],
      fluerocent: [],
      tension: [],
      stone: [],
      days: '',
      late: '',
      IR: []
    })
    onMounted(() => {
      setTimeout(() => {
        getParties()
        getProcessData()
        getDepartment()
        getDiamontData()
      },1500)
      getAvailPkt()
      // getDataDate()
    })
    function onColorCheck(val) {
      filter.color = val;
    }
    function onPurityCheck(val) {
      filter.purity = val;
    }
    function onCutCheck(val) {
      filter.cut = val;
    }
    function onPolishCheck(val) {
      filter.polish = val;
    }
    function onShapeCheck(val) {
      filter.shape = val;
    }
    function onSymmetryCheck(val) {
      filter.symmetry = val;
    }
    function onStoneCheck(val) {
      filter.stone = val;
    }
    function onFluerocentCheck(val) {
      filter.fluerocent = val;
    }
    function onTensionCheck(val) {
      filter.tension = val;
    }
    function onIRCheck(val) {
      filter.IR = val;
    }
    // function onCheck(val) {
    //   pagedata.selected = val;
    // }
    function getAvailablePkt(url) {
      axios({
        url: url,
        method: 'post',
        data: filter,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.packets = res.data.packets.data
        pagedata.pagelinks = res.data.packets.links
        pagedata.prev_page_url = res.data.packets.prev_page_url
        pagedata.next_page_url = res.data.packets.next_page_url
        pagedata.first_page_url = res.data.packets.first_page_url
        pagedata.last_page_url = res.data.packets.last_page_url
        pagedata.current_page = res.data.packets.current_page
        pagedata.last_page = res.data.packets.last_page
        pagedata.heading.total_weight = res.data.total[0].total_weight
        pagedata.heading.total_polish_weight = res.data.total[0].total_polish_weight
        pagedata.heading.total_current_weight = res.data.total[0].total_current_weight
        pagedata.heading.processes = res.data.total[0].total_processes
    })
    }
    function getAvailPkt() {
       axios({
        url: `/report/stock_report`,
        method: 'post',
        baseURL: BASE_URL,
        data: filter,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.packets = res.data.packets.data
        pagedata.pagelinks = res.data.packets.links
        pagedata.prev_page_url = res.data.packets.prev_page_url
        pagedata.next_page_url = res.data.packets.next_page_url
        pagedata.first_page_url = res.data.packets.first_page_url
        pagedata.last_page_url = res.data.packets.last_page_url
        pagedata.current_page = res.data.packets.current_page
        pagedata.last_page = res.data.packets.last_page
        pagedata.heading.total_weight = res.data.total[0].total_weight
        pagedata.heading.total_polish_weight = res.data.total[0].total_polish_weight
        pagedata.heading.total_current_weight = res.data.total[0].total_current_weight
        pagedata.heading.processes = res.data.total[0].total_processes
      })
    }
    function getParties() {
      axios({
        url: '/parties',
        method: 'get',
        baseURL: BASE_URL,
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.parties = res.data.parties
      })
    }
    function getDepartment() {
      axios({
        url: '/allDepartments',
        method: 'get',
        baseURL: BASE_URL,
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.departments = res.data.departments
      })
    }
    function getManager() {
      filter.manager = '0'
      filter.employee = '0'
      axios({
        url: `/user/allManager?dept_id=${filter.department}`,
        method: 'get',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.managers = res.data.managers
        getAvailPkt()
      })
    }
    function getEmployee() {
      filter.employee = '0'
      if(filter.type == 'makeable' || filter.type == '0') {
        axios({
          url: `/user/manager-employee/${filter.manager}`,
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.employees = res.data.employees
          getAvailPkt()
        })
      } else if(filter.type == 'rough') {
        axios({
          url: '/user/planner_checker_ls',
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.employees = res.data.users
          getAvailPkt()
        })
      }
    }
    function getProcessData() {
      axios({
        url: '/all-select',
        method: 'get',
        baseURL: BASE_URL,
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.processes = res.data.processes
      })
    }
    function getChangesTypeWise() {
      if(filter.type == 'makeable' || filter.type== 'rough' || filter.type == '0') {
        getEmployee()
      } else {
        pagedata.employees = []
        filter.employee = '0'
      }

    }
    function getDiamontData() {
      axios({
        url: '/GetAll',
        method: 'get',
        baseURL: BASE_URL,
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.parameters.color = res.data.diamonds.colors
        pagedata.parameters.cut = res.data.diamonds.cut
        pagedata.parameters.purity = res.data.diamonds.purity
        pagedata.parameters.symmetry = res.data.diamonds.symmetry
        pagedata.parameters.polish = res.data.diamonds.polish
        pagedata.parameters.fluerocent = res.data.diamonds.fluerocent
        pagedata.parameters.tension = res.data.diamonds.tension
        pagedata.parameters.shape = res.data.diamonds.shape
        pagedata.parameters.stone = res.data.diamonds.stones
      })
    }
    watch(() => filter.number, (current) => {
      if(current == '') {
        getAvailPkt()
      }
    })
    watch(() => filter.days, (current) => {
      if(current == '') {
        getAvailPkt()
      }
    })
    watch(() => filter.late, (current) => {
      if(current == '') {
        getAvailPkt()
      }
    })
    watch(() => filter.color, (current) => {
        getAvailPkt()
    })
    watch(() => filter.cut, (current) => {
        getAvailPkt()
    })
    watch(() => filter.purity, (current) => {
        getAvailPkt()
    })
    watch(() => filter.polish, (current) => {
        getAvailPkt()
    })
    watch(() => filter.shape, (current) => {
        getAvailPkt()
    })
    watch(() => filter.symmetry, (current) => {
        getAvailPkt()
    })
    watch(() => filter.fluerocent, (current) => {
        getAvailPkt()
    })
    watch(() => filter.tension, (current) => {
        getAvailPkt()
    })
    watch(() => filter.stone, (current) => {
        getAvailPkt()
    })
    watch(() => filter.IR, (current) => {
        getAvailPkt()
    })
    return {
      getAvailablePkt,
      getAvailPkt,
      getChangesTypeWise,
      getEmployee,
      getManager,
      pagedata,
      filter,
      pagelinks,
      onColorCheck,
      onPurityCheck,
      onCutCheck,
      onPolishCheck,
      onShapeCheck,
      onSymmetryCheck,
      onFluerocentCheck,
      onStoneCheck,
      onTensionCheck,
      onIRCheck
    }
  }
})
</script>
<style scoped>
input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .custom-number-input input:focus {
    outline: none !important;
  }

  .custom-number-input button:focus {
    outline: none !important;
  }
  .dropdown {
  position: relative;
  cursor: pointer;
}

</style>

<template>
  <div>
    <div id="basic-non-sticky-notification-layout-pkts" class="toastify-content hidden flex flex-col sm:flex-row">
        <div class="flex items-center justify-around" v-if="pagedata.error">
          <CheckCircleIcon class="w-6 h-6 text-theme-6 mx-auto" />
          <div class="font-medium text-black">{{ pagedata.error }}</div>
        </div>
    </div>
    <div class="xl:grid block xl:grid-cols-2 gap-2">
      <div>
        <div class="flex items-center">
          <h2 class="intro-y text-lg font-medium ml-2">Scan Packet</h2>
          <div class="flex items-center ml-2">
            <div class="mr-2 flex items-center">
              <input
                id="remember-me"
                type="checkbox" v-model="pagedata.check"
                class="form-check-input border mr-2 bg-white"
              />
              <label for="remember-me" class="text-md font-medium">Menual</label>
            </div>
          </div>
        </div>
        <div class="w-full flex sm:w-auto mt-2 ml-2">
          <div class="w-56 relative text-gray-700 dark:text-gray-300 mr-4">
            <input
              type="text"
              v-model="pagedata.search"
              @change="getPacketDetailsScan"
              ref="focusMe"
              autofocus
              class="form-control w-56 box pr-10 placeholder-theme-13"
              placeholder="Search Packet"
            />
            <SearchIcon
              class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
            />
          </div>
          <button class="btn btn-primary shadow-md mr-2" @click="AddtoList">Add</button>
          <a class="btn btn-primary shadow-md mr-2" href="javascript:;" data-toggle="modal" v-if="pagedata.addlist.length > 0 && user_details.user_role != 3" data-target="#deliver-all-confirmation-modal">Deliver Packets</a>
          <a class="btn btn-primary shadow-md mr-2" href="javascript:;" data-toggle="modal" v-if="pagedata.addlist.length > 0 && user_details.user_role == 3" data-target="#accept-confirmation-modal">Accept Packets</a>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-5 mt-2">
      <div
        class="col-span-6 xl:col-span-2 md:col-span-3 sm:col-span-4 box p-5 cursor-pointer zoom-in" v-for="(add,index) in pagedata.addlist" :key="index"
      >
        <div class="flex justify-between">
          <div class="font-medium text-base">{{ add }}</div>
          <button class="btn btn-outline-danger p-1 pl-2 hover:text-white" @click="removeItem(index)"><Trash2Icon class="w-4 h-4 mr-1" /></button>
        </div>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
          <div class=" flex items-center mt-2 xl:mt-0">
            <h2 class="text-xl font-bold">Outward Packets</h2>
            <div class="mr-3 ml-3" v-if="user_details.user_role != 3">
              <TomSelect
                v-model="filter.party_id"
                @change="initTabulator"
                :options="{
                  placeholder: 'Select your favorite actors'
                }"
                class="w-44"
              >
                <option value="0">Party</option>
                <option v-for="dept in pagedata.vendors" :key="dept.id" :value="dept.id">{{ dept.firm_name ? dept.firm_name : dept.first_name + ' ' + dept.last_name }}</option>
              </TomSelect>
            </div>
          </div>
        </form>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <!-- BEGIN: Deliver All Packet Process -->
  <div
    id="deliver-all-confirmation-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body p-3">
          <div class="p-5 text-center">
            <XCircleIcon class="w-16 h-16 text-theme-3 mx-auto mt-3" />
            <div class="text-3xl mt-5">Are you sure?</div>
            <div class="text-gray-600 mt-2">
              Do you really want to Deliver Packets? <br />This process
              cannot be undone.
            </div>
          </div>
          <div class="px-5 pb-8 text-center">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-24 mr-1"
            >
              BACK
            </button>
            <button type="button" class="btn btn-primary w-24" @click="DeliverPackets">Deliver</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Cancel Packet Process -->
    <!-- BEGIN: Deliver All Packet Process -->
  <div
    id="accept-confirmation-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body p-3">
          <div class="p-5 text-center">
            <XCircleIcon class="w-16 h-16 text-theme-3 mx-auto mt-3" />
            <div class="text-3xl mt-5">Are you sure?</div>
            <div class="text-gray-600 mt-2">
              Do you really want to Accept Packets? <br />This process
              cannot be undone.
            </div>
          </div>
          <div class="px-5 pb-8 text-center">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-24 mr-1"
            >
              BACK
            </button>
            <button type="button" class="btn btn-primary w-24" @click="AcceptPackets">Accept</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Cancel Packet Process -->
  </div>
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import store from '@/store'
import axios from 'axios'
import cash from 'cash-dom'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import Toastify from 'toastify-js'
import { computed, defineComponent, inject, onMounted, reactive, ref, watch } from 'vue'

export default defineComponent({
  setup() {
    const focusMe = ref()
    const pagedata = reactive({
      vendors: [],
      cancelId: '',
      addlist: [],
      error: '',
      submit: false,
      check: false,
      search: '',
      cancelId: '',
      waiting: false,
      addornot: false,
    })
    const user_details = computed(() => store.state.user.currentUser)
    const tableRef = ref()
    const tabulator = ref()
    const filter = reactive({
      party_id: '0',
    })
    const swal = inject('$swal')
    onMounted(() => {
      focusMe.value.focus()
      initTabulator()
      setTimeout(() => {
        getParties()
      }, 500);
    })
    function getParties() {
      axios({
        url: 'api/master/dp_parties',
        method: 'post',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.vendors = res.data.items
      })
    }
    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + 'api/outward/get_outward_packets',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams: {
          party_id: filter.party_id == '0' ? '' : window.btoa(filter.party_id)
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        headerSort: false,
        pagination: 'remote',
        paginationSize: 10,
        paginationSizeSelector: [10, 20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [
          {
            formatter: 'responsiveCollapse',
            width: 40,
            minWidth: 30,
            hozAlign: 'center',
            resizable: false,
            headerSort: false
          },

          // For HTML table
          {
            title: 'Packet No',
            responsive: 0,

            field: 'packet_code',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().number ? cell.getData().number : ''
                }</div>
              </div>`
            }
          },
          {
            title: 'Vepari No',
            responsive: 0,
            field: 'number',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().vepari_no ? cell.getData().vepari_no : ''
                }</div>
              </div>`
            }
          },
          {
            title: 'Party',
            responsive: 0,
            field: 'party_first_name',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().firm_name ? cell.getData().firm_name : cell.getData().party_first_name + ' ' + cell.getData().party_last_name
                }</div>
              </div>`
            }
          },
          {
            title: 'Weight (Ct)',
            responsive: 0,
            field: 'weight',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().weight ? cell.getData().weight : ''
                }</div>
              </div>`
            }
          },
          {
            title: 'Cr Weight (Ct)',
            responsive: 0,
            field: 'cr_weight',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().cr_weight ? cell.getData().cr_weight : ''
                }</div>
              </div>`
            }
          },
          {
            title: 'Polish Wt',
            responsive: 0,
            field: 'cr_polish_weight',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().cr_polish_weight ? cell.getData().cr_polish_weight : ''
                }</div>
              </div>`
            }
          },
          {
            title: 'Action',
            field: 'outword',
            minWidth: 100,
            vertAlign: 'middle',
            print: false,
            download: false,
            columns: [
              {
                field: '',
                hozAlign: 'center',
                vertAlign: 'middle',
                print: false,
                download: false,
                formatter(cell) {
                  if(user_details.value.user_role != 3) {

                    const a = cash(`<div class="flex lg:justify-center items-center">
                      ${
                        cell.getData().status == 5 && cell.getData().outward_date == null ? '<button class="btn btn-primary p-1 px-2"> <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Deliver </button>' : cell.getData().status == 5 && cell.getData().outward_date != null ? '<button class="btn btn-dark p-1 px-1"> <i data-feather="check-square" class="w-4 h-4"></i>Accept</a>' : ''
                      }
                    </div>`)
                    cash(a).on('click', function() {
                      if(cell.getData().status == 5 && cell.getData().outward_date == null) {
                        DeliverPacket(cell.getData().id)
                      } else if(cell.getData().status == 5 && cell.getData().outward_date != null) {
                        AcceptPacket(cell.getData().id)
                      }
                    })
                    return a[0]
                  } else {
                    const a = cash('<div class="flex lg:justify-center items-center"><button class="btn btn-dark p-1 px-1"> <i data-feather="check-square" class="w-4 h-4"></i>Accept</a></div>')
                    cash(a).on('click', function() {
                      AcceptPacket(cell.getData().id)
                    })
                    return a[0]
                  }

                }
              },
              {
                field: '',
                hozAlign: 'center',
                vertAlign: 'middle',
                print: false,
                download: false,
                formatter(cell) {
                  const a = cash(`<div class="flex lg:justify-center items-center">
                    ${
                      cell.getData().status == 5 && cell.getData().outward_date != null ? '<button class="btn btn-danger p-1 px-2"> <i data-feather="trash" class="w-4 h-4"></i> Cancel </button>' : (cell.getData().status == 5 && cell.getData().outward_date == null ? '<button class="btn btn-danger p-1 px-2">To Process </button>' : '')
                    }
                  </div>`)
                  cash(a).on('click', function() {
                    if(cell.getData().status == 5 && cell.getData().outward_date != null) {
                      CancelPacket(cell.getData().id)
                    } else if(cell.getData().status == 5 && cell.getData().outward_date == null) {
                      CancelOutwardPacket(cell.getData().id)
                    }
                  })

                  return a[0]
                }
              },
            ],
          },
        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }
  // Redraw table onresize
      const reInitOnResizeWindow = () => {
        window.addEventListener('resize', () => {
          tabulator.value.redraw()
          feather.replace({
            'stroke-width': 1
          })
        })
      }
    function AddtoList() {
      if(pagedata.search != '') {
        if(pagedata.addlist.length <= 0) {
          checkadd()
        } else {
          for (let i = 0; i < pagedata.addlist.length; i++) {
            if(pagedata.addlist[i] == pagedata.search) {
              // pagedata.checklist.splice(pagedata.addlist[i].id,1)
              pagedata.addlist.splice(i, 1);
              pagedata.addornot = true
              pagedata.search = ''
              break
            } else {
              pagedata.addornot = false
            }

          }
          if(pagedata.addornot == false) {
              checkadd()
            }
        }
      }
    }
    function checkadd() {
      let params = {
        packet_id : window.btoa(pagedata.search)
      }
      axios({
        url: 'api/outward/scan_outward_packet',
        method: 'post',
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        if(res.data.error == "YES") {
          pagedata.error = res.data.error
          setTimeout(() => {
            basicNonStickyNotificationPack()
          },100)
        } else {
          const index = ref(pagedata.addlist.length)
          pagedata.addlist[index.value] = res.data.item.number
          pagedata.search = ''
          const focusMe = ref()
        }
      })
    }
    function DeliverPacket(id) {
      swal({
        title: "Are you sure?",
        text: "You want Packet Transfer To Makeable!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Accept",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if(result.isConfirmed){

          axios({
            url: 'api/outward/deliver_packet_item',
            method: 'post',
            baseURL: BASE_URL,
            data: { packet_id : window.btoa(id) },
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
            config: { headers: { 'Accept':'application/json' } }
          }).then((res) => {
            pagedata.addlist = []
            initTabulator()
            swal("Success!", "Packets Out For Delivery !", "success")
          }).catch((err) => {
            pagedata.error = err.response.data.error
            setTimeout(() => {
              basicNonStickyNotificationPack()
            }, 10);
          })
        }
      });
    }
    function DeliverPackets() {
      axios({
          url: 'api/outward/deliver_packet_items',
          method: 'post',
          baseURL: BASE_URL,
          data: { packets: pagedata.addlist },
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.addlist = []
          cash('#deliver-all-confirmation-modal').modal('hide')
          initTabulator()
          swal("Success!", "Packets Out For Delivery !", "success")
        }).catch((err) => {
          pagedata.error = err.response.data.error
          setTimeout(() => {
            basicNonStickyNotificationPack()
          }, 10);
        })
    }
    function AcceptPacket(id) {
      swal({
        title: "Are you sure?",
        text: "You want Accept Packet!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Accept",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if(result.isConfirmed){

          axios({
            url: 'api/outward/accept_outward_packet',
            method: 'post',
            baseURL: BASE_URL,
            data: { packet_id : window.btoa(id) },
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
            config: { headers: { 'Accept':'application/json' } }
          }).then((res) => {
            pagedata.addlist = []
            initTabulator()
            swal("Success!", "Packets Delivered !", "success")
          }).catch((err) => {
            pagedata.error = err.response.data.error
            setTimeout(() => {
              basicNonStickyNotificationPack()
            }, 10);
          })
        }
      });
    }
    function AcceptPackets() {
      axios({
          url: 'api/outward/accept_outward_packets',
          method: 'post',
          baseURL: BASE_URL,
          data: { packets: pagedata.addlist },
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.addlist = []
          cash('#deliver-all-confirmation-modal').modal('hide')
          initTabulator()
          swal("Success!", "Packets Delivered !", "success")
        }).catch((err) => {
          pagedata.error = err.response.data.error
          setTimeout(() => {
            basicNonStickyNotificationPack()
          }, 10);
        })
    }
    function CancelPacket(id) {
      swal({
        title: "Are you sure?",
        text: "You want Cancel Delivery!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Accept",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if(result.isConfirmed){

          axios({
            url: 'api/outward/cancel_out_delivery_packet',
            method: 'post',
            baseURL: BASE_URL,
            data: { packet_id : window.btoa(id) },
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
            config: { headers: { 'Accept':'application/json' } }
          }).then((res) => {
            pagedata.addlist = []
            initTabulator()
            swal("Success!", "Packets Outward Cancelled !", "success")
          }).catch((err) => {
            pagedata.error = err.response.data.error
            setTimeout(() => {
              basicNonStickyNotificationPack()
            }, 10);
          })
        }
      });
    }
    function CancelPackets() {
      axios({
          url: 'api/outward/cancel_out_delivery_packets',
          method: 'post',
          baseURL: BASE_URL,
          data: { packets: pagedata.addlist },
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.addlist = []
          cash('#deliver-all-confirmation-modal').modal('hide')
          initTabulator()
          swal("Success!", "Packet Outward Cancelled !", "success")
        }).catch((err) => {
          pagedata.error = err.response.data.error
          setTimeout(() => {
            basicNonStickyNotificationPack()
          }, 10);
        })
    }
    function CancelOutwardPacket(id) {
      swal({
        title: "Are you sure?",
        text: "You want Transfer to Process!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Accept",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if(result.isConfirmed){

          axios({
            url: 'api/outward/cancel_outward_packet',
            method: 'post',
            baseURL: BASE_URL,
            data: { packet_id : window.btoa(id) },
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
            config: { headers: { 'Accept':'application/json' } }
          }).then((res) => {
            pagedata.addlist = []
            initTabulator()
            swal("Success!", "Packets Outward Cancelled !", "success")
          }).catch((err) => {
            pagedata.error = err.response.data.error
            setTimeout(() => {
              basicNonStickyNotificationPack()
            }, 10);
          })
        }
      });
    }
    function CancelOutwardPackets() {
      axios({
          url: 'api/outward/cancel_outward_packets',
          method: 'post',
          baseURL: BASE_URL,
          data: { packets: pagedata.addlist },
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.addlist = []
          cash('#deliver-all-confirmation-modal').modal('hide')
          initTabulator()
          swal("Success!", "Packet Outward Cancelled !", "success")
        }).catch((err) => {
          pagedata.error = err.response.data.error
          setTimeout(() => {
            basicNonStickyNotificationPack()
          }, 10);
        })
    }
    function getPacketDetailsScan() {
        if(!pagedata.check && pagedata.search) {
          AddtoList();
        }
      }
    const basicNonStickyNotificationPack = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification-layout-pkts')
          .clone()
          .removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    function removeItem(id) {
      pagedata.addlist.splice(id, 1);
    }
    return {
      pagedata,
      focusMe,
      filter,
      user_details,
      tableRef,
      getPacketDetailsScan,
      reInitOnResizeWindow,
      initTabulator,
      getParties,
      AcceptPackets,
      removeItem,
      DeliverPackets,
      CancelPackets,
      CancelOutwardPackets,
      basicNonStickyNotificationPack
    }
  }
})
</script>

<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: General Report -->
        <div class="col-span-12 mt-5">
          <div class="grid grid-cols-12 gap-6 mt-5">
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex items-center justify-between">
                    <img src="@/assets/images/diamond.gif" class="w-14 h-14 mb-2 text-theme-9" alt="">
                    <div class="text-3xl font-medium leading-8 mt-6">{{ pagedata.headings.total_packets }}</div>
                  </div>
                  <div class="flex items-center justify-between">
                    <div class="text-base text-gray-600 mt-1">Total Packets</div>
                    <div class="text-base text-gray-600 mt-1">{{ parseFloat(pagedata.headings.total_packets_weight).toFixed(2) }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex items-center justify-between">
                    <img src="@/assets/images/processing.gif" class="w-14 h-14 mb-2 text-theme-10" alt="">
                    <div class="text-3xl font-medium leading-8 mt-6">{{ pagedata.headings.processing }}</div>
                  </div>
                  <div class="flex items-center justify-between">
                    <div class="text-base text-gray-600 mt-1">In Process</div>
                    <div class="text-base text-gray-600 mt-1">{{ parseFloat(pagedata.headings.processing_weight).toFixed(2) }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex items-center justify-between">
                    <img src="@/assets/images/shoppingcart.gif" class="w-16 h-16 text-theme-12" alt="">
                    <div class="text-3xl font-medium leading-8 mt-6">{{ pagedata.headings.external }}</div>
                  </div>
                  <div class="flex items-center justify-between">
                    <div class="text-base text-gray-600 mt-1">In External</div>
                    <div class="text-base text-gray-600 mt-1">{{ parseFloat(pagedata.headings.external_weight).toFixed(2) }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex items-center justify-between">
                    <img src="@/assets/images/process.gif" class="w-16 h-16 text-theme-12" alt="">
                    <div class="text-3xl font-medium leading-8 mt-6">{{ pagedata.headings.outward }}</div>
                  </div>
                  <div class="flex items-center justify-between">
                    <div class="text-base text-gray-600 mt-1">In Delivery</div>
                    <div class="text-base text-gray-600 mt-1">{{ parseFloat(pagedata.headings.outward_weight).toFixed(2) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: General Report -->
        <!-- BEGIN: Sales Report -->
        <div class="col-span-12 lg:col-span-6 mt-8">
          <div class="intro-y block sm:flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">Packets Growth</h2>
          </div>
          <div class="intro-y box p-5 mt-12 sm:mt-5">
            <div class="flex flex-col xl:flex-row xl:items-center">
              <div class="flex">
                <div>
                  <div
                    class="text-theme-19 dark:text-gray-300 text-lg xl:text-xl font-medium"
                  >
                    {{ pagedata.currerrent_month_packets }} Packets
                  </div>
                  <div class="mt-0.5 text-gray-600 dark:text-gray-600">
                    This Month
                  </div>
                </div>
                <div
                  class="w-px h-12 border border-r border-dashed border-gray-300 dark:border-dark-5 mx-4 xl:mx-5"
                ></div>
                <div>
                  <div
                    class="text-gray-600 dark:text-gray-600 text-lg xl:text-xl font-medium"
                  >
                    {{ pagedata.prev_month_packets }} Packets
                  </div>
                  <div class="mt-0.5 text-gray-600 dark:text-gray-600">
                    Last Month
                  </div>
                </div>
              </div>
            </div>
            <div>
              <ReportLineChart :height="169" class="mt-6" :grawth="pagedata.party_growth" :last_grawth="pagedata.last_year_party_growth" />
            </div>
          </div>
        </div>
        <!-- END: Sales Report -->
        <!-- BEGIN: Weekly Top Seller -->
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 mt-8">
          <div class="intro-y flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">Weekly Top Seller</h2>
          </div>
          <div class="intro-y box p-5 mt-5">
            <Chart
              type="pie"
              :height="300"
              :data="data"
              :options="options" class="mt-3"
            />
            <div class="mt-3">
              <div class="flex items-center">
                <div class="w-2 h-2 bg-theme-6 rounded-full mr-3"></div>
                <span class="truncate">Profit</span>
                <div
                  class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                ></div>
                <span class="font-medium xl:ml-auto">{{ parseFloat(pagedata.companey_cost - pagedata.employees_cost.manager_rates - pagedata.employees_cost.employee_rates - (pagedata.jobwork_cost ? pagedata.jobwork_cost:0)).toFixed(2) }}</span>
              </div>
              <div class="flex items-center mt-3">
                <div class="w-2 h-2 bg-theme-12 rounded-full mr-3"></div>
                <span class="truncate">Employee Rate</span>
                <div
                  class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                ></div>
                <span class="font-medium xl:ml-auto">{{ pagedata.employees_cost.employee_rates}}</span>
              </div>
              <div class="flex items-center mt-3">
                <div class="w-2 h-2 bg-theme-11 rounded-full mr-3"></div>
                <span class="truncate">Manager Rate</span>
                <div
                  class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                ></div>
                <span class="font-medium xl:ml-auto">{{ pagedata.employees_cost.manager_rates}}</span>
              </div>
              <div class="flex items-center mt-3">
                <div class="w-2 h-2 bg-theme-1 rounded-full mr-3"></div>
                <span class="truncate">Jober Rate</span>
                <div
                  class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                ></div>
                <span class="font-medium xl:ml-auto">{{ pagedata.jobwork_cost ? pagedata.jobwork_cost:0 }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Weekly Top Seller -->
        <!-- BEGIN: Sales Report -->
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 mt-8">
          <div class="intro-y flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">Sales Report</h2>
          </div>
          <div class="intro-y box p-5 mt-5">
            <ReportDonutChart :height="300" class="mt-3" />
            <div class="mt-8">
              <div class="flex items-center">
                <div class="w-2 h-2 bg-theme-11 rounded-full mr-3"></div>
                <span class="truncate">17 - 30 Years old</span>
                <div
                  class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                ></div>
                <span class="font-medium xl:ml-auto">62%</span>
              </div>
              <div class="flex items-center mt-4">
                <div class="w-2 h-2 bg-theme-1 rounded-full mr-3"></div>
                <span class="truncate">31 - 50 Years old</span>
                <div
                  class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                ></div>
                <span class="font-medium xl:ml-auto">33%</span>
              </div>
              <div class="flex items-center mt-4">
                <div class="w-2 h-2 bg-theme-12 rounded-full mr-3"></div>
                <span class="truncate">>= 50 Years old</span>
                <div
                  class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                ></div>
                <span class="font-medium xl:ml-auto">10%</span>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Sales Report -->
        <div class="col-span-12">
        <div class="grid grid-cols-12 gap-6">
          <!-- BEGIN: Weekly Best Sellers -->
          <div class="col-span-4 mt-6">
            <div class="intro-y flex items-center h-10">
              <h2 class="text-lg font-bold truncate mr-5">
                Weekly Top Parties
              </h2>
            </div>
            <div class="mt-5">
              <div
                v-for="(party, index) in pagedata.top_parties"
                :key="index"
                class="intro-y"
              >
                <div class="box px-4 py-4 mb-3 flex items-center zoom-in">
                  <div
                    class="text-lg font-medium truncate mr-3"
                  >{{ party.total_packets }}
                  </div>
                  <div class="ml-4 mr-auto">
                    <div class="font-medium">
                      {{ party.party }}
                    </div>
                    <div class="text-gray-600 text-xs mt-0.5">
                      {{ party.firmname }}
                    </div>
                  </div>
                  <div
                    class="py-1 px-2 rounded-full text-xs bg-theme-9 text-white cursor-pointer font-medium"
                  >
                    {{ parseFloat(party.total_weight).toFixed(2) }} ct.
                  </div>
                </div>
              </div>
              <a
                href=""
                class="intro-y w-full block text-center rounded-md py-4 border border-dotted border-theme-15 dark:border-dark-5 text-theme-16 dark:text-gray-600"
                >View More</a
              >
            </div>
          </div>
          <!-- END: Weekly Best Sellers -->
          <!-- BEGIN: Weekly Top Products -->
          <div class="col-span-8 mt-6">
            <div class="intro-y flex items-center h-10">
              <h2 class="text-lg font-bold truncate mr-5">
                Discuss Packets
              </h2>
            </div>
            <div class="mt-5 overflow-auto lg:overflow-visible">
              <table class="table-report w-full" v-if="pagedata.discuss.length > 0">
                <thead>
                  <tr>
                    <th class="text-center whitespace-nowrap">#</th>
                    <th class="text-center whitespace-nowrap">Vepari No</th>
                    <th class="text-center whitespace-nowrap">Number</th>
                    <th class="text-center whitespace-nowrap">Weight ( ct. )</th>
                    <th class="text-center whitespace-nowrap">Polish Weight ( ct. )</th>
                    <th class="text-center whitespace-nowrap">Current Weight ( ct. )</th>
                    <th class="text-center whitespace-nowrap">In Stock</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(discuss, index) in pagedata.discuss"
                    :key="index"
                    class="intro-x"
                  >
                    <td class="py-2 text-center whitespace-nowrap">{{ index + 1 }}</td>
                    <td class="py-2 text-center whitespace-nowrap">{{ discuss.vepari_no }}</td>
                    <td class="py-2 text-center whitespace-nowrap">{{ discuss.number }}</td>
                    <td class="py-2 text-center whitespace-nowrap">{{ discuss.weight }}</td>
                    <td class="py-2 text-center whitespace-nowrap">{{ discuss.total_pwt > 0 ? discuss.total_pwt : discuss.pwt }}</td>
                    <td class="py-2 text-center whitespace-nowrap">{{ discuss.receive_weight }}</td>
                    <td class="py-2 text-center whitespace-nowrap">{{ discuss.username }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="intro-y box py-4 text-center" v-else>
                    Discuss Packets not Found
                </div>
            </div>
          </div>
          <!-- END: Weekly Top Products -->
        </div>
      </div>
        <!-- BEGIN: Weekly Top Products -->
        <div class="col-span-12 mt-3">
          <div class="intro-y block sm:flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">
              Party Packets
            </h2>
          </div>
          <div class="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0">
            <table class="table-report w-full" v-if="pagedata.parties.length > 0">
              <thead>
                <tr>
                  <th class="text-center whitespace-nowrap">#</th>
                  <th class="text-center whitespace-nowrap">Name</th>
                  <th class="text-center whitespace-nowrap">Firmname</th>
                  <th class="text-center whitespace-nowrap">Packets</th>
                  <th class="text-center whitespace-nowrap">Weight ( ct. )</th>
                  <th class="text-center whitespace-nowrap">Polish Weight ( ct. )</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(party, index) in pagedata.parties"
                  :key="index"
                  class="intro-x"
                >
                  <td class="py-2 text-center whitespace-nowrap">{{ index + 1 }}</td>
                  <td class="py-2 text-center whitespace-nowrap">{{ party.party }}</td>
                  <td class="py-2 text-center whitespace-nowrap">{{ party.firmname }}</td>
                  <td class="py-2 text-center whitespace-nowrap">{{ party.total_packets }}</td>
                  <td class="py-2 text-center whitespace-nowrap">{{ party.total_weight }}</td>
                  <td class="py-2 text-center whitespace-nowrap">{{ party.total_pwt }}</td>
                </tr>
              </tbody>
            </table>
            <div class="intro-y box py-4 text-center" v-else>
                    Party has not Packets Yet !
                </div>
          </div>
          <!-- BEGIN: Pagination -->
          <div
            class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center mt-3 mb-7"
          >
            <ul class="pagination">
              <li>
                <button class="pagination__link" :disabled="pagedata.current_page == 1" @click="AdminDashboardUrl(pagedata.first_page_url)">
                  <ChevronsLeftIcon class="w-4 h-4" />
                </button>
              </li>
              <li>
                <button class="pagination__link" :disabled="pagedata.prev_page_url == null" @click="AdminDashboardUrl(pagedata.prev_page_url)">
                  <ChevronLeftIcon class="w-4 h-4" />
                </button>
              </li>
              <li v-for="(pagelink,index) in pagelinks" :key="index">
                <button class="pagination__link" :class="{'pagination__link--active' : pagelink.active == true}" v-if="pagelinks[0] != pagelink && pagelinks[pagelinks.length - 1] != pagelink" @click='AdminDashboardUrl(pagelink.url)'>{{ pagelink.label }}</button>
              </li>
              <li>
                <button class="pagination__link" :disabled="pagedata.next_page_url == null" @click="AdminDashboardUrl(pagedata.next_page_url)">
                  <ChevronRightIcon class="w-4 h-4" />
                </button>
              </li>
              <li>
                <button class="pagination__link" :disabled="pagedata.current_page == pagedata.last_page" @click="AdminDashboardUrl(pagedata.last_page_url)">
                  <ChevronsRightIcon class="w-4 h-4" />
                </button>
              </li>
            </ul>
            <select class="w-20 form-select box mt-3 sm:mt-0" v-model="pagedata.size" @change="AdminDashboard">
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
            </select>
          </div>
          <!-- END: Pagination -->
        </div>
        <!-- END: Weekly Top Products -->
      </div>
    </div>
    <div class="col-span-12">
      <div class="2xl:border-l border-theme-5 -mb-10 pb-10">
        <div class="2xl:pl-6 grid grid-cols-12 gap-6">
          <!-- BEGIN: Important Notes -->
          <div
            class="col-span-12 md:col-span-6 xl:col-span-12 xl:col-start-1 xl:row-start-1 2xl:col-start-auto 2xl:row-start-auto"
          >
            <div class="intro-x flex items-center h-10">
              <h2 class="text-lg font-medium truncate mr-auto">
                Blogs
              </h2>
              <button
                data-carousel="important-notes"
                data-target="prev"
                class="tiny-slider-navigator btn px-2 border-gray-400 text-gray-700 dark:text-gray-300 mr-2"
                @click="prevImportantNotes"
              >
                <ChevronLeftIcon class="w-4 h-4" />
              </button>
              <button
                data-carousel="important-notes"
                data-target="next"
                class="tiny-slider-navigator btn px-2 border-gray-400 text-gray-700 dark:text-gray-300 mr-2"
                @click="nextImportantNotes"
              >
                <ChevronRightIcon class="w-4 h-4" />
              </button>
            </div>
            <div class="mt-5 intro-x">
              <div class="box zoom-in">
                <TinySlider ref-key="importantNotesRef">
                  <div class="p-5">
                    <div class="text-base font-medium truncate">
                      Lorem Ipsum is simply dummy text
                    </div>
                    <div class="text-gray-500 mt-1">20 Hours ago</div>
                    <div class="text-gray-600 text-justify mt-1">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s.
                    </div>
                    <div class="font-medium flex mt-5">
                      <button type="button" class="btn btn-secondary py-1 px-2">
                        View Notes
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-secondary py-1 px-2 ml-auto ml-auto"
                      >
                        Dismiss
                      </button>
                    </div>
                  </div>
                  <div class="p-5">
                    <div class="text-base font-medium truncate">
                      Lorem Ipsum is simply dummy text
                    </div>
                    <div class="text-gray-500 mt-1">20 Hours ago</div>
                    <div class="text-gray-600 text-justify mt-1">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s.
                    </div>
                    <div class="font-medium flex mt-5">
                      <button type="button" class="btn btn-secondary py-1 px-2">
                        View Notes
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-secondary py-1 px-2 ml-auto ml-auto"
                      >
                        Dismiss
                      </button>
                    </div>
                  </div>
                  <div class="p-5">
                    <div class="text-base font-medium truncate">
                      Lorem Ipsum is simply dummy text
                    </div>
                    <div class="text-gray-500 mt-1">20 Hours ago</div>
                    <div class="text-gray-600 text-justify mt-1">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s.
                    </div>
                    <div class="font-medium flex mt-5">
                      <button type="button" class="btn btn-secondary py-1 px-2">
                        View Notes
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-secondary py-1 px-2 ml-auto ml-auto"
                      >
                        Dismiss
                      </button>
                    </div>
                  </div>
                </TinySlider>
              </div>
            </div>
          </div>
          <!-- END: Important Notes -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { defineComponent, ref, provide, onMounted, reactive, toRef, computed } from 'vue'
import ReportLineChart from '@/components/line-chart/Main.vue'
import ReportDonutChart from '@/components/report-donut-chart/Main.vue'
import ReportPieChart from '@/components/report-pie-chart/Main.vue'
import axios from 'axios'
import { BASE_URL } from '@/constants/config'
import store from '@/store'

export default defineComponent({
  components: {
    ReportLineChart,
    ReportDonutChart,
    ReportPieChart
  },
  setup() {
    const salesReportFilter = ref()
    const importantNotesRef = ref()

    provide('bind[importantNotesRef]', el => {
      importantNotesRef.value = el
    })

    const prevImportantNotes = () => {
      const el = importantNotesRef.value
      el.tns.goTo('prev')
    }
    const nextImportantNotes = () => {
      const el = importantNotesRef.value
      el.tns.goTo('next')
    }
    const pagedata = reactive({
      headings: [],
      size: 5,
      currerrent_month_packets: null,
      prev_month_packets: null,
      party_growth: [],
      last_year_party_growth: [],
      companey_cost: [],
      employees_cost: [],
      jobwork_cost: [],
      top_parties: [],
      parties: [],
      discuss: [],
      pagelinks: [],
      prev_page_url: null,
      next_page_url: null,
      first_page_url: null,
      last_page_url: null,
      current_page: 1,
      last_page: '',
    })
    const darkMode = computed(() => store.state.main.darkMode)
    const data = computed(() => {
      return {
        labels: ['Manager Rate', 'Employee Rate','Job Work Rate','Profit'],
        datasets: [
          {
            data: [pagedata.employees_cost.manager_rates, pagedata.employees_cost.employee_rates, pagedata.jobwork_cost ? pagedata.jobwork_cost:0,
            parseFloat(pagedata.companey_cost - pagedata.employees_cost.manager_rates - pagedata.employees_cost.employee_rates - (pagedata.jobwork_cost ? pagedata.jobwork_cost:0)).toFixed(2)],
            backgroundColor: ['#FF8B26', '#FFC533', '#285FD3','#d32929'],
            hoverBackgroundColor: ['#FF8B26', '#FFC533', '#285FD3','#d32929'],
            borderWidth: 5,
            borderColor: darkMode.value ? '#303953' : '#fff'
          }
        ]
      }
    })
    const options = computed(() => {
      return {
        legend: {
          display: false
        }
      }
    })
    const pagelinks = toRef(pagedata, 'pagelinks')
    onMounted(() => {
      // AdminDashboard()
    })
    function AdminDashboardUrl() {
        axios({
          url: url + `&size${pagedata.size}`,
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.headings = res.data.headings[0]
          pagedata.currerrent_month_packets = res.data.currerrent_month_packets
          pagedata.prev_month_packets = res.data.prev_month_packets
          pagedata.party_growth = res.data.party_growth
          pagedata.last_year_party_growth = res.data.last_year_party_growth
          pagedata.companey_cost = res.data.companey_cost[0].total_rate
          pagedata.employees_cost = res.data.employees_cost[0]
          pagedata.jobwork_cost = res.data.jobwork_cost[0].jober_rates
          pagedata.top_parties = res.data.top_parties
          pagedata.parties = res.data.parties.data
          pagedata.pagelinks = res.data.parties.links
          pagedata.prev_page_url = res.data.parties.prev_page_url
          pagedata.next_page_url = res.data.parties.next_page_url
          pagedata.first_page_url = res.data.parties.first_page_url
          pagedata.last_page_url = res.data.parties.last_page_url
          pagedata.current_page = res.data.parties.current_page
          pagedata.last_page = res.data.parties.last_page
          pagedata.discuss = res.data.discuss
        })
    }
    function AdminDashboard() {
        axios({
          url: `/dashboard/admin?size=${pagedata.size}`,
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.headings = res.data.headings[0]
          pagedata.currerrent_month_packets = res.data.currerrent_month_packets
          pagedata.prev_month_packets = res.data.prev_month_packets
          pagedata.party_growth = res.data.party_growth
          pagedata.last_year_party_growth = res.data.last_year_party_growth
          pagedata.companey_cost = res.data.companey_cost[0].total_rate
          pagedata.employees_cost = res.data.employees_cost[0]
          pagedata.jobwork_cost = res.data.jobwork_cost[0].jober_rates
          pagedata.top_parties = res.data.top_parties
          pagedata.parties = res.data.parties.data
          pagedata.pagelinks = res.data.parties.links
          pagedata.prev_page_url = res.data.parties.prev_page_url
          pagedata.next_page_url = res.data.parties.next_page_url
          pagedata.first_page_url = res.data.parties.first_page_url
          pagedata.last_page_url = res.data.parties.last_page_url
          pagedata.current_page = res.data.parties.current_page
          pagedata.last_page = res.data.parties.last_page
          pagedata.discuss = res.data.discuss
        })
    }
    return {
      pagedata,
      pagelinks,
      data,
      options,
      salesReportFilter,
      prevImportantNotes,
      nextImportantNotes,
      AdminDashboard,
      AdminDashboardUrl
    }
  }
})
</script>

<template>
  <div id="basic-non-sticky-notification-packet" class="toastify-content hidden flex flex-col sm:flex-row">
      <div class="font-medium text-primary-3" v-if="pagedata.error">{{ pagedata.error }}</div>
  </div>
  <div>
    <div class="xl:grid block xl:grid-cols-2 gap-6">
      <div>
        <h2 class="intro-y text-lg font-medium mt-5 ml-2">Scan Packet</h2>
        <div class="flex items-center mt-2 ml-2">
          <div class="mr-2 flex items-center">
            <input
              id="remember-me"
              type="checkbox" v-model="pagedata.check"
              class="form-check-input border mr-2 bg-white"
            />
            <label for="remember-me" class="text-md font-medium">Menual</label>
          </div>
          <!-- <div class="mx-2 flex items-center">
            <input
              id="transfer-me"
              type="checkbox" v-model="pagedata.transfer"
              class="form-check-input border mr-2 bg-white"
            />
            <label for="transfer-me" class="text-md font-medium">Packet Transfer</label>
          </div>
          <div class="mx-2 flex items-center">
            <input
              id="transfer-me"
              type="checkbox" v-model="pagedata.jobwork_done"
              class="form-check-input border mr-2 bg-white"
            />
            <label for="transfer-me" class="text-md font-medium">Jobwork Done</label>
          </div> -->
        </div>
        <div class="w-full flex sm:w-auto mt-3 ml-2">
          <div class="w-56 relative text-gray-700 dark:text-gray-300 mr-4">
            <input
              type="text"
              v-model="pagedata.search"
              autofocus
              @change="getPacketDetailsScan"
              ref="focusMe"
              class="form-control w-56 box pr-10 placeholder-theme-13"
              placeholder="Search Packet"
            />
            <SearchIcon
              class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
            />
          </div>
          <button class="btn btn-primary shadow-md mr-2" @click="AddtoList">Add</button>
          <a class="btn btn-primary shadow-md mr-2" href="javascript:;" data-toggle="modal" @click="transferMakeable" data-target="#new-process-modal" v-if="pagedata.addlist.length > 0">Makeable Transfer</a>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-5 mt-5">
      <div
        class="col-span-6 xl:col-span-3 md:col-span-3 sm:col-span-4 box p-5 cursor-pointer zoom-in" v-for="(add,index) in pagedata.addlist" :key="index"
      >
        <div class="flex justify-between">
          <div class="font-medium text-base">{{ add }}</div>
          <button class="btn btn-outline-danger p-1 pl-2 hover:text-white" @click="removeItem(index)"><Trash2Icon class="w-4 h-4 mr-1" /></button>
        </div>
      </div>
    </div>
    <!-- BEGIN: Data List -->
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex items-center">
        <h2 class="text-lg font-bold">Rough Packets</h2>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import axios from 'axios'
import cash from 'cash-dom'
import { computed, defineComponent, inject, onMounted, reactive, ref, toRef, watch } from 'vue'
import print from 'vue3-print-nb'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import Toastify from 'toastify-js'
import store from '@/store'
import router from '@/router'
export default defineComponent({
  directives: {
    print
  },
  setup() {
    const user_details = computed(() => store.state.user.currentUser)
    const pagedata = reactive({
      search: '',
      check: false,
      addlist: [],
      addornot: false,
      error: '',
    })
    const focusMe = ref()
    const swal = inject('$swal')
    const tableRef = ref()
    const tabulator = ref()
    onMounted(() => {
      initTabulator()
      focusMe.value.focus()
    })
    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + 'api/transfer/get_rough_available_packets',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        headerSort: false,
        pagination: 'remote',
        paginationSize: 10,
        paginationSizeSelector: [10, 20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [
          {
            formatter: 'responsiveCollapse',
            width: 40,
            minWidth: 30,
            hozAlign: 'center',
            resizable: false,
            headerSort: false
          },

          // For HTML table
          {
            title: 'Packet No',
            responsive: 0,
            minWidth:120,
            field: 'number',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().urgent == 1 ? '<span class="text-theme-6">'+ cell.getData().number +'</span>' : cell.getData().number
                }</div>
              </div>`
            }
          },
          {
            title: 'Weight',
            responsive: 0,
            field: 'cr_weight',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().cr_weight
                }</div>
              </div>`
            }
          },
          {
            title: 'Exp. Wt',
            responsive: 0,
            field: 'cr_polish_weight',
            print: false,
            download: false
          },
          {
            title: 'Shp',
            field: 'shape',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Clr',
            field: 'color',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Prt',
            field: 'purity',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Ct',
            field: 'cut',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Pl',
            field: 'polish',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Sym',
            field: 'symmetry',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Fluc',
            field: 'fluerocent',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Tn',
            field: 'tension',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Stn',
            field: 'stone',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }
    function AddtoList() {
      if(pagedata.search != '') {
        if(pagedata.addlist.length <= 0) {
          checkadd()
        } else {
          for (let i = 0; i < pagedata.addlist.length; i++) {
            if(pagedata.addlist[i] == pagedata.search) {
              // pagedata.checklist.splice(pagedata.addlist[i].id,1)
              pagedata.addlist.splice(i, 1);
              pagedata.addornot = true
              pagedata.search = ''
              break
            } else {
              pagedata.addornot = false
            }

          }
          if(pagedata.addornot == false) {
              checkadd()
            }
        }
      }
    }
    function checkadd() {
      const params = {
        number : window.btoa(pagedata.search),
      }
      axios({
        url: 'api/transfer/scan_transfer_rough_available_packets',
        method: 'post',
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        const index = ref(pagedata.addlist.length)
        pagedata.addlist[index.value] = res.data.packet.number
        pagedata.search = ''
        focusMe.value.focus()
        // pagedata.checklist[pagedata.addlist[index.value].id] = true
      }).catch((err) => {
        pagedata.error = err.response.data.error
        pagedata.search = ''
        focusMe.value.focus()
        console.warn(pagedata.error)
        setTimeout(() => {
          basicNonStickyNotificationPacket()
        },10)

      })
    }
    function removeItem(id) {
      pagedata.addlist.splice(id, 1);
    }
    function transferMakeable() {
      swal({
        title: "Are you sure?",
        text: "You want Packet Transfer To Makeable!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Accept",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        const params = {
          packets: pagedata.addlist
        }
        axios({
          url: 'api/transfer/packet_transfer_rough_to_makeable',
          method: 'post',
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then(() => {
          pagedata.addlist = []
          initTabulator()
          swal("Success!", "Packets Transfered To Makeable!", "success")
        }).catch((err) => {
          swal("Warning!", err.response.data.error, "warning")
        })
      });
    }
    function getPacketDetailsScan() {
        if(!pagedata.check && pagedata.search) {
          AddtoList();
        }
      }
    const basicNonStickyNotificationPacket = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification-packet')
          .clone()
          .removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    return {
      pagedata,
      focusMe,
      user_details,
      tableRef,
      AddtoList,
      removeItem,
      initTabulator,
      transferMakeable,
      getPacketDetailsScan,
      basicNonStickyNotificationPacket,
    }
  }
  // ,
  // mounted() {
  //   this.$refs.focusMe.focus()
  // }
})
</script>


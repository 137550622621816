<template>
<div>
  <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Discuss Stock</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div class="flex items-center mt-2 ml-2">
                <div class="mr-2 flex items-center">
                    <input id="remember-me" type="checkbox" v-model="page_data.check" class="form-check-input border mr-2 bg-white" />
                    <label for="remember-me" class="text-md font-medium">Manual</label>
                </div>
            </div>
            <div class="w-full flex sm:w-auto mt-3 ml-2">
                <div class="w-56 relative text-gray-700 dark:text-gray-300 mr-4">
                    <input type="text" v-model="page_data.scan_lot_no" @change="get_packet_scan" autofocus ref="focusMe" :disabled="page_data.scan_disable == true" class="form-control w-56 box pr-10 placeholder-theme-13" placeholder="Scan Packet" />
                    <SearchIcon class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0" />
                </div>
                <button class="btn btn-primary shadow-md mr-2" @click="scan_stock_no">Issue</button>
            </div>
      </div>
    </div>
    <!-- <div class="xl:grid block xl:grid-cols-2 gap-6">
        <div>
            <h2 class="intro-y text-lg font-medium mt-5 ml-2">Scan Packet</h2>
            <div class="flex items-center mt-2 ml-2">
                <div class="mr-2 flex items-center">
                    <input id="remember-me" type="checkbox" v-model="page_data.check" class="form-check-input border mr-2 bg-white" />
                    <label for="remember-me" class="text-md font-medium">Manual</label>
                </div>
            </div>
            <div class="w-full flex sm:w-auto mt-3 ml-2">
                <div class="w-56 relative text-gray-700 dark:text-gray-300 mr-4">
                    <input type="text" v-model="page_data.scan_lot_no" @change="get_packet_scan" autofocus ref="focusMe" :disabled="page_data.scan_disable == true" class="form-control w-56 box pr-10 placeholder-theme-13" placeholder="Scan Packet" />
                    <SearchIcon class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0" />
                </div>
                <button class="btn btn-primary shadow-md mr-2" @click="scan_stock_no">Issue</button>
            </div>
        </div>
    </div> -->
    <!-- BEGIN: Data List -->
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
        <div class="grid grid-cols-12 gap-x-2">
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div class="">
                    <label for="update-profile-form-6" class="form-label py-1">Packet / Stock</label>
                    <input id="tabulator-html-filter-value" v-model="filters.packet_no" type="text" class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0" placeholder="Search Packet / Stock" />
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div>
                  <label for="update-profile-form-6" class="form-label py-1">Select Kapan</label>
                    <TomSelect v-model="filters.kapan_id" :options="{
                  placeholder: 'Select Kapan',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Kapan'
                    }
                  }
                }" class="w-full">
                        <option value="0">Select Kapan</option>
                        <option v-for="item in page_data.purchase_list" :key="item.id" :value="item.id">{{item.lot_no}} ( {{ item.voucher_no ? item.voucher_no : '-' }} )</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div>
                  <label for="update-profile-form-6" class="form-label py-1">Select Process</label>
                    <TomSelect v-model="filters.process_id" :options="{
                  placeholder: 'Select Process',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Process'
                    }
                  }
                }" class="w-full">
                        <option value="0">Select Process</option>
                        <option v-for="item in page_data.process_list" :key="item.id" :value="item.id">{{item.process_name}} ( {{ item.process_short_code ? item.process_short_code : '-' }} )</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2" v-if="user_details.user_role != 4">
                <div>
                  <label for="update-profile-form-6" class="form-label py-1">Select Out Party</label>
                    <TomSelect v-model="filters.employee_id" :options="{
                  placeholder: 'Select Out Party',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Out Party'
                    }
                  }
                }" class="w-full">
                        <option value="0">Select Out Party</option>
                        <option v-for="item in page_data.employee_list" :key="item.id" :value="item.id">{{item.first_name}} {{ item.last_name ? item.last_name : '' }}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div>
                  <label for="update-profile-form-6" class="form-label py-1">Select Status</label>
                    <TomSelect v-model="filters.status" :options="{
                  placeholder: 'Select Status',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Status'
                    }
                  }
                }" class="w-full">
                        <option value="0">Select Status</option>
                        <option value="1">On Hold</option>
                        <option value="4">Active</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-2 md:col-span-2 xl:col-span-1">
                <div class="mt-7 flex pt-2">

                    <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2" @click="reset_filters">
                        Reset
                    </button>
                </div>
            </div>
            <div class="col-span-2 md:col-span-2 xl:col-span-1">
                <div class="mt-7 flex pt-2">

                    <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full ml-2" tabindex="5" @click="initTabulator()">
                        Generate
                    </button>
                </div>
            </div>
        </div>
        <div class="overflow-x-auto scrollbar-hidden">
            <div id="tabulator" ref="tableRef" class="mt-5 table-report table-report--tabulator"></div>
        </div>
    </div>
    <!-- END: HTML Table Data -->
</div>
<!-- BEGIN: new Process Modal -->
<div id="discuss-issue-modal" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
                <a data-dismiss="modal" href="javascript:;">
                    <XIcon class="w-8 h-8 text-gray-500" />
                </a>
                <div class="modal-body p-10">
                    <!-- <div id="vertical-form"> -->
                    <div class="flex flex-col justify-between mt-5">
                        <!-- BEGIN: Data List -->
                        <div class="intro-y overflow-auto">
                            <div class="flex items-center">
                                <h2 class="intro-y text-lg font-medium mr-5">Discuss Issue</h2>
                            </div>
                        </div>
                        <div class="grid grid-cols-12 gap-4">
                            <div class="intro-y col-span-12">
                                <!-- BEGIN: Input -->
                                <div class="">
                                    <div id="input" class="py-5">
                                        <div class="preview">
                                            <div>
                                                <!-- <label class="form-label">Kapan No & Stone Type</label> -->
                                                <div class="sm:grid grid-cols-2 gap-2">
                                                    <div>
                                                        <div class="">
                                                            <div id="input-group-4">Packet NO</div>

                                                            <input id="pos-form-1" v-model="page_data.packet_details.number" name="number" type="text" disabled class="form-control flex-1" placeholder="Packet No" />
                                                        </div>
                                                    </div>
                                                    <div class="mt-2 sm:mt-0">
                                                        <div class="">
                                                            <div id="input-group-4">Stock NO</div>

                                                            <input id="pos-form-1" v-model="page_data.packet_details.vepari_no" name="vepari_no" type="text" disabled class="form-control flex-1" placeholder="Stock No" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-3">
                                                <div class="sm:grid grid-cols-2 gap-2">
                                                    <div>
                                                        <div>

                                                            <div class="">
                                                                <div id="input-group-3">Issue Weight</div>
                                                                <input type="text" class="form-control" name="weight" disabled v-model="page_data.packet_details.cr_weight" aria-describedby="input-group-3" />
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="mt-2 sm:mt-0">
                                                        <div class="">
                                                            <div id="input-group-4">Process</div>
                                                            <input type="text" class="form-control" name="process_name" disabled v-model="page_data.packet_details.process_name" aria-describedby="input-group-4" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-3">
                                                <div class="sm:grid grid-cols-1 gap-2">

                                                      <div class="">
                                                          <div id="input-group-3">Process Party</div>
                                                          <input type="text" class="form-control w-full" name="process_party_name" disabled v-model="page_data.packet_details.process_party_name" aria-describedby="input-group-3" />
                                                      </div>
                                                  </div>
                                            </div>
                                            <div class="mt-3">
                                                <div class="sm:grid grid-cols-1 gap-2">

                                                      <div class="">
                                                          <div id="input-group-3">Select Manager</div>
                                                          <TomSelect v-model="discuss_form.manager_id" :options="{
                                                              placeholder: 'Select Manager',
                                                              loadingClass: 'loading',
                                                              plugins: {
                                                                dropdown_header: {
                                                                  title: 'Manager'
                                                                }
                                                              }
                                                            }" class="w-full">
                                                                    <!-- <option value="0">Select Process</option> -->
                                                                    <option v-for="item in page_data.user_details" :key="item.id" :value="item.id">{{item.first_name}} {{ item.last_name ? item.last_name : '' }} ({{ item.user_role == 1 ? 'Admin' : (item.user_role == 4 ? 'Process Party' : 'Manager') }})</option>
                                                                </TomSelect>
                                                      </div>
                                                  </div>
                                            </div>
                                            <div class="mt-3">
                                                <div class="sm:grid grid-cols-1 gap-2">

                                                      <div class="">
                                                          <div id="input-group-3">Remark</div>
                                                          <textarea id="message" rows="4" class="form-control" placeholder="Write your statement here..." name="remark" v-model="discuss_form.remark"></textarea>
                                                      </div>
                                                  </div>
                                            </div>
                                            <div class="mt-3">
                                                <!-- <label class="form-label">Stone && Est Value</label> -->
                                                <div class="sm:grid grid-cols-2 gap-2">
                                                    <div>

                                                        <div class="">

                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="mt-2 sm:mt-0">
                                                            <div class="flex justify-end items-center mt-5">
                                                                <button type="button" class="btn btn-secondary w-24 m-auto mr-3" data-dismiss="modal" href="javascript:;">Cancel</button>
                                                                <button type="button" @click="discuss_issue" :disabled="page_data.submit == true" class="btn btn-primary w-24 m-auto">Issue
                            <LoadingIcon v-if="page_data.submit == true" icon="oval" color="white" class="w-4 h-4 ml-2" />
                        </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- END: Input -->
                            </div>
                        </div>
                        <!-- END: Data List -->
                    </div>
                    <!-- </div> -->
                </div>
            </div>
    </div>
</div>
<!-- END: new Process Modal -->
</template>

<script>
/* eslint-disable */
import {
    BASE_URL
} from '@/constants/config'
import axios from 'axios'
import cash from 'cash-dom'
import {
    computed,
    defineComponent,
    inject,
    onMounted,
    reactive,
    ref,
    toRef,
    watch
} from 'vue'
import print from 'vue3-print-nb'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import Toastify from 'toastify-js'
import store from '@/store'
import router from '@/router'
export default defineComponent({
    directives: {
        print
    },
    setup() {
        const user_details = computed(() => store.state.user.currentUser)
        const focusMe = ref()
        const tableRef = ref()
        const tabulator = ref()
        const swal = inject('$swal')

        const page_data = reactive({
            scan_lot_no: '',
            check: false,
            form_submit: false,
            user_list: [],
            packet_details: [],
            process_list: [],
            manager_list: [],
            purchase_list: [],
            employee_list: [],
            scan_disable: false
        })

        const filters = reactive({
            packet_no: '',
            kapan_id: '0',
            process_id: '0',
            employee_id: '0',
            manager_id: '0',
            status: '0'
        })

        const discuss_form = reactive({
            packet_id: '',
            manager_id: '',
            remark: ''
        })

        const initTabulator = () => {

            const params = {
                packet_no: (filters.packet_no == '' ? '' : window.btoa(filters.packet_no)),
                kapan_id: (filters.kapan_id == '0' ? '' : window.btoa(filters.kapan_id)),
                process_id: (filters.process_id == '0' ? '' : window.btoa(filters.process_id)),
                employee_id: (filters.employee_id == '0' ? '' : window.btoa(filters.employee_id)),
                manager_id: (filters.manager_id == '0' ? '' : window.btoa(filters.manager_id)),
                status: (filters.status == '0' ? '' : window.btoa(filters.status)),
            }

            if (user_details.value.user_role == 4) {
                tabulator.value = new Tabulator(tableRef.value, {
                    ajaxURL: BASE_URL + 'api/discuss/d_gds',
                    ajaxConfig: {
                        method: "GET", //set request type to Position
                        headers: {
                            "Content-type": 'application/json; charset=utf-8', //set specific content type
                            "Authorization": "Bearer " + localStorage.getItem("token")
                        },
                    },
                    ajaxParams: params,
                    ajaxFiltering: true,
                    ajaxSorting: true,
                    printAsHtml: true,
                    printStyled: true,
                    headerSort: false,
                    pagination: 'remote',
                    paginationSize: 20,
                    paginationSizeSelector: [20, 30, 40],
                    layout: 'fitColumns',
                    responsiveLayout: 'collapse',
                    placeholder: 'No matching records found',
                    columns: [{
                            formatter: 'responsiveCollapse',
                            width: 40,
                            minWidth: 30,
                            hozAlign: 'center',
                            resizable: false,
                            headerSort: false
                        },

                        // For HTML table
                        {
                            title: 'Packet No',
                            responsive: 0,
                            minWidth: 120,
                            field: 'number',
                            vertAlign: 'middle',
                            print: false,
                            download: false,
                            formatter(cell) {
                                return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().urgent == 1 ? '<span class="text-theme-6">'+ cell.getData().number +'</span>' : cell.getData().number
                }</div>
              </div>`
                            }
                        },
                        {
                            title: 'Stock No',
                            responsive: 0,
                            field: 'type',
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            print: false,
                            download: false,
                            formatter(cell) {
                                return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().vepari_no
                }</div>
              </div>`
                            }
                        },
                        {
                            title: 'Weight',
                            responsive: 0,
                            field: 'cr_weight',
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            print: false,
                            download: false,
                            formatter(cell) {
                                return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().cr_weight
                }</div>
                </div>`
                            }
                        },
                        {
                            title: 'Type',
                            responsive: 0,
                            field: 'type',
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            print: false,
                            download: false,
                            formatter(cell) {
                                return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().type
                  }</div>
                </div>`
                            }
                        },
                        {
                            title: 'Process',
                            field: 'process_name',
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            print: false,
                            download: false,
                            formatter(cell) {
                                return `<div>
                  <div class="">${
                    cell.getData().process_name + ' ' +  '(' + (cell.getData().process_short_code ? cell.getData().process_short_code : '-') + ')'
                  }</div>
                </div>`
                            }
                        },
                        {
                            title: 'Current User',
                            field: 'cr_users',
                            hozAlign: 'left',
                            vertAlign: 'middle',
                            print: false,
                            download: false,
                            formatter(cell) {
                                return `<div>
                  <div class="">${
                    cell.getData().user_first_name + ' ' + (cell.getData().user_last_name ? cell.getData().user_last_name : '')
                  }</div>
                </div>`
                            }
                        },
                        {
                        title: 'Status',
                        field: 'status',
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {

                            if (cell.getData().discuss_status == 1 && cell.getData().hold_status == 1) {
                                    return `<div class="flex items-center lg:justify-center text-theme-1">
                    On Hold
                  </div>`
                                } else if (cell.getData().discuss_status == 1 && cell.getData().hold_status == null) {
                                    return `<div class="flex items-center lg:justify-center text-theme-1">
                    Active
                  </div>`
                                } else if (cell.getData().discuss_status == 2) {
                                    return `<div class="flex items-center lg:justify-center text-theme-9">
                  Returned
                  </div>`
                                } else if (cell.getData().discuss_status == 3) {
                                    return `<div class="flex items-center lg:justify-center text-theme-6">
                    Cancelled
                  </div>`
                                }
                          }
                      }
                        // {
                        //   title: 'Action',
                        //   minWidth: 80,
                        //   field: '',
                        //   responsive: 1,
                        //   hozAlign: 'center',
                        //   vertAlign: 'middle',
                        //   headerSort:false,
                        //   print: false,
                        //   download: false,
                        //   formatter(cell) {
                        //     if(cell.getData().last_planner == user_details.value.account_id) {
                        //       const a = cash(`<div class="flex lg:justify-center items-center">
                        //             <button class="btn btn-primary btn-sm flex items-center mr-3">
                        //               <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Edit Plan
                        //             </button>
                        //           </div>`)
                        //           cash(a).on('click', function() {
                        //             cash('#new-manual-planning').modal('show')
                        //           })
                        //           return a[0]
                        //         } else {
                        //           const a = cash(`<div class="flex lg:justify-center items-center">
                        //             <button class="btn btn-primary btn-sm flex items-center mr-3">
                        //               <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Add Plan
                        //               </button>
                        //               </div>`)
                        //               cash(a).on('click', function() {
                        //             cash('#new-manual-planning').modal('show')
                        //           })
                        //           return a[0]
                        //     }
                        //   }
                        // },
                    ],
                    renderComplete() {
                        feather.replace({
                            'stroke-width': 1.5
                        })
                    }
                })
            } else {
                tabulator.value = new Tabulator(tableRef.value, {
                    ajaxURL: BASE_URL + 'api/discuss/d_gds',
                    ajaxConfig: {
                        method: "GET", //set request type to Position
                        headers: {
                            "Content-type": 'application/json; charset=utf-8', //set specific content type
                            "Authorization": "Bearer " + localStorage.getItem("token")
                        },
                    },
                    ajaxParams: params,
                    ajaxFiltering: true,
                    ajaxSorting: true,
                    printAsHtml: true,
                    printStyled: true,
                    headerSort: false,
                    pagination: 'remote',
                    paginationSize: 20,
                    paginationSizeSelector: [20, 30, 40],
                    layout: 'fitColumns',
                    responsiveLayout: 'collapse',
                    placeholder: 'No matching records found',
                    columns: [{
                            formatter: 'responsiveCollapse',
                            width: 40,
                            minWidth: 30,
                            hozAlign: 'center',
                            resizable: false,
                            headerSort: false
                        },

                        // For HTML table
                        {
                            title: 'Packet No',
                            responsive: 0,
                            minWidth: 120,
                            field: 'number',
                            vertAlign: 'middle',
                            print: false,
                            download: false,
                            formatter(cell) {
                                return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().urgent == 1 ? '<span class="text-theme-6">'+ cell.getData().number +'</span>' : cell.getData().number
                }</div>
              </div>`
                            }
                        },
                        {
                            title: 'Stock No',
                            responsive: 0,
                            field: 'type',
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            print: false,
                            download: false,
                            formatter(cell) {
                                return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().vepari_no
                }</div>
              </div>`
                            }
                        },
                        {
                            title: 'Weight',
                            responsive: 0,
                            field: 'cr_weight',
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            print: false,
                            download: false,
                            formatter(cell) {
                                return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().cr_weight
                }</div>
                </div>`
                            }
                        },
                        {
                            title: 'Type',
                            responsive: 0,
                            field: 'type',
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            print: false,
                            download: false,
                            formatter(cell) {
                                return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().type
                  }</div>
                </div>`
                            }
                        },
                        {
                            title: 'Process',
                            field: 'process_name',
                            hozAlign: 'center',
                            vertAlign: 'middle',
                            print: false,
                            download: false
                        },
                        {
                            title: 'Manager',
                            field: 'manager_name',
                            hozAlign: 'left',
                            vertAlign: 'middle',
                            print: false,
                            download: false,
                            formatter(cell) {
                                return `<div>
                  <div class="">${
                    cell.getData().party_first_name + ' ' + (cell.getData().party_last_name ? cell.getData().party_last_name : '')
                  }</div>
                </div>`
                            }
                        },
                        {
                            title: 'Current User',
                            field: 'stone',
                            hozAlign: 'left',
                            vertAlign: 'middle',
                            print: false,
                            download: false,
                            formatter(cell) {
                                return `<div>
                  <div class="">${
                    cell.getData().user_first_name + ' ' + (cell.getData().user_last_name ? cell.getData().user_last_name : '')
                  }</div>
                </div>`
                            }
                        },
                        {
                        title: 'Status',
                        field: 'status',
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {

                            if (cell.getData().discuss_status == 1 && cell.getData().hold_status == 1) {
                                    return `<div class="flex items-center lg:justify-center text-theme-1">
                    On Hold
                  </div>`
                                } else if (cell.getData().discuss_status == 1 && cell.getData().hold_status == null) {
                                    return `<div class="flex items-center lg:justify-center text-theme-1">
                    Active
                  </div>`
                                } else if (cell.getData().discuss_status == 2) {
                                    return `<div class="flex items-center lg:justify-center text-theme-9">
                  Returned
                  </div>`
                                } else if (cell.getData().discuss_status == 3) {
                                    return `<div class="flex items-center lg:justify-center text-theme-6">
                    Cancelled
                  </div>`
                                }
                          }
                      }
                    ],
                    renderComplete() {
                        feather.replace({
                            'stroke-width': 1.5
                        })
                    }
                })
            }

        }

        const scan_stock_no = async () => {

          if(page_data.scan_lot_no == '') {
            return;
          }

            const params = {
                packet_no: window.btoa(page_data.scan_lot_no),
            }

            page_data.scan_disable = true

            let link_url = 'api/discuss/d_sdp'

            let promise = axios({
                url: link_url,
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    page_data.scan_disable = false

                    if (data.status == 'error') {
                        page_data.loading = false
                        swal('Warning', data.message, 'warning');
                    } else {

                        page_data.packet_details = data.data
                        discuss_form.packet_id = page_data.packet_details.id

                        // Manager Details
                        page_data.user_details = data.user_list

                        var i = page_data.user_details.length;
                        while (i--) {
                            if (page_data.user_details[i]['id'] == user_details.value.id) {
                                page_data.user_details.splice(i, 1);
                            }
                        }
                        discuss_form.manager_id = String(page_data.user_details[0].id)

                        cash('#discuss-issue-modal').modal('show')

                        page_data.scan_lot_no = ''
                    }
                })
                .catch(_error => {
                    page_data.scan_disable = false
                    swal('Error', 'Something went wrong.', 'error');
                });
        }

        const discuss_issue = async () => {
            let params = {
                packet_id: (discuss_form.packet_id ? window.btoa(discuss_form.packet_id) : ''),
                manager_id: (discuss_form.manager_id ? window.btoa(discuss_form.manager_id) : ''),
                remark: (discuss_form.remark ? window.btoa(discuss_form.remark) : '')
            }

            page_data.submit = true

            let link_url = 'api/discuss/d_td'

            let promise = axios({
                url: link_url,
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    page_data.submit = false

                    if (data.status == 'error') {
                        page_data.loading = false
                        swal('Warning', data.message, 'warning');
                    } else {
                        // Manager Details
                        page_data.packet_details = []
                        page_data.user_details = []
                        discuss_form.manager_id = ''
                        discuss_form.remark = ''
                        discuss_form.packet_id = ''

                        swal('Success', data.message, 'success');
                        cash('#discuss-issue-modal').modal('hide')

                        initTabulator()
                    }
                })
                .catch(_error => {
                    page_data.submit = false
                    swal('Error', 'Something went wrong.', 'error');
                });
        }

        const get_process_details = async () => {

            const params = {
              id: window.btoa(user_details.value.account_id)
            }

            let promise_url = ''
            if(user_details.value.user_role == 4) {

              promise_url = 'api/master/get_process_by_jober_encr'
            } else {
              promise_url = 'api/master/dp_process'
            }

            let promise = axios({
                url: promise_url,
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    page_data.process_list = data.items
                    filters.process_id = '0'

                })
                .catch(_error => {

                    page_data.process_list = []
                    filters.process_id = '0'
                });
        }

        const get_managers_details = async () => {

            const params = {}

            let promise_url = 'api/master/dp_managers'

            let promise = axios({
                url: promise_url,
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    page_data.manager_list = data.items
                    filters.manager_id = '0'

                })
                .catch(_error => {

                    page_data.manager_list = []
                    filters.manager_id = '0'
                });
        }

        const get_kapan_details = async () => {

            const params = {}

            let promise_url = 'api/discuss/d_gdp'

            let promise = axios({
                url: promise_url,
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    page_data.purchase_list = data.items
                    filters.kapan_id = '0'

                })
                .catch(_error => {

                    page_data.purchase_list = []
                    filters.kapan_id = '0'
                });
        }

        const get_employees_details = async () => {

            const params = {}

            let promise_url = 'api/discuss/d_gde'

            let promise = axios({
                url: promise_url,
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    page_data.employee_list = data.items
                    filters.employee_id = '0'

                })
                .catch(_error => {

                    page_data.employee_list = []
                    filters.employee_id = '0'
                });
        }

        const get_packet_scan = () => {
            if (!page_data.check && page_data.scan_lot_no) {
                scan_stock_no();
            }
        }

        const reset_filters = () => {

            filters.packet_no = ''
            filters.kapan_id = '0'
            filters.process_id = '0'
            filters.employee_id = '0'
            filters.manager_id = '0'
        }

        onMounted(() => {
            initTabulator()
            get_process_details()
            get_kapan_details()
            get_employees_details()
            get_managers_details()
            focusMe.value.focus()
        })

        return {
            page_data,
            focusMe,
            filters,
            user_details,
            initTabulator,
            tableRef,
            scan_stock_no,
            discuss_issue,
            get_packet_scan,
            reset_filters,
            discuss_form
        }
    }
})
</script>

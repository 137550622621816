<template>
  <div>
    <div id="basic-non-sticky-notification-layout-pkts" class="toastify-content hidden flex flex-col sm:flex-row">
        <div class="flex items-center justify-around" v-if="pagedata.error">
          <CheckCircleIcon class="w-6 h-6 text-theme-6 mx-auto" />
          <div class="font-medium text-black">{{ pagedata.error }}</div>
        </div>
        <div class="flex items-center justify-around" v-if="pagedata.success">
          <CheckCircleIcon class="w-6 h-6 text-theme-9 mx-auto" />
          <div class="font-medium text-black">{{ pagedata.success }}</div>
        </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <h2 class="text-lg font-bold">Delivered Packets</h2>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import store from '@/store'
import axios from 'axios'
import cash from 'cash-dom'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import Toastify from 'toastify-js'
import { defineComponent, onMounted, reactive, ref, watch } from 'vue'

export default defineComponent({
  setup() {
    onMounted(() => {
      initTabulator()
    })
    const pagedata = reactive({
      party: store.state.user.data.id,
      error: '',
      success: '',
    })
    const tableRef = ref()
    const tabulator = ref()
    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + `/outword/outward-packets?party=${pagedata.party}`,
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [
          {
            formatter: 'responsiveCollapse',
            width: 40,
            minWidth: 30,
            hozAlign: 'center',
            resizable: false,
            headerSort: false
          },

          // For HTML table
          {
            title: 'Packet No',
            responsive: 0,

            field: 'number',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().number
                }</div>
              </div>`
            }
          },
          {
            title: 'Vepari No',
            responsive: 0,
            field: 'number',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().vepari_no
                }</div>
              </div>`
            }
          },
          {
            title: 'Weight',
            responsive: 0,
            field: 'weight',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().weight
                }</div>
              </div>`
            }
          },
          {
            title: 'Polish Wt',
            responsive: 0,
            field: 'polish_wt',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().admin_pwt
                }</div>
              </div>`
            }
          },
          {
            title: 'Party',
            responsive: 0,
            field: 'party',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
          },
          // {
          //   title: 'Department',
          //   field: 'department',
          //   hozAlign: 'center',
          //   vertAlign: 'middle',
          //   print: false,
          //   download: false
          // },
          {
            title: 'Shape',
            field: 'shape',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Color',
            field: 'color',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Purity',
            field: 'purity',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Cut',
            field: 'cut',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Polish',
            field: 'polish',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'symmetry',
            field: 'symmetry',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Fluer',
            field: 'fluerocent',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Tension',
            field: 'tension',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Stone',
            field: 'stone',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Urgent',
            field: 'urgent',
            minWidth: 100,
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              const a = cash(`<div class="flex lg:justify-center items-center">
              '<button class="btn btn-primary p-1 px-2 mr-1"> <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Accept </button>'
              </div>`)
              cash(a).on('click', function() {
                  AcceptRequest(cell.getData().id)
              })

              return a[0]
            }
          },
        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }
    function AcceptRequest(id) {
      axios({
        url: `/outword/party-accept-packets/${id}`,
        method: 'get',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.success = res.data.success
          setTimeout(() => {
            basicNonStickyNotificationPack()
          }, 100);
          setTimeout(() => {
            pagedata.success = ''
          }, 5000);
        initTabulator()
      }).catch((err) => {
          pagedata.error = err.response.data.error
          setTimeout(() => {
            basicNonStickyNotificationPack()
          }, 100);
          setTimeout(() => {
            pagedata.error = ''
          }, 5000);
        })
    }
  // Redraw table onresize
      const reInitOnResizeWindow = () => {
        window.addEventListener('resize', () => {
          tabulator.value.redraw()
          feather.replace({
            'stroke-width': 1
          })
        })
      }
      const basicNonStickyNotificationPack = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification-layout-pkts')
          .clone()
          .removeClass('hidden')[0],
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    return {
      pagedata,
      tableRef,
      reInitOnResizeWindow,
      initTabulator,
      basicNonStickyNotificationPack
    }
  }
})
</script>

<template>
  <div>
    <!-- BEGIN: Profile Info -->
    <div id="basic-non-sticky-notification-packet" class="toastify-content hidden flex flex-col sm:flex-row">
        <div class="font-medium text-primary-3" v-if="errors.error">{{ errors.error }}</div>
        <div class="font-medium text-primary-4" v-if="pagedata.success">{{ pagedata.success }}</div>
    </div>
    <div>
        <div>
          <h2 class="intro-y text-lg font-medium mt-5 ml-2">Scan Packet</h2>
          <div class="flex items-center mt-2 ml-2">
            <div class="mr-2 flex items-center">
              <input
                id="remember-me"
                type="checkbox" v-model="pagedata.check"
                class="form-check-input border mr-2 bg-white"
              />
              <label for="remember-me" class="text-md font-medium">Menual</label>
            </div>
          </div>
          <div class="flex items-end justify-between">
            <div class="w-full flex sm:w-auto mt-3 ml-2">
              <div class="w-56 relative text-gray-700 dark:text-gray-300 mr-4">
                <input
                  type="text"
                  v-model="pagedata.search"
                  autofocus
                  class="form-control w-56 box pr-10 placeholder-theme-13"
                  placeholder="Search Packet"
                />
                <SearchIcon
                  class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
                />
              </div>
              <button class="btn btn-primary shadow-md mr-2" @click="AddtoList">Add</button>
              <a class="btn btn-primary shadow-md mr-2" href="javascript:;" data-toggle="modal" @click="MultiplePacketAssign" data-target="#single-process-modal" v-if="pagedata.addlist.length > 0">Assign Packets</a>
            </div>
            <div>
              <a target="_blank"  href="http://bluemoonlabelprint.dkinfoway.com/print-transfered-label/makeable" @click="printAllBarcode" class=" btn btn-primary p-2 w-20">
          <PrinterIcon class="w-4 h-4 text-white" /> Print
        </a>
            </div>
          </div>
        </div>
      <div class="grid grid-cols-12 gap-5 mt-5">
        <div
          class="col-span-6 md:col-span-3 sm:col-span-4 box p-5 cursor-pointer zoom-in" v-for="(add,index) in addlist" :key="index"
        >
          <div class="flex justify-between">
            <div class="font-medium text-base">{{ add }}</div>
            <button class="btn btn-outline-danger p-1 pl-2 hover:text-white" @click="removeItem(index)"><Trash2Icon class="w-4 h-4 mr-1" /></button>
          </div>
        </div>
      </div>
      <!-- BEGIN: Data List -->
      <h2 class="intro-y text-lg font-medium mt-5 ml-2">Packets</h2>
      <div v-if="packets.length > 0">
        <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
          <table class="table-report mt-3 w-full">
          <thead>
            <tr>
              <th class="py-2 text-center">Pkt No</th>
              <th class="py-2 text-center">Vepari No</th>
              <th class="py-2 text-center">Parent</th>
              <th class="py-2 text-center">Weight</th>
              <th class="py-2 text-center">Exp. Wt</th>
              <th class="py-2 text-center">Shape</th>
              <th class="py-2 text-center">Color</th>
              <th class="py-2 text-center">Purity</th>
              <th class="py-2 text-center">Cut</th>
              <th class="py-2 text-center">Polish</th>
              <th class="py-2 text-center">Symm</th>
              <th class="py-2 text-center">Fluc</th>
              <th class="py-2 text-center">Tension</th>
              <th class="py-2 text-center">Stone</th>
              <th class="py-2 text-center">Discount</th>
              <th class="py-2 text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(packet, index) in pagedata.packets"
              :key="index"
              class="intro-x"
            >
              <td>
                <div class="py-3 px-1 font-medium whitespace-nowrap">{{
                  packet.number
                }}</div>
              </td>
              <td class="py-2 px-1 font-medium text-center">{{ packet.vepari_no }}</td>
              <td class="py-2 px-1 font-medium text-center">{{ packet.parent }}</td>
              <td class="py-2 px-1 font-medium text-center">{{ packet.weight }}</td>
              <td class="py-2 px-1 font-medium text-center">{{ packet.pwt }}</td>
              <td class="py-2 px-1 font-medium text-center">{{ packet.shape }}</td>
              <td class="py-2 px-1 font-medium text-center">{{ packet.color }}</td>
              <td class="py-2 px-1 font-medium text-center">{{ packet.purity }}</td>
              <td class="py-2 px-1 font-medium text-center">{{ packet.cut }}</td>
              <td class="py-2 px-1 font-medium text-center">{{ packet.polish }}</td>
              <td class="py-2 px-1 font-medium text-center">{{ packet.symmetry }}</td>
              <td class="py-2 px-1 font-medium text-center">{{ packet.fluerocent }}</td>
              <td class="py-2 px-1 font-medium text-center">{{ packet.tension }}</td>
              <td class="py-2 px-1 font-medium text-center">{{ packet.stone }}</td>
              <td class="py-2 px-1 font-medium text-center">{{ packet.discount }}</td>
              <td class="py-2 px-1 font-medium text-center">
                <div class="sm:grid grid-cols-2">
                  <div>
                    <a target="_blank" :href="`http://bluemoonlabelprint.dkinfoway.com/print-label/${packet.id}`" @click="printBarcode(packet.id)" class="flex items-center" :class="{ 'text-theme-1' : packet.print == 0, 'text-theme-6' : packet.print == 1 }">
                      <PrinterIcon class="w-6 h-6" />
                    </a>
                  </div>
                  <div v-if="pagedata.user == packet.manager">
                    <a class="flex items-center text-theme-9" href="javascript:;" title="Assigh Process"
                      data-toggle="modal" @click="SinglePacketAssign(packet.id, packet.number)"
                      data-target="#single-process-modal">
                      <ChevronsRightIcon class="w-6 h-6" />
                    </a>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        </div>
        <!-- END: Data List -->
        <!-- BEGIN: Pagination -->
        <div
          class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center mb-7"
        >
          <ul class="pagination">
            <li>
              <button class="pagination__link" :disabled="pagedata.current_page == 1" @click="getPackets(pagedata.first_page_url)">
                <ChevronsLeftIcon class="w-4 h-4" />
              </button>
            </li>
            <li>
              <button class="pagination__link" :disabled="pagedata.prev_page_url == null" @click="getPackets(pagedata.prev_page_url)">
                <ChevronLeftIcon class="w-4 h-4" />
              </button>
            </li>
            <li v-for="(pagelink,index) in pagelinks" :key="index">
              <button class="pagination__link" :class="{'pagination__link--active' : pagelink.active == true}" v-if="pagelinks[0] != pagelink && pagelinks[pagelinks.length - 1] != pagelink" @click='getPackets(pagelink.url)'>{{ pagelink.label }}</button>
            </li>
            <li>
              <button class="pagination__link" :disabled="pagedata.next_page_url == null" @click="getPackets(pagedata.next_page_url)">
                <ChevronRightIcon class="w-4 h-4" />
              </button>
            </li>
            <li>
              <button class="pagination__link" :disabled="pagedata.current_page == pagedata.last_page" @click="getPackets(pagedata.last_page_url)">
                <ChevronsRightIcon class="w-4 h-4" />
              </button>
            </li>
          </ul>
          <select class="w-20 form-select box mt-3 sm:mt-0" v-model="pagedata.size" @change="getPacketss">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
          </select>
        </div>
        <!-- END: Pagination -->
      </div>
      <div v-else class="text-center">No Packet Found</div>
    </div>
    <!-- END: Profile Info -->
    <!-- BEGIN: new Process Modal -->
    <div
      id="single-process-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <a data-dismiss="modal" href="javascript:;">
            <XIcon class="w-8 h-8 text-gray-500" />
          </a>
          <div class="modal-body p-10 text-center">
            <div class="intro-y box">
              <!-- <div id="vertical-form"> -->
              <div class="flex flex-col justify-between mt-5">
                <!-- BEGIN: Data List -->
                <div class="intro-y overflow-auto">
                  <div class="flex items-center">
                    <h2 class="intro-y text-lg font-medium mr-5">Packet No :  <span class="ml-2 text-primary-1">{{ process.number }}</span></h2>
                  </div>
                </div>
                <div class="flex items-center mt-3">
                  <div class="form-check">
                    <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer" value="0" type="radio" name="flexRadioDefault" id="flexRadioDefault1" v-model="process.eternals">
                    <label class="form-check-label inline-block text-black text-lg" for="flexRadioDefault1">
                      Internal
                    </label>
                  </div>
                  <div class="form-check ml-6">
                    <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer" value="1" type="radio" name="flexRadioDefault" id="flexRadioDefault2" v-model="process.eternals">
                    <label class="form-check-label inline-block text-black text-lg" for="flexRadioDefault2">
                      Enternal
                    </label>
                  </div>
                </div>
                <div v-if="process.eternals == '0'" class="m-auto mt-3">
                  <TomSelect
                    v-model="process.manager"
                      :options="{
                        placeholder: 'Select Manager'
                      }"
                      class="w-60"
                  >
                    <option value="0">Select Manager</option>
                    <option v-for="(manager,ind) in pagedata.managers" :key="ind" :value="manager.id">{{ manager.name }}</option>
                  </TomSelect>
                  <span class="text-primary-3 mb-2" v-if="errors.manager">{{ errors.manager[0] }}</span>
                </div>
                <div v-else class="m-auto">
                  <TomSelect
                    v-model="process.parties"
                    @change="getExternalSinglePartiesProcess(process.parties)"
                      :options="{
                        placeholder: 'Select Paery'
                      }"
                      class="w-72 mt-3"
                  >
                    <option value="0">Select Party</option>
                    <option v-for="(party,ind) in pagedata.externals" :key="ind" :value="party.id">{{ party.firmname }}  ({{ ind + 1 }})</option>
                  </TomSelect>
                  <span class="text-primary-3 mb-2" v-if="errors.parties">{{ errors.parties[0] }}</span>
                  <TomSelect
                    v-model="process.partyman"
                    :options="{
                        placeholder: 'Select Paery'
                      }"
                      class="w-72 mt-3"
                  >
                    <option value="0">Select Manager</option>
                    <option v-for="(man,ind) in pagedata.managers" :key="ind" :value="man.id">{{ man.name }}  ({{ ind + 1 }})</option>
                  </TomSelect>
                  <span class="text-primary-3 mb-2" v-if="errors.partyman">{{ errors.partyman[0] }}</span>
                  <div v-if="process.parties != 0">
                    <TomSelect
                      v-model="process.partyproc"
                        :options="{
                          placeholder: 'Select Process'
                        }" multiple
                        class="w-72 mt-3"
                    >
                      <option v-for="(proc,ind) in pagedata.partyproc" :key="ind" :value="proc.id">{{ proc.name }} ({{ ind + 1 }})</option>
                    </TomSelect>
                    <span class="text-primary-3 mb-2" v-if="process.parties != 0 && errors.partyproc">{{ errors.partyproc[0] }}</span>
                    <div class="text-left mt-3">Expected Weight</div>
                    <div class="input-group mt-2" v-if="process.multiple == 0">
                      <input
                        type="number" v-model="process.expected_weight"
                        class="form-control"
                        placeholder="Expected Weight"
                        aria-describedby="input-group-3"
                      />
                    </div>
                    <div v-else>
                      <div class="mt-3" v-for="(packet,index) in process.multiple_packets" :key="index">
                        <div class="w-72">
                          <div class="input-group">
                            <div id="input-group-3" class="input-group-text w-44">{{ packet }}</div>
                            <input
                              type="number" v-model="process.multiple_expected_weight[index]"
                              class="form-control"
                              placeholder="Expected Weight"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <span class="text-primary-3 mb-2" v-if="errors.error">{{ errors.error }}</span>
                  </div>
                </div>
                <button type="button" @click="AssignSingleProcess" :disabled="pagedata.submit" class="btn btn-primary w-24 m-auto mt-5">Submit
                  <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End: new Process Modal -->
  </div>
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import axios from 'axios'
import cash from 'cash-dom'
import { defineComponent, onMounted, reactive, ref, toRef, watch } from 'vue'
import print from 'vue3-print-nb'
import Toastify from 'toastify-js'
import store from '@/store'
export default defineComponent({
  directives: {
    print
  },
  setup() {
    const pagedata = reactive({
      user : store.state.user.data.id,
      errors: [],
      packets: [],
      pagelinks: [],
      addlist: [],
      managers: [],
      externals: [],
      partyproc: [],
      search: '',
      type: 'deliverables',
      size: 10,
      check: false,
      waiting: false,
      addornot: false,
      delId: '',
      messages: [],
      prev_page_url: null,
      next_page_url: null,
      first_page_url: null,
      last_page_url: null,
      current_page: 1,
      last_page: '',
      submit: false,
      success: '',
      error: ''
    })
    const errors = toRef(pagedata,'errors')
    const pagelinks = toRef(pagedata, 'pagelinks')
    const packets = toRef(pagedata, 'packets')
    const addlist = toRef(pagedata,'addlist')
    const process = reactive({
      multiple: 0,
      id: '',
      eternals: 0,
      number: '',
      manager: '',
      parties: '',
      partyman: '',
      partyproc: [],
      expected_weight: '',
      type: 'makeable',
      multiple_packets: [],
      multiple_expected_weight: []
    })
    onMounted(() => {
      getPacketss()
    })
    function getPackets(url) {
      axios({
        url: url + `&size=${pagedata.size}`,
        method: 'get',
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.packets = res.data.packets.data
        pagedata.pagelinks = res.data.packets.links
        pagedata.prev_page_url = res.data.packets.prev_page_url
        pagedata.next_page_url = res.data.packets.next_page_url
        pagedata.first_page_url = res.data.packets.first_page_url
        pagedata.last_page_url = res.data.packets.last_page_url
        pagedata.current_page = res.data.packets.current_page
        pagedata.last_page = res.data.packets.last_page
      })
    }
    function getPacketss() {
      axios({
        url: `/lsbs/get_lsbs_packets?size=${pagedata.size}&type=makeable`,
        method: 'get',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.packets = res.data.packets.data
        pagedata.pagelinks = res.data.packets.links
        pagedata.prev_page_url = res.data.packets.prev_page_url
        pagedata.next_page_url = res.data.packets.next_page_url
        pagedata.first_page_url = res.data.packets.first_page_url
        pagedata.last_page_url = res.data.packets.last_page_url
        pagedata.current_page = res.data.packets.current_page
        pagedata.last_page = res.data.packets.last_page
      })
    }
    function SinglePacketAssign(id,no) {
      process.multiple = 0
      process.multiple_packets = []
      process.multiple_expected_weight = []
      process.number = no
      process.id = id
      getAllManager()
    }
    function MultiplePacketAssign() {
      process.multiple = 1
      process.number = ''
      process.id = ''
      process.multiple_packets = pagedata.addlist
      getAllManager()
    }
    function getAllManager() {
      axios({
        url: '/user/allManager',
        method: 'get',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.managers = res.data.managers
      })
    }
    function getExternalParties() {
      if(pagedata.externals.length <= 0) {
        axios({
          url: '/get-external-parties',
          method: 'get',
          baseURL: BASE_URL,
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.externals = res.data.party
        })
      }
    }
    function getExternalSinglePartiesProcess(id) {
      axios({
        url: `/get-external-parties-process/${id}`,
        method: 'get',
        baseURL: BASE_URL,
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.partyproc = res.data.processes
      })
    }
    function AssignSingleProcess() {
      pagedata.submit = true
      axios({
        url: `/lsbs/packet-single-detail`,
        method: 'post',
        baseURL: BASE_URL,
        data: process,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.success = res.data.success
        setTimeout(() => {
          basicNonStickyNotificationPacket()
        },100)
        pagedata.submit = false
        getPacketss()
        setTimeout(() => {
          process.number = ''
          process.id = ''
          process.manager = '0'
          process.parties = ''
          process.expected_weight= ''
          process.multiple_expected_weight= []
          process.multiple_packets= []
          pagedata.addlist= []
        }, 100);
        cash('#single-process-modal').modal('hide')
        setTimeout(() => {
            pagedata.success = ''
          }, 3000);
        // basicNonStickyNotificationPacketAssign()
      }).catch((err) => {
        pagedata.submit = false
        pagedata.errors = err.response.data.errors
        pagedata.error = err.response.data.error
        if(pagedata.error) {
          setTimeout(() => {
            basicNonStickyNotificationPacket()
          },100)
          setTimeout(() => {
            pagedata.error = ''
          }, 3000);
        } else {
          setTimeout(() => {
            pagedata.errors = []
          }, 3000);
        }
      })
    }
    watch(() => pagedata.search, (currentValue) => {
      if(!pagedata.check) {
        if (!pagedata.waiting) {
          setTimeout(() => {
            AddtoList()
            pagedata.waiting = false;
          }, 200); // 1 sec delay
        }
        pagedata.waiting = true;
      }
    })
    function AddtoList() {
      if(pagedata.search != '') {
        if(pagedata.addlist.length <= 0) {
          checkadd()
        } else {
          for (let i = 0; i < pagedata.addlist.length; i++) {
            if(pagedata.addlist[i] == pagedata.search) {
              // pagedata.checklist.splice(pagedata.addlist[i].id,1)
              pagedata.addlist.splice(i, 1);
              pagedata.addornot = true
              pagedata.search = ''
              break
            } else {
              pagedata.addornot = false
            }

          }
          if(pagedata.addornot == false) {
              checkadd()
            }
        }
      }
    }
    function removeItem(id) {
      console.warn(id)
      pagedata.addlist.splice(id, 1);
    }
    function checkadd() {
      axios({
        url: `/lsbs/transfered_packets_assign/${pagedata.search}`,
        method: 'get',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        const index = ref(pagedata.addlist.length)
        pagedata.addlist[index.value] = res.data.packet
        pagedata.search = ''
        // pagedata.checklist[pagedata.addlist[index.value].id] = true
      }).catch((err) => {
        pagedata.errors = err.response.data
        console.warn(pagedata.errors)
        setTimeout(() => {
          basicNonStickyNotificationPacket()
        },100)
        setTimeout(() => {
          pagedata.errors = []
        }, 5000);

      })
    }
    function printBarcode(id) {
      axios({
          url: `/purchase/printBarcode/${id}`,
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.success = res.data.success
          setTimeout(() => {
            basicNonStickyNotificationPacket()
          },100)
          getPacketss()
          setTimeout(() => {
            pagedata.success = ''
          }, 5000);
        })
    }
    function printAllBarcode() {
      setTimeout(() => {
        axios({
          url: '/purchase/printTransferedBarcode?type=makeable',
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.success = res.data.success
          setTimeout(() => {
            basicNonStickyNotificationPacket()
          },100)
          getPacketss()
          setTimeout(() => {
            pagedata.success = ''
          }, 5000);
        })
      }, 5000);
    }
    const basicNonStickyNotificationPacket = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification-packet')
          .clone()
          .removeClass('hidden')[0],
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    watch(() => process.eternals, (currentValue) => {
      if(currentValue == '1') {
        getExternalParties()
        if(process.multiple == 1) {
          for(let i = 0; i < process.multiple_packets; i++) {
            process.multiple_expected_weight[i] = ''
          }
        }
      }
    })
    return {
      pagedata,
      pagelinks,
      errors,
      packets,
      addlist,
      getPacketss,
      getPackets,
      basicNonStickyNotificationPacket,
      AddtoList,
      removeItem,
      process,
      SinglePacketAssign,
      MultiplePacketAssign,
      getExternalSinglePartiesProcess,
      AssignSingleProcess,
      printBarcode,
      printAllBarcode
    }
  }
})
</script>

<template>
  <div class="my-8">
    <div class="xl:grid block xl:grid-cols-2 gap-6">
      <div>
        <div class="flex items-center">
          <div class="text-lg font-medium mr-2">Please Scan the Packet</div>
          <div>
            <input
                  id="remember-me"
                  type="checkbox" v-model="pagedata.check"
                  class="form-check-input border mr-2 bg-white"
                />
                <label for="remember-me" class="text-md font-medium">Menual</label>
          </div>
        </div>
        <div class="w-full flex sm:w-auto xl:mt-0 mt-3 sm:ml-auto md:ml-0">
          <div class="w-56 relative text-gray-700 dark:text-gray-300 mr-4">
            <input
              type="text"
              v-model="pagedata.search"
              autofocus
              ref="focusMe"
              class="form-control w-56 box pr-10 placeholder-theme-13"
              placeholder="Search..."
            />
            <SearchIcon
              class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
            />
          </div>
          <button class="btn btn-primary shadow-md mr-2" @click="getPaketDetail">Search</button>
        </div>
      </div>
      <div class="flex items-end mt-10 xl:mt-0">
        <div class="mx-5" v-if="paketDet.number != ''">
          <h2 class="intro-y text-lg font-medium">Packet No : {{ paketDet.number }}</h2>
          <table class="w-48">
            <tbody>
              <tr>
                <th v-if="paketDet.shape" class="py-2 font-bold border-2 text-md px-1 text-left">Shape</th>
                <th class="py-2 font-bold border-2 text-md px-1 text-left">Color</th>
                <th class="py-2 font-bold border-2 text-md px-1 text-left">Purity</th>
                <th v-if="paketDet.cut" class="py-2 font-bold border-2 text-md px-1 text-left">Cut</th>
                <th v-if="paketDet.polish" class="py-2 font-bold border-2 text-md px-1 text-left">Polish</th>
                <th v-if="paketDet.symmetry" class="py-2 font-bold border-2 text-md px-1 text-left">Symmetry</th>
                <th v-if="paketDet.tension" class="py-2 font-bold border-2 text-md px-1 text-left">Tension</th>
                <th class="py-2 font-bold border-2 text-md px-1 text-left">Stone</th>
                <th class="py-2 font-bold border-2 text-md px-1 text-left whitespace-nowrap">Issue Wt</th>
                <th class="py-2 font-bold border-2 text-md px-1 text-left whitespace-nowrap">Oriznal Wt</th>
                <th class="py-2 font-bold border-2 text-md px-1 text-left whitespace-nowrap">PWT</th>
              </tr>
              <tr>
                <td v-if="paketDet.shape" class="font-bold text-md border-2 text-center">{{ paketDet.shape ? paketDet.shape.name : '' }}</td>
                <td class="font-bold text-md border-2 text-center">{{ paketDet.color.name }}</td>
                <td class="font-bold text-md border-2 text-center">{{ paketDet.purity.name }}</td>
                <td v-if="paketDet.cut" class="font-bold text-md border-2 text-center">{{ paketDet.cut ? paketDet.cut.name : '' }}</td>
                <td v-if="paketDet.polish" class="font-bold text-md border-2 text-center">{{ paketDet.polish ? paketDet.polish.name : '' }}</td>
                <td v-if="paketDet.symmetry" class="font-bold text-md border-2 text-center">{{ paketDet.symmetry ? paketDet.symmetry.name : '' }}</td>
                <td v-if="paketDet.tension" class="font-bold text-md border-2 text-center">{{ paketDet.tension ? paketDet.tension.name : '' }}</td>
                <td class="font-bold text-md border-2 text-center">{{ paketDet.stone.name }}</td>
                <td class="font-bold text-md border-2 text-center">{{ paketDet.issueWt }}</td>
                <td class="font-bold text-md border-2 text-center">{{ paketDet.oriznal_wt }}</td>
                <td class="font-bold text-md border-2 text-center">{{ paketDet.admin_pwt > 0 ? paketDet.admin_pwt : paketDet.pwt  }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="hidden">
          <div id="paketDetails" class="mx-5">
          <table class="w-48 border-2 border-black">
            <tbody>
              <tr>
                <th class="p-2 font-bold text-lg text-left">
                  Packet No:
                </th>
                <th class="font-bold text-lg text-center">{{ paketDet.number }}</th>
              </tr>
              <tr v-if="paketDet.shape">
                <td class="p-2 border-2 border-black font-bold text-md text-left">
                  Shape :
                </td>
                <td class="font-bold text-md border-2 border-black text-center">{{ paketDet.shape ? paketDet.shape.name : '' }}</td>
              </tr>
              <tr>
                <td class="p-2 border-2 border-black font-bold text-md text-left">
                  Color :
                </td>
                <td class="font-bold text-md border-2 border-black text-center">{{ paketDet.color.name }}</td>
              </tr>
              <tr>
                <td class="p-2 border-2 border-black font-bold text-md text-left">
                  Purity :
                </td>
                <td class="font-bold text-md border-2 border-black text-center">{{ paketDet.purity.name }}</td>
              </tr>
              <tr v-if="paketDet.cut">
                <td class="p-2 border-2 border-black font-bold text-md text-left">
                  Cut :
                </td>
                <td class="font-bold text-md border-2 border-black text-center">{{ paketDet.cut ? paketDet.cut.name : '' }}</td>
              </tr>
              <tr v-if="paketDet.polish">
                <td class="p-2 border-2 border-black font-bold text-md text-left">
                  Polish :
                </td>
                <td class="font-bold text-md border-2 border-black text-center">{{ paketDet.polish ? paketDet.polish.name : ''}}</td>
              </tr>
              <tr v-if="paketDet.symmetry">
                <td class="p-2 border-2 border-black font-bold text-md text-left">
                  Symmetry :
                </td>
                <td class="font-bold text-md border-2 border-black text-center">{{ paketDet.symmetry ? paketDet.symmetry.name : '' }}</td>
              </tr>
              <tr v-if="paketDet.tension">
                <td class="p-2 border-2 border-black font-bold text-md text-left">
                  Tension :
                </td>
                <td class="font-bold text-md border-2 border-black text-center">{{ paketDet.tension ? paketDet.tension.name : '' }}</td>
              </tr>
              <tr>
                <td class="p-2 border-2 border-black font-bold text-md text-left">
                  Iss. Wt :
                </td>
                <td class="font-bold text-md border-2 border-black text-center">{{ paketDet.issueWt }}</td>
              </tr>
              <tr>
                <td class="p-2 border-2 border-black font-bold text-md text-left">
                  Oriz. Wt :
                </td>
                <td class="font-bold text-md border-2 border-black text-center">{{ paketDet.oriznal_wt }}</td>
              </tr>
            </tbody>
          </table>
          </div>
        </div>
        <div>
          <div class="flex items-center">
          <button  v-if="paketDet.number != ''"
            type="button" v-print="'#paketDetails'"
            class="btn btn-primary w-16 mr-1 py-1"
          >
            Print
          </button>
          <RotateCwIcon  v-if="paketDet.number != ''" class="w-5 h-5 text-primary-6 mx-1 cursor-pointer" @click="getPaketDetail"></RotateCwIcon>
        </div>
        </div>
      </div>
      <div v-if="paketDet.status == 3" class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center text-primary-3">Packet is Cancelled, You can not make change to this Packet</div>
      <div v-else-if="paketDet.status == 7" class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center text-primary-3">Packet is External, You can not make change to this Packet</div>
      <div v-else-if="paketDet.status == 4" class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center text-primary-3">Packet is Blocked, You can not make change to this Packet</div>
      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 xl:mt-0 mt-2" v-if="paketDet.number != ''">
        <div class="intro-y col-span-12 overflow-auto" v-if="packets.length > 0">
            <table class="table-report -mt-2 w-full">
              <thead>
                <tr>
                  <th class="PY-2 whitespace-nowrap">#</th>
                  <th class="PY-2 whitespace-nowrap">T No</th>
                  <th class="PY-2 whitespace-nowrap">Proc.</th>
                  <th class="PY-2 whitespace-nowrap">Dept.</th>
                  <th class="PY-2 whitespace-nowrap">Mgr.</th>
                  <th class="PY-2 text-center whitespace-nowrap">Emp.</th>
                  <th class="PY-2 text-center whitespace-nowrap">Issue Wt.</th>
                  <th class="PY-2 text-center whitespace-nowrap">Rec. Wt.</th>
                  <th class="PY-2 text-center whitespace-nowrap">Issue Time</th>
                  <th class="PY-2 text-center whitespace-nowrap">Rec. Time</th>
                  <th class="PY-2 text-center whitespace-nowrap">Shape</th>
                  <th class="PY-2 text-center whitespace-nowrap">Color</th>
                  <th class="PY-2 text-center whitespace-nowrap">Purity</th>
                  <th class="PY-2 text-center whitespace-nowrap">Cut</th>
                  <th class="PY-2 text-center whitespace-nowrap">Polish</th>
                  <th class="PY-2 text-center whitespace-nowrap">Symm</th>
                  <th class="PY-2 text-center whitespace-nowrap">Flurc</th>
                  <th class="PY-2 text-center whitespace-nowrap">Tension</th>
                  <th class="PY-2 text-center whitespace-nowrap">Stone</th>
                  <th class="PY-2 text-center whitespace-nowrap">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(packet, index) in packets"
                  :key="index"
                  class="intro-x"
                >
                  <td class="text-center font-medium py-2 lg:px-2 px-1">{{ index + 1 }}</td>
                  <td class="text-center font-medium py-2 lg:px-2 px-1">{{ packet.t_id }}</td>
                  <td class="text-center font-medium py-2 lg:px-2 px-1">{{ packet.process }}</td>
                  <td class="text-center font-medium py-2 lg:px-2 px-1">{{ packet.department }}</td>
                  <td class="text-center font-medium py-2 lg:px-2 px-1">{{ packet.manager }}</td>
                  <td class="text-center font-medium py-2 lg:px-2 px-1">{{ packet.employee }}</td>
                  <td class="text-center font-medium py-2 lg:px-2 px-1">{{ packet.issue_weight }}</td>
                  <td class="text-center font-medium py-2 lg:px-2 px-1">{{ packet.receive_weight }}</td>
                  <td class="text-center font-medium py-2 lg:px-2 px-1">{{ packet.issue_date }}</td>
                  <td class="text-center font-medium py-2 lg:px-2 px-1">{{ packet.receive_date }}</td>
                  <td class="text-center font-medium py-2 lg:px-2 px-1">{{ packet.shape ? packet.shape.name : '' }}</td>
                  <td class="text-center font-medium py-2 lg:px-2 px-1">{{ packet.color.name }}</td>
                  <td class="text-center font-medium py-2 lg:px-2 px-1">{{ packet.purity.name }}</td>
                  <td class="text-center font-medium py-2 lg:px-2 px-1">{{ packet.cut ? packet.cut.name : '' }}</td>
                  <td class="text-center font-medium py-2 lg:px-2 px-1">{{ packet.polish ? packet.polish.name : '' }}</td>
                  <td class="text-center font-medium py-2 lg:px-2 px-1">{{ packet.symmetry ? packet.symmetry.name : '' }}</td>
                  <td class="text-center font-medium py-2 lg:px-2 px-1">{{ packet.fluerocent ? packet.fluerocent.name : '' }}</td>
                  <td class="text-center font-medium py-2 lg:px-2 px-1">{{ packet.tension ? packet.tension.name : '' }}</td>
                  <td class="text-center font-medium py-2 lg:px-2 px-1">{{ packet.stone.name }}</td>
                  <td class="text-center font-medium py-2 lg:px-2 px-1">
                    <div class="flex justify-center items-center" v-if="packet.status == 0">
                      <span class="text-primary-1 fond-medium">InProcess</span>
                    </div>
                    <div class="flex justify-center items-center" v-else-if="packet.status == 2">
                      <span class="text-primary-3 fond-medium">Cancelled</span>
                    </div>
                    <div class="flex justify-center items-center" v-else-if="packet.status == 3">
                      <span class="text-primary-1 fond-medium">Blocked</span>
                    </div>
                    <div class="flex justify-center items-center" v-else-if="packet.status == 4">
                      <span class="text-primary-6 fond-medium">External</span>
                    </div>
                    <div class="flex justify-center items-center" v-else-if="packet.status == 1">
                      <CheckIcon class="h-6 w-6 font-bold text-primary-4"></CheckIcon>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
        </div>
        <div v-else class="intro-y col-span-12 overflow-auto mt-2 text-center">No Packet Found</div>
      </div>
      <div v-else class="intro-y col-span-12 overflow-auto mt-2 text-center">Please Scan Packet</div>
      <!-- END: Data List -->
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import axios from 'axios'
import cash from 'cash-dom'
import { defineComponent, reactive, toRef, watch, onMounted } from 'vue'
import print from 'vue3-print-nb'
import TableList from '@/views/ManagerDash/tableList.vue'
export default defineComponent({
  directives: {
    print
  },
  components:{
    TableList
  },
  setup() {
    const pagedata = reactive({
      packets: [],
      search: '',
      check: false,
      waiting: false,
      errors: []
    })
    const packets = toRef(pagedata,'packets')
    const errors = toRef(pagedata,'errors')
    const paketDet = reactive({
      id: '',
      number: '',
      color: '',
      cut: '',
      purity: '',
      polish: '',
      shape: '',
      symmetry: '',
      fluerocent: '',
      stone: '',
      tension: '',
      issueWt: '',
      pwt: '',
      admin_pwt: '',
      oriznal_wt: '',
      type: '',
      status: '',
      r_status: ''
    })
    function getPaketDetail() {
      if(pagedata.search != '' && pagedata.search != null){
        axios({
          url: `/process/admin-party-packet/${pagedata.search}`,
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.packets = res.data.packets
          paketDet.id = res.data.paket.id
          paketDet.number = res.data.paket.number
          paketDet.color = res.data.paket.color
          paketDet.cut = res.data.paket.cut
          paketDet.purity = res.data.paket.purity
          paketDet.polish = res.data.paket.polish
          paketDet.shape = res.data.paket.shape
          paketDet.symmetry = res.data.paket.symmetry
          paketDet.fluerocent = res.data.paket.fluerocent
          paketDet.stone = res.data.paket.stone
          paketDet.tension = res.data.paket.tension
          paketDet.issueWt = res.data.paket.weight
          paketDet.pwt = res.data.paket.pwt
          paketDet.admin_pwt = res.data.paket.admin_pwt
          paketDet.status = res.data.paket.status
          paketDet.r_status = res.data.paket.r_status
          paketDet.oriznal_wt = res.data.paket.oriznal_wt
          paketDet.type = res.data.paket.type
          setTimeout(() => {
            pagedata.search = ''
          }, 1000);
          // if(pagedata.packets.length == 0) {
          //   paketDet.inprocess = 0
          // }
        }).catch((err) => {
          pagedata.errors = err.response.data
          cash('#delete-confirmation-modal').modal('show')
          setTimeout(() => {
            pagedata.errors = []
            cash('#delete-confirmation-modal').modal('hide')
          }, 3000);
          setTimeout(() => {
            pagedata.search = ''
          }, 1000);
          pagedata.packets = []
          paketDet.id = ''
          process.paketid = ''
          paketDet.number = ''
          paketDet.color = []
          paketDet.cut = []
          paketDet.purity = []
          paketDet.polish = []
          paketDet.shape = []
          paketDet.stone = []
          paketDet.symmetry = []
          paketDet.tension = []
          paketDet.issueWt = ''
          paketDet.status = ''
          paketDet.r_status = ''
        })
      }
    }
    watch(() => pagedata.search, (currentValue) => {
      if(!pagedata.check) {
        if (!pagedata.waiting) {
          setTimeout(() => {
            getPaketDetail();
            pagedata.waiting = false;
          }, 100);
        }
        pagedata.waiting = true;
      }
    })
    return {
      pagedata,
      packets,
      errors,
      paketDet,
      getPaketDetail
    }
  },
  mounted() {
    this.$refs.focusMe.focus()
  }
})
</script>

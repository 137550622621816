<template>
  <div>
    <div class="intro-y box mt-2">
      <div class="flex items-center justify-between">
        <div>
          <h2 class="text-lg font-medium mr-auto ml-2 pt-2">Rough Report</h2>
        </div>
        <div class="flex items-center">
          <div class=" font-medium mr-auto ml-2">In Detail</div>
          <div class="mr-5 input-group">
            <input
              type="checkbox"
              v-model="filter.detail"
              @change="getAvailPkt"
              class="form-check-switch border-2 py-1 my-2"
            />
          </div>
        </div>
      </div>
      <div class="flex flex-col sm:items-start relative">
        <div class="flex sm:flex-wrap md:flex-row flex-col items-start ">
          <div class="input-group m-1 my-2">
            <div id="input-group-3" class="input-group-text text-center w-20">Date</div>
            <Litepicker
              v-model="pagedata.date"
              :options="{
                autoApply: false,
                singleMode: false,
                numberOfColumns: 2,
                numberOfMonths: 2,
                showWeekNumbers: true,
                dropdowns: {
                  minYear: 1990,
                  maxYear: null,
                  months: true,
                  years: true
                }
              }"
              class="form-control w-48 block mx-auto"
            />
          </div>
          <div class="input-group m-1 my-2">
            <div id="input-group-3" class="input-group-text text-center w-20">Party</div>
            <TomSelect
            @change="getAvailPkt"
            v-model="filter.party"
              :options="{
                placeholder: 'Select your favorite actors'
              }"
              class="w-48"
            >
            <option value="0">Select Party</option>
              <option v-for="(party,index) in pagedata.parties" :key="index" :value="party.id">{{ party.name }}({{ index + 1 }})</option>
            </TomSelect>
          </div>
          <div class="input-group m-1">
            <div id="input-group-3" class="input-group-text text-center mt-1 w-20">Search</div>
            <div class="w-48 mt-1 relative text-gray-700 dark:text-gray-300">
              <input
                type="text"
                v-model="filter.number"
                autofocus
                ref="focusMe"
                class="form-control w-48 pr-10"
                placeholder="Search Packet"
              />
              <SearchIcon
                class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
              />
            </div>
          </div>
          <div class="input-group mr-2 mt-1">
            <button class="btn btn-primary p-2 w-24 mt-1 shadow-md mr-2" @click="getAvailPkt">Search</button>
          </div>
        </div>
        <div class="flex sm:flex-wrap md:flex-row flex-col items-start ">
          <div class="flex flex-col mt-2">
            <div class="flex items-center mt-1">
              <div class="mx-2 flex items-center">
                <input
                  id="transfer-me"
                  @change="getAvailPkt"
                  type="checkbox" v-model="filter.bonus"
                  class="form-check-input border mr-2 bg-white"
                />
                <label for="transfer-me" class="text-md font-medium">Bonus</label>
              </div>
              <div class="mx-2 flex items-center">
                <input
                  id="transfer-me"
                  @change="getAvailPkt"
                  type="checkbox" v-model="filter.panalty"
                  class="form-check-input border mr-2 bg-white"
                />
                <label for="transfer-me" class="text-md font-medium">Panalty</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- BEGIN: Data List -->
      <div class="overflow-x-auto scrollbar-hidden" v-if="pagedata.packets.length > 0">
        <div class="intro-y col-span-12 overflow-auto">
          <table class="mt-2 w-full border-2">
                <thead>
                  <tr class="bg-theme-1 text-white">
                    <th class="py-2 text-xs whitespace-nowrap">#</th>
                    <th class="py-2 text-xs whitespace-nowrap">Packet</th>
                    <th class="py-2 text-xs whitespace-nowrap">Vepari No</th>
                    <th class="py-2 text-xs whitespace-nowrap">Party</th>
                    <th class="py-2 text-xs whitespace-nowrap" v-if="filter.detail == true"><div>Shp</div></th>
                    <th class="py-2 text-xs whitespace-nowrap"><div>Clr</div></th>
                    <th class="py-2 text-xs whitespace-nowrap"><div>Prt</div></th>
                    <th class="py-2 text-xs whitespace-nowrap" v-if="filter.detail == true"><div>Cut</div></th>
                    <th class="py-2 text-xs whitespace-nowrap" v-if="filter.detail == true"><div>Pol</div></th>
                    <th class="py-2 text-xs whitespace-nowrap" v-if="filter.detail == true"><div>Sym</div></th>
                    <th class="py-2 text-xs whitespace-nowrap" v-if="filter.detail == true"><div>FL</div></th>
                    <th class="py-2 text-xs whitespace-nowrap" v-if="filter.detail == true"><div>TN</div></th>
                    <th class="py-2 text-xs whitespace-nowrap"><div>Stn</div></th>
                    <th class="py-2 text-xs whitespace-nowrap">I. Wt</th>
                    <th class="py-2 text-xs whitespace-nowrap" v-if="filter.detail == true">P. Wt</th>
                    <th class="py-2 text-xs whitespace-nowrap" v-if="filter.detail == true">Status</th>
                    <th class="py-2 text-xs whitespace-nowrap">Rate</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="bg-gray-200 text-gray-700">
                    <td class="py-2 border-b text-xs text-center"></td>
                    <td class="py-2 border-b text-xs text-center"></td>
                    <td class="py-2 border-b text-xs text-center"></td>
                    <td class="py-2 border-b text-xs text-center"></td>
                    <td class="py-2 border-b text-xs text-center" v-if="filter.detail == true"></td>
                    <td class="py-2 border-b text-xs text-center"></td>
                    <td class="py-2 border-b text-xs text-center"></td>
                    <td class="py-2 border-b text-xs text-center" v-if="filter.detail == true"></td>
                    <td class="py-2 border-b text-xs text-center" v-if="filter.detail == true"></td>
                    <td class="py-2 border-b text-xs text-center" v-if="filter.detail == true"></td>
                    <td class="py-2 border-b text-xs text-center" v-if="filter.detail == true"></td>
                    <td class="py-2 border-b text-xs text-center" v-if="filter.detail == true"></td>
                    <td class="py-2 border-b text-xs text-center"></td>
                    <td class="py-2 border-b text-xs text-center"></td>
                    <td class="py-2 border-b text-xs text-center" v-if="filter.detail == true"></td>
                    <td class="py-2 border-b text-xs text-center" v-if="filter.detail == true"></td>
                    <td class="py-2 border-b text-xs text-center">{{ parseFloat(pagedata.heading.total_rate).toFixed(2) }}</td>

                  </tr>
                  <tr v-for="(packet, index) in pagedata.packets" :key="index">
                    <td class="py-2 border-b text-xs text-center">{{ index + 1 }}</td>
                    <td class="py-2 border-b text-xs text-center">{{ packet.number }}</td>
                    <td class="py-2 border-b text-xs text-center">{{ packet.vepari_no }}</td>
                    <td class="py-2 border-b text-xs text-center">{{ packet.party }}</td>
                    <td class="py-2 border-b text-xs text-center" v-if="filter.detail == true">{{ packet.shape }}</td>
                    <td class="py-2 border-b text-xs text-center">{{ packet.color }}</td>
                    <td class="py-2 border-b text-xs text-center">{{ packet.purity }}</td>
                    <td class="py-2 border-b text-xs text-center" v-if="filter.detail == true">{{ packet.cut }}</td>
                    <td class="py-2 border-b text-xs text-center" v-if="filter.detail == true">{{ packet.polish }}</td>
                    <td class="py-2 border-b text-xs text-center" v-if="filter.detail == true">{{ packet.symmetry }}</td>
                    <td class="py-2 border-b text-xs text-center" v-if="filter.detail == true">{{ packet.fluerocent }}</td>
                    <td class="py-2 border-b text-xs text-center" v-if="filter.detail == true">{{ packet.tension }}</td>
                    <td class="py-2 border-b text-xs text-center">{{ packet.stone }}</td>
                    <td class="py-2 border-b text-xs text-center">{{ packet.weight }}</td>
                    <td class="py-2 border-b text-xs text-center" v-if="filter.detail == true">{{ packet.admin_pwt > 0 ? packet.admin_pwt : packet.pwt }}</td>
                    <td class="py-2 border-b text-xs text-center" v-if="filter.detail == true">
                      <div v-if="packet.status == 1" class="text-primary-1">Active</div>
                      <div v-if="packet.status == 2" class="text-primary-1">Processing</div>
                      <div v-if="packet.status == 3" class="text-primary-3">Cancelled</div>
                      <div v-if="packet.status == 4" class="text-primary-4">Blocked</div>
                      <div v-if="packet.status == 5 || packet.status == 6" class="text-primary-8">JobDone</div>
                      <div v-if="packet.status == 7" class="text-primary-6">External</div>
                      <div v-if="packet.status == 8" class="text-primary-4">Delivered</div>
                      <div v-if="packet.status == 9" class="text-primary-2">Discuss</div>
                    </td>
                    <td class="py-2 border-b text-xs text-center">{{ packet.total_rate }}</td>

                  </tr>
                </tbody>
              </table>
        </div>
        <!-- END: Data List -->
        <!-- BEGIN: Pagination -->
        <div
          class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center mt-3 mb-7"
        >
          <ul class="pagination">
            <li>
              <button class="pagination__link" :disabled="pagedata.current_page == 1" @click="getAvailablePkt(pagedata.first_page_url)">
                <ChevronsLeftIcon class="w-4 h-4" />
              </button>
            </li>
            <li>
              <button class="pagination__link" :disabled="pagedata.prev_page_url == null" @click="getAvailablePkt(pagedata.prev_page_url)">
                <ChevronLeftIcon class="w-4 h-4" />
              </button>
            </li>
            <li v-for="(pagelink,index) in pagelinks" :key="index">
              <button class="pagination__link" :class="{'pagination__link--active' : pagelink.active == true}" v-if="pagelinks[0] != pagelink && pagelinks[pagelinks.length - 1] != pagelink" @click='getAvailablePkt(pagelink.url)'>{{ pagelink.label }}</button>
            </li>
            <li>
              <button class="pagination__link" :disabled="pagedata.next_page_url == null" @click="getAvailablePkt(pagedata.next_page_url)">
                <ChevronRightIcon class="w-4 h-4" />
              </button>
            </li>
            <li>
              <button class="pagination__link" :disabled="pagedata.current_page == pagedata.last_page" @click="getAvailablePkt(pagedata.last_page_url)">
                <ChevronsRightIcon class="w-4 h-4" />
              </button>
            </li>
          </ul>
          <select class="w-20 form-select box mt-3 sm:mt-0" v-model="filter.size" @change="getAvailPkt">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
          </select>
        </div>
        <!-- END: Pagination -->
      </div>
      <div v-else class="text-center">No Packet Found</div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config';
import axios from 'axios';
import moment from 'moment';
import { defineComponent, onMounted, reactive, toRef, watch } from 'vue';

export default defineComponent({
  setup() {
    const pagedata = reactive({
      packets: [],
      labour: false,
      bonus: false,
      pagelinks: [],
      prev_page_url: null,
      next_page_url: null,
      first_page_url: null,
      last_page_url: null,
      current_page: 1,
      last_page: '',
      date: '',
      parties: [],
      heading: {
        total_packets: null,
        total_weight: null,
        total_pwt: null,
        total_rate: null
      }
    })
    const pagelinks = toRef(pagedata, 'pagelinks')
    const filter = reactive({
      size : 10,
      start_date : '',
      end_date : '',
      party : '',
      number : '',
      bonus: true,
      panalty: true,
      detail: false
    })
    onMounted(() => {
      let start = moment().startOf('month').format('DD MMM, YYYY')
      let end = moment().endOf('month').format('DD MMM, YYYY')
      pagedata.date = start + '-' + end
      setTimeout(() => {
        getParties()
      },1500)
    })
    function getAvailablePkt(url) {
      axios({
        url: url,
        method: 'post',
        data: filter,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.packets = res.data.packets.data
        pagedata.pagelinks = res.data.packets.links
        pagedata.prev_page_url = res.data.packets.prev_page_url
        pagedata.next_page_url = res.data.packets.next_page_url
        pagedata.first_page_url = res.data.packets.first_page_url
        pagedata.last_page_url = res.data.packets.last_page_url
        pagedata.current_page = res.data.packets.current_page
        pagedata.last_page = res.data.packets.last_page
        pagedata.heading.total_weight = res.data.total[0].total_weight
        pagedata.heading.total_pwt = res.data.total[0].total_pwt
        pagedata.heading.total_rate = res.data.total[0].total_rate
    })
    }
    function getAvailPkt() {
       axios({
        url: `/report/rough_report`,
        method: 'post',
        baseURL: BASE_URL,
        data: filter,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.packets = res.data.packets.data
        pagedata.pagelinks = res.data.packets.links
        pagedata.prev_page_url = res.data.packets.prev_page_url
        pagedata.next_page_url = res.data.packets.next_page_url
        pagedata.first_page_url = res.data.packets.first_page_url
        pagedata.last_page_url = res.data.packets.last_page_url
        pagedata.current_page = res.data.packets.current_page
        pagedata.last_page = res.data.packets.last_page
        pagedata.heading.total_weight = res.data.total[0].total_weight
        pagedata.heading.total_pwt = res.data.total[0].total_pwt
        pagedata.heading.total_rate = res.data.total[0].total_rate
      })
    }
    function getParties() {
      axios({
        url: '/parties',
        method: 'get',
        baseURL: BASE_URL,
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.parties = res.data.parties
      })
    }
      watch(() => pagedata.date, (currentDate) => {
        if(currentDate) {
          let divide = pagedata.date.split("-")
          filter.start_date = moment(divide[0]).format('YYYY-MM-DD');
          filter.end_date = moment(divide[1]).format('YYYY-MM-DD');
          getAvailPkt()
        }
      })
      watch(() => filter.number, (current) => {
        if(current == '') {
          getAvailPkt()
        }
      })
    return {
      getAvailablePkt,
      getAvailPkt,
      pagedata,
      filter,
      pagelinks
    }
  }
})
</script>

<template>
  <div>
    <div id="basic-non-sticky-notification-packet" class="toastify-content hidden flex flex-col sm:flex-row">
      <div class="font-medium text-primary-3" v-if="pagedata.errors.error">{{ pagedata.errors.error }}</div>
      <div class="flex items-center justify-around" v-if="pagedata.success">
          <CheckCircleIcon class="w-6 h-6 text-theme-9 mx-auto" />
          <div class="font-medium text-black">{{ pagedata.success }}</div>
        </div>
    </div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto"><span v-if="this.$route.params.id">Add Back Details</span><span v-else>Update Back Details</span></h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-8">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div id="input" class="p-5">
            <div class="preview">
              <div class="mt-3">
                <div class="sm:grid grid-cols-2 gap-2">
                  <div>
                    <div class="input-group mt-2 sm:mt-0">
                      <div id="input-group-3" class="input-group-text w-36 text-center">From</div>
                      <input
                        type="number"
                        class="form-control"
                        v-model="rateData.from"
                        placeholder="From"
                        aria-describedby="input-group-3"
                      />
                      <div class="flex-col">
                        <button class="btn input-group-text-custom px-3" @click="IncrementWeight('increment','From')"><ChevronUpIcon class="w-4 h-4" /></button>
                        <button class="btn input-group-text-custom px-3" :disabled="pagedata.fromdis" @click="IncrementWeight('decrement','From')"><ChevronDownIcon class="w-4 h-4" /></button>
                      </div>
                    </div>
                    <div class="text-primary-3" v-if="pagedata.errors.from">{{ pagedata.errors.from[0] }}</div>
                  </div>
                  <div>
                    <div class="input-group">
                      <div id="input-group-3" class="input-group-text w-36 text-center">To</div>
                        <input
                          type="number"
                          class="form-control"
                          v-model="rateData.to"
                          placeholder="To"
                          aria-describedby="input-group-3"
                        />
                        <div class="flex-col">
                        <button class="btn input-group-text-custom px-3" @click="IncrementWeight('increment','To')"><ChevronUpIcon class="w-4 h-4" /></button>
                        <button class="btn input-group-text-custom px-3" :disabled="pagedata.todis" @click="IncrementWeight('decrement','To')"><ChevronDownIcon class="w-4 h-4" /></button>
                      </div>
                    </div>
                    <div class="text-primary-3" v-if="pagedata.errors.to">{{ pagedata.errors.to[0] }}</div>
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-6 mt-5">
                <div class="intro-y col-span-12 md:col-span-6">
                  <div class="mt-3">
                    <div class="input-group mt-2 sm:mt-0">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Color</div>
                      <TomSelect
                        v-model="rateData.color"
                            :options="{
                              placeholder: 'Select Colors'
                            }"
                            class="w-full"
                          multiple
                          >
                        <option value="All">All</option>
                        <option v-for="col in pagedata.colors" :key="col.id" :value="col.id">{{ col.color_name }}</option>
                      </TomSelect>
                    </div>
                    <div class="text-primary-3" v-if="pagedata.errors.color">{{ pagedata.errors.color[0] }}</div>
                  </div>
                  <div class="mt-3">
                    <div class="input-group">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Purity</div>
                        <TomSelect
                          v-model="rateData.purity"
                            :options="{
                              placeholder: 'Select Purity'
                            }"
                            class="w-full"
                          multiple
                          >
                        <option value="All">All</option>
                        <option v-for="col in pagedata.purities" :key="col.id" :value="col.id">{{ col.purity_name }}</option>
                      </TomSelect>
                    </div>
                  <div class="text-primary-3" v-if="pagedata.errors.purity">{{ pagedata.errors.purity[0] }}</div>
                  </div>
                  <div class="mt-3">
                    <div class="input-group mt-2 sm:mt-0">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Shape</div>
                      <TomSelect
                        v-model="rateData.shape"
                            :options="{
                              placeholder: 'Select your favorite actors'
                            }"
                            class="w-full"
                          multiple
                          >
                        <option value="All">All</option>
                        <option v-for="col in pagedata.shapes" :key="col.id" :value="col.id">{{ col.shape_name }}</option>
                      </TomSelect>
                    </div>
                    <div class="text-primary-3" v-if="pagedata.errors.shape">{{ pagedata.errors.shape[0] }}</div>
                  </div>
                  <div class="mt-3">
                    <div class="input-group mt-2 sm:mt-0">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Flurocent</div>
                      <TomSelect
                        v-model="rateData.fluerocent"
                            :options="{
                              placeholder: 'Select your favorite actors'
                            }"
                            class="w-full"
                          multiple
                          >
                        <option value="All">All</option>
                        <option v-for="col in pagedata.fluerocents" :key="col.id" :value="col.id">{{ col.fluerocent_name }}</option>
                      </TomSelect>
                    </div>
                     <div class="text-primary-3" v-if="pagedata.errors.fluerocent">{{ pagedata.errors.fluerocent[0] }}</div>
                  </div>
                  <div class="mt-3">
                    <div class="input-group mt-2 sm:mt-0">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Stone</div>
                      <TomSelect
                        v-model="rateData.stone"
                            :options="{
                              placeholder: 'Select your favorite actors'
                            }"
                            class="w-full"
                          multiple
                          >
                        <option value="All">All</option>
                        <option v-for="col in pagedata.stones" :key="col.id" :value="col.id">{{ col.stone_name }}</option>
                      </TomSelect>
                    </div>
                    <div class="text-primary-3" v-if="pagedata.errors.stone">{{ pagedata.errors.stone[0] }}</div>
                  </div>
                  <div class="mt-3">
                    <div class="input-group">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Cut</div>
                      <TomSelect
                        v-model="rateData.cut"
                            :options="{
                              placeholder: 'Select Cut'
                            }"
                            class="w-full"
                          multiple
                          >
                        <option value="All">All</option>
                        <option v-for="col in pagedata.cuts" :key="col.id" :value="col.id">{{ col.cut_name }}</option>
                      </TomSelect>
                    </div>
                    <div class="text-primary-3" v-if="pagedata.errors.cut">{{ pagedata.errors.cut[0] }}</div>
                  </div>
                  <div class="mt-3">
                    <div class="input-group mt-2 sm:mt-0">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Polish</div>
                      <TomSelect
                        v-model="rateData.polish"
                            :options="{
                              placeholder: 'Select your favorite actors'
                            }"
                            class="w-full"
                          multiple
                          >
                        <option value="All">All</option>
                        <option v-for="col in pagedata.polish" :key="col.id" :value="col.id">{{ col.polish_name }}</option>
                      </TomSelect>
                    </div>
                    <div class="text-primary-3" v-if="pagedata.errors.polish">{{ pagedata.errors.polish[0] }}</div>
                  </div>
                </div>
                <div class="intro-y col-span-12 md:col-span-6">
                  <div class="mt-3">
                    <div class="input-group">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Symmerty</div>
                      <TomSelect
                        v-model="rateData.symmetry"
                            :options="{
                              placeholder: 'Select Symmetry'
                            }"
                            class="w-full"
                          multiple
                          >
                        <option value="All">All</option>
                        <option v-for="col in pagedata.symmetry" :key="col.id" :value="col.id">{{ col.symmetry_name }}</option>
                      </TomSelect>
                    </div>
                    <div class="text-primary-3" v-if="pagedata.errors.symmetry">{{ pagedata.errors.symmetry[0] }}</div>
                  </div>
                  <div class="mt-3">
                    <div class="input-group">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Ratio</div>
                      <TomSelect
                        v-model="rateData.ratio"
                            :options="{
                              placeholder: 'Select your favorite actors'
                            }"
                            class="w-full"
                          multiple
                          >
                        <option value="All">All</option>
                        <option v-for="col in pagedata.ratios" :key="col.id" :value="col.id">{{ col.ratio_name }}</option>
                      </TomSelect>
                    </div>
                     <div class="text-primary-3" v-if="pagedata.errors.tension">{{ pagedata.errors.ratio[0] }}</div>
                  </div>
                  <div class="mt-3">
                    <div class="input-group">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Tabl</div>
                      <TomSelect
                        v-model="rateData.tabl"
                            :options="{
                              placeholder: 'Select your favorite actors'
                            }"
                            class="w-full"
                          multiple
                          >
                        <option value="All">All</option>
                        <option v-for="col in pagedata.tabls" :key="col.id" :value="col.id">{{ col.tabl_name }}</option>
                      </TomSelect>
                    </div>
                     <div class="text-primary-3" v-if="pagedata.errors.tension">{{ pagedata.errors.tabl[0] }}</div>
                  </div>
                  <div class="mt-3">
                    <div class="input-group">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Height</div>
                      <TomSelect
                        v-model="rateData.height"
                            :options="{
                              placeholder: 'Select your favorite actors'
                            }"
                            class="w-full"
                          multiple
                          >
                        <option value="All">All</option>
                        <option v-for="col in pagedata.heights" :key="col.id" :value="col.id">{{ col.height_name }}</option>
                      </TomSelect>
                    </div>
                     <div class="text-primary-3" v-if="pagedata.errors.tension">{{ pagedata.errors.height[0] }}</div>
                  </div>
                  <div class="mt-3">
                    <div class="input-group">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Back</div>
                      <input
                        type="text"
                        name="Back"
                        v-model="rateData.back"

                        class="form-control"
                        placeholder="Enter Back"
                      />
                    </div>
                     <div class="text-primary-3" v-if="pagedata.errors.tension">{{ pagedata.errors.back[0] }}</div>
                  </div>
                  <div class="mt-3">
                    <div class="input-group mt-2 sm:mt-0">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Processing</div>
                      <select
                        class="form-select"
                        v-model="rateData.processing"
                        aria-label="Default select example"
                      >
                        <option value="all">All</option>
                        <option value="normal">Normal</option>
                        <option value="special">Special</option>
                        <option value="lab">LAB</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-right mt-3">
                    <router-link :to="{ name: 'back' }" class="btn btn-outline-secondary w-24 mr-1">
                      Cancel
                    </router-link>
                    <button type="button" @click="SaveEmployeeRate" :disabled="pagedata.submit" class="btn btn-primary w-24">Save
                      <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
                    </button>
                  </div>
            </div>
          </div>
        </div>
        <!-- END: Input -->
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import router from '@/router'
import { useRoute } from 'vue-router'
import axios from 'axios'
import Toastify from 'toastify-js'
import { defineComponent, onMounted, reactive, toRef, watch } from 'vue'
import moment from 'moment'
import cash from 'cash-dom'
import { stringify } from 'json5'
export default defineComponent({
  setup() {
    const pagedata = reactive({
      submit: false,
      endis: false,
      processes: [],
      errors: [],
      success: '',
      colors: [],
      cuts: [],
      purities: [],
      polish: [],
      shapes: [],
      stones: [],
      // tensions: [],
      fluerocents: [],
      symmetry: [],
      tabls: [],
      ratios: [],
      heights: [],
      fromdis: true,
      todis: true
    })
    const route = useRoute()
    const rateData = reactive({
      id: '',
      from: 0,
      to: 0.01,
      color: '',
      cut: '',
      purity: '',
      polish: '',
      shape: '',
      symmetry: '',
      stone: '',
      // tension: '',
      fluerocent: '',
      s_date: '',
      e_date: '',
      tabl: '',
      ratio: '',
      height: '',
      back: '',
      processing: 'all',
    })
    onMounted(() => {
      getDiamondDetails()
      if(route.params.id) {
        pagedata.endis = true
        rateData.id = route.params.id
        getProcessRates()
      }
    })
    function getDiamondDetails() {
      axios({
        url: 'api/master/get_all_parameters',
        method: 'post',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.colors = res.data.color
          pagedata.cuts = res.data.cut
          pagedata.purities = res.data.purity
          pagedata.polish = res.data.polish
          pagedata.shapes = res.data.shape
          pagedata.symmetry = res.data.symmetry
          pagedata.fluerocents = res.data.fluerocent
          // pagedata.tensions = res.data.tension
          pagedata.stones = res.data.stone
          pagedata.tabls = res.data.tabl
          pagedata.heights = res.data.height
          pagedata.ratios = res.data.ratio
        })
    }
    function SaveEmployeeRate() {
        if(route.params.id) {
          pagedata.submit = true
          axios({
            url: 'api/master/update_back_details',
            method: 'post',
            baseURL: BASE_URL,
            data: rateData,
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
            config: { headers: { 'Accept':'application/json' } }
          }).then((res) => {
            pagedata.submit = false
            pagedata.success = res.data.success
            setTimeout(() => {
              // basicNonStickyNotificationPacket()
            }, 100);
            setTimeout(() => {
              pagedata.success = ''
            }, 4000);
            router.push({ name: 'back' })
          }).catch((err) => {
            pagedata.errors = err.response.data.errors
            setTimeout(() => {
              pagedata.errors = []
            }, 5000);
            pagedata.submit = false
          })
        } else {
          let url = 'api/master/add_back_details'
          pagedata.submit = true
          axios({
          url: url,
          method: 'post',
          baseURL: BASE_URL,
          data: rateData,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
          }).then((res) => {
            pagedata.submit = false
            pagedata.success = res.data.success
              setTimeout(() => {
                basicNonStickyNotificationPacket()
              }, 100);
              setTimeout(() => {
                pagedata.success = ''
              }, 5000);
            router.push({ name: 'back' })
          }).catch((err) => {
            pagedata.submit = false
            if(err.response.data.error) {
              pagedata.errors.error = err.response.data.error
              pagedata.errors.parameters = err.response.data.parameters
              pagedata.errors.prevEmpRate = err.response.data.prevEmpRate
              cash('#superlarge-modal-size-preview2').modal('show')
            } else {
              pagedata.errors = err.response.data.errors
              setTimeout(() => {
                pagedata.errors = []
              }, 5000);
            }
          })
        }
    }
    function getProcessRates() {
      axios({
          url: 'api/master/get_update_item_details',
          method: 'post',
          baseURL: BASE_URL,
          data: {id : route.params.id},
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          rateData.from = res.data.item.from
          rateData.to = res.data.item.to
          // rateData.tension = res.data.rates.tension
          rateData.color = res.data.item.color
          rateData.cut = res.data.item.cut
          rateData.purity = res.data.item.purity
          rateData.polish = res.data.item.polish
          rateData.shape = res.data.item.shape
          rateData.symmetry = res.data.item.symmetry
          rateData.fluerocent = res.data.item.fluerocent
          rateData.stone = res.data.item.stone
          rateData.tabl = res.data.item.tabl
          rateData.ratio = res.data.item.ratio
          rateData.height = res.data.item.height
          rateData.back = res.data.item.back
          rateData.processing = res.data.item.processing
        })
    }
    const basicNonStickyNotificationPacket = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification-packet')
          .clone()
          .removeClass('hidden')[0],
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    function IncrementWeight(type,name) {
      if(name == 'From') {
        if(type == 'increment') {
          rateData.from = parseFloat((rateData.from + 0.01).toFixed(2))
         }else {
           rateData.from = parseFloat((rateData.from - 0.01).toFixed(2))
         }
      } else if(name == 'To') {
        if(type == 'increment') {
          rateData.to = parseFloat((rateData.to + 0.01).toFixed(2))
         }else {
           rateData.to = parseFloat((rateData.to - 0.01).toFixed(2))
         }
      }
    }
    watch(() => rateData.color , (CurrentValue, OldValue) => {
      if(rateData.color.includes('All')) {
        rateData.color.splice(0,rateData.color.length - 1)
        rateData.color[0] = 'All'
      }
    })
    watch(() => rateData.cut , (CurrentValue, OldValue) => {
      if(rateData.cut.includes('All')) {
        rateData.cut.splice(0,rateData.cut.length - 1)
        rateData.cut[0] = 'All'
      }
    })
    watch(() => rateData.purity , (CurrentValue, OldValue) => {
      if(rateData.purity.includes('All')) {
        rateData.purity.splice(0,rateData.purity.length - 1)
        rateData.purity[0] = 'All'
      }
    })
    watch(() => rateData.polish , (CurrentValue, OldValue) => {
      if(rateData.polish.includes('All')) {
        rateData.polish.splice(0,rateData.polish.length - 1)
        rateData.polish[0] = 'All'
      }
    })
    watch(() => rateData.shape , (CurrentValue, OldValue) => {
      if(rateData.shape.includes('All')) {
        rateData.shape.splice(0,rateData.shape.length - 1)
        rateData.shape[0] = 'All'
      }
    })
    watch(() => rateData.symmetry , (CurrentValue, OldValue) => {
      if(rateData.symmetry.includes('All')) {
        rateData.symmetry.splice(0,rateData.symmetry.length - 1)
        rateData.symmetry[0] = 'All'
      }
    })
    watch(() => rateData.stone , (CurrentValue, OldValue) => {
      if(rateData.stone.includes('All')) {
        rateData.stone.splice(0,rateData.stone.length - 1)
        rateData.stone[0] = 'All'
      }
    })
    // watch(() => rateData.tension , (CurrentValue, OldValue) => {
    //   if(rateData.tension.includes('All')) {
    //     rateData.tension.splice(0,rateData.tension.length - 1)
    //     rateData.tension[0] = 'All'
    //   }
    // })
    watch(() => rateData.fluerocent , (CurrentValue, OldValue) => {
      if(rateData.fluerocent.includes('All')) {
        rateData.fluerocent.splice(0,rateData.fluerocent.length - 1)
        rateData.fluerocent[0] = 'All'
      }
    })
    watch(() => rateData.tabl , (CurrentValue, OldValue) => {
      if(rateData.tabl.includes('All')) {
        rateData.tabl.splice(0,rateData.tabl.length - 1)
        rateData.tabl[0] = 'All'
      }
    })
    watch(() => rateData.height , (CurrentValue, OldValue) => {
      if(rateData.height.includes('All')) {
        rateData.height.splice(0,rateData.height.length - 1)
        rateData.height[0] = 'All'
      }
    })
    watch(() => rateData.ratio , (CurrentValue, OldValue) => {
      if(rateData.ratio.includes('All')) {
        rateData.ratio.splice(0,rateData.ratio.length - 1)
        rateData.ratio[0] = 'All'
      }
    })
    watch(() => rateData.from , (CurrentValue, OldValue) => {
      if(rateData.from <= 0) {
        pagedata.fromdis = true
      } else {
        pagedata.fromdis = false
      }
      if(rateData.to <= rateData.from) {
        rateData.to = parseFloat((rateData.from).toFixed(2))
      }
    })
    watch(() => rateData.to , (CurrentValue, OldValue) => {
      if(rateData.to <= rateData.from) {
        rateData.to = parseFloat((rateData.from).toFixed(2))
        pagedata.todis = true
      } else {
        pagedata.todis = false
      }
    })
    return {
      pagedata,
      rateData,
      basicNonStickyNotificationPacket,
      SaveEmployeeRate,
      IncrementWeight
    }
  }
})
</script>
<style scoped>
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type=number] {
            -moz-appearance: textfield;
        }

        .input-group .input-group-text-custom {
            border-width: 1px;
            --tw-border-opacity: 1;
            border-color: rgba(226, 232, 240, var(--tw-border-opacity));
            --tw-bg-opacity: 1;
            background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
            padding-top: 0.1rem;
            padding-bottom: 0.1rem;
            padding-left: 1rem;
            padding-right: 1rem;
            --tw-text-opacity: 1;
            color: rgba(113, 128, 150, var(--tw-text-opacity));
            --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
            box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
        }
    </style>

<template>
  <Chart
    type="line"
    :width="width"
    :height="height"
    :data="data"
    :options="options"
  />
</template>

<script>
/* eslint-disable */
import { defineComponent, computed } from 'vue'

export default defineComponent({
  props: ['width' , 'height' , 'grawth','last_grawth'],
  setup(props) {
    const data = computed(() => {
      return {
        labels: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec'
        ],
        datasets: [
          {
            label: 'Current Year',
            data: props.grawth,
            borderWidth: 2,
            borderColor: '#3160D8',
            backgroundColor: 'transparent',
            pointBorderColor: 'transparent'
          },
          {
            label: 'Previous Year',
            data: props.last_grawth,
            borderWidth: 2,
            borderDash: [2, 2],
            borderColor: '#a0afbf',
            backgroundColor: 'transparent',
            pointBorderColor: 'transparent'
          }
        ]
      }
    })
    const options = computed(() => {
      return {
        scales: {
          xAxes: [
            {
              ticks: {
                fontSize: '12',
                fontColor: '#777777'
              },
              gridLines: {
                display: false
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                fontSize: '12',
                fontColor: '#777777',
                callback: function(value) {
                  return value
                }
              },
              gridLines: {
                color: '#D8D8D8',
                zeroLineColor: '#D8D8D8',
                borderDash: [2, 2],
                zeroLineBorderDash: [2, 2],
                drawBorder: false
              }
            }
          ]
        }
      }
    })

    return {
      data,
      options
    }
  }
})
</script>

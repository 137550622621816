<template>
  <div>
    <div id="basic-non-sticky-notification-packet" class="toastify-content hidden flex flex-col sm:flex-row">
      <div class="font-medium text-primary-3" v-if="pagedata.error">{{ pagedata.error }}</div>
      <div class="font-medium text-primary-3" v-if="pagedata.success">{{ pagedata.success }}</div>
    </div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">{{ plan.pkt_number }}</h2>
    </div>
    <!-- <div class="grid grid-cols-12 gap-6 mt-5"> -->
      <div class="grid grid-cols-12 gap-3 mt-5">
        <div class="intro-y col-span-12 lg:col-span-8">
          <!-- BEGIN: Input -->
          <div class="intro-y box">
            <div id="input" class="p-4">
              <div class="preview">
                <div class="sm:grid grid-cols-3 gap-2">
                  <div class="input-group">
                    <div id="input-group-3" class="input-group-text">piece</div>
                    <input
                      type="number"
                      class="form-control"
                      @change="getPaketDetail"
                      v-model="plan.piece"
                      placeholder="No of Pieces"
                      aria-describedby="input-group-3"
                    />
                  </div>
                  <div class="input-group mt-2 sm:mt-0">
                    <div id="input-group-4" class="input-group-text">weight</div>
                    <input
                      type="number"
                      class="form-control" disabled
                      v-model="plan.weight"
                      placeholder="Total Weight"
                      aria-describedby="input-group-4"
                    />
                  </div>
                  <div class="input-group mt-2 sm:mt-0">
                    <div id="input-group-4" class="input-group-text">PWT</div>
                    <input
                      type="number"
                      class="form-control" disabled
                      v-model="plan.total_pwt"
                      placeholder="Total Weight"
                      aria-describedby="input-group-4"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END: Input -->
        </div>
        <div class="intro-y col-span-12 lg:col-span-6 mb-8" v-if="plan.piece > 0">
          <!-- BEGIN: Vertical Form -->
          <div class="intro-y box">
            <div id="vertical-form" class="p-5">
              <div v-if="this.$route.params.id">
                <h2 class="text-lg font-medium mx-5" v-if="plan.number[pagedata.paket_num]">{{ plan.number[pagedata.paket_num] }}</h2>
                <h2 class="text-lg font-medium mx-5" v-else><div v-if="pagedata.paket_num < 9">{{ plan.pkt_number }}.0{{ pagedata.paket_num + 1 }}</div><div v-else>{{ plan.pkt_number }}-{{ paket_num + 1 }}</div></h2>
              </div>
              <div class="grid grid-cols-12 gap-6 mt-5">
                <div class="intro-y col-span-12 md:col-span-6">
                  <div class="mt-3">
                    <div class="input-group">
                      <input
                      @change="getWeight(pagedata.paket_num)"
                        type="number"
                        v-model="plan.pwt[pagedata.paket_num]"
                        class="form-control"
                        placeholder="Polsh Weight"
                        aria-describedby="input-group-3"
                      />
                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.pwt[pagedata.paket_num]">{{ pagedata.errors.pwt[pagedata.paket_num] }}</span>
                  </div>
                  <div class="mt-3">
                    <div class="input-group mt-2 sm:mt-0">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Color</div>
                      <select
                        class="form-select"
                        @change="getRepoRate(pagedata.paket_num)"
                        v-model="plan.color[pagedata.paket_num]"
                        aria-label="Default select example"
                      >
                        <option v-for="col in pagedata.colors" :key="col.id" :value="col.id">{{ col.name }}</option>
                      </select>
                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.color[pagedata.paket_num]">{{ pagedata.errors.color[pagedata.paket_num] }}</span>
                  </div>
                  <div class="mt-3">
                    <div class="input-group">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Purity</div>
                      <select
                        class="form-select"
                        @change="getRepoRate(pagedata.paket_num)"
                        v-model="plan.purity[pagedata.paket_num]"
                        aria-label="Default select example"
                      >
                        <option v-for="col in pagedata.purities" :key="col.id" :value="col.id">{{ col.name }}</option>
                      </select>
                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.purity[pagedata.paket_num]">{{ pagedata.errors.purity[pagedata.paket_num] }}</span>
                  </div>
                  <div class="mt-3">
                    <div class="input-group mt-2 sm:mt-0">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Shape</div>
                      <select
                        class="form-select"
                        @change="getRepoRate(pagedata.paket_num)"
                        v-model="plan.shape[pagedata.paket_num]"
                        aria-label="Default select example"
                      >
                        <option v-for="col in pagedata.shapes" :key="col.id" :value="col.id">{{ col.name }}</option>
                      </select>
                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.shape[pagedata.paket_num]">{{ pagedata.errors.shape[pagedata.paket_num] }}</span>
                  </div>
                  <div class="mt-3">
                    <div class="input-group">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Cut</div>
                      <select
                        class="form-select"
                        v-model="plan.cut[pagedata.paket_num]"
                        aria-label="Default select example"
                      >
                        <option v-for="col in pagedata.cuts" :key="col.id" :value="col.id">{{ col.name }}</option>
                      </select>
                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.cut[pagedata.paket_num]">{{ pagedata.errors.cut[pagedata.paket_num] }}</span>
                  </div>
                  <div class="mt-3">
                    <div class="input-group mt-2 sm:mt-0">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Polish</div>
                      <select
                        class="form-select"
                        v-model="plan.polish[pagedata.paket_num]"
                        aria-label="Default select example"
                      >
                        <option v-for="col in pagedata.polish" :key="col.id" :value="col.id">{{ col.name }}</option>
                      </select>
                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.polish[pagedata.paket_num]">{{ pagedata.errors.polish[pagedata.paket_num] }}</span>
                  </div>
                  <div class="mt-3">
                    <div class="input-group">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Symmerty</div>
                      <select
                        class="form-select"
                        v-model="plan.symmatry[pagedata.paket_num]"
                        aria-label="Default select example"
                      >
                        <option v-for="col in pagedata.symmetry" :key="col.id" :value="col.id">{{ col.name }}</option>
                      </select>
                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.symmatry[pagedata.paket_num]">{{ pagedata.errors.symmatry[pagedata.paket_num] }}</span>
                  </div>
                  <div class="mt-3">
                    <div class="input-group mt-2 sm:mt-0">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Flurocent</div>
                      <select
                        class="form-select"
                        v-model="plan.fluerocent[pagedata.paket_num]"
                        aria-label="Default select example"
                      >
                        <option v-for="col in pagedata.fluerocents" :key="col.id" :value="col.id">{{ col.name }}</option>
                      </select>
                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.fluerocent[pagedata.paket_num]">{{ pagedata.errors.fluerocent[pagedata.paket_num] }}</span>
                  </div>
                </div>
                <div class="intro-y col-span-12 md:col-span-6">
                  <div class="mt-3">
                    <div class="input-group">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Tension</div>
                      <select
                        class="form-select"
                        v-model="plan.tension[pagedata.paket_num]"
                        aria-label="Default select example"
                      >
                        <option v-for="col in pagedata.tensions" :key="col.id" :value="col.id">{{ col.name }}</option>
                      </select>
                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.tension[pagedata.paket_num]">{{ pagedata.errors.tension[pagedata.paket_num] }}</span>
                  </div>
                  <div class="mt-3">
                    <div class="input-group">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Stone</div>
                      <select
                        class="form-select"
                        v-model="plan.stone[pagedata.paket_num]"
                        aria-label="Default select example"
                      >
                        <option v-for="col in pagedata.stones" :key="col.id" :value="col.id">{{ col.name }}</option>
                      </select>
                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.stone[pagedata.paket_num]">{{ pagedata.errors.stone[pagedata.paket_num] }}</span>
                  </div>
                  <div class="mt-3">
                    <div class="input-group">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Ratio</div>
                      <input
                        type="number"
                        v-model="plan.ratio[pagedata.paket_num]"
                        class="form-control"
                        placeholder="Ratio"
                        aria-describedby="input-group-3"
                      />
                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.ratio[pagedata.paket_num]">{{ pagedata.errors.ratio[pagedata.paket_num] }}</span>
                  </div>
                  <div class="mt-3">
                    <div class="input-group">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Tabl</div>
                      <input
                        type="number"
                        v-model="plan.tabl[pagedata.paket_num]"
                        class="form-control"
                        placeholder="Tabl"
                        aria-describedby="input-group-3"
                      />
                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.tabl[pagedata.paket_num]">{{ pagedata.errors.tabl[pagedata.paket_num] }}</span>
                  </div>
                  <div class="mt-3">
                    <div class="input-group">
                      <div id="input-group-3" class="input-group-text w-36 text-center">R Rate</div>
                      <input
                        type="number"
                        disabled
                        v-model="plan.r_rate[pagedata.paket_num]"
                        class="form-control"
                        placeholder="R-Rate"
                        aria-describedby="input-group-3"
                      />
                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.r_rate[pagedata.paket_num]">{{ pagedata.errors.r_rate[pagedata.paket_num] }}</span>
                  </div>
                  <div class="mt-3">
                    <div class="input-group">
                      <div id="input-group-3" class="input-group-text w-36 text-center">S Rate</div>
                      <input
                        type="number"
                        disabled
                        v-model="plan.s_rate[pagedata.paket_num]"
                        class="form-control"
                        placeholder="S Rate"
                        aria-describedby="input-group-3"
                      />
                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.s_rate[pagedata.paket_num]">{{ pagedata.errors.s_rate[pagedata.paket_num] }}</span>
                  </div>
                  <div class="mt-3">
                    <div class="input-group">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Height</div>
                      <input
                        type="number"
                        v-model="plan.height[pagedata.paket_num]"
                        class="form-control"
                        placeholder="Height"
                        aria-describedby="input-group-3"
                      />
                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.height[pagedata.paket_num]">{{ pagedata.errors.height[pagedata.paket_num] }}</span>
                  </div>
                  <div class="mt-3">
                    <div class="input-group">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Discount</div>
                      <input
                        type="number"
                        disabled
                        v-model="plan.discount[pagedata.paket_num]"
                        class="form-control"
                        placeholder="Discount"
                        aria-describedby="input-group-3"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex items-center justify-between mt-5">
                <router-link :to="{ name: 'packet-plans', params: { id: plan.id} }" class="btn btn-outline-secondary w-24 mr-1">
                  Cancel
                </router-link>
                <div class="text-right">
                  <button class="btn btn-outline-secondary w-24 mr-1" @click="prevnext('prev')" :disabled="pagedata.btnPrev">
                    Previous
                  </button>
                  <button type="button" v-if="!pagedata.btnDis" @click="prevnext('next')" class="btn btn-primary w-24">Next</button>
                  <button type="button" :disabled="pagedata.submit" v-else @click="SavePlan" class="btn btn-primary w-24">Save
                  <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" /></button>
                </div>
              </div>
            </div>
          </div>
          <!-- END: Vertical Form -->
        </div>
      </div>
    <!-- </div> -->
  </div>
</template>

<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import router from '@/router'
import axios from 'axios'
import { defineComponent, onMounted, reactive, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import Toastify from 'toastify-js'
export default defineComponent({
  setup() {
    const route = useRoute()
    const pagedata = reactive({
      paket_num: 0,
      btnDis: false,
      btnPrev: true,
      submit: false,
      errors: {
        pwt: [],
        color: [],
        purity: [],
        shape: [],
        cut: [],
        polish: [],
        symmatry: [],
        fluerocent: [],
        tension: [],
        stone: [],
        ratio: [],
        height: [],
        s_rate: [],
        r_rate: [],
        tabl: [],
      },
      error: '',
      success: '',
      colors: [],
      cuts: [],
      purities: [],
      symmetry: [],
      polish: [],
      fluerocents: [],
      tensions: [],
      stones: [],
      shapes: [],
    })
    const plan = reactive({
      piece: 1,
      weight: 0,
      total_pwt: 0,
      id: '0',
      pkt_number: '',
      pwt: [''],
      color: [''],
      purity: [''],
      shape: [''],
      cut: [''],
      polish: [''],
      symmatry: [''],
      fluerocent: [''],
      tension: [''],
      stone: [''],
      tabl: [],
      s_rate: [0],
      r_rate: [0],
      ratio: [],
      height: [],
      discount: [0],
      number: [],
    })
    onMounted(() => {
      getDiamondDet()
      if(route.params.id) {
        getPlanData()
      }
      getPaketDetail()
    })
    function SavePlan() {
      if(validateData()) {
        pagedata.submit = true
        if(route.name == 'update-packet-plan') {
          axios({
            url: `/rough/plan/${route.params.id}`,
            method: 'put',
            baseURL: BASE_URL,
            data: plan,
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
            config: { headers: { 'Accept':'application/json' } }
          }).then((res) => {
            pagedata.submit = false
            pagedata.success = res.data.success
            setTimeout(() => {
              basicNonStickyNotificationPacket()
            }, 100);
            setTimeout(() => {
              pagedata.success =''
            }, 5000);
            router.push({ name: 'packet-plans', params: { id: plan.id} })
          }).catch((err) => {
            pagedata.error = err.response.data.error
            setTimeout(() => {
              basicNonStickyNotificationPacket()
            }, 100);
            setTimeout(() => {
              pagedata.error =''
            }, 5000);
            pagedata.submit = false
          })
        } else {
          axios({
            url: '/rough/plan',
            method: 'post',
            baseURL: BASE_URL,
            data: plan,
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
            config: { headers: { 'Accept':'application/json' } }
          }).then((res) => {
            pagedata.submit = false
            pagedata.success = res.data.success
            setTimeout(() => {
              basicNonStickyNotificationPacket()
            }, 100);
            setTimeout(() => {
              pagedata.success =''
            }, 5000);
            router.push({ name: 'packet-plans', params: { id: plan.id} })
          }).catch((err) => {
            pagedata.error = err.response.data.error
            setTimeout(() => {
              basicNonStickyNotificationPacket()
            }, 100);
            setTimeout(() => {
              pagedata.error =''
            }, 5000);
            pagedata.submit = false
          })
        }
      }
    }
    function getPlanData() {
      if(route.name == 'create-packet-plan') {
        axios({
          url: `/rough/singlePlan/${route.params.id}`,
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          plan.weight = res.data.packet.weight
          plan.id = res.data.packet.id
          plan.pkt_number = res.data.packet.number
        })
      } else if(route.name == 'update-packet-plan') {
         axios({
          url: `/rough/singlePlan/${route.params.id}?type=1`,
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          plan.piece = res.data.packet.pieces
          plan.weight = res.data.packet.weight
          plan.id = res.data.packet.id
          plan.pkt_number = res.data.packet.number
          getPaketDetail()
          setTimeout(() => {
            const ind = ref(0);
            res.data.plans.forEach(pkt => {
              plan.number[ind.value] =  pkt.number
              plan.pwt[ind.value] =  pkt.pwt
              plan.color[ind.value] =  pkt.color
              plan.purity[ind.value] =  pkt.purity
              plan.shape[ind.value] =  pkt.shape
              plan.cut[ind.value] =  pkt.cut
              plan.polish[ind.value] =  pkt.polish
              plan.symmatry[ind.value] =  pkt.symmetry
              plan.fluerocent[ind.value] =  pkt.fluerocent
              plan.tension[ind.value] =  pkt.tension
              plan.stone[ind.value] =  pkt.stone
              plan.tabl[ind.value] = pkt.tabl
              plan.s_rate[ind.value] = pkt.s_rate
              plan.r_rate[ind.value] = pkt.r_rate
              plan.ratio[ind.value] = pkt.ratio
              plan.height[ind.value] = pkt.height
              plan.discount[ind.value] =  pkt.discount
              ind.value = ind.value + 1
            });
            getWeight()
          }, 100);
        })
      }
    }
    function getDiamondDet() {
      axios({
        url: '/GetAll',
        method: 'get',
        baseURL: BASE_URL,
        config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.colors = res.data.diamonds.colors
          pagedata.cuts = res.data.diamonds.cut
          pagedata.purities = res.data.diamonds.purity
          pagedata.symmetry = res.data.diamonds.symmetry
          pagedata.polish = res.data.diamonds.polish
          pagedata.fluerocents = res.data.diamonds.fluerocent
          pagedata.tensions = res.data.diamonds.tension
          pagedata.shapes = res.data.diamonds.shape
          pagedata.stones = res.data.diamonds.stones
        })
    }
    function getPaketDetail() {
      if(pagedata.paket_num == 0 && plan.piece == 1) {
        pagedata.btnDis = true
        pagedata.btnPrev = true
      } else if(pagedata.paket_num == 0 && plan.piece > 1) {
        pagedata.btnDis = false
        pagedata.btnPrev = true
      } else if(pagedata.paket_num != 0 && plan.piece == (pagedata.paket_num +1)) {
        pagedata.btnPrev = false
        pagedata.btnDis = true
      } else {
        pagedata.btnDis = false
        pagedata.btnPrev = false
      }
    }
    function prevnext(type) {
      if(type == 'next') {
        if(validateData()) {
          pagedata.paket_num = pagedata.paket_num + 1;
        }
      } else if(type == 'prev') {
        pagedata.paket_num = pagedata.paket_num - 1;
      }
      getPaketDetail()
    }
    function getWeight(index) {
      getRepoRate(index)
      plan.total_pwt = 0
      plan.pwt.forEach(element => {
        plan.total_pwt = plan.total_pwt + element
        // plan.weight = parseFloat((plan.weight + element).toFixed(2))
      });
      plan.total_pwt = parseFloat(plan.total_pwt).toFixed(2)
      if(plan.total_pwt > plan.weight) {
          plan.pwt[pagedata.paket_num] = 0
          pagedata.errors.pwt[pagedata.paket_num] = 'Polish Weight Less then Weight'
          setTimeout(() => {
            pagedata.errors.pwt[pagedata.paket_num] = ''
          }, 3000);
        }
    }
    function validateData() {
      if(plan.pwt[pagedata.paket_num] == '') {
          pagedata.errors.pwt[pagedata.paket_num] = 'Polish Weight is Required'
          setTimeout(() => {
            pagedata.errors.pwt[pagedata.paket_num] = ''
          }, 3000);
        } else if(plan.pwt[pagedata.paket_num] > plan.weight) {
          pagedata.errors.pwt[pagedata.paket_num] = 'Polish Weight Less then Weight'
          plan.pwt[pagedata.paket_num] = 0
          setTimeout(() => {
            pagedata.errors.pwt[pagedata.paket_num] = ''
          }, 3000);
        } else if(plan.color[pagedata.paket_num] == '') {
          pagedata.errors.color[pagedata.paket_num] = 'Color is Required'
          setTimeout(() => {
            pagedata.errors.color[pagedata.paket_num] = ''
          }, 3000);
        } else if(plan.purity[pagedata.paket_num] == '') {
          pagedata.errors.purity[pagedata.paket_num] = 'Purity is Required'
          setTimeout(() => {
            pagedata.errors.purity[pagedata.paket_num] = ''
          }, 3000);
        } else if(plan.shape[pagedata.paket_num] == '') {
          pagedata.errors.shape[pagedata.paket_num] = 'Shape is Required'
          setTimeout(() => {
            pagedata.errors.shape[pagedata.paket_num] = ''
          }, 3000);
        } else if(plan.cut[pagedata.paket_num] == '') {
          pagedata.errors.cut[pagedata.paket_num] = 'Cut is Required'
          setTimeout(() => {
            pagedata.errors.cut[pagedata.paket_num] = ''
          }, 3000);
        } else if(plan.polish[pagedata.paket_num] == '') {
          pagedata.errors.polish[pagedata.paket_num] = 'Polish is Required'
          setTimeout(() => {
            pagedata.errors.polish[pagedata.paket_num] = ''
          }, 3000);
        } else if(plan.symmatry[pagedata.paket_num] == '') {
          pagedata.errors.symmatry[pagedata.paket_num] = 'Symmatry is Required'
          setTimeout(() => {
            pagedata.errors.symmatry[pagedata.paket_num] = ''
          }, 3000);
        } else if(plan.fluerocent[pagedata.paket_num] == '') {
          pagedata.errors.fluerocent[pagedata.paket_num] = 'Fluerocent is Required'
          setTimeout(() => {
            pagedata.errors.fluerocent[pagedata.paket_num] = ''
          }, 3000);
        } else if(plan.tension[pagedata.paket_num] == '') {
          pagedata.errors.tension[pagedata.paket_num] = 'Tension is Required'
          setTimeout(() => {
            pagedata.errors.tension[pagedata.paket_num] = ''
          }, 3000);
        } else if(plan.stone[pagedata.paket_num] == '') {
          pagedata.errors.stone[pagedata.paket_num] = 'Plan is Required'
          setTimeout(() => {
            pagedata.errors.stone[pagedata.paket_num] = ''
          }, 3000);
        } else if(plan.ratio[pagedata.paket_num] == '') {
          pagedata.errors.ratio[pagedata.paket_num] = 'Ratio is Required'
          setTimeout(() => {
            pagedata.errors.ratio[pagedata.paket_num] = ''
          }, 3000);
        } else if(plan.tabl[pagedata.paket_num] == '') {
          pagedata.errors.tabl[pagedata.paket_num] = 'Tabl is Required'
          setTimeout(() => {
            pagedata.errors.tabl[pagedata.paket_num] = ''
          }, 3000);
        } else if(plan.height[pagedata.paket_num] == '') {
          pagedata.errors.height[pagedata.paket_num] = 'Height is Required'
          setTimeout(() => {
            pagedata.errors.height[pagedata.paket_num] = ''
          }, 3000);
        } else {
          return true;
        }
    }
    function getRepoRate(index) {
      if(plan.color[index] != '' && plan.shape[index] != '' && plan.purity[index] != '' && plan.pwt[index] != '') {
        axios({
          url: '/rough/get-repo-rate',
          method: 'post',
          baseURL: BASE_URL,
          data: { color:plan.color[index],purity:plan.purity[index],shape:plan.shape[index],weight:plan.pwt[index]},
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          plan.r_rate[index] = res.data.price
          if(plan.s_rate[index] == 0) {
            plan.discount[index] = 0
          } else {
            let rate = plan.s_rate[index] - plan.r_rate[index]
            let discount = (rate * 100)/ plan.r_rate[index]
            plan.discount[index] = parseFloat(discount).toFixed(2)
          }

        })
      }
    }
    const basicNonStickyNotificationPacket = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification-packet')
          .clone()
          .removeClass('hidden')[0],
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    watch(() => plan.piece, (currentValue, oldValue) => {
      const i = ref(oldValue);
      const c = ref(currentValue)
      if(currentValue > oldValue) {
        for (i.value; i.value < c.value; i.value++ ) {
          plan.pwt[i.value] = ''
          plan.color[i.value] = ''
          plan.purity[i.value] = ''
          plan.shape[i.value] = ''
          plan.cut[i.value] = ''
          plan.polish[i.value] = ''
          plan.symmatry[i.value] = ''
          plan.fluerocent[i.value] = ''
          plan.tension[i.value] = ''
          plan.stone[i.value] = ''
          plan.discount[i.value] = 0
          plan.tabl[i.value] = ''
          plan.s_rate[i.value] = 0
          plan.r_rate[i.value] = 0
          plan.ratio[i.value] = ''
          plan.height[i.value] = ''
        }
      } else {
          plan.pwt.splice(c.value,plan.pwt.length)
          plan.color.splice(c.value,plan.pwt.length)
          plan.purity.splice(c.value,plan.pwt.length)
          plan.shape.splice(c.value,plan.pwt.length)
          plan.cut.splice(c.value,plan.pwt.length)
          plan.polish.splice(c.value,plan.pwt.length)
          plan.symmatry.splice(c.value,plan.pwt.length)
          plan.fluerocent.splice(c.value,plan.pwt.length)
          plan.tension.splice(c.value,plan.pwt.length)
          plan.discount.splice(c.value,plan.pwt.length)
          plan.tabl.splice(c.value,plan.pwt.length)
          plan.s_rate.splice(c.value,plan.pwt.length)
          plan.r_rate.splice(c.value,plan.pwt.length)
          plan.ratio.splice(c.value,plan.pwt.length)
          plan.height.splice(c.value,plan.pwt.length)
          plan.stone.splice(c.value,plan.pwt.length)
          if(pagedata.paket_num + 1 == oldValue && currentValue != 0) {
            pagedata.paket_num = c.value - 1
          }
        }
    });
    return {
      pagedata,
      plan,
      SavePlan,
      getPaketDetail,
      prevnext,
      getWeight,
      getRepoRate
    }
  }
})
</script>

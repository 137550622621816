<template>
  <div>
    <div id="basic-non-sticky-notification-layout-pkts" class="toastify-content hidden flex flex-col sm:flex-row">
        <div class="flex items-center justify-around" v-if="pagedata.error">
          <CheckCircleIcon class="w-6 h-6 text-theme-6 mx-auto" />
          <div class="font-medium text-black">{{ pagedata.error }}</div>
        </div>
        <div class="flex items-center justify-around" v-if="pagedata.success">
          <CheckCircleIcon class="w-6 h-6 text-theme-9 mx-auto" />
          <div class="font-medium text-black">{{ pagedata.success }}</div>
        </div>
    </div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-3">
      <div class="w-full sm:w-auto flexsm:mt-0">
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
          <div class=" flex items-center justify-between mt-2 xl:mt-0">
            <h2 class="text-lg font-medium truncate mr-5">Urgent Packets</h2>
            <div class="mr-3">
              <TomSelect
                v-model="filter.party"
                @change="getNumbersType"
                :options="{
                  placeholder: 'Select your favorite actors'
                }"
                class="w-44"
              >
                <option value="0">Party</option>
                <option v-for="dept in pagedata.parties" :key="dept.id" :value="dept.id">{{ dept.name }}</option>
              </TomSelect>
            </div>
            <div class="input-group mt-2 sm:mt-0 mr-3" v-if="filter.party != '0'">
              <select
                class="form-select"
                v-model="filter.type"
                @change="getNumbersType"
                aria-label="Default select example"
              >
                <option value="">Packet Number Type</option>
                <option value="">All</option>
                <option value="1">Vepary Number</option>
                <option value="2">Packet Number</option>
                <option value="3">Voucher No</option>
              </select>
            </div>
            <div v-if="filter.type">
              <TomSelect
                v-model="filter.number"
                @change="getTabulatorData"
                :options="{
                  placeholder: 'Select your favorite actors'
                }"
                class="w-44"
              >
                <option value="0">Select Number</option>
                <option v-for="(dept,index) in pagedata.numbers" :key="index" :value="dept.num">{{ dept.num }}</option>
              </TomSelect>
            </div>
          </div>
        </form>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <!-- BEGIN: Cancel Packet Process -->
  <div
    id="cancel-confirmation-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body p-3">
          <div class="p-5 text-center">
            <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
            <div class="text-3xl mt-5">Are you sure?</div>
            <div class="text-gray-600 mt-2">
              Do you really want to Cancel Packet? <br />This process
              cannot be undone.
            </div>
          </div>
          <div class="px-5 pb-8 text-center">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-24 mr-1"
            >
              BACK
            </button>
            <button type="button" class="btn btn-danger w-24" @click="CancelUrgent">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Cancel Packet Process -->
  </div>
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import axios from 'axios'
import cash from 'cash-dom'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import Toastify from 'toastify-js'
import { defineComponent, onMounted, reactive, ref, watch } from 'vue'

export default defineComponent({
  setup() {
    onMounted(() => {
      initTabulator()
      setTimeout(() => {
        getParties()
      }, 500);
    })
    const pagedata = reactive({
      parties: [],
      numbers: [],
      cancelId: '',
      success: '',
      error: ''
    })
    const tableRef = ref()
    const tabulator = ref()
    const filter = reactive({
      party: '0',
      type: '',
      number: ''
    })
    function getParties() {
      axios({
        url: '/parties',
        method: 'get',
        baseURL: BASE_URL,
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.parties = res.data.parties
      })
    }
    function getNumbersType() {
      if(filter.type != '' && filter.party != '0') {
        axios({
          url: `/urgent/party-numbers-type/${filter.party}?type=${filter.type}`,
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.numbers = res.data.numbers
        })
      } else {
        initTabulator()
      }
    }
    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + `/urgent/admin-packets?type=${filter.type}&party=${filter.party}&number=${filter.number}&diamond_type=makeable`,
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 10,
        paginationSizeSelector: [10, 20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [
          {
            formatter: 'responsiveCollapse',
            width: 40,
            minWidth: 30,
            hozAlign: 'center',
            resizable: false,
            headerSort: false
          },

          // For HTML table
          {
            title: 'Packet No',
            responsive: 0,

            field: 'number',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().number
                }</div>
              </div>`
            }
          },
          {
            title: 'Vepari No',
            responsive: 0,
            field: 'number',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().vepari_no
                }</div>
              </div>`
            }
          },
          {
            title: 'Weight',
            responsive: 0,
            field: 'weight',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().weight
                }</div>
              </div>`
            }
          },
          {
            title: 'Polish Wt',
            responsive: 0,
            field: 'polish_wt',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().admin_pwt
                }</div>
              </div>`
            }
          },
          {
            title: 'Manager',
            responsive: 0,
            field: 'user_id',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
          },
          // {
          //   title: 'Party',
          //   responsive: 0,
          //   field: 'party',
          //   hozAlign: 'center',
          //   vertAlign: 'middle',
          //   print: false,
          //   download: false,
          // },
          // {
          //   title: 'Department',
          //   field: 'department',
          //   hozAlign: 'center',
          //   vertAlign: 'middle',
          //   print: false,
          //   download: false
          // },
          {
            title: 'Shape',
            field: 'shape',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Color',
            field: 'color',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Purity',
            field: 'purity',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Cut',
            field: 'cut',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Polish',
            field: 'polish',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'symmetry',
            field: 'symmetry',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          // {
          //   title: 'Stone',
          //   field: 'stone',
          //   hozAlign: 'center',
          //   vertAlign: 'middle',
          //   print: false,
          //   download: false
          // },
          // {
          //   title: 'Fluer',
          //   field: 'fluerocent',
          //   hozAlign: 'center',
          //   vertAlign: 'middle',
          //   print: false,
          //   download: false
          // },
          // {
          //   title: 'Tension',
          //   field: 'tension',
          //   hozAlign: 'center',
          //   vertAlign: 'middle',
          //   print: false,
          //   download: false
          // },
          {
            title: 'Urgent',
            field: 'urgent',
            minWidth: 100,
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              const a = cash(`<div class="flex lg:justify-center items-center">
                ${
                  cell.getData().urgent == 0 ? '<button class="btn btn-primary p-1 px-2 mr-1"> <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Add </button>' : '<a class="flex items-center text-theme-6"> <i data-feather="trash-2" class="w-4 h-4 mr-1"></i> Cancel</a>'
                }
              </div>`)
              cash(a).on('click', function() {
                if(cell.getData().urgent == 0) {
                  AddUrgent(cell.getData().id)
                } else {
                  pagedata.cancelId = cell.getData().id
                  cash('#cancel-confirmation-modal').modal('show')
                }
              })

              return a[0]
            }
          },
        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }
  // Redraw table onresize
      const reInitOnResizeWindow = () => {
        window.addEventListener('resize', () => {
          tabulator.value.redraw()
          feather.replace({
            'stroke-width': 1
          })
        })
      }
    function getTabulatorData() {
      // if(filter.number != '0') {
        initTabulator()
      // }
    }
    function AddUrgent(id) {
      axios({
        url: `/urgent/add-urgent-packet/${id}`,
        method: 'get',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.success = res.data.success
          setTimeout(() => {
            basicNonStickyNotificationPack()
          }, 100);
          setTimeout(() => {
            pagedata.success = ''
          }, 5000);
        initTabulator()
      }).catch((err) => {
          pagedata.error = err.response.data.error
          setTimeout(() => {
            basicNonStickyNotificationPack()
          }, 100);
          setTimeout(() => {
            pagedata.error = ''
          }, 5000);
        })
    }
    function CancelUrgent() {
      axios({
          url: `/urgent/cancel-urgent-packet/${pagedata.cancelId}`,
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.success = res.data.success
          setTimeout(() => {
            basicNonStickyNotificationPack()
          }, 100);
          cash('#cancel-confirmation-modal').modal('hide')
          setTimeout(() => {
            pagedata.success = ''
          }, 5000);
          initTabulator()
        }).catch((err) => {
          pagedata.error = err.response.data.error
          setTimeout(() => {
            basicNonStickyNotificationPack()
          }, 100);
          setTimeout(() => {
            pagedata.error = ''
          }, 5000);
        })
    }
    watch(() => filter.party,(newValue) => {
      if(filter.party == '0') {
        filter.type = ''
      }
    })
    const basicNonStickyNotificationPack = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification-layout-pkts')
          .clone()
          .removeClass('hidden')[0],
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    return {
      pagedata,
      filter,
      tableRef,
      reInitOnResizeWindow,
      getNumbersType,
      initTabulator,
      getParties,
      getTabulatorData,
      AddUrgent,
      CancelUrgent,
      basicNonStickyNotificationPack
    }
  }
})
</script>

import { createStore } from 'vuex'
import main from './main'
import sideMenu from './side-menu'
import user from './user'

const store = createStore({
  modules: {
    main,
    sideMenu,
    user
  }
})

export function useStore() {
  return store
}

export default store

<template>
  <!-- BEGIN: Profile Info -->
  <div id="basic-non-sticky-notification-packet" class="toastify-content hidden flex flex-col sm:flex-row">
      <div class="font-medium text-primary-3" v-if="errors.error">{{ errors.error }}</div>
  </div>
  <div id="basic-non-sticky-notification-packet-assign" class="toastify-content hidden flex flex-col sm:flex-row">
      <div class="flex items-center justify-around">
        <CheckCircleIcon class="w-6 h-6 text-theme-9 mx-auto" />
        <div class="font-medium text-black">Packet Assigned</div>
      </div>
  </div>
  <div>
    <div class="xl:grid block xl:grid-cols-2 gap-6">
      <div>
        <h2 class="intro-y text-lg font-medium mt-5 ml-2">Scan Packet</h2>
        <div class="flex items-center mt-2 ml-2">
          <div class="mr-2 flex items-center">
            <input
              id="remember-me"
              type="checkbox" v-model="pagedata.check"
              class="form-check-input border mr-2 bg-white"
            />
            <label for="remember-me" class="text-md font-medium">Menual</label>
          </div>
          <div class="mx-2 flex items-center">
            <input
              id="transfer-me"
              type="checkbox" v-model="pagedata.transfer"
              class="form-check-input border mr-2 bg-white"
            />
            <label for="transfer-me" class="text-md font-medium">Packet Transfer</label>
          </div>
          <div class="mx-2 flex items-center">
            <input
              id="transfer-me"
              type="checkbox" v-model="pagedata.jobwork_done"
              class="form-check-input border mr-2 bg-white"
            />
            <label for="transfer-me" class="text-md font-medium">Jobwork Done</label>
          </div>
        </div>
        <div class="w-full flex sm:w-auto mt-3 ml-2">
          <div class="w-56 relative text-gray-700 dark:text-gray-300 mr-4">
            <input
              type="text"
              v-model="pagedata.search"
              autofocus
              ref="focusMe"
              class="form-control w-56 box pr-10 placeholder-theme-13"
              placeholder="Search Packet"
            />
            <SearchIcon
              class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
            />
          </div>
          <button class="btn btn-primary shadow-md mr-2" @click="AddtoList">Add</button>
          <a class="btn btn-primary shadow-md mr-2" href="javascript:;" data-toggle="modal" @click="getProcessesCheck" data-target="#new-process-modal" v-if="pagedata.transfer == false && pagedata.jobwork_done == false && addlist.length > 0">Assign Packets</a>
          <a class="btn btn-primary shadow-md mr-2" href="javascript:;" data-toggle="modal" data-target="#transfer-packet-modal" @click="getTransferCheck" v-if="pagedata.transfer == true && addlist.length > 0">Transfer Packets</a>
          <a class="btn btn-primary shadow-md mr-2" href="javascript:;" data-toggle="modal" data-target="#jobwork-done-packet-modal" v-if="pagedata.jobwork_done == true && addlist.length > 0">Jobwork Done</a>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-5 mt-5">
      <div
        class="col-span-6 xl:col-span-2 md:col-span-3 sm:col-span-4 box p-5 cursor-pointer zoom-in" v-for="(add,index) in addlist" :key="index"
      >
        <div class="flex justify-between">
          <div class="font-medium text-base">{{ add }}</div>
          <button class="btn btn-outline-danger p-1 pl-2 hover:text-white" @click="removeItem(index)"><Trash2Icon class="w-4 h-4 mr-1" /></button>
        </div>
      </div>
    </div>
    <!-- BEGIN: Data List -->
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex items-center">
        <h2 class="text-lg font-bold">Processing Packets</h2>
        <div class="w-48 relative text-gray-700 dark:text-gray-300 ml-4">
            <select
                class="form-select"
                v-model="pagedata.type"
                @change="initTabulator"
                aria-label="Default select example"
              >
                <option value="">Select Type</option>
                <option value="makeable">Makeable</option>
                <option value="rough">Rough</option>
                <option value="video">Video</option>
                <option value="lab">Lab</option>
              </select>
          </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
  <!-- BEGIN: new Process Modal -->
  <div
    id="new-process-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <a data-dismiss="modal" href="javascript:;">
          <XIcon class="w-8 h-8 text-gray-500" />
        </a>
        <div class="modal-body p-10 text-center">
          <div class="intro-y box">
            <!-- <div id="vertical-form"> -->
            <div class="flex flex-col justify-between mt-5">
              <!-- BEGIN: Data List -->
              <div class="intro-y overflow-auto">
                <div class="flex items-center">
                </div>
              </div>
              <div class="flex items-center mt-3">
                <div class="form-check">
                  <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer" value="0" type="radio" name="flexRadioDefault" id="flexRadioDefault1" v-model="process.eternals">
                  <label class="form-check-label inline-block text-black text-lg" for="flexRadioDefault1">
                    Internal
                  </label>
                </div>
                <div class="form-check ml-6">
                  <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer" value="1" type="radio" name="flexRadioDefault" id="flexRadioDefault2" v-model="process.eternals">
                  <label class="form-check-label inline-block text-black text-lg" for="flexRadioDefault2">
                    Enternal
                  </label>
                </div>
                <div class="form-check ml-6" v-if="pagedata.search_type == 'makeable'">
                  <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer" value="2" type="radio" name="flexRadioDefault" id="flexRadioDefault3" v-model="process.eternals">
                  <label class="form-check-label inline-block text-black text-lg" for="flexRadioDefault3">
                    Discuss
                  </label>
                </div>
              </div>
              <div v-if="process.eternals == '0'" class="m-auto">
                  <TomSelect
                  v-model="process.process"
                  @change="getEmployees(process.process)"
                    :options="{
                      placeholder: 'Select Process'
                    }"
                    class="w-60 my-3"
                >
                  <option value="0">Select Process</option>
                  <option v-for="(manager,ind) in pagedata.process" :key="ind" :value="manager.id">{{ manager.name }}</option>
                </TomSelect>
                <span class="text-primary-3" v-if="errors.process">{{ errors.process[0] }}</span>
                  <TomSelect
                  v-model="process.employee"
                    :options="{
                      placeholder: 'Select Employee'
                    }"
                    class="w-60 mb-3"
                >
                  <option value="0">Select Employee</option>
                  <option v-for="(manager,ind) in pagedata.employees" :key="ind" :value="manager.id">{{ manager.name }}</option>
                </TomSelect>
                <span class="text-primary-3" v-if="errors.employee">{{ errors.employee[0] }}</span>
              </div>
              <div v-else-if="process.eternals == '1'" class="m-auto">
                  <TomSelect
                  @change="getExternalPartiesProcess"
                  v-model="process.parties"
                    :options="{
                      placeholder: 'Select Party'
                    }"
                    class="w-60 m-auto mt-3"
                >
                  <option value="0">Select Party</option>
                  <option v-for="(manager,ind) in pagedata.parties" :key="ind" :value="manager.id">{{ manager.firmname }}</option>
                </TomSelect>
                <span class="text-primary-3" v-if="errors.parties">{{ errors.parties[0] }}</span>
                <div v-if="process.parties != 0">
                  <TomSelect
                    v-model="process.partyproc"
                      :options="{
                        placeholder: 'Select Process'
                      }" multiple
                      class="w-60 m-auto my-3"
                  >
                  <option v-for="(proc,ind) in pagedata.partyproc" :key="ind" :value="proc.id">{{ proc.name }} ({{ ind + 1 }})</option>
                  </TomSelect>
                  <span class="text-primary-3" v-if="process.parties != 0 && errors.partyproc">{{ errors.partyproc[0] }}</span>
                    <div><label class="form-label">Expexted Weight</label></div>
                  <div class="mt-3" v-for="(packet,index) in addlist" :key="index">
                    <div class="w-60">
                      <div class="input-group">
                        <div id="input-group-3" class="input-group-text w-32">{{ packet }}</div>
                        <input
                          type="number" v-model="process.expected_weight[index]"
                          class="form-control"
                          placeholder="Expected Weight"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="text-primary-3" v-if="errors.error">{{ errors.error }}</div>
                </div>
              </div>
              <div v-else-if="process.eternals == '2'">
                <div class="mt-3">
                  <select class="w-40 form-select box mt-3 sm:mt-0" v-model="process.discuss">
                    <option value="admin">Admin</option>
                    <option value="party">Party</option>
                  </select>
                </div>
                <div class="mt-3">
                  <div class="form-label w-full text-left">Remark</div>
                  <textarea
                    id="regular-form-4"
                    type="password"
                    class="form-control"
                    v-model="process.description"
                    placeholder="Remark"
                  ></textarea>
                </div>
              </div>
              <button type="button" @click="AssignProcess" :disabled="pagedata.submit" class="btn btn-primary w-24 m-auto mt-5">Submit
                <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: new Process Modal -->
  <!-- BEGIN: new Process Modal -->
  <div
    id="transfer-packet-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <a data-dismiss="modal" href="javascript:;">
          <XIcon class="w-8 h-8 text-gray-500" />
        </a>
        <div class="modal-body p-10 text-center">
          <div class="intro-y box">
            <TomSelect
                  v-model="process.manager"
                    :options="{
                      placeholder: 'Select Process'
                    }"
                    class="w-60 my-3"
                >
                  <option value="0">Select Manager</option>
                  <option v-for="(manager,ind) in pagedata.managers" :key="ind" :value="manager.id">{{ manager.name }} ({{ ind + 1 }})</option>
                </TomSelect>
                <span class="text-primary-3" v-if="errors.manager">{{ errors.manager[0] }}</span>
                <button type="button" @click="TransferMan" :disabled="pagedata.submit" class="btn btn-primary w-32 m-auto">Transfer
                <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
              </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: new Process Modal -->
  <!-- Start: Success Modal -->
  <div
    id="success-modal-preview"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body p-0">
          <div class="p-5 text-center">
            <CheckCircleIcon class="w-16 h-16 text-theme-9 mx-auto mt-3" />
            <div class="text-3xl mt-5">Packets Transfered</div>
            <div class="text-gray-600 mt-2 box w-52 p-3 m-auto my-3">
              <div v-if="success.manager" class="text-black font-bold pb-2">Manager : <span class="text-primary-1">{{ success.manager }}</span></div>
              <div v-if="success.department" class="text-black font-bold m-auto pb-2">Department : <span class="text-primary-1">{{ success.department }}</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End: Success Modal -->
  <!-- Start: Job Done Modal -->
  <div
    id="jobwork-done-packet-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body p-0">
          <div class="p-5 text-center">
            <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
            <div class="text-3xl mt-5">Are You Sure ?</div>
            <div class="text-gray-600 mt-2">
              Do you really want to Job Done? <br />This process
              cannot be undone.
            </div>
          </div>
          <div class="px-5 pb-8 text-center">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-24 mr-1"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-danger w-24" @click="TransferToJobDone">Confirm</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End: Job Done Modal -->
  <!-- END: Profile Info -->
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import axios from 'axios'
import cash from 'cash-dom'
import { defineComponent, onMounted, reactive, ref, toRef, watch } from 'vue'
import print from 'vue3-print-nb'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import Toastify from 'toastify-js'
export default defineComponent({
  directives: {
    print
  },
  setup() {
    const pagedata = reactive({
      search: '',
      search_type: '',
      transfer: false,
      jobwork_done: false,
      check: false,
      waiting: false,
      type: '',
      addlist: [],
      errors: [],
      addornot: false,
      employees: [],
      process: [],
      parties: [],
      submit: false,
      managers: [],
      success: {
        manager: '',
        department: ''
      },
      message: '',
      partyproc: []
    })
    const errors = toRef(pagedata, 'errors')
    const addlist = toRef(pagedata, 'addlist')
    const success = toRef(pagedata, 'success')
    const process = reactive({
      eternals: '0',
      employee: '',
      process: '',
      parties: '',
      allpackets: pagedata.addlist,
      manager: '',
      partyproc: '',
      discuss: 'admin',
      expected_weight: [],
      description: ''
    })
    const tableRef = ref()
    const tabulator = ref()
    onMounted(() => {
      initTabulator()
    })
    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + `/packet/packetsap/1?type=${pagedata.type}`,
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 10,
        paginationSizeSelector: [10, 20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [
          {
            formatter: 'responsiveCollapse',
            width: 40,
            minWidth: 30,
            hozAlign: 'center',
            resizable: false,
            headerSort: false
          },

          // For HTML table
          {
            title: 'Packet No',
            responsive: 0,

            field: 'number',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().number
                }</div>
              </div>`
            }
          },
          {
            title: 'Type',
            responsive: 0,
            field: 'type',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().type
                }</div>
              </div>`
            }
          },
          {
            title: 'Parent',
            responsive: 0,
            field: 'parent',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().parent
                }</div>
              </div>`
            }
          },
          {
            title: 'Weight',
            responsive: 0,
            field: 'weight',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().weight
                }</div>
              </div>`
            }
          },
          {
            title: 'Exp. Wt',
            responsive: 0,
            field: 'pwt',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().admin_pwt > 0 ? cell.getData().admin_pwt : cell.getData().pwt
                }</div>
              </div>`
            }
          },
          {
            title: 'Shape',
            field: 'shape',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Color',
            field: 'color',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Purity',
            field: 'purity',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Cut',
            field: 'cut',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Polish',
            field: 'polish',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'symmetry',
            field: 'symmetry',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Fluer',
            field: 'fluerocent',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Tension',
            field: 'tension',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Stone',
            field: 'stone',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }
    function AddtoList() {
      if(pagedata.search != '') {
        if(pagedata.addlist.length <= 0) {
          checkadd()
        } else {
          for (let i = 0; i < pagedata.addlist.length; i++) {
            if(pagedata.addlist[i] == pagedata.search) {
              // pagedata.checklist.splice(pagedata.addlist[i].id,1)
              pagedata.addlist.splice(i, 1);
              if(pagedata.addlist.length <= 0) {
                pagedata.search_type = ''
              }
              pagedata.addornot = true
              pagedata.search = ''
              break
            } else {
              pagedata.addornot = false
            }

          }
          if(pagedata.addornot == false) {
              checkadd()
            }
        }
      }
    }
    function checkadd() {
      axios({
        url: `/purchase/alltoAssign/${pagedata.search}?assrec=1&type=${pagedata.search_type}`,
        method: 'get',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        const index = ref(pagedata.addlist.length)
        pagedata.addlist[index.value] = res.data.packet.number
        pagedata.search_type = res.data.packet.type
        pagedata.search = ''
        // pagedata.checklist[pagedata.addlist[index.value].id] = true
      }).catch((err) => {
        pagedata.errors = err.response.data
        console.warn(pagedata.errors)
        setTimeout(() => {
          basicNonStickyNotificationPacket()
        },100)
        setTimeout(() => {
          pagedata.search = ''
        }, 1000);
        setTimeout(() => {
          pagedata.errors = []
        }, 5000);

      })
    }
    function removeItem(id) {
      console.warn(id)
      pagedata.addlist.splice(id, 1);
      if(pagedata.addlist.length <= 0) {
        pagedata.search_type = ''
      }
    }
    function getTransferCheck() {
      axios({
        url: '/user/transferManager',
        method: 'get',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.managers = res.data.managers
      })
    }
    function TransferMan() {
      pagedata.submit = true
      axios({
        url: '/user/transferManager',
        method: 'post',
        baseURL: BASE_URL,
        data: process,
        config: { headers: { 'Accept':'application/json' } },
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      }).then((res) => {
        pagedata.submit = false
        pagedata.addlist = []
        pagedata.search_type = ''
        pagedata.success.manager = res.data.manager
        pagedata.success.department = res.data.department
        initTabulator()
        cash('#transfer-packet-modal').modal('hide')
        cash('#success-modal-preview').modal('show')
        setTimeout(() => {
          cash('#success-modal-preview').modal('hide')
        }, 3000);
      }).catch((err) => {
        pagedata.submit = false
        pagedata.errors = err.response.data.errors
      })
    }
    function TransferToJobDone() {
      pagedata.submit = true
      axios({
        url: '/outword/multiple-outward-packets',
        method: 'post',
        baseURL: BASE_URL,
        data: { packets: pagedata.addlist },
        config: { headers: { 'Accept':'application/json' } },
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      }).then((res) => {
        pagedata.message = res.data.success
        setTimeout(() => {
          basicNonStickyNotificationPacketAssign()
        }, 100);
        initTabulator()
        pagedata.submit = false
        pagedata.addlist = []
        pagedata.search_type = ''
        setTimeout(() => {
          pagedata.message = ''
        },5000)
        cash('#jobwork-done-packet-modal').modal('hide')
      }).catch((err) => {
        pagedata.errors.error = err.response.data.success
        setTimeout(() => {
          basicNonStickyNotificationPacket()
        }, 100);
        pagedata.submit = false
        setTimeout(() => {
          pagedata.errors.error = ''
        },5000)
      })
    }
    function AssignProcess() {
      pagedata.submit = true
      axios({
        url: '/process/assignallProc',
        method: 'post',
        baseURL: BASE_URL,
        data: process,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.message = res.data.success
        pagedata.submit = false
        initTabulator()
        setTimeout(() => {
          basicNonStickyNotificationPacketAssign()
        }, 100);
        cash('#new-process-modal').modal('hide')
        pagedata.addlist = []
        pagedata.search_type = ''
        process.employee = ''
        process.process = ''
        process.parties = ''
        process.expected_weight = ''
        setTimeout(() => {
          pagedata.message = ''
        },5000)
      }).catch((err) => {
        pagedata.submit = false
        pagedata.errors = err.response.data.errors
        setTimeout(() => {
          pagedata.errors = []
        }, 5000);
      })
    }
    function getExternalPartiesProcess() {
      if(process.parties == 0) {
        pagedata.partyproc = []
      } else {
        axios({
          url: `/get-external-parties-process/${process.parties}`,
          method: 'get',
          baseURL: BASE_URL,
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.partyproc = res.data.processes
        })
      }
    }
    function getEmployees(id) {
      if(id == 0) {
        pagedata.employees = []
      } else {
          axios({
            url: `/user/manager-employee?id=${id}&type=${pagedata.search_type}`,
            method: 'get',
            baseURL: BASE_URL,
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
            config: { headers: { 'Accept':'application/json' } }
          }).then((res) => {
            pagedata.employees = res.data.employees
          })
      }
    }
    function getProcessData() {
      if(pagedata.search_type == 'rough') {
        axios({
          url: '/rough-process-select',
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.process = res.data.processes
        })
      }
      else {
        axios({
          url: '/user/manager-Processes',
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.process = res.data.processes
        })
      }
    }
    function getExternalParties() {
      if(pagedata.parties.length <= 0) {
        axios({
          url: '/get-external-parties',
          method: 'get',
          baseURL: BASE_URL,
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.parties = res.data.party
        })
      }
    }
    watch(() => pagedata.search, (currentValue) => {
      if(!pagedata.check) {
        if (!pagedata.waiting) {
          setTimeout(() => {
            AddtoList()
            pagedata.waiting = false;
          }, 100); // 1 sec delay
        }
        pagedata.waiting = true;
      }
    })
    const basicNonStickyNotificationPacket = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification-packet')
          .clone()
          .removeClass('hidden')[0],
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    function getProcessesCheck() {
      cash('#new-process-modal').show()
      getProcessData()
    }
    watch(() => process.eternals, (currentValue) => {
      if(currentValue == '1') {
        getExternalParties()
        const i = ref(0)
          for(i.value; i.value < pagedata.addlist.length; i.value++) {
            process.expected_weight[i.value] = ''
          }
      }
    })
    watch(() => pagedata.jobwork_done, (currentValue) => {
      if(currentValue == true) {
        if(pagedata.transfer == true) {
          pagedata.transfer = false
        }
      }
    })
    watch(() => pagedata.transfer, (currentValue) => {
      if(currentValue == true) {
        if(pagedata.jobwork_done == true) {
          pagedata.jobwork_done = false
        }
      }
    })
    const basicNonStickyNotificationPacketAssign = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification-packet-assign')
          .clone()
          .removeClass('hidden')[0],
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    return {
      pagedata,
      errors,
      process,
      success,
      tableRef,
      addlist,
      basicNonStickyNotificationPacketAssign,
      AddtoList,
      removeItem,
      TransferMan,
      getTransferCheck,
      getEmployees,
      getExternalParties,
      getProcessData,
      AssignProcess,
      basicNonStickyNotificationPacket,
      getProcessesCheck,
      getExternalPartiesProcess,
      TransferToJobDone,
      initTabulator
    }
  },
  mounted() {
    this.$refs.focusMe.focus()
  }
})
</script>


<template>
  <div>
    <div id="basic-non-sticky-notification-content" class="toastify-content hidden flex flex-col sm:flex-row">
      <div class="font-medium text-primary-3"  v-if="pagedata.error">{{ pagedata.error }}</div>
      <div class="font-medium" v-if="pagedata.success"><CheckCircleIcon class="w-6 h-6 text-theme-9 mx-auto" /> {{ pagedata.success }}</div>
    </div>
    <h2 class="intro-y text-lg font-medium mt-10">Discuss Packets</h2>
    <div class="grid grid-cols-12 gap-6 my-8">
      <!-- BEGIN: Data List -->
      <div v-if="pagedata.loading == true" class="intro-y col-span-12 overflow-auto lg:overflow-visible text-center">Loading</div>
      <div v-else class="intro-y col-span-12 overflow-auto lg:overflow-visible text-center">
        <div v-if="pagedata.packets.length > 0">
          <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
            <table class="table-report -mt-2">
              <thead>
                <tr>
                  <th class="p-1 md:p-2 xl:p-4 whitespace-nowrap">No</th>
                  <th class="p-1 md:p-2 xl:p-4 whitespace-nowrap">Vepari Number</th>
                  <th class="p-1 md:p-2 xl:p-4 whitespace-nowrap">Packet Number</th>
                  <th class="p-1 md:p-2 xl:p-4 whitespace-nowrap">In Stock</th>
                  <th class="p-1 md:p-2 xl:p-4 whitespace-nowrap">Issue Weight</th>
                  <th class="p-1 md:p-2 xl:p-4 whitespace-nowrap">Polish Weight</th>
                  <th class="p-1 md:p-2 xl:p-4 whitespace-nowrap">Admin PWT</th>
                  <th class="p-1 md:p-2 xl:p-4 whitespace-nowrap">Processing</th>
                  <th class="p-1 md:p-2 xl:p-4 whitespace-nowrap">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(packet, index) in pagedata.packets"
                  :key="index"
                  class="intro-x" @mouseover="pagedata.allshow = true" @mouseleave="pagedata.allshow = false"
                >
                  <td  class="text-center">{{ index + 1 }}</td>
                  <td>
                    <div class="font-medium whitespace-nowrap text-center">{{
                      packet.vepari_no
                    }}</div>
                  </td>
                  <td class="p-1 md:p-2 xl:p-4 text-center font-medium whitespace-nowrap">{{ packet.number }}</td>
                  <td class="p-1 md:p-2 xl:p-4 text-center font-medium whitespace-nowrap"><div v-if="packet.manager == packet.old_user">Manager</div><div v-else>{{ packet.instock }}</div></td>
                  <td class="p-1 md:p-2 xl:p-4 text-center font-medium whitespace-nowrap">{{ packet.weight }}</td>
                  <td class="p-1 md:p-2 xl:p-4 text-center font-medium whitespace-nowrap">{{ packet.pwt }}</td>
                  <td class="p-1 md:p-2 xl:p-4 text-center font-medium whitespace-nowrap">{{ packet.admin_pwt }}</td>
                  <td class="p-1 md:p-2 xl:p-4 text-center">{{ packet.processing }}</td>
                  <td class="p-1 md:p-2 xl:p-4 table-report__action">
                    <div v-if="user.id == packet.new_user">
                      <div class="flex justify-center items-center" v-if="packet.status == 0">
                        <button class="btn btn-primary" @click="acceptPacket(packet.id)">Accept</button>
                      </div>
                      <div class="flex justify-center items-center" v-else>
                        <a  data-target="#discuss-transections" data-toggle="modal" href="javascript:;" class="flex items-center" @click="getTransectionDetails(packet.packet)"><ChevronsRightIcon class="h-6 w-6 text-primary-4" /></a>
                        <a  data-target="#return-confirmation-modal" data-toggle="modal" href="javascript:;" class="flex items-center" @click="pagedata.transferid = packet.id"><LogOutIcon class="h-6 w-6 text-primary-1" /></a>
                      </div>
                    </div>
                    <div v-else>
                      <div class="flex justify-center items-center">
                        <a  data-target="#discuss-transections" data-toggle="modal" href="javascript:;" class="flex items-center" @click="getTransectionDetails(packet.packet)"><ChevronsRightIcon class="h-6 w-6 text-primary-4" /></a>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-else class="text-center">No Packet Found</div>
      </div>
      <!-- END: Data List -->
    </div>
    <!-- BEGIN: Return Confirmation Modal -->
    <div
      id="return-confirmation-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
              <div class="text-3xl mt-5">Are you sure?</div>
              <div class="text-gray-600 mt-2">
                Do you really want to Return Packet
              </div>
              <div class="mt-3">
                <select class="w-40 form-select box mt-3 sm:mt-0" v-model="pagedata.discuss">
                  <option value="admin">Admin</option>
                  <option value="party">Party</option>
                </select>
                <div class="mt-3">
                  <div class="form-label w-full text-left">Remark</div>
                  <textarea
                    id="regular-form-4"
                    type="password"
                    class="form-control"
                    v-model="pagedata.description"
                    placeholder="Remark"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-24 mr-1"
              >
                Cancel
              </button>
              <button type="button" class="btn btn-danger w-24" @click="transferPacket">Send</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Return Confirmation Modal -->
    <!-- Begin: Transections Confirmation Modal -->
    <div id="discuss-transections"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <a data-dismiss="modal" href="javascript:;">
              <XIcon class="w-8 h-8 text-gray-500" />
          </a>
          <div class="modal-body pt-16 text-center">
            <!-- BEGIN: Vertical Form -->
            <div class="intro-y box">
              <!-- <div id="vertical-form"> -->
                <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
                  <table class="table-report -mt-2">
                    <thead>
                      <tr>
                        <th class="p-1 md:p-2 xl:p-4 whitespace-nowrap">No</th>
                        <th class="p-1 md:p-2 xl:p-4 whitespace-nowrap">From</th>
                        <th class="p-1 md:p-2 xl:p-4 whitespace-nowrap">To</th>
                        <th class="p-1 md:p-2 xl:p-4 whitespace-nowrap">T Time</th>
                        <th class="p-1 md:p-2 xl:p-4 whitespace-nowrap">R Time</th>
                        <th class="p-1 md:p-2 xl:p-4 whitespace-nowrap">Remark</th>
                        <th class="p-1 md:p-2 xl:p-4 whitespace-nowrap">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(packet, index) in pagedata.histories"
                        :key="index"
                        class="intro-x" @mouseover="pagedata.allshow = true" @mouseleave="pagedata.allshow = false"
                      >
                        <td  class="text-center">{{ index + 1 }}</td>
                        <td class="p-1 md:p-2 xl:p-4 text-center font-medium whitespace-nowrap">{{ packet.from }}</td>
                        <td class="p-1 md:p-2 xl:p-4 text-center font-medium whitespace-nowrap">{{ packet.to }}</td>
                        <td class="p-1 md:p-2 xl:p-4 text-center font-medium"><div class="w-24">{{ packet.s_time }}</div></td>
                        <td class="p-1 md:p-2 xl:p-4 text-center font-medium"><div class="w-24">{{ packet.e_time }}</div></td>
                        <td class="p-1 md:p-2 xl:p-4 text-center font-medium"><div class="w-56">{{ packet.remark }}</div></td>
                        <td class="p-1 md:p-2 xl:p-4 table-report__action">
                          <div v-if="user.id == packet.new_user">
                            <div class="flex justify-center items-center" v-if="packet.status == 0">
                              <button class="btn btn-primary" @click="acceptPacket(packet.id)">Accept</button>
                            </div>
                            <div class="flex justify-center items-center" v-if="packet.status == 1">
                              <button class="flex items-center" @click="getPacketDetails(packet.number)"><ChevronsRightIcon class="h-6 w-6 text-primary-4" /></button>
                              <a  data-target="#return-confirmation-modal" data-toggle="modal" href="javascript:;" class="flex items-center" @click="pagedata.transferid = packet.id"><LogOutIcon class="h-6 w-6 text-primary-1" /></a>
                            </div>
                            <div class="flex justify-center items-center" v-if="packet.status == 2">
                              <div class="text-primary-7">Hold</div>
                            </div>
                            <div class="flex justify-center items-center" v-if="packet.status == 3">
                              <div><CheckIcon class="h-4 w-4 text-primary-4" /></div>
                            </div>
                          </div>
                          <div v-else>
                            <div class="flex justify-center items-center" v-if="packet.status == 0 || packet.status == 1">
                              <button class="flex items-center" @click="getPacketDetails(packet.number)"><ChevronsRightIcon class="h-6 w-6 text-primary-4" /></button>
                            </div>
                            <div class="flex justify-center items-center" v-if="packet.status == 2">
                              <button class="flex items-center" @click="getPacketDetails(packet.number)"><ChevronsRightIcon class="h-6 w-6 text-primary-4" /></button>
                              <div class="text-primary-7">Hold</div>
                            </div>
                            <div class="flex justify-center items-center" v-if="packet.status == 3">
                              <button class="flex items-center" @click="getPacketDetails(packet.number)"><ChevronsRightIcon class="h-6 w-6 text-primary-4" /></button>
                              <div><CheckIcon class="h-4 w-4 text-primary-4" /></div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              <!-- </div> -->
            </div>
            <!-- END: Vertical Form -->
          </div>
        </div>
      </div>
    </div>
    <!-- END: Transections Confirmation Modal -->
  </div>
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import router from '@/router'
import store from '@/store'
import axios from 'axios'
import cash from 'cash-dom'
import Toastify from 'toastify-js'
import { computed, defineComponent, onMounted, reactive } from 'vue'

export default defineComponent({
  setup() {
    const pagedata = reactive({
      allshow: false,
      loading: true,
      packets: [],
      error: '',
      success: '',
      histories: [],
      transferid: '',
      discuss: 'admin',
      description: ''
    })
    const user = computed(() => store.state.user.data)
    onMounted(() => {
      getPackets()
    })
    function getPackets() {
      pagedata.loading = true
      axios({
        url: '/discuss/manager-discuss',
        method: 'get',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.loading = false
        pagedata.packets = res.data.packets
      }).catch((err) => {
        pagedata.loading = false
        pagedata.error = err.response.data.error
        setTimeout(() => {
          basicNonStickyNotificationToggle()
        }, 100);
        setTimeout(() => {
          pagedata.error = ''
        }, 4000);
      })
    }
    function acceptPacket(id) {
      axios({
        url: `/discuss/accept-packet/${id}`,
        method: 'get',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.success = res.data.success
        getPackets()
        setTimeout(() => {
          basicNonStickyNotificationToggle()
        }, 100);
        setTimeout(() => {
          pagedata.success = ''
        }, 4000);
      }).catch((err) => {
        pagedata.error = err.response.data.error
        setTimeout(() => {
          basicNonStickyNotificationToggle()
        }, 100);
        setTimeout(() => {
          pagedata.error = ''
        }, 4000);
      })
    }
    function getPacketDetails(id) {
      router.push({ name: 'discuss-packet-data', params: { id: id}})
      cash('#discuss-transections').modal('hide')
    }
    function transferPacket() {
      axios({
        url: `/discuss/transfer-packet/${pagedata.transferid}?to=${pagedata.discuss}`,
        method: 'post',
        baseURL: BASE_URL,
        data: { description: pagedata.description },
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.success = res.data.success
        getPackets()
        cash('#discuss-transections').modal('hide')
        cash('#return-confirmation-modal').modal('hide')
        setTimeout(() => {
          basicNonStickyNotificationToggle()
        }, 100);
        setTimeout(() => {
          pagedata.success = ''
        }, 4000);
      }).catch((err) => {
        pagedata.error = err.response.data.error
        setTimeout(() => {
          basicNonStickyNotificationToggle()
        }, 100);
        setTimeout(() => {
          pagedata.error = ''
        }, 4000);
      })
    }
    function getTransectionDetails(id) {
      axios({
        url: `/discuss/transfer-history-packet/${id}`,
        method: 'get',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.histories = res.data.history
        cash('#discuss-transections').modal('show')
      }).catch((err) => {
        pagedata.error = err.response.data.error
        setTimeout(() => {
          basicNonStickyNotificationToggle()
        }, 100);
        setTimeout(() => {
          pagedata.error = ''
        }, 4000);
      })
    }
    const basicNonStickyNotificationToggle = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification-content')
          .clone()
          .removeClass('hidden')[0],
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    return {
      pagedata,
      user,
      basicNonStickyNotificationToggle,
      acceptPacket,
      getPacketDetails,
      transferPacket,
      getTransectionDetails
    }
  }
})
</script>

<template>
  <div>
    <div class="pos intro-y grid">
      <h2 class="text-lg font-medium mr-auto">Party Stock Report</h2>
      <!-- BEGIN: Item List -->
      <!-- <div class="intro-y col-span-12">
        <div class="grid grid-cols-12 gap-3 mt-1">
          <div class="col-span-4 sm:col-span-2 box text-center cursor-pointer zoom-in p-1">
              <div>
                <div class="text-xs whitespace-nowrap overflow-hidden">Admin</div>
                <div class="text-md">
                  <span class="text-md mr-2 px-2 rounded-lg text-white bg-primary-1">{{ pagedata.admin_total_packets }}</span>
                </div>
              </div>
          </div>
          <div class="col-span-4 sm:col-span-2 box text-center cursor-pointer zoom-in p-1" @click="getManagerPkt">
              <div>
                <div class="text-xs whitespace-nowrap overflow-hidden">Manager</div>
                <div class="text-md">
                  <span class="text-md mr-2 px-2 rounded-lg text-white bg-primary-4">{{ pagedata.manager_total_packets }}</span>
                </div>
              </div>
          </div>
          <div class="col-span-4 sm:col-span-2 box text-center cursor-pointer zoom-in p-1">
              <div>
                <div class="text-xs whitespace-nowrap overflow-hidden">External</div>
                <div class="text-md">
                  <span class="text-md mr-2 px-2 rounded-lg text-white bg-primary-3">{{ pagedata.external_packets }}</span>
                </div>
              </div>
          </div>
          <div class="col-span-4 sm:col-span-2 box text-center cursor-pointer zoom-in p-1">
              <div>
                <div class="text-xs whitespace-nowrap overflow-hidden">Admin Discuss</div>
                <div class="text-md">
                  <span class="text-md mr-2 px-2 rounded-lg text-white bg-primary-9">{{ pagedata.admin_discuss_packets }}</span>
                </div>
              </div>
          </div>
          <div class="col-span-4 sm:col-span-2 box text-center cursor-pointer zoom-in p-1">
              <div>
                <div class="text-xs whitespace-nowrap overflow-hidden">Party Discuss</div>
                <div class="text-md">
                  <span class="text-md mr-2 px-2 rounded-lg text-white bg-primary-6">{{ pagedata.party_discuss_packets }}</span>
                </div>
              </div>
          </div>
        </div>
      </div> -->
      <!-- END: Item List -->
    </div>
    <!-- BEgin: Process Report Start-->
    <div class="intro-y box p-5 mt-5">
      <div class="overflow-x-auto scrollbar-hidden">
        <div class="intro-y col-span-12 overflow-auto">
          <table class="mt-2 w-full border-2">
            <thead>
              <tr class="bg-theme-1 text-white">
                <th class="py-2 text-xs whitespace-nowrap">#</th>
                <th class="py-2 text-xs whitespace-nowrap">Party</th>
                <th class="py-2 text-xs whitespace-nowrap">Packets</th>
                <th class="py-2 text-xs whitespace-nowrap">Weight</th>
              </tr>
            </thead>
            <tbody>
              <tr class="bg-gray-100">
                <td class="py-2 border-b border-r text-xs text-center"></td>
                <td class="py-2 border-b border-r text-xs text-center"></td>
                <td class="py-2 border-b border-r text-xs text-center">{{ pagedata.total.total_packets ? pagedata.total.total_packets : '-' }}</td>
                <td class="py-2 border-b border-r text-xs text-center">{{ pagedata.total.issue_weight ? $h.myFloat(pagedata.total.issue_weight) : '-' }}</td>
              </tr>
            </tbody>
            <tbody v-for="(packet, index) in pagedata.packets" :key="index" @click="hidePacketDetails(index)" class="border-b hover:bg-gray-200 p-0 cursor-pointer">
              <tr class="bg-gray-200">
                <td class="py-2 border-b border-r text-xs text-center">{{ index + 1 }}</td>
                <td class="py-2 border-b border-r text-xs text-center">{{ (packet.first_name ? packet.first_name : '-') + ' ' + (packet.last_name ? packet.last_name : '') }} ({{ packet.firm_name ? packet.firm_name : '' }})</td>
                <td class="py-2 border-b border-r text-xs text-center">{{ packet.total_packets ? packet.total_packets : '-' }}</td>
                <td class="py-2 border-b border-r text-xs text-center">{{ packet.issue_weight ? $h.myFloat(packet.issue_weight) : '-' }}</td>
              </tr>
              <tr v-if="pagedata.allshow[index] == true && packet.children.length > 0">
                <td class="py-2 border-b border-r text-xs text-center"></td>
                <td class="py-2 border-b border-r text-xs text-center" colspan="3">
                  <table class="w-full border-2">
                    <thead>
                        <tr class="bg-theme-3 text-white">
                          <th class="py-2 text-xs whitespace-nowrap border-r">#</th>
                          <th class="py-2 text-xs whitespace-nowrap border-r">Manager</th>
                          <th class="py-2 text-xs whitespace-nowrap border-r">Packets</th>
                          <th class="py-2 text-xs whitespace-nowrap border-r">Weight</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, ind) in packet.children" :key="ind">
                          <td class="py-2 text-xs whitespace-nowrap text-center border">{{ ind + 1 }}</td>
                          <td class="py-2 text-xs whitespace-nowrap text-center border">{{ item.first_name + ' ' + item.last_name }}</td>
                          <td class="py-2 text-xs whitespace-nowrap text-center border">{{ item.total_packets ? item.total_packets : '-' }}</td>
                          <td class="py-2 text-xs whitespace-nowrap text-center border">{{ item.issue_weight ? $h.myFloat(item.issue_weight) : '-' }}</td>
                        </tr>
                      </tbody>
                  </table>
                </td>
              </tr>
              <!-- <tr class="bg-theme-3 text-white" v-if="pagedata.allshoe[index] == true && packet.children.length > 0">
                <th class="py-2 text-xs whitespace-nowrap">#</th>
                <th class="py-2 text-xs whitespace-nowrap">Manager</th>
                <th class="py-2 text-xs whitespace-nowrap">Packets</th>
                <th class="py-2 text-xs whitespace-nowrap">Weight</th>
              </tr>
              <tr class="bg-theme-3 text-white" v-for="(packet, index) in pagedata.packets" :key="index" v-if="pagedata.allshoe[index] == true && packet.children.length > 0">
                <th class="py-2 text-xs whitespace-nowrap">#</th>
                <th class="py-2 text-xs whitespace-nowrap">Manager</th>
                <th class="py-2 text-xs whitespace-nowrap">Packets</th>
                <th class="py-2 text-xs whitespace-nowrap">Weight</th>
              </tr> -->
            </tbody>
            <tbody>
              <tr class="bg-gray-100">
                <td class="py-2 border-b border-r text-xs text-center"></td>
                <td class="py-2 border-b border-r text-xs text-center"></td>
                <td class="py-2 border-b border-r text-xs text-center">{{ pagedata.total.total_packets ? pagedata.total.total_packets : '-' }}</td>
                <td class="py-2 border-b border-r text-xs text-center">{{ pagedata.total.issue_weight ? $h.myFloat(pagedata.total.issue_weight) : '-' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config';
import axios from 'axios';
import cash from 'cash-dom';
import { defineComponent, onMounted, reactive, toRef, watch } from 'vue';

export default defineComponent({
  setup() {
    const pagedata = reactive({
      packets: [],
      total: [],
      allshow: []
    })
    onMounted(() => {
      getProcessReportDetails()
    })
    function getProcessReportDetails() {
       axios({
        url: `api/report/get_party_stock_report`,
        method: 'post',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.packets = res.data.items
        pagedata.total = res.data.total

        for(let i = 0; i < pagedata.packets.length; i++) {
          pagedata.allshow[i] = false
        }
      })
    }
    function hidePacketDetails(index) {
      pagedata.allshow[index] = !pagedata.allshow[index]
    }
    return {
      pagedata,
      hidePacketDetails
    }
  }
})
</script>

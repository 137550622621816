<template>
  <div id="basic-non-sticky-notification-packet-error" class="toastify-content hidden flex flex-col sm:flex-row">
        <div class="flex items-center justify-around">
          <CheckCircleIcon class="w-6 h-6 text-theme-6 mx-auto" />
          <div class="font-medium text-black" v-if="pagedata.error">{{ pagedata.error }}</div>
        </div>
  </div>
  <div id="basic-non-sticky-notification-packet-receive" class="toastify-content hidden flex flex-col sm:flex-row">
      <div class="flex items-center justify-around">
        <CheckCircleIcon class="w-6 h-6 text-theme-9 mx-auto" />
        <div class="font-medium text-black" v-if="pagedata.success.success">{{ pagedata.success.success }}</div>
      </div>
  </div>
  <div id="basic-non-sticky-notification-packet-update" class="toastify-content hidden flex flex-col sm:flex-row">
      <div class="flex items-center justify-around">
        <div class="font-medium text-black" v-if="pagedata.success.success">{{ pagedata.success.success }}</div>
      </div>
  </div>
  <div class="my-8">
    <div class="xl:grid block xl:grid-cols-2 gap-4">
      <div class="flex items-end mt-10 xl:mt-0" v-if="newParams.status == 0">
        <div class="mx-5" v-if="newParams.t_id != ''">
          <h2 class="intro-y text-lg font-medium">Transection Id : {{ newParams.t_id }}</h2>
          <table class="w-48">
            <tbody>
              <tr>
                <th class="py-2 font-bold border-2 text-md px-2 text-left">Shape</th>
                <th class="py-2 font-bold border-2 text-md px-2 text-left">Color</th>
                <th class="py-2 font-bold border-2 text-md px-2 text-left">Purity</th>
                <th class="py-2 font-bold border-2 text-md px-2 text-left">Cut</th>
                <th class="py-2 font-bold border-2 text-md px-2 text-left">Polish</th>
                <th class="py-2 font-bold border-2 text-md px-2 text-left">Symmetry</th>
                <th class="py-2 font-bold border-2 text-md px-2 text-left">Fluec</th>
                <th class="py-2 font-bold border-2 text-md px-2 text-left">Tension</th>
                <th class="py-2 font-bold border-2 text-md px-2 text-left">Stone</th>
                <th class="py-2 font-bold border-2 text-md px-2 text-left whitespace-nowrap">Issue Wt</th>
                <th class="py-2 font-bold border-2 text-md px-2 text-left">Receive Weight</th>
                <th class="py-2 font-bold border-2 text-md px-2 text-left whitespace-nowrap">Oriznal Wt</th>
                <th class="py-2 font-bold border-2 text-md px-2 text-left whitespace-nowrap">Polish Wt</th>
              </tr>
              <tr>
                <td class="font-bold text-md border-2 text-center"><span :class="{ 'text-primary-3': newParams.shape != lastpkt.shape }">{{ newParams.shape }}</span> / {{ lastpkt.shape }}</td>
                <td class="font-bold text-md border-2 text-center"><span :class="{ 'text-primary-3': newParams.color != lastpkt.color }">{{ newParams.color }}</span> /{{ lastpkt.color }}</td>
                <td class="font-bold text-md border-2 text-center"><span :class="{ 'text-primary-3': newParams.purity != lastpkt.purity }">{{ newParams.purity }}</span> / {{ lastpkt.purity }}</td>
                <td class="font-bold text-md border-2 text-center"><span :class="{ 'text-primary-3': newParams.cut != lastpkt.cut }">{{ newParams.cut }}</span> / {{ lastpkt.cut }}</td>
                <td class="font-bold text-md border-2 text-center"><span :class="{ 'text-primary-3': newParams.polish != lastpkt.polish }">{{ newParams.polish }}</span> / {{ lastpkt.polish }}</td>
                <td class="font-bold text-md border-2 text-center"><span :class="{ 'text-primary-3': newParams.symmetry != lastpkt.symmetry }">{{ newParams.symmetry }}</span> / {{ lastpkt.symmetry }}</td>
                <td class="font-bold text-md border-2 text-center"><span :class="{ 'text-primary-3': newParams.fluerocent != lastpkt.fluerocent }">{{ newParams.fluerocent }}</span> / {{ lastpkt.fluerocent }}</td>
                <td class="font-bold text-md border-2 text-center"><span :class="{ 'text-primary-3': newParams.tension != lastpkt.tension }">{{ newParams.tension }}</span> / {{ lastpkt.tension }}</td>
                <td class="font-bold text-md border-2 text-center"><span :class="{ 'text-primary-3': newParams.stone != lastpkt.stone }">{{ newParams.stone }}</span> / {{ lastpkt.stone }}</td>
                <td class="font-bold text-md border-2 text-center">{{ lastpkt.issue_weight }}</td>
                <td class="font-bold text-md border-2 text-center" ><span :class="{ 'text-primary-3': newParams.weight < newParams.admin_pwt > 0 ? newParams.admin_pwt : newParams.pwt }">{{ newParams.weight }}</span></td>
                <td class="font-bold text-md border-2 text-center">{{ newParams.oriznal_wt }}</td>
                <td class="font-bold text-md border-2 text-center">{{ newParams.admin_pwt > 0 ? newParams.admin_pwt : newParams.pwt  }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="mx-5" v-else>
        <h2 class="intro-y text-lg font-medium">Packet No : {{ paketDet.number }}</h2>
        <table class="w-48">
          <tbody>
            <tr>
              <th class="py-2 font-bold border-2 text-md px-1 text-left">Shape</th>
              <th class="py-2 font-bold border-2 text-md px-1 text-left">Color</th>
              <th class="py-2 font-bold border-2 text-md px-1 text-left">Purity</th>
              <th class="py-2 font-bold border-2 text-md px-1 text-left">Cut</th>
              <th class="py-2 font-bold border-2 text-md px-1 text-left">Polish</th>
              <th class="py-2 font-bold border-2 text-md px-1 text-left">Symmetry</th>
              <th class="py-2 font-bold border-2 text-md px-1 text-left">Tension</th>
              <th class="py-2 font-bold border-2 text-md px-1 text-left whitespace-nowrap">Issue Wt</th>
              <th class="py-2 font-bold border-2 text-md px-1 text-left whitespace-nowrap">Oriznal Wt</th>
            </tr>
            <tr>
              <td class="font-bold text-md border-2 text-center">{{ paketDet.shape.name }}</td>
              <td class="font-bold text-md border-2 text-center">{{ paketDet.color.name }}</td>
              <td class="font-bold text-md border-2 text-center">{{ paketDet.purity.name }}</td>
              <td class="font-bold text-md border-2 text-center">{{ paketDet.cut.name }}</td>
              <td class="font-bold text-md border-2 text-center">{{ paketDet.polish.name }}</td>
              <td class="font-bold text-md border-2 text-center">{{ paketDet.symmetry.name }}</td>
              <td class="font-bold text-md border-2 text-center">{{ paketDet.tension.name }}</td>
              <td class="font-bold text-md border-2 text-center">{{ paketDet.issueWt }}</td>
              <td class="font-bold text-md border-2 text-center">{{ paketDet.oriznal_wt }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto mt-6" v-if="packets.length > 0">
          <table class="table-report -mt-2">
            <thead>
              <tr>
                <th class=" lg:px-2 px-1 whitespace-nowrap">#</th>
                <th class=" lg:px-2 px-1 whitespace-nowrap">T No</th>
                <th class=" lg:px-2 px-1 whitespace-nowrap">Proc.</th>
                <th class=" lg:px-2 px-1 whitespace-nowrap">Dept.</th>
                <th class=" lg:px-2 px-1 whitespace-nowrap">Mgr.</th>
                <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Emp.</th>
                <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Issue Wt.</th>
                <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Rec. Wt.</th>
                <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Issue Time</th>
                <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Rec. Time</th>
                <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Shape</th>
                <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Color</th>
                <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Purity</th>
                <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Cut</th>
                <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Polish</th>
                <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Symm</th>
                <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Flurc</th>
                <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Tension</th>
                <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Stone</th>
                <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Action</th>
              </tr>
            </thead>
            <tbody>
              <TableList v-for="(packet, index) in packets"
                :key="index" :index="index" :packet="packet" :packetDet="paketDet" :newParams="newParams" :diamond="diamond" @updatParameters="updatParameters" @getData="getPaketDetail" @showNotification="showNotification"/>
            </tbody>
          </table>
      </div>
      <div v-else class="intro-y col-span-12 overflow-auto mt-5 text-center">No Packet Found</div>
      <!-- END: Data List -->
    </div>
  </div>
  <!-- BEGIN: Parameter Change Packet Process -->
  <div
    id="middle-parameter-confirmation-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body p-3">
          <div class="p-5 text-center">
            <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
            <div class="text-3xl mt-5">Are you sure?</div>
            <div class="text-gray-600 mt-2">
              Do you really want to Send Request?
            </div>
          </div>
          <div class="px-5 pb-8 text-center">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-24 mr-1"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-danger w-24" :disabled="pagedata.submit" @click="sendRequestMdlParam">Send
            <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Parameter Change Packet Process -->
  <!-- BEGIN: Receive Packet Modal -->
    <div
      id="edit-confirmation-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <a data-dismiss="modal" href="javascript:;">
              <XIcon class="w-8 h-8 text-gray-500" />
          </a>
          <div class="modal-body p-10 text-center">
            <!-- BEGIN: Vertical Form -->
            <div class="intro-y box">
              <div id="vertical-form" class="p-5">
                <div class="text-lg font-medium mx-3">Accept Request</div>
                  <div class="mt-3" v-if="pagedata.weight_issue">
                    <div class="input-group">
                      <div id="input-group-3" class="input-group-text w-22">New PWT</div>
                      <input
                        type="number"
                        class="form-control w-40"
                        v-model="pagedata.newpwt"
                        placeholder="New Polish Weight"
                        aria-describedby="input-group-3"
                      />
                    </div>
                    <span v-if="errors.newpwt" class="text-primary-3">{{ errors.newpwt[0] }}</span>
                  </div>
                  <div class="mt-3">
                    <div class="text-lg font-medium text-left m-3">Panalty</div>
                    <div class="flex items-center mt-3">
                      <div class="form-check">
                        <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer" value="0" type="radio" name="flexRadioDefault" id="flexRadioDefault1" v-model="pagedata.panalty">
                        <label class="form-check-label inline-block text-black text-md" for="flexRadioDefault1">
                          None
                        </label>
                      </div>
                      <div class="form-check ml-6">
                        <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer" value="1" type="radio" name="flexRadioDefault" id="flexRadioDefault2" v-model="pagedata.panalty">
                        <label class="form-check-label inline-block text-black text-md" for="flexRadioDefault2">
                          Fixed
                        </label>
                      </div>
                      <div class="form-check ml-6">
                        <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer" value="2" type="radio" name="flexRadioDefault" id="flexRadioDefault2" v-model="pagedata.panalty">
                        <label class="form-check-label inline-block text-black text-md" for="flexRadioDefault2">
                          Percent
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="mt-3" v-if="pagedata.panalty == 1 || pagedata.panalty == 2">
                    <div class="sm:grid grid-cols-2 gap-2">
                      <div>
                        <div class="input-group">
                          <div id="input-group-3" class="input-group-text">Manager</div>
                          <div>
                              <input
                                type="text"
                                class="form-control"
                                v-model="pagedata.manager_panalty"
                                placeholder="Panalty"
                                aria-describedby="input-group-3"
                              />
                          </div>
                        </div>
                        <div class="text-primary-3" v-if="pagedata.errors.manager_panalty">{{ pagedata.errors.manager_panalty[0] }}</div>
                      </div>
                      <div>
                        <div class="input-group mt-2 sm:mt-0">
                          <div id="input-group-4" class="input-group-text">Employee</div>
                          <div>
                              <input
                                type="text"
                                class="form-control"
                                v-model="pagedata.employee_panalty"
                                placeholder="Panalty"
                                aria-describedby="input-group-4"
                              />
                          </div>
                        </div>
                        <div class="text-primary-3" v-if="pagedata.errors.employee_panalty">{{ pagedata.errors.employee_panalty[0] }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-3">
                    <div class="form-label w-full text-left">Remark</div>
                    <textarea
                      id="regular-form-4"
                      type="password"
                      class="form-control"
                      v-model="pagedata.description"
                      placeholder="Remark"
                    ></textarea>
                  </div>
                  <div class="text-right mt-5">
                    <button
                      type="button"
                      data-dismiss="modal"
                      class="btn btn-outline-secondary w-24 mr-1"
                    >
                      Cancel
                    </button>
                    <button type="button" class="btn btn-primary w-24" :disabled="pagedata.submit" @click="updateParams">Accept
                    <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" /></button>
                  </div>
              </div>
            </div>
          <!-- END: Vertical Form -->
          </div>
        </div>
      </div>
    </div>
    <!-- END: Receive Packet Modal -->
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import axios from 'axios'
import cash from 'cash-dom'
import { useRoute } from 'vue-router'
import { defineComponent, reactive, toRef, onMounted, watch } from 'vue'
import print from 'vue3-print-nb'
import Toastify from 'toastify-js'
import TableList from '@/views/AdminNotification/tableList.vue'
export default defineComponent({
  directives: {
    print
  },
  components:{
    TableList
  },
  setup() {
    const route = useRoute()
    const pagedata = reactive({
      packets: [],
      search: route.params.id,
      check: false,
      waiting: false,
      submit: false,
      errors: [],
      success: [],
      error: '',
      changes: '',
      newParams: '',
      lastpkt: '',
      panalty: 0,
      manager_panalty: '',
      employee_panalty: '',
      newpwt: '',
      description: [],
      weight_issue: false
    })
    const diamond = reactive({
      colors: [],
      cuts: [],
      purities: [],
      symmetry: [],
      polish: [],
      fluerocents: [],
      tensions: [],
      stones: [],
      shapes: [],
    })
    const packets = toRef(pagedata,'packets')
    const newParams = toRef(pagedata,'newParams')
    const lastpkt = toRef(pagedata,'lastpkt')
    const errors = toRef(pagedata,'errors')
    const success = toRef(pagedata,'success')
    const paketDet = reactive({
      id: '',
      number: '',
      color: '',
      cut: '',
      purity: '',
      polish: '',
      shape: '',
      symmetry: '',
      fluerocent: '',
      stone: '',
      tension: '',
      issueWt: '',
      pwt: '',
      admin_pwt: '',
      inprocess: 1,
      oriznal_wt: '',
      blocked: ''
    })
    onMounted(() => {
      setTimeout(() => {
        getDiamontData()
      }, 300);
        getParams()
      setTimeout(() => {
        getPaketDetail()
      }, 200);
    })
    function getParams() {
        axios({
          url: `/packet/single-mdl-param-cancel-requests/${route.params.id}`,
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.newParams = res.data.params
          pagedata.lastpkt = res.data.packet
        })
    }
    function getPaketDetail() {
        axios({
          url: `/process/admin-party-packet/${pagedata.search}`,
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.packets = res.data.packets
          paketDet.id = res.data.paket.id
          paketDet.number = res.data.paket.number
          paketDet.inprocess = res.data.paket.inProcess
          paketDet.color = res.data.paket.color
          paketDet.cut = res.data.paket.cut
          paketDet.purity = res.data.paket.purity
          paketDet.polish = res.data.paket.polish
          paketDet.shape = res.data.paket.shape
          paketDet.symmetry = res.data.paket.symmetry
          paketDet.fluerocent = res.data.paket.fluerocent
          paketDet.stone = res.data.paket.stone
          paketDet.tension = res.data.paket.tension
          paketDet.issueWt = res.data.paket.weight
          paketDet.pwt = res.data.paket.pwt
          paketDet.admin_pwt = res.data.paket.admin_pwt
          paketDet.oriznal_wt = res.data.paket.oriznal_wt
          paketDet.blocked = res.data.paket.blocked
        })
    }
    function getDiamontData() {
      axios({
        url: '/GetAll',
        method: 'get',
        baseURL: BASE_URL,
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        diamond.colors = res.data.diamonds.colors
        diamond.cuts = res.data.diamonds.cut
        diamond.purities = res.data.diamonds.purity
        diamond.symmetry = res.data.diamonds.symmetry
        diamond.polish = res.data.diamonds.polish
        diamond.fluerocents = res.data.diamonds.fluerocent
        diamond.tensions = res.data.diamonds.tension
        diamond.shapes = res.data.diamonds.shape
        diamond.stones = res.data.diamonds.stones
      })
    }
    function updatParameters(changes) {
      cash('#edit-confirmation-modal').modal('show')
      pagedata.changes = changes
      if(newParams.value.admin_pwt > 0) {
        if(changes.weight < newParams.value.admin_pwt) {
          pagedata.weight_issue = true
          pagedata.newpwt = changes.weight
        } else {
          pagedata.weight_issue = false
        }
      } else {
        if(changes.weight < newParams.value.pwt) {
          pagedata.weight_issue = true
          pagedata.newpwt = changes.weight
        } else {
          pagedata.weight_issue = false
        }
      }
    }
    function showNotification(message) {
      pagedata.success.success = message
      setTimeout(() => {
        basicNonStickyNotificationPacketUpdate()
      }, 200);
      setTimeout(() => {
        pagedata.success.success = ''
      }, 4000);
    }
    function updateParams() {
      pagedata.submit = true
      axios({
        url: `/process/update-accept-middle-proc-request/${newParams.value.id}`,
        method: 'post',
        baseURL: BASE_URL,
        data: {changes_color: pagedata.changes.color,changes_id: pagedata.changes.id,changes_weight: pagedata.changes.weight,
        changes_cut: pagedata.changes.cut,changes_purity: pagedata.changes.purity,
        changes_polish: pagedata.changes.polish,changes_shape: pagedata.changes.shape,
        changes_symmetry: pagedata.changes.symmetry,changes_stone: pagedata.changes.stone,
        changes_tension: pagedata.changes.tension,changes_fluerocent: pagedata.changes.fluerocent,penalty: pagedata.panalty,
         manager_panalty: pagedata.manager_panalty, employee_panalty:pagedata.employee_panalty, newpwt: pagedata.newpwt},
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      }).then((res) => {
        pagedata.submit = false
        pagedata.success.success = res.data.success
         cash('#edit-confirmation-modal').modal('hide')
        setTimeout(() => {
          cash('#success-modal-preview').modal('show')
        }, 100);
        getPaketDetail()
        getParams()
        setTimeout(() => {
          cash('#success-modal-preview').modal('hide')
          pagedata.success.success = ''
        }, 4000);
      }).catch((err) => {
        pagedata.submit = false
        pagedata.error = err.response.data.error
        setTimeout(() => {
          basicNonStickyNotificationPacketError()
        }, 100);
        setTimeout(() => {
          pagedata.error = ''
        }, 4000);
      })
    }
    const basicNonStickyNotificationPacketReceive = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification-packet-receive')
          .clone()
          .removeClass('hidden')[0],
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    const basicNonStickyNotificationPacketError = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification-packet-error')
          .clone()
          .removeClass('hidden')[0],
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    const basicNonStickyNotificationPacketUpdate = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification-packet-update')
          .clone()
          .removeClass('hidden')[0],
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    watch(() => pagedata.panalty, (currentValue,oldValue) => {
      pagedata.manager_panalty = ''
      pagedata.employee_panalty = ''
    })
    return {
      pagedata,
      packets,
      errors,
      paketDet,
      lastpkt,
      diamond,
      success,
      newParams,
      basicNonStickyNotificationPacketError,
      basicNonStickyNotificationPacketReceive,
      basicNonStickyNotificationPacketUpdate,
      getPaketDetail,
      updatParameters,
      showNotification,
      updateParams
    }
  }
})
</script>

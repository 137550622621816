<template>
  <div>
    <div id="basic-non-sticky-notification-packet" class="toastify-content hidden flex flex-col sm:flex-row">
      <div class="font-medium text-primary-3" v-if="pagedata.error">{{ pagedata.error }}</div>
      <div class="font-medium text-primary-4" v-if="pagedata.success">{{ pagedata.success }}</div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex items-center justify-between">
        <h2 class="text-lg font-bold">Plan Packet : {{ pagedata.plan.number }}</h2>
      </div>
      <table class="w-full mt-2">
        <thead>
          <tr class="bg-gray-700 dark:bg-dark-1 text-white">
            <th class="whitespace-nowrap py-1">#</th>
            <th class="whitespace-nowrap py-1">Number</th>
            <th class="whitespace-nowrap py-1">PWT</th>
            <th class="whitespace-nowrap py-1">Shape</th>
            <th class="whitespace-nowrap py-1">Color</th>
            <th class="whitespace-nowrap py-1">Purity</th>
            <th class="whitespace-nowrap py-1">Cut</th>
            <th class="whitespace-nowrap py-1">Polish</th>
            <th class="whitespace-nowrap py-1">Symm</th>
            <th class="whitespace-nowrap py-1">Fluc</th>
            <th class="whitespace-nowrap py-1">Tension</th>
            <th class="whitespace-nowrap py-1">Stone</th>
            <th class="whitespace-nowrap py-1">R Rate</th>
            <th class="whitespace-nowrap py-1">S Rate</th>
            <th class="whitespace-nowrap py-1">Tabl</th>
            <th class="whitespace-nowrap py-1">Height</th>
            <th class="whitespace-nowrap py-1">Discount</th>
            <th class="whitespace-nowrap py-1">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(plandet, i) in pagedata.plan_details" :class="{'text-primary-3' : plandet.status == 2}"
            :key="i">
            <td class="border-b dark:border-dark-5 text-center py-1">{{ i + 1 }}</td>
            <td class="border-b dark:border-dark-5 text-center py-1">{{ plandet.number }}</td>
            <td class="border-b dark:border-dark-5 text-center py-1">{{ plandet.pwt }}</td>
            <td class="border-b dark:border-dark-5 text-center py-1">{{ plandet.shape_name }}</td>
            <td class="border-b dark:border-dark-5 text-center py-1">{{ plandet.color_name }}</td>
            <td class="border-b dark:border-dark-5 text-center py-1">{{ plandet.purity_name }}</td>
            <td class="border-b dark:border-dark-5 text-center py-1">{{ plandet.cut_name }}</td>
            <td class="border-b dark:border-dark-5 text-center py-1">{{ plandet.polish_name }}</td>
            <td class="border-b dark:border-dark-5 text-center py-1">{{ plandet.symmetry_name }}</td>
            <td class="border-b dark:border-dark-5 text-center py-1">{{ plandet.fluerocent_name }}</td>
            <td class="border-b dark:border-dark-5 text-center py-1">{{ plandet.tension_name }}</td>
            <td class="border-b dark:border-dark-5 text-center py-1">{{ plandet.stone_name }}</td>
            <td class="border-b dark:border-dark-5 text-center py-1">{{ plandet.r_rate }}</td>
            <td class="border-b dark:border-dark-5 text-center py-1">{{ plandet.s_rate }}</td>
            <td class="border-b dark:border-dark-5 text-center py-1">{{ plandet.tabl }}</td>
            <td class="border-b dark:border-dark-5 text-center py-1">{{ plandet.height }}</td>
            <td class="border-b dark:border-dark-5 text-center py-1">{{ plandet.discount }}</td>
            <td class="border-b dark:border-dark-5 text-center py-1">
              <div v-if="plandet.status == 0">
                <AlertCircleIcon class="h-6 w-6 text-primary-6" />
              </div>
              <div v-else-if="plandet.status == 1">
                <CheckIcon class="h-6 w-6 text-primary-4" />
              </div>
              <div v-else-if="plandet.status == 2">
                <a class="btn btn-danger p-1"
                  href="javascript:;"
                  data-toggle="modal"
                  data-target="#active-confirmation-modal" @click="pagedata.delete_id = plandet.id">
                  <XIcon class="h-4 w-4 text-white" />
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- END: HTML Table Data -->
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex items-center justify-between">
        <h2 class="text-lg font-bold">LS Receive</h2>
        <div>
          <button class="btn btn-dark px-3 py-1 mr-2" v-if="pagedata.new_plan" @click="createNewPacket">New Packet</button>
          <button :disabled="!pagedata.done_status || pagedata.rate_status == 0" @click="doneModalShow" class="btn btn-danger px-3 py-1">Done</button>
        </div>
      </div>
      <table class="w-full mt-2">
        <thead>
          <tr class="bg-gray-700 dark:bg-dark-1 text-white">
            <th class="whitespace-nowrap py-1">#</th>
            <th class="whitespace-nowrap py-1">Number</th>
            <th class="whitespace-nowrap py-1">Weight</th>
            <th class="whitespace-nowrap py-1">PWT</th>
            <th class="whitespace-nowrap py-1">Shape</th>
            <th class="whitespace-nowrap py-1">Color</th>
            <th class="whitespace-nowrap py-1">Purity</th>
            <th class="whitespace-nowrap py-1">Cut</th>
            <th class="whitespace-nowrap py-1">Polish</th>
            <th class="whitespace-nowrap py-1">Symm</th>
            <th class="whitespace-nowrap py-1">Fluc</th>
            <th class="whitespace-nowrap py-1">Tension</th>
            <th class="whitespace-nowrap py-1">Stone</th>
            <th class="whitespace-nowrap py-1">R Rate</th>
            <th class="whitespace-nowrap py-1">S Rate</th>
            <th class="whitespace-nowrap py-1">Tabl</th>
            <th class="whitespace-nowrap py-1">Height</th>
            <th class="whitespace-nowrap py-1">Discount</th>
            <th class="whitespace-nowrap py-1">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(plandet, i) in pagedata.lsbs_details"
            :key="i">
            <td class="border-b dark:border-dark-5 text-center py-1">{{ i + 1 }}</td>
            <td class="border-b dark:border-dark-5 text-center py-1">{{ plandet.number }}</td>
            <td class="border-b dark:border-dark-5 text-center py-1">{{ plandet.weight }}</td>
            <td class="border-b dark:border-dark-5 text-center py-1">{{ plandet.pwt }}</td>
            <td class="border-b dark:border-dark-5 text-center py-1">{{ plandet.shape_name }}</td>
            <td class="border-b dark:border-dark-5 text-center py-1">{{ plandet.color_name }}</td>
            <td class="border-b dark:border-dark-5 text-center py-1">{{ plandet.purity_name }}</td>
            <td class="border-b dark:border-dark-5 text-center py-1">{{ plandet.cut_name }}</td>
            <td class="border-b dark:border-dark-5 text-center py-1">{{ plandet.polish_name }}</td>
            <td class="border-b dark:border-dark-5 text-center py-1">{{ plandet.symmetry_name }}</td>
            <td class="border-b dark:border-dark-5 text-center py-1">{{ plandet.fluerocent_name }}</td>
            <td class="border-b dark:border-dark-5 text-center py-1">{{ plandet.tension_name }}</td>
            <td class="border-b dark:border-dark-5 text-center py-1">{{ plandet.stone_name }}</td>
            <td class="border-b dark:border-dark-5 text-center py-1">{{ plandet.r_rate }}</td>
            <td class="border-b dark:border-dark-5 text-center py-1">{{ plandet.s_rate }}</td>
            <td class="border-b dark:border-dark-5 text-center py-1">{{ plandet.tabl }}</td>
            <td class="border-b dark:border-dark-5 text-center py-1">{{ plandet.height }}</td>
            <td class="border-b dark:border-dark-5 text-center py-1">{{ plandet.discount }}</td>
            <td class="border-b dark:border-dark-5 text-center py-1">
              <div class="flex justify-center items-center" v-if="plandet.packet">
                <button class="flex items-center mr-3" @click="updatePacketDetails(i)">
                  <CheckSquareIcon class="w-4 h-4 mr-1" /></button>
              </div>
              <div class="flex justify-center items-center" v-else>
                <button class="flex items-center mr-3" @click="updatePacketDetails(i)">
                  <CheckSquareIcon class="w-4 h-4 mr-1" /></button>
                <a
                  class="flex items-center text-theme-6"
                  href="javascript:;"
                  data-toggle="modal"
                  data-target="#delete-confirmation-modal" @click="pagedata.delete_id = plandet.id"
                ><Trash2Icon class="w-4 h-4 mr-1" /></a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="flex justify-center">
                <div class="form-check form-switch">
                  <input class="form-check-switch" type="checkbox" role="switch" v-model="pagedata.rejection_status">
                </div>
              </div>
            </td>
            <td class="border-b dark:border-dark-5 text-center py-1">Rejection</td>
            <td class="border-b dark:border-dark-5 text-center py-1">
              <div v-if="pagedata.rejection_status">
                <div>
                  <input
                    type="number"
                    v-model="pagedata.rejection"
                    class="form-control w-20 text-center p-0"
                    aria-describedby="input-group-3"
                  />
                </div>
                <span class="text-primary-3" v-if="pagedata.errors.rejection">{{ pagedata.errors.rejection }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td></td>
            <td class="border-b dark:border-dark-5 text-center py-1">Ghat</td>
            <td class="border-b dark:border-dark-5 text-center py-1">
              <input
                type="number"
                v-model="pagedata.ghat"
                class="form-control w-20 text-center p-0"
                aria-describedby="input-group-3"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- END: HTML Table Data -->
    <!-- BEGIN: Receive Packet Modal -->
    <div
      id="edit-confirmation-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <a data-dismiss="modal" href="javascript:;">
              <XIcon class="w-8 h-8 text-gray-500" />
          </a>
          <div class="modal-body p-10 text-center">
            <!-- BEGIN: Vertical Form -->
            <div class="intro-y box">
              <div id="vertical-form" class="p-5">
                <div class="text-lg font-medium mx-3" v-if="new_packet.packet_number">Packet No :- <span class="text-primary-1">{{ new_packet.packet_number }}</span></div>
                <div class="text-lg font-medium mx-3" v-else>New Packet</div>
                <div class="grid grid-cols-12 gap-6 mt-5">
                  <div class="intro-y col-span-12 md:col-span-6">
                    <div class="mt-3">
                      <div class="mt-2 sm:mt-0">
                        <div class="input-group">
                          <div id="input-group-3" class="input-group-text w-36 text-center">Weight</div>
                          <input
                          type="number"
                          @change="getRepoRate"
                          v-model="new_packet.weight"
                          class="form-control"
                          placeholder="Weight"
                          aria-describedby="input-group-4"
                        />
                        </div>
                        <span class="text-primary-3" v-if="pagedata.errors.weight">{{ pagedata.errors.weight }}</span>
                      </div>
                    </div>
                    <div class="mt-3">
                      <div class="mt-2 sm:mt-0">
                        <div class="input-group">
                          <div id="input-group-3" class="input-group-text w-36 text-center">PWT</div>
                          <input
                          type="number" :disabled="pagedata.edit_pwt == false"
                          @change="getRepoRate"
                          v-model="new_packet.pwt"
                          class="form-control"
                          placeholder="Weight"
                          aria-describedby="input-group-4"
                        />
                        </div>
                        <span class="text-primary-3" v-if="pagedata.errors.pwt">{{ pagedata.errors.pwt }}</span>
                      </div>
                    </div>
                    <div class="mt-3">
                      <div class="input-group mt-2 sm:mt-0">
                        <div id="input-group-3" class="input-group-text w-36 text-center">Color</div>
                        <select
                          class="form-select"
                          @change="getRepoRate"
                          v-model="new_packet.color"
                          aria-label="Default select example"
                        >
                          <option v-for="col in diamond.colors" :key="col.id" :value="col.id">{{ col.name }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="mt-3">
                      <div class="input-group">
                        <div id="input-group-3" class="input-group-text w-36 text-center">Purity</div>
                        <select
                          class="form-select"
                          @change="getRepoRate"
                          v-model="new_packet.purity"
                          aria-label="Default select example"
                        >
                          <option v-for="col in diamond.purities" :key="col.id" :value="col.id">{{ col.name }}</option>
                      </select>
                      </div>
                    </div>
                    <div class="mt-3">
                      <div class="input-group mt-2 sm:mt-0">
                        <div id="input-group-3" class="input-group-text w-36 text-center">Shape</div>
                        <select
                          class="form-select"
                          v-model="new_packet.shape"
                          aria-label="Default select example"
                        >
                          <option v-for="col in diamond.shapes" :key="col.id" :value="col.id">{{ col.name }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="mt-3">
                      <div class="input-group">
                        <div id="input-group-3" class="input-group-text w-36 text-center">Cut</div>
                        <select
                          class="form-select"
                          v-model="new_packet.cut"
                          aria-label="Default select example"
                        >
                          <option v-for="col in diamond.cuts" :key="col.id" :value="col.id">{{ col.name }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="mt-3">
                      <div class="input-group mt-2 sm:mt-0">
                        <div id="input-group-3" class="input-group-text w-36 text-center">Polish</div>
                        <select
                          class="form-select"
                          v-model="new_packet.polish"
                          aria-label="Default select example"
                        >
                          <option v-for="col in diamond.polish" :key="col.id" :value="col.id">{{ col.name }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="mt-3">
                      <div class="input-group">
                        <div id="input-group-3" class="input-group-text w-36 text-center">Symmerty</div>
                        <select
                          class="form-select"
                          v-model="new_packet.symmetry"
                          aria-label="Default select example"
                        >
                          <option v-for="col in diamond.symmetry" :key="col.id" :value="col.id">{{ col.name }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="mt-3">
                      <div class="input-group mt-2 sm:mt-0">
                        <div id="input-group-3" class="input-group-text w-36 text-center">Flurocent</div>
                        <select
                          class="form-select"
                          v-model="new_packet.fluerocent"
                          aria-label="Default select example"
                        >
                          <option v-for="col in diamond.fluerocents" :key="col.id" :value="col.id">{{ col.name }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="intro-y col-span-12 md:col-span-6">
                    <div class="mt-3">
                      <div class="input-group">
                        <div id="input-group-3" class="input-group-text w-36 text-center">Tension</div>
                        <select
                          class="form-select"
                          v-model="new_packet.tension"
                          aria-label="Default select example"
                        >
                          <option v-for="col in diamond.tensions" :key="col.id" :value="col.id">{{ col.name }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="mt-3">
                      <div class="input-group mt-2 sm:mt-0">
                        <div id="input-group-3" class="input-group-text w-36 text-center">Stone</div>
                        <select
                          class="form-select"
                          @change="getRepoRate"
                          v-model="new_packet.stone"
                          aria-label="Default select example"
                        >
                          <option v-for="col in diamond.stones" :key="col.id" :value="col.id">{{ col.name }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="mt-3">
                      <div class="input-group">
                        <div id="input-group-3" class="input-group-text w-36 text-center">Ratio</div>
                        <input
                        type="number"
                        v-model="new_packet.ratio"
                        class="form-control"
                        placeholder="Ratio"
                        aria-describedby="input-group-4"
                      />
                      </div>
                      <span class="text-primary-3" v-if="pagedata.errors.ratio">{{ pagedata.errors.ratio[0] }}</span>
                    </div>
                    <div class="mt-3">
                      <div class="input-group">
                        <div id="input-group-3" class="input-group-text w-36 text-center">Tabl</div>
                        <input
                        type="number"
                        v-model="new_packet.tabl"
                        class="form-control"
                        placeholder="Tabl"
                        aria-describedby="input-group-4"
                      />
                      </div>
                      <span class="text-primary-3" v-if="pagedata.errors.tabl">{{ pagedata.errors.tabl[0] }}</span>
                    </div>
                    <div class="mt-3">
                      <div class="input-group">
                        <div id="input-group-3" class="input-group-text w-36 text-center">R Rate</div>
                        <input
                        type="number"
                        v-model="new_packet.r_rate"
                        disabled
                        class="form-control"
                        placeholder="R Rate"
                        aria-describedby="input-group-4"
                      />
                      </div>
                      <span class="text-primary-3" v-if="pagedata.errors.r_rate">{{ pagedata.errors.r_rate[0] }}</span>
                    </div>
                    <div class="mt-3">
                      <div class="input-group">
                        <div id="input-group-3" class="input-group-text w-36 text-center">S Rate</div>
                        <input
                        type="number"
                        v-model="new_packet.s_rate"
                        class="form-control"
                        disabled
                        placeholder="S Rate"
                        aria-describedby="input-group-4"
                      />
                      </div>
                      <span class="text-primary-3" v-if="pagedata.errors.s_rate">{{ pagedata.errors.s_rate[0] }}</span>
                    </div>
                    <div class="mt-3">
                      <div class="input-group">
                        <div id="input-group-3" class="input-group-text w-36 text-center">Height</div>
                        <input
                        type="number"
                        v-model="new_packet.height"
                        class="form-control"
                        placeholder="Height"
                        aria-describedby="input-group-4"
                      />
                      </div>
                      <span class="text-primary-3" v-if="pagedata.errors.height">{{ pagedata.errors.height[0] }}</span>
                    </div>
                    <div class="mt-3">
                      <div class="input-group">
                        <div id="input-group-3" class="input-group-text w-36 text-center">Discount</div>
                        <input
                        type="number"
                        v-model="new_packet.discount"
                        class="form-control"
                        disabled
                        placeholder="Discount"
                        aria-describedby="input-group-4"
                      />
                      </div>
                      <span class="text-primary-3" v-if="pagedata.errors.discount">{{ pagedata.errors.discount[0] }}</span>
                    </div>
                  </div>
                </div>
                <div class="text-right">
                  <button type="button" class="btn btn-primary w-24" :disabled="pagedata.submit" @click="receivePKT">Receive
                  <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" /></button>
                </div>
              </div>
            </div>
          <!-- END: Vertical Form -->
          </div>
        </div>
      </div>
    </div>
    <!-- END: Receive Packet Modal -->
    <!-- BEGIN: Delete Confirmation Modal -->
    <div
      id="delete-confirmation-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
              <div class="text-3xl mt-5">Are you sure?</div>
              <div class="text-gray-600 mt-2">
                Do you really want to delete these records? <br />This process
                cannot be undone.
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-24 mr-1"
              >
                Cancel
              </button>
              <button type="button" @click="cancelPacket" :disabled="pagedata.submit" class="btn btn-danger w-32 m-auto">Delete
                <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Delete Confirmation Modal -->
    <!-- BEGIN: Process Done Confirmation Modal -->
    <div
      id="done-confirmation-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <CheckCircleIcon class="w-16 h-16 text-theme-9 mx-auto mt-3" />
              <div class="text-3xl mt-5">Are you sure?</div>
              <div class="text-gray-600 mt-2">
                Do you really want to Process Done ?
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-24 mr-1"
              >
                Cancel
              </button>
              <button type="button" @click="processDone" :disabled="pagedata.submit" class="btn btn-primary w-32 m-auto">Done
                <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Process Done Confirmation Modal -->
    <!-- BEGIN: Active Confirmation Modal -->
    <div
      id="active-confirmation-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <XCircleIcon class="w-16 h-16 text-theme-9 mx-auto mt-3" />
              <div class="text-3xl mt-5">Are you sure?</div>
              <div class="text-gray-600 mt-2">
                Do you really want to Active Plan ?
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-24 mr-1"
              >
                Cancel
              </button>
              <button type="button" @click="activePacket" :disabled="pagedata.submit" class="btn btn-primary w-32 m-auto">Active
                <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Active Confirmation Modal -->
    <!-- BEGIN: Rate Not Found Process -->
  <div
    id="rate-show-confirmation-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body p-3">
          <div class="p-5 text-center">
            <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
            <div class="text-3xl mt-5" v-if="pagedata.errors.error">{{ pagedata.errors.error }}</div>
            <div class="text-gray-600 mt-2" v-if="pagedata.errors.message">
              {{ pagedata.errors.message }}
            </div>
          </div>
          <div class="px-5 pb-8 text-center">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-24 mr-1"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-danger w-24" :disabled="pagedata.submit" v-if="pagedata.errors.error == 'Rate Not Found' || pagedata.errors.error == 'Manager Rate Not Found'" @click="createRateRequest">Send
              <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Rate Not Found Process -->
  </div>
  <!-- END: Profile Info -->
</template>
<script>
/* eslint-disable */
import cash from 'cash-dom'
import { defineComponent, onMounted, computed, reactive, ref, watch } from 'vue'
import print from 'vue3-print-nb'
import Toastify from 'toastify-js'
import axios from 'axios'
import { BASE_URL } from '@/constants/config'
import { useRoute } from 'vue-router'
import router from '@/router'
export default defineComponent({
  directives: {
    print
  },
  setup() {
    const route = useRoute()
    const pagedata = reactive({
      submit: false,
      type: '',
      new_plan: false,
      edit_pwt: false,
      plan: [],
      plan_details: [],
      lsbs_details: [],
      errors: [],
      error: '',
      success: '',
      delete_id: '',
      done_status: false,
      rate_status: '',
      rejection: 0,
      ghat: 0,
      rejection_status: false
    })
    const diamond = reactive({
      colors: [],
      cuts: [],
      purities: [],
      symmetry: [],
      polish: [],
      fluerocents: [],
      tensions: [],
      stones: [],
      shapes: [],
    })
    const new_packet = reactive({
      packet: route.params.id,
      packet_number: '',
      plan_detail_id: '',
      ls_packet: '',
      color: '',
      cut: '',
      purity: '',
      polish: '',
      shape: '',
      shape: '',
      symmetry: '',
      fluerocent: '',
      tension: '',
      stone: '',
      weight: '',
      pwt: '',
      weight: '',
      r_rate: 0,
      s_rate: 0,
      ratio: 0,
      height: 0,
      tabl: 0,
      discount: 0,
    })
    onMounted(() => {
      PacketDetails()
    })
    function PacketDetails() {
      axios({
          url: `/rough/show-lsbs-plans/${route.params.id}`,
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.plan = res.data.plan
          pagedata.plan_details = res.data.plan_details
          pagedata.lsbs_details = res.data.lsbs_details
          pagedata.done_status = res.data.done_status
          pagedata.rate_status = res.data.rate_status
          pagedata.rejection = res.data.rejection
          if(pagedata.rejection > 0) {
            pagedata.rejection_status = true
          }
          const paketFilter = ref(computed(() => pagedata.plan_details.filter(purc => {
            return purc.status == 2
          })))
          if(paketFilter.value.length > 0) {
            pagedata.new_plan = false
          } else {
            pagedata.new_plan = true
          }
          countGhat()
        })
    }
    function countGhat() {
      let total_wt = 0;
      for(let i = 0; i < pagedata.lsbs_details.length; i++) {
        if(pagedata.lsbs_details[i].packet) {
          total_wt = total_wt + pagedata.lsbs_details[i].weight
        }
      }
      if(pagedata.rejection_status) {
        let ghat_count = pagedata.plan.total_weight - total_wt - pagedata.rejection
        if(ghat_count < 0) {
          pagedata.errors.rejection = 'Rejection is Incorrect'
          pagedata.rejection = 0
          setTimeout(() => {
            pagedata.errors = []
          }, 3000);
        }
        pagedata.ghat = parseFloat(ghat_count).toFixed(2)
      } else {
        let ghat_count = pagedata.plan.total_weight - total_wt
        pagedata.ghat = parseFloat(ghat_count).toFixed(2)
      }
    }
    function createNewPacket() {
      new_packet.packet_number = ''
      new_packet.plan_detail_id = ''
      new_packet.ls_packet = ''
      new_packet.color = ''
      new_packet.cut = ''
      new_packet.purity = ''
      new_packet.polish = ''
      new_packet.shape = ''
      new_packet.shape = ''
      new_packet.symmetry = ''
      new_packet.fluerocent = ''
      new_packet.tension = ''
      new_packet.stone = ''
      new_packet.pwt = ''
      new_packet.weight = ''
      new_packet.r_rate = 0
      new_packet.s_rate = 0
      new_packet.ratio = 0
      new_packet.height = 0
      new_packet.tabl = 0
      new_packet.discount = 0
      getDiamontData()
      cash('#edit-confirmation-modal').modal('show')
    }
    function updatePacketDetails(index) {
      new_packet.packet_number = pagedata.lsbs_details[index].number
      new_packet.plan_detail_id = pagedata.lsbs_details[index].id
      new_packet.color = pagedata.lsbs_details[index].color
      new_packet.cut = pagedata.lsbs_details[index].cut
      new_packet.purity = pagedata.lsbs_details[index].purity
      new_packet.polish = pagedata.lsbs_details[index].polish
      new_packet.shape = pagedata.lsbs_details[index].shape
      new_packet.shape = pagedata.lsbs_details[index].shape
      new_packet.symmetry = pagedata.lsbs_details[index].symmetry
      new_packet.fluerocent = pagedata.lsbs_details[index].fluerocent
      new_packet.tension = pagedata.lsbs_details[index].tension
      new_packet.stone = pagedata.lsbs_details[index].stone
      new_packet.pwt = pagedata.lsbs_details[index].pwt
      new_packet.weight = pagedata.lsbs_details[index].weight
      new_packet.r_rate = pagedata.lsbs_details[index].r_rate
      new_packet.s_rate = pagedata.lsbs_details[index].s_rate
      new_packet.ratio = pagedata.lsbs_details[index].ratio
      new_packet.height = pagedata.lsbs_details[index].height
      new_packet.tabl = pagedata.lsbs_details[index].tabl
      new_packet.discount = pagedata.lsbs_details[index].discount
      new_packet.ls_packet = pagedata.lsbs_details[index].packet ? true : false
      getDiamontData()
      cash('#edit-confirmation-modal').modal('show')
    }
    function getDiamontData() {
      if(diamond.colors.length <= 0) {
        axios({
          url: '/GetAll',
          method: 'get',
          baseURL: BASE_URL,
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          diamond.colors = res.data.diamonds.colors
          diamond.cuts = res.data.diamonds.cut
          diamond.purities = res.data.diamonds.purity
          diamond.symmetry = res.data.diamonds.symmetry
          diamond.polish = res.data.diamonds.polish
          diamond.fluerocents = res.data.diamonds.fluerocent
          diamond.tensions = res.data.diamonds.tension
          diamond.shapes = res.data.diamonds.shape
          diamond.stones = res.data.diamonds.stones
        })
      }
    }
    function getRepoRate() {
      if(new_packet.color != '' && new_packet.shape != '' && new_packet.purity != '' && new_packet.pwt != '') {
        axios({
          url: '/rough/get-repo-rate',
          method: 'post',
          baseURL: BASE_URL,
          data: { color:new_packet.color,purity:new_packet.purity,shape:new_packet.shape,weight:new_packet.pwt},
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          new_packet.r_rate = res.data.price
          if(new_packet.s_rate == 0) {
            new_packet.discount = 0
          } else {
            let rate = new_packet.s_rate - new_packet.r_rate
            let discount = (rate * 100)/ new_packet.r_rate
            new_packet.discount = parseFloat(discount).toFixed(2)
          }

        })
      }
    }
    function receivePKT() {
      pagedata.submit = true
      axios({
        url: '/rough/add-update-lsbs-plans',
        method: 'post',
        baseURL: BASE_URL,
        data: new_packet,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.success = res.data.success
        setTimeout(() => {
          basicNonStickyNotificationPacket()
        }, 100);
        cash('#edit-confirmation-modal').modal('hide')
        pagedata.submit = false
        PacketDetails()
        setTimeout(() => {
          pagedata.success = ''
        }, 5000);
      }).catch((err) => {
        pagedata.submit = false
        pagedata.error = err.response.data.error
        setTimeout(() => {
          basicNonStickyNotificationPacket()
        }, 100);
        setTimeout(() => {
          pagedata.error = ''
        }, 5000);
      })
    }
    function cancelPacket() {
      pagedata.submit = true
      axios({
        url: `/rough/cancel-lsbs-plans/${pagedata.delete_id}`,
        method: 'get',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.success = res.data.success
        setTimeout(() => {
          basicNonStickyNotificationPacket()
        }, 100);
        pagedata.submit = false
        PacketDetails()
        setTimeout(() => {
          pagedata.success = ''
        }, 5000);

        cash('#delete-confirmation-modal').modal('hide')
      }).catch((err) => {
        pagedata.submit = false
        pagedata.error = err.response.data.error
        setTimeout(() => {
          basicNonStickyNotificationPacket()
        }, 100);
        setTimeout(() => {
          pagedata.error = ''
        }, 5000);
      })
    }
    function activePacket() {
      pagedata.submit = true
      axios({
        url: `/rough/active-lsbs-plans/${pagedata.delete_id}`,
        method: 'get',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.success = res.data.success
        setTimeout(() => {
          basicNonStickyNotificationPacket()
        }, 100);
        pagedata.submit = false
        PacketDetails()
        setTimeout(() => {
          pagedata.success = ''
        }, 5000);

        cash('#active-confirmation-modal').modal('hide')
      }).catch((err) => {
        pagedata.submit = false
        pagedata.error = err.response.data.error
        setTimeout(() => {
          basicNonStickyNotificationPacket()
        }, 100);
        setTimeout(() => {
          pagedata.error = ''
        }, 5000);
      })
    }
    function processDone() {
      pagedata.submit = true
      axios({
        url: `/rough/done-lsbs-plans/${route.params.id}`,
        method: 'post',
        baseURL: BASE_URL,
        data: { rejection: pagedata.rejection,rejection_status: pagedata.rejection_status, ghat: pagedata.ghat },
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.success = res.data.success
        setTimeout(() => {
          basicNonStickyNotificationPacket()
        }, 100);
        pagedata.submit = false
          cash('#done-confirmation-modal').modal('hide')
        setTimeout(() => {
          pagedata.success = ''
        }, 5000);
        router.push({ name: 'lsbs-plan-packets' })
      }).catch((err) => {
        pagedata.submit = false
        if(err.response.data.message) {
            pagedata.errors.message = err.response.data.message
            pagedata.errors.error = err.response.data.error
            pagedata.type = err.response.data.type
            cash('#done-confirmation-modal').modal('hide')
            cash('#rate-show-confirmation-modal').modal('show')
          } else {
            pagedata.error = err.response.data.error
            setTimeout(() => {
              basicNonStickyNotificationPacket()
            },100)
            setTimeout(() => {
              pagedata.error = ''
            }, 5000);
          }
      })
    }
    function doneModalShow() {
      cash('#done-confirmation-modal').modal('show')
    }
    function createRateRequest() {
      pagedata.submit = true
      axios({
        url: `/packet/create-rough-rate-request/${route.params.id}?type=${pagedata.type}`,
        method: 'get',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      }).then((res) => {
        pagedata.submit = false
        pagedata.success = res.data.success
        PacketDetails()
        setTimeout(() => {
          basicNonStickyNotificationPacket()
        }, 100);
        cash('#rate-show-confirmation-modal').modal('hide')
      }).catch((err) => {
        pagedata.error = err.response.data.error
        pagedata.submit = false
        setTimeout(() => {
          basicNonStickyNotificationPacket()
        }, 100);
      })
    }
    const basicNonStickyNotificationPacket = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification-packet')
          .clone()
          .removeClass('hidden')[0],
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    watch(() => pagedata.rejection, (newValue) => {
      countGhat()
    })
    watch(() => new_packet.weight, (newValue) => {
      if(newValue < new_packet.pwt) {
        pagedata.edit_pwt = true
      } else {
        pagedata.edit_pwt = false
      }
      if(new_packet.pwt > new_packet.weight && new_packet.weight != '') {
        pagedata.errors.pwt = 'Polish wt Less then Weight'
      } else {
        pagedata.errors.pwt = ''
      }
    })
    watch(() => new_packet.pwt, (newValue) => {
      if(newValue > new_packet.weight && new_packet.weight != '') {
        pagedata.errors.pwt = 'Polish wt Less then Weight'
      } else {
        pagedata.errors.pwt = ''
      }
    })
    watch(() => pagedata.ghat, (newValue) => {
      countGhat()
    })
    watch(() => pagedata.rejection_status, (newValue) => {
      countGhat()
    })
    return {
      pagedata,
      diamond,
      new_packet,
      PacketDetails,
      createNewPacket,
      getRepoRate,
      receivePKT,
      cancelPacket,
      activePacket,
      processDone,
      doneModalShow,
      updatePacketDetails,
      createRateRequest,
      basicNonStickyNotificationPacket
    }
  }
})
</script>
<style scoped>
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }

  input[type=number] {
      -moz-appearance: textfield;
  }

  .input-group .input-group-text-custom {
      border-width: 1px;
      --tw-border-opacity: 1;
      border-color: rgba(226, 232, 240, var(--tw-border-opacity));
      --tw-bg-opacity: 1;
      background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
      padding-top: 0.1rem;
      padding-bottom: 0.1rem;
      padding-left: 1rem;
      padding-right: 1rem;
      --tw-text-opacity: 1;
      color: rgba(113, 128, 150, var(--tw-text-opacity));
      --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
</style>


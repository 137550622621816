<template>
  <div>
    <!-- BEgin: Process Report Start-->
    <div class="intro-y box p-5 pt-2 mt-5">
      <h2 class="intro-y text-lg font-medium mt-2">Costing Rates</h2>
      <div class="overflow-x-auto scrollbar-hidden">
        <div class="intro-y col-span-12 overflow-auto">
          <table class="mt-2 w-full border-2">
            <thead>
              <tr class="bg-theme-1 text-white">
                <th class="py-2 text-xs whitespace-nowrap">Type</th>
                <th class="py-2 text-xs whitespace-nowrap">Rate</th>
              </tr>
            </thead>
            <tbody>
              <tr class="cursor-pointer">
                <td class="py-2 border-b text-xs text-center">Makeable</td>
                <td class="py-2 border-b border-r text-xs text-center">{{ pagedata.manager_costing }}</td>
              </tr>
              <tr class="cursor-pointer">
                <td class="py-2 border-b text-xs text-center">Rough</td>
                <td class="py-2 border-b text-xs text-center">{{ pagedata.rough_costing }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- BEgin: Process Report Start-->
    <div class="intro-y box p-5 pt-2 mt-5">
      <h2 class="intro-y text-lg font-medium mt-2">Makeable Report</h2>
      <div class="overflow-x-auto scrollbar-hidden" v-if="pagedata.makeable_packets.length > 0">
        <div class="intro-y col-span-12 overflow-auto">
          <table class="mt-2 w-full border-2">
                <thead>
                  <tr class="bg-theme-1 text-white">
                    <th class="py-2 text-xs whitespace-nowrap">#</th>
                    <th class="py-2 text-xs whitespace-nowrap">Packet</th>
                    <th class="py-2 text-xs whitespace-nowrap">Vepari No</th>
                    <th class="py-2 text-xs whitespace-nowrap">Party</th>
                    <th class="py-2 text-xs whitespace-nowrap"><div>Shp</div></th>
                    <th class="py-2 text-xs whitespace-nowrap"><div>Clr</div></th>
                    <th class="py-2 text-xs whitespace-nowrap"><div>Prt</div></th>
                    <th class="py-2 text-xs whitespace-nowrap"><div>Cut</div></th>
                    <th class="py-2 text-xs whitespace-nowrap"><div>Pol</div></th>
                    <th class="py-2 text-xs whitespace-nowrap"><div>Sym</div></th>
                    <th class="py-2 text-xs whitespace-nowrap"><div>FL</div></th>
                    <th class="py-2 text-xs whitespace-nowrap"><div>TN</div></th>
                    <th class="py-2 text-xs whitespace-nowrap"><div>Stn</div></th>
                    <th class="py-2 text-xs whitespace-nowrap">I. Wt</th>
                    <th class="py-2 text-xs whitespace-nowrap">P. Wt</th>
                    <th class="py-2 text-xs whitespace-nowrap">Status</th>
                    <th class="py-2 text-xs whitespace-nowrap">Rate</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="cursor-pointer" v-for="(packet, index) in pagedata.makeable_packets" :key="index" @click="getDetailsPacket(packet.id)">
                    <td class="py-2 border-b text-xs text-center">{{ index + 1 }}</td>
                    <td class="py-2 border-b text-xs text-center">{{ packet.number }}</td>
                    <td class="py-2 border-b text-xs text-center">{{ packet.vepari_no }}</td>
                    <td class="py-2 border-b text-xs text-center">{{ packet.party }}</td>
                    <td class="py-2 border-b text-xs text-center">{{ packet.shape }}</td>
                    <td class="py-2 border-b text-xs text-center">{{ packet.color }}</td>
                    <td class="py-2 border-b text-xs text-center">{{ packet.purity }}</td>
                    <td class="py-2 border-b text-xs text-center">{{ packet.cut }}</td>
                    <td class="py-2 border-b text-xs text-center">{{ packet.polish }}</td>
                    <td class="py-2 border-b text-xs text-center">{{ packet.symmetry }}</td>
                    <td class="py-2 border-b text-xs text-center">{{ packet.fluerocent }}</td>
                    <td class="py-2 border-b text-xs text-center">{{ packet.tension }}</td>
                    <td class="py-2 border-b text-xs text-center">{{ packet.stone }}</td>
                    <td class="py-2 border-b text-xs text-center">{{ packet.weight }}</td>
                    <td class="py-2 border-b text-xs text-center">{{ packet.admin_pwt > 0 ? packet.admin_pwt : packet.pwt }}</td>
                    <td class="py-2 border-b text-xs text-center">
                      <div v-if="packet.status == 1" class="text-primary-1">Active</div>
                      <div v-if="packet.status == 2" class="text-primary-1">Processing</div>
                      <div v-if="packet.status == 3" class="text-primary-3">Cancelled</div>
                      <div v-if="packet.status == 4" class="text-primary-4">Blocked</div>
                      <div v-if="packet.status == 5 || packet.status == 6" class="text-primary-8">JobDone</div>
                      <div v-if="packet.status == 7" class="text-primary-6">External</div>
                      <div v-if="packet.status == 8" class="text-primary-4">Delivered</div>
                      <div v-if="packet.status == 9" class="text-primary-2">Discuss</div>
                    </td>
                    <td class="py-2 border-b text-xs text-center">{{ packet.total_rate }}</td>
                  </tr>
                </tbody>
              </table>
        </div>
        <!-- END: Data List -->
      </div>
    </div>
    <div class="intro-y box p-5 pt-2 mt-5">
      <h2 class="intro-y text-lg font-medium mt-2">Rough Report</h2>
      <!-- BEGIN: Data List -->
      <div class="overflow-x-auto scrollbar-hidden" v-if="pagedata.rough_packets.length > 0">
        <div class="intro-y col-span-12 overflow-auto">
          <table class="mt-2 w-full border-2">
                <thead>
                  <tr class="bg-theme-1 text-white">
                    <th class="py-2 text-xs whitespace-nowrap">#</th>
                    <th class="py-2 text-xs whitespace-nowrap">Packet</th>
                    <th class="py-2 text-xs whitespace-nowrap">Vepari No</th>
                    <th class="py-2 text-xs whitespace-nowrap">Party</th>
                    <th class="py-2 text-xs whitespace-nowrap"><div>Clr</div></th>
                    <th class="py-2 text-xs whitespace-nowrap"><div>Prt</div></th>
                    <th class="py-2 text-xs whitespace-nowrap"><div>Stn</div></th>
                    <th class="py-2 text-xs whitespace-nowrap">I. Wt</th>
                    <th class="py-2 text-xs whitespace-nowrap">Status</th>
                    <th class="py-2 text-xs whitespace-nowrap">Rate</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="cursor-pointer" v-for="(packet, index) in pagedata.rough_packets" :key="index" @click="getDetailsPacket(packet.id)">
                    <td class="py-2 border-b text-xs text-center">{{ index + 1 }}</td>
                    <td class="py-2 border-b text-xs text-center">{{ packet.number }}</td>
                    <td class="py-2 border-b text-xs text-center">{{ packet.vepari_no }}</td>
                    <td class="py-2 border-b text-xs text-center">{{ packet.party }}</td>
                    <td class="py-2 border-b text-xs text-center">{{ packet.color }}</td>
                    <td class="py-2 border-b text-xs text-center">{{ packet.purity }}</td>
                    <td class="py-2 border-b text-xs text-center">{{ packet.stone }}</td>
                    <td class="py-2 border-b text-xs text-center">{{ packet.weight }}</td>
                    <td class="py-2 border-b text-xs text-center">
                      <div v-if="packet.status == 1" class="text-primary-1">Active</div>
                      <div v-if="packet.status == 2" class="text-primary-1">Processing</div>
                      <div v-if="packet.status == 3" class="text-primary-3">Cancelled</div>
                      <div v-if="packet.status == 4" class="text-primary-4">Blocked</div>
                      <div v-if="packet.status == 7" class="text-primary-6">External</div>
                      <div v-if="packet.status == 9" class="text-primary-2">Discuss</div>
                    </td>
                    <td class="py-2 border-b text-xs text-center">{{ packet.total_rate }}</td>
                  </tr>
                </tbody>
              </table>
        </div>
        <!-- END: Data List -->
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config';
import router from '@/router';
import axios from 'axios';
import { defineComponent, onMounted, reactive } from 'vue';

export default defineComponent({
  setup() {
    const pagedata = reactive({
      makeable_packets: [],
      rough_packets: [],
      manager_costing: null,
      rough_costing: null,
    })
    onMounted(() => {
      getAvailPkt()
    })
    function getAvailPkt() {
       axios({
        url: '/report/costing_report',
        method: 'get',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.makeable_packets = res.data.makeable_packets
        pagedata.rough_packets = res.data.rough_packets
        pagedata.manager_costing = res.data.makeable_costing
        pagedata.rough_costing = res.data.rough_costing
      })
    }
    function getDetailsPacket(id) {
      router.push({ name: 'costing-detail', params:{ id: id }})
    }
    return {
      getDetailsPacket,
      getAvailPkt,
      pagedata
    }
  }
})
</script>

<template>
    <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Form Layout</h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <div>
            <select
              class="form-select w-60"
              v-model="process.type"
              @change="getProcessD"
              aria-label="Default select example"
            >
              <option value="transfer">Process Transfer</option>
              <option value="addprocess">Process Add</option>
              <option value="delete">Process Delete</option>
            </select>
          </div>
          <div class="mt-3">
            <label class="form-label">Select Managers</label>
            <TomSelect
                v-model="process.manager"
                @change="getProcessData"
                :options="{
                  placeholder: 'Select your favorite actors'
                }"
                class="w-full"
              >
                <option v-for="dept in managers" :key="dept.id" :value="dept.id">{{ dept.name }}</option>
              </TomSelect>
          </div>
          <div class="mt-3">
            <label class="form-label">Select Processes</label>
            <TomSelect
            v-model="process.porcess"
                :options="{
                  placeholder: 'Select your favorite actors'
                }"
                class="w-full"
              multiple
              >
                <option v-for="process in porcesses" :key="process.id" :value="process.id">{{ process.name }}</option>
              </TomSelect>
              <span class="text-primary-3" v-if="errors.porcess">{{ errors.porcess[0] }}</span>
          </div>
          <div class="mt-3" v-if="process.type == 'transfer'">
            <label class="form-label">Select Managers</label>
            <TomSelect
                v-model="process.newManager"
                :options="{
                  placeholder: 'Select your favorite actors'
                }"
                class="w-full"
              >
                <option v-for="dept in changeManagers" :key="dept.id" :value="dept.id">{{ dept.name }}</option>
              </TomSelect>
          </div>
          <div class="text-right mt-5">
            <router-link :to="{ name: 'master' }" type="button" class="btn btn-outline-secondary w-24 mr-1">
              Cancel
            </router-link>
            <button type="button" @click="SaveManager" :disabled="pagedata.submit" class="btn btn-primary w-24">Save
            <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" /></button>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import router from '@/router'
import axios from 'axios'
import { defineComponent, onMounted, reactive, ref, toRef } from 'vue'

export default defineComponent({
  setup() {
    const process = reactive({
      type: 'transfer',
      manager: '',
      porcess: [],
      newManager: ''
    })
    const pagedata = reactive({
      submit: false,
      managers: [],
      porcesses: [],
      errors: [],
      changeManagers: []
    })
    const managers = toRef(pagedata,'managers')
    const porcesses = toRef(pagedata,'porcesses')
    const errors = toRef(pagedata,'errors')
    const changeManagers = toRef(pagedata,'changeManagers')
    onMounted(() => {
      axios({
        url: 'api/master/dp_managers',
        method: 'post',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.managers = res.data.items
        process.manager = managers.value[0].id
      })
    })
    function getProcessData() {
      getProcess()
      getOtherManagers()
    }
    function getProcess() {
      if(process.type == 'addprocess') {
        getProcessD()
      } else {
        axios({
          url: `/master/transfer-process-manager-processes?id=${process.manager}`,
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.porcesses = res.data.processes
        })
      }
    }
    function getOtherManagers() {
      axios({
        url: `/user/allManager?id=${process.manager}`,
        method: 'get',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.changeManagers = res.data.managers
        process.newManager = changeManagers.value[0].id
      })
    }
    function SaveManager() {
      pagedata.submit = true
      axios({
        url: '/user/update-manager-process',
        method: 'post',
        baseURL: BASE_URL,
        data: process,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.submit = false
        router.push({ name: 'master' })
      }).catch((err) => {
        pagedata.submit = false
        pagedata.errors = err.response.data.errors
        setTimeout(() => {
          pagedata.errors = []
        }, 5000);
      })
    }
    function getProcessD() {
      if(process.type == 'addprocess') {
        axios({
          url: `/master/addProcess/${process.manager}`,
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.porcesses = res.data.processes
        })
      } else {
        getProcess()
      }
    }
    return {
      process,
      managers,
      porcesses,
      errors,
      changeManagers,
      pagedata,
      getProcessData,
      SaveManager,
      getProcessD
    }
  }
})
</script>

<style>

</style>

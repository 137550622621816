  <template>
    <div class="my-8">
      <div class="grid grid-cols-12 gap-x-2 items-center" v-if="pagedata.show_details == true">
        <div class="col-span-11">
          <div class="flex items-end mt-10 xl:mt-0">
            <div class="mx-5">
              <h2 class="intro-y text-lg font-medium">Packet No : {{ pagedata.packet_details.packet_code }}</h2>
              <table class="w-48">
                <tbody>
                  <tr>
                    <th class="py-2 font-bold border-2 text-md px-1 text-center whitespace-nowrap">Transaction</th>
                    <th class="py-2 font-bold border-2 text-md px-1 text-center whitespace-nowrap">Issue Wt</th>
                    <th class="py-2 font-bold border-2 text-md px-1 text-center whitespace-nowrap">Polish Wt</th>
                    <th class="py-2 font-bold border-2 text-md px-1 text-center whitespace-nowrap">Request Wt</th>
                    <th class="py-2 font-bold border-2 text-md px-1 text-center" v-if="pagedata.packet_details.shape">Shape</th>
                    <th class="py-2 font-bold border-2 text-md px-1 text-center" v-if="pagedata.packet_details.color">Color</th>
                    <th class="py-2 font-bold border-2 text-md px-1 text-center" v-if="pagedata.packet_details.purity">Purity</th>
                    <th class="py-2 font-bold border-2 text-md px-1 text-center" v-if="pagedata.packet_details.cut">Cut</th>
                    <th class="py-2 font-bold border-2 text-md px-1 text-center" v-if="pagedata.packet_details.polish">Polish</th>
                    <th class="py-2 font-bold border-2 text-md px-1 text-center" v-if="pagedata.packet_details.symmetry">Symmetry</th>
                    <th class="py-2 font-bold border-2 text-md px-1 text-center" v-if="pagedata.packet_details.tension">Fluerocent</th>
                    <th class="py-2 font-bold border-2 text-md px-1 text-center" v-if="pagedata.packet_details.tension">Tension</th>
                    <th class="py-2 font-bold border-2 text-md px-1 text-center" v-if="pagedata.packet_details.stone">Stone</th>
                  </tr>
                  <tr>
                    <td class="font-bold text-md border-2 text-center">{{ pagedata.packet_details.transaction_code }}</td>
                    <td class="font-bold text-md border-2 text-center">{{ pagedata.packet_details.issue_weight }}</td>
                    <td class="py-2 font-bold border-2 text-md px-1 text-center whitespace-nowrap"><div>{{ pagedata.packet_details.cr_polish_weight }}</div></td>
                    <td class="py-2 font-bold border-2 text-md px-1 text-center whitespace-nowrap"><div :class="{ 'text-primary-3': pagedata.packet_details.weight < pagedata.packet_details.cr_polish_weight }">{{ pagedata.packet_details.weight }}</div></td>
                    <td class="py-2 font-bold border-2 text-md px-1 text-center whitespace-nowrap"><span :class="{ 'text-primary-3': pagedata.packet_details.shape_name != pagedata.packet_details.prev_shape_name }">{{ pagedata.packet_details.shape_name }}</span> / {{ pagedata.packet_details.prev_shape_name }}</td>
                    <td class="py-2 font-bold border-2 text-md px-1 text-center whitespace-nowrap"><span :class="{ 'text-primary-3': pagedata.packet_details.color_name != pagedata.packet_details.prev_color_name }">{{ pagedata.packet_details.color_name }}</span> / {{ pagedata.packet_details.prev_color_name }}</td>
                    <td class="py-2 font-bold border-2 text-md px-1 text-center whitespace-nowrap"><span :class="{ 'text-primary-3': pagedata.packet_details.purity_name != pagedata.packet_details.prev_purity_name }">{{ pagedata.packet_details.purity_name }}</span> / {{ pagedata.packet_details.prev_purity_name }}</td>
                    <td class="py-2 font-bold border-2 text-md px-1 text-center whitespace-nowrap"><span :class="{ 'text-primary-3': pagedata.packet_details.cut_name != pagedata.packet_details.prev_cut_name }">{{ pagedata.packet_details.cut_name }}</span> / {{ pagedata.packet_details.prev_cut_name }}</td>
                    <td class="py-2 font-bold border-2 text-md px-1 text-center whitespace-nowrap"><span :class="{ 'text-primary-3': pagedata.packet_details.polish_name != pagedata.packet_details.prev_polish_name }">{{ pagedata.packet_details.polish_name }}</span> / {{ pagedata.packet_details.prev_polish_name }}</td>
                    <td class="py-2 font-bold border-2 text-md px-1 text-center whitespace-nowrap"><span :class="{ 'text-primary-3': pagedata.packet_details.symmetry_name != pagedata.packet_details.prev_symmetry_name }">{{ pagedata.packet_details.symmetry_name }}</span> / {{ pagedata.packet_details.prev_symmetry_name }}</td>
                    <td class="py-2 font-bold border-2 text-md px-1 text-center whitespace-nowrap"><span :class="{ 'text-primary-3': pagedata.packet_details.fluerocent_name != pagedata.packet_details.prev_fluerocent_name }">{{ pagedata.packet_details.fluerocent_name }}</span> / {{ pagedata.packet_details.prev_fluerocent_name }}</td>
                    <td class="py-2 font-bold border-2 text-md px-1 text-center whitespace-nowrap"><span :class="{ 'text-primary-3': pagedata.packet_details.tension_name != pagedata.packet_details.prev_tension_name }">{{ pagedata.packet_details.tension_name }}</span> / {{ pagedata.packet_details.prev_tension_name }}</td>
                    <td class="py-2 font-bold border-2 text-md px-1 text-center whitespace-nowrap"><span :class="{ 'text-primary-3': pagedata.packet_details.stone_name != pagedata.packet_details.prev_stone_name }">{{ pagedata.packet_details.stone_name }}</span> / {{ pagedata.packet_details.prev_stone_name }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-span-1">
          <button class="btn btn-primary" @click="acceptRequest">Accept</button>
        </div>
      </div>
      <div class="grid grid-cols-12 gap-x-2 mt-8">
        <!-- BEGIN: Data List -->
        <div class="intro-y col-span-12 xl:mt-0 mt-2">
          <div class="intro-y col-span-12 overflow-auto" v-if="pagedata.transaction_details.length > 0">
              <table class="table-report -mt-2 w-full">
                <thead>
                  <tr>
                    <th class="PY-2 whitespace-nowrap">#</th>
                    <th class="PY-2 whitespace-nowrap">T No</th>
                    <th class="PY-2 whitespace-nowrap">Proc.</th>
                    <th class="PY-2 whitespace-nowrap">Mgr.</th>
                    <th class="PY-2 text-center whitespace-nowrap">Emp.</th>
                    <th class="PY-2 text-center whitespace-nowrap">Issue Wt.</th>
                    <th class="PY-2 text-center whitespace-nowrap">Rec. Wt.</th>
                    <th class="PY-2 text-center whitespace-nowrap">Issue Time</th>
                    <th class="PY-2 text-center whitespace-nowrap">Rec. Time</th>
                    <th class="PY-2 text-center whitespace-nowrap">Shape</th>
                    <th class="PY-2 text-center whitespace-nowrap">Color</th>
                    <th class="PY-2 text-center whitespace-nowrap">Purity</th>
                    <th class="PY-2 text-center whitespace-nowrap">Cut</th>
                    <th class="PY-2 text-center whitespace-nowrap">Polish</th>
                    <th class="PY-2 text-center whitespace-nowrap">Symm</th>
                    <th class="PY-2 text-center whitespace-nowrap">Flurc</th>
                    <th class="PY-2 text-center whitespace-nowrap">Tension</th>
                    <th class="PY-2 text-center whitespace-nowrap">Stone</th>
                    <th class="PY-2 text-center whitespace-nowrap">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <TableList v-for="(packet, index) in pagedata.transaction_details" :key="index" :index="index" :packet="packet" :packetDet="pagedata.packet_details" :diamond="diamond" @getData="getPaketDetail" @updateParams="getChangeDetails"/>
                </tbody>
              </table>
          </div>
          <!-- <div v-else class="intro-y col-span-12 overflow-auto mt-2 text-center">No Packet Found</div> -->
        </div>
        <!-- END: Data List -->
      </div>
      <!-- BEGIN: Receive Packet Modal -->
   <div
      id="edit-confirmation-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <a data-dismiss="modal" href="javascript:;">
              <XIcon class="w-8 h-8 text-gray-500" />
          </a>
          <div class="modal-body p-10 text-center">
            <!-- BEGIN: Vertical Form -->
            <div class="intro-y box">
              <div id="vertical-form" class="p-5">
                <div class="text-lg font-medium mx-3">Accept Request</div>
                  <div class="mt-3" v-if="pagedata.weight_issue">
                    <div class="input-group">
                      <div id="input-group-3" class="input-group-text w-22">New PWT</div>
                      <input
                        type="number"
                        class="form-control w-40"
                        v-model="formData.newpwt"
                        placeholder="New Polish Weight"
                        aria-describedby="input-group-3"
                      />
                    </div>
                    <!-- <span v-if="errors.newpwt" class="text-primary-3">{{ errors.newpwt[0] }}</span> -->
                  </div>
                  <div class="mt-3">
                    <div class="text-lg font-medium text-left m-3">Panalty</div>
                    <div class="flex items-center mt-3">
                      <div class="form-check">
                        <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer" value="0" type="radio" name="flexRadioDefault" id="flexRadioDefault1" v-model="formData.panalty">
                        <label class="form-check-label inline-block text-black text-md" for="flexRadioDefault1">
                          None
                        </label>
                      </div>
                      <div class="form-check ml-6">
                        <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer" value="1" type="radio" name="flexRadioDefault" id="flexRadioDefault2" v-model="formData.panalty">
                        <label class="form-check-label inline-block text-black text-md" for="flexRadioDefault2">
                          Fixed
                        </label>
                      </div>
                      <div class="form-check ml-6">
                        <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer" value="2" type="radio" name="flexRadioDefault" id="flexRadioDefault2" v-model="formData.panalty">
                        <label class="form-check-label inline-block text-black text-md" for="flexRadioDefault2">
                          Percent
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="mt-3" v-if="formData.panalty == 1 || formData.panalty == 2">
                    <div class="sm:grid grid-cols-2 gap-2">
                      <div>
                        <div class="input-group">
                          <div id="input-group-3" class="input-group-text">Manager</div>
                          <div>
                              <input
                                type="text"
                                class="form-control"
                                v-model="formData.manager_panalty"
                                placeholder="Panalty"
                                aria-describedby="input-group-3"
                              />
                          </div>
                        </div>
                        <!-- <div class="text-primary-3" v-if="pagedata.errors.manager_panalty">{{ pagedata.errors.manager_panalty[0] }}</div> -->
                      </div>
                      <div>
                        <div class="input-group mt-2 sm:mt-0">
                          <div id="input-group-4" class="input-group-text">Employee</div>
                          <div>
                              <input
                                type="text"
                                class="form-control"
                                v-model="formData.employee_panalty"
                                placeholder="Panalty"
                                aria-describedby="input-group-4"
                              />
                          </div>
                        </div>
                        <!-- <div class="text-primary-3" v-if="pagedata.errors.employee_panalty">{{ pagedata.errors.employee_panalty[0] }}</div> -->
                      </div>
                    </div>
                  </div>
                  <div class="mt-3">
                    <div class="form-label w-full text-left">Remark</div>
                    <textarea
                      id="regular-form-4"
                      type="password"
                      class="form-control"
                      v-model="formData.description"
                      placeholder="Remark"
                    ></textarea>
                  </div>
                  <div class="text-right mt-5">
                    <button
                      type="button"
                      data-dismiss="modal"
                      class="btn btn-outline-secondary w-24 mr-1"
                    >
                      Cancel
                    </button>
                    <button type="button" class="btn btn-primary w-24" :disabled="pagedata.submit" @click="updateParameterDetails">Accept
                    <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" /></button>
                  </div>
              </div>
            </div>
          <!-- END: Vertical Form -->
          </div>
        </div>
      </div>
    </div>
    <!-- END: Receive Packet Modal -->
    </div>
  </template>
  <script>
  /* eslint-disable */
  import { BASE_URL } from '@/constants/config'
  import axios from 'axios'
  import cash from 'cash-dom'
  import { defineComponent, reactive, onMounted, computed, inject } from 'vue'
  import print from 'vue3-print-nb'
  import TableList from '@/views/notification-section/parameter-edit/tableList.vue'
  import store from '@/store'
import router from '@/router'
import { useRoute } from 'vue-router'
  export default defineComponent({
    directives: {
      print
    },
    components:{
      TableList
    },
    setup() {
      const user_details = computed(() => store.state.user.currentUser)
      const route = useRoute()
      const pagedata = reactive({
        transaction_details: [],
        packet_details : [],
        id: route.params.id,
        show_details: false,
        submit: false,
        weight_issue: false,
        param_issue: false,
        changes: [],
      })
      const swal = inject('$swal')
      const diamond = reactive({
        colors: [],
        cuts: [],
        purities: [],
        symmetry: [],
        polish: [],
        fluerocents: [],
        tensions: [],
        stones: [],
        shapes: [],
      })
      const formData = reactive({
      manager_panalty: 0,
      employee_panalty: 0,
      newpwt: 0,
      panalty: 0,
      description: '',
      request_id: route.params.id,
    })
      onMounted(() => {
        getDiamontData()
        getPaketDetail()
      })
      function getPaketDetail() {
        axios({
            url: 'api/request/get_request_packet_details',
            method: 'post',
            baseURL: BASE_URL,
            data: { id: pagedata.id },
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
            config: { headers: { 'Accept':'application/json' } }
          }).then((res) => {
            pagedata.transaction_details = []
            pagedata.packet_details = []
            pagedata.transaction_details = res.data.packet_transaction_details
            pagedata.packet_details = res.data.paket_items
            pagedata.show_details = true
          }).catch((err) => {
            pagedata.show_details = false
            swal("Warning!", err.response.data.error, "warning");
          })
      }
      function acceptRequest() {
        swal({
        title: "Are you sure?",
        text: "You Want to Accept Request!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Accept",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
          if(result.isConfirmed){
            axios({
              url: 'api/request/accept_mdl_parameter_request',
              method: 'post',
              baseURL: BASE_URL,
              data:formData,
              headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
              config: { headers: { 'Accept':'application/json' } }
            }).then((res) => {
              router.push({ name: 'notification-edit-param'})
              swal("Success!", "Request Accepted.", "success");
              getParams()
            }).catch(() => {
              swal("Warning!", "Request Not Accepted!", "warning");
            })
          }
      });
      }
      function getDiamontData() {
        axios({
          url: 'api/master/get_all_parameters',
          method: 'post',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          diamond.colors = res.data.color
          diamond.cuts = res.data.cut
          diamond.purities = res.data.purity
          diamond.polish = res.data.polish
          diamond.shapes = res.data.shape
          diamond.symmetry = res.data.symmetry
          diamond.fluerocents = res.data.fluerocent
          diamond.tensions = res.data.tension
          diamond.stones = res.data.stone
        })
      }
      function getChangeDetails(changes) {
        console.warn(changes)
        pagedata.changes = changes
        pagedata.weight_issue = changes.weight_issue
        pagedata.param_issue = changes.param_issue
        cash('#edit-confirmation-modal').modal('show')
        // cash('#delete-confirmation-modal').modal('hide')
      }
      function updateParameterDetails() {
        let params = {
            penalty_data: formData,
            parameter_change: pagedata.changes,
          }
        console.warn(params)
        pagedata.submit = true
        axios({
          url: 'api/request/update_mdl_process_params',
          method: 'post',
          baseURL: BASE_URL,
          data:params,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.submit = false
          cash('#edit-confirmation-modal').modal('hide')
          formData.manager_panalty = 0
          formData.employee_panalty = 0
          formData.newpwt = 0
          formData.panalty = 0
          formData.description = ''
          pagedata.param_issue = false
          pagedata.weight_issue = false
          pagedata.changes = []
          getPaketDetail()
        }).catch(() => {
          pagedata.submit = false
          cash('#edit-confirmation-modal').modal('hide')
          swal("Warning!", "Please Change Parameters !", "warning");
        })
      }
      return {
        pagedata,user_details,diamond,formData,getPaketDetail,getChangeDetails,updateParameterDetails,acceptRequest
      }
    }
  })
  </script>

<template>
  <div>
    <h2 class="intro-y text-lg font-medium mt-10">Edit Parameter Requests</h2>
      <!-- BEGIN: Data List -->
      <div v-if="pagedata.loading == true" class="text-center text-md mt-5">Loading...</div>
      <div v-else>
        <div v-if="pagedata.params.length <= 0" class="text-center text-md mt-5">Requests Not Found</div>
        <div v-else class="intro-y col-span-12 overflow-auto lg:overflow-visible mt-5">
          <table class="table-report -mt-2 w-full">
            <thead>
              <tr>
                <th class="py-2 whitespace-nowrap">No</th>
                <th class="py-2 whitespace-nowrap">User</th>
                <th class="py-2 whitespace-nowrap">Packet</th>
                <th class="py-2 whitespace-nowrap">T_Id</th>
                <th class="py-2 whitespace-nowrap">Process</th>
                <th class="py-2 whitespace-nowrap">Weight</th>
                <th class="py-2 whitespace-nowrap">Polish WT</th>
                <th class="py-2 whitespace-nowrap">Shape</th>
                <th class="py-2 whitespace-nowrap">Color</th>
                <th class="py-2 whitespace-nowrap">Purity</th>
                <th class="py-2 whitespace-nowrap">Cut</th>
                <th class="py-2 whitespace-nowrap">Polish</th>
                <th class="py-2 whitespace-nowrap">Symm</th>
                <th class="py-2 whitespace-nowrap">Fluer</th>
                <th class="py-2 whitespace-nowrap">Tension</th>
                <th class="py-2 whitespace-nowrap">Stone</th>
                <!-- <th class="py-2 whitespace-nowrap">remark</th> -->
                <th class="py-2 whitespace-nowrap">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(rate, index) in pagedata.params"
                :key="index"
                class="intro-x"
              >
                <td  class="text-center">{{ index + 1 }}</td>
                <td>
                  <div class="font-medium whitespace-nowrap">{{
                    rate.manager_first_name + ' ' + rate.manager_last_name
                  }}</div>
                </td>
                <td class="py-2 font-medium text-center">{{ rate.packet_code }}</td>
                <td class="py-2 font-medium text-center">{{ rate.transaction_code }}</td>
                <td class="py-2 font-medium text-center">{{ rate.process_name }}</td>
                <td class="py-2 font-medium text-center"><div v-if="rate.cr_polish_weight" :class="{ 'text-primary-3': rate.weight < rate.cr_polish_weight }">{{ rate.weight }}</div></td>
                <td class="py-2 font-medium text-center"><div v-if="rate.cr_polish_weight">{{ rate.cr_polish_weight }}</div></td>
                <td class="py-2 font-medium text-center"><span :class="{ 'text-primary-3': rate.shape_name != rate.prev_shape_name }">{{ rate.shape_name }}</span> / {{ rate.prev_shape_name }}</td>
                <td class="py-2 font-medium text-center"><span :class="{ 'text-primary-3': rate.color_name != rate.prev_color_name }">{{ rate.color_name }}</span> / {{ rate.prev_color_name }}</td>
                <td class="py-2 font-medium text-center"><span :class="{ 'text-primary-3': rate.purity_name != rate.prev_purity_name }">{{ rate.purity_name }}</span> / {{ rate.prev_purity_name }}</td>
                <td class="py-2 font-medium text-center"><span :class="{ 'text-primary-3': rate.cut_name != rate.prev_cut_name }">{{ rate.cut_name }}</span> / {{ rate.prev_cut_name }}</td>
                <td class="py-2 font-medium text-center"><span :class="{ 'text-primary-3': rate.polish_name != rate.prev_polish_name }">{{ rate.polish_name }}</span> / {{ rate.prev_polish_name }}</td>
                <td class="py-2 font-medium text-center"><span :class="{ 'text-primary-3': rate.symmetry_name != rate.prev_symmetry_name }">{{ rate.symmetry_name }}</span> / {{ rate.prev_symmetry_name }}</td>
                <td class="py-2 font-medium text-center"><span :class="{ 'text-primary-3': rate.fluerocent_name != rate.prev_fluerocent_name }">{{ rate.fluerocent_name }}</span> / {{ rate.prev_fluerocent_name }}</td>
                <td class="py-2 font-medium text-center"><span :class="{ 'text-primary-3': rate.tension_name != rate.prev_tension_name }">{{ rate.tension_name }}</span> / {{ rate.prev_tension_name }}</td>
                <td class="py-2 font-medium text-center"><span :class="{ 'text-primary-3': rate.stone_name != rate.prev_stone_name }">{{ rate.stone_name }}</span> / {{ rate.prev_stone_name }}</td>
                <!-- <td class="py-2 font-medium text-center">{{ rate.remark }}</td> -->
                <td class="py-2 table-report__action">
                  <div class="flex justify-center items-center">
                    <button class="btn btn-success mr-1 p-1" @click="AcceptRequest(rate.id)">
                      <CheckSquareIcon class="w-5 h-5" /><span v-if="user_details.user_role != 1"> Accept</span>
                    </button>
                    <button class="btn btn-danger p-1" v-if="user_details.user_role == 1" @click="cancelRequest(rate.id)"><XSquareIcon class="w-5 h-5" /></button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- END: Data List -->
  </div>
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import axios from 'axios'
import Toastify from 'toastify-js'
import cash from 'cash-dom'
import { computed, defineComponent, inject, onMounted, reactive, ref, toRef, watch } from 'vue'
import store from '@/store'
import router from '@/router'

export default defineComponent({
  setup() {
    const swal = inject('$swal')
    const user_details = computed(() => store.state.user.currentUser)
    const pagedata = reactive({
      params: [],
      loading: false,
    })
    onMounted(() => {
      getParams()
    })
    function getParams() {
      pagedata.loading = true
      axios({
        url: 'api/request/get_parameter_edit_requests',
        method: 'post',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.loading = false
        pagedata.params = res.data.params
      }).catch(() => {
        pagedata.loading = false
      })
    }
    function cancelRequest(id) {

      swal({
        title: "Are you sure?",
        text: "You Want to Cancel Request!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
          if(result.isConfirmed){
            axios({
              url: 'api/request/cancel_parameter_request',
              method: 'post',
              baseURL: BASE_URL,
              data: {id : id},
              headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
              config: { headers: { 'Accept':'application/json' } }
            }).then((res) => {
              cash('#delete-confirmation-modal').modal('hide')
              swal("Success!", "Request Cancelled.", "success");
              getParams()
            }).catch(() => {
              swal("Warning!", "Request Not Cancelled!", "warning");
            })
          }
      });
    }
    function AcceptRequest(id) {

      if(user_details.value.user_role == 1) {
        router.push({ path: '/notification/edit-param/' + window.btoa(id)})
      } else {
        swal({
        title: "Are you sure?",
        text: "You Want to Accept Request!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Accept",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
          if(result.isConfirmed){
            axios({
              url: 'api/request/accept_parameter_request_manager',
              method: 'post',
              baseURL: BASE_URL,
              data: {id : id},
              headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
              config: { headers: { 'Accept':'application/json' } }
            }).then((res) => {
              swal("Success!", "Request Accepted.", "success");
              getParams()
            }).catch(() => {
              swal("Warning!", "Request Not Accepted!", "warning");
            })
          }
        });
      }
    }
    return {
      pagedata,
      user_details,
      cancelRequest,
      AcceptRequest
    }
  }
})
</script>

<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: General Report -->
        <div class="col-span-12 mt-5">
          <div class="grid grid-cols-12 gap-6 mt-5">
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex items-center justify-between">
                    <img src="@/assets/images/diamond.gif" class="w-14 h-14 text-theme-9 mb-2" alt="">
                    <div class="text-3xl font-medium leading-8 mt-6">{{ pagedata.headings.total_packets }}</div>
                  </div>
                  <div class="flex items-center justify-between">
                    <div class="text-base text-gray-600 mt-1">Total Packets</div>
                    <div class="text-base text-gray-600 mt-1">{{ parseFloat(pagedata.headings.total_packets_weight).toFixed(2) }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex items-center justify-between">
                    <img src="@/assets/images/processing.gif" class="w-14 h-14 mb-2 text-theme-10" alt="">
                    <div class="text-3xl font-medium leading-8 mt-6">{{ pagedata.headings.processing }}</div>
                  </div>
                  <div class="flex items-center justify-between">
                    <div class="text-base text-gray-600 mt-1">In Process</div>
                    <div class="text-base text-gray-600 mt-1">{{ parseFloat(pagedata.headings.processing_weight).toFixed(2) }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex items-center justify-between">
                    <img src="@/assets/images/shoppingcart.gif" class="w-16 h-16 text-theme-12" alt="">
                    <div class="text-3xl font-medium leading-8 mt-6">{{ pagedata.headings.in_purchase }}</div>
                  </div>
                  <div class="flex items-center justify-between">
                    <div class="text-base text-gray-600 mt-1">In Purchase</div>
                    <div class="text-base text-gray-600 mt-1">{{ parseFloat(pagedata.headings.in_purchase_weight).toFixed(2) }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex items-center justify-between">
                    <img src="@/assets/images/in_outward.png" class="w-16 h-16 text-theme-12" alt="">
                    <div class="text-3xl font-medium leading-8 mt-6">{{ pagedata.headings.outward }}</div>
                  </div>
                  <div class="flex items-center justify-between">
                    <div class="text-base text-gray-600 mt-1">In Delivery</div>
                    <div class="text-base text-gray-600 mt-1">{{ parseFloat(pagedata.headings.outward_weight).toFixed(2) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: General Report -->
        <!-- BEGIN: Sales Report -->
        <div class="col-span-12 lg:col-span-6 mt-8">
          <div class="intro-y block sm:flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">Packets Growth</h2>
          </div>
          <div class="intro-y box p-5 mt-12 sm:mt-5">
            <div class="flex flex-col xl:flex-row xl:items-center">
              <div class="flex">
                <div>
                  <div
                    class="text-theme-19 dark:text-gray-300 text-lg xl:text-xl font-medium"
                  >
                    {{ pagedata.currerrent_month_packets }} Packets
                  </div>
                  <div class="mt-0.5 text-gray-600 dark:text-gray-600">
                    This Month
                  </div>
                </div>
                <div
                  class="w-px h-12 border border-r border-dashed border-gray-300 dark:border-dark-5 mx-4 xl:mx-5"
                ></div>
                <div>
                  <div
                    class="text-gray-600 dark:text-gray-600 text-lg xl:text-xl font-medium"
                  >
                    {{ pagedata.prev_month_packets }} Packets
                  </div>
                  <div class="mt-0.5 text-gray-600 dark:text-gray-600">
                    Last Month
                  </div>
                </div>
              </div>
            </div>
            <div>
              <ReportLineChart :height="169" class="mt-6" :grawth="pagedata.party_growth" :last_grawth="pagedata.last_year_party_growth" />
            </div>
          </div>
        </div>
        <!-- END: Sales Report -->
        <!-- BEGIN: Weekly Top Seller -->
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 mt-8">
          <div class="intro-y flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">Packets</h2>
          </div>
          <div class="intro-y box p-5 mt-5">
            <Chart
              type="pie"
              :height="300"
              :data="data"
              :options="options" class="mt-3"
            />
            <div class="mt-8">
              <div class="flex items-center">
                <div class="w-2 h-2 bg-theme-11 rounded-full mr-3"></div>
                <span class="truncate">Processing</span>
                <div
                  class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                ></div>
                <span class="font-medium xl:ml-auto">{{ pagedata.headings.processing }}</span>
              </div>
              <div class="flex items-center mt-4">
                <div class="w-2 h-2 bg-theme-12 rounded-full mr-3"></div>
                <span class="truncate">In Purchase</span>
                <div
                  class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                ></div>
                <span class="font-medium xl:ml-auto">{{ pagedata.headings.in_purchase }}</span>
              </div>
              <div class="flex items-center mt-4">
                <div class="w-2 h-2 bg-theme-1 rounded-full mr-3"></div>
                <span class="truncate">Outward</span>
                <div
                  class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                ></div>
                <span class="font-medium xl:ml-auto">{{ pagedata.headings.outward }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Weekly Top Seller -->
        <!-- BEGIN: Sales Report -->
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 mt-8">
          <div class="intro-y flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">Packet Weight</h2>
          </div>
          <div class="intro-y box p-5 mt-5">
            <Chart
              type="doughnut"
              :height="300"
              :data="weight_data"
              :options="options" class="mt-3"
            />
            <div class="mt-8">
              <div class="flex items-center">
                <div class="w-2 h-2 bg-theme-11 rounded-full mr-3"></div>
                <span class="truncate">Processing Weight</span>
                <div
                  class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                ></div>
                <span class="font-medium xl:ml-auto">{{ pagedata.headings.processing_weight }}</span>
              </div>
              <div class="flex items-center mt-4">
                <div class="w-2 h-2 bg-theme-12 rounded-full mr-3"></div>
                <span class="truncate">In Purchase Weight</span>
                <div
                  class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                ></div>
                <span class="font-medium xl:ml-auto">{{ pagedata.headings.in_purchase_weight }}</span>
              </div>
              <div class="flex items-center mt-4">
                <div class="w-2 h-2 bg-theme-1 rounded-full mr-3"></div>
                <span class="truncate">Outward Weight</span>
                <div
                  class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                ></div>
                <span class="font-medium xl:ml-auto">{{ pagedata.headings.outward_weight }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Sales Report -->
        <!-- BEGIN: Weekly Top Products -->
        <div class="col-span-12 mt-3">
          <div class="intro-y block sm:flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">
              In Delivery Packets
            </h2>
          </div>
          <div class="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0">
            <table class="table-report w-full">
              <thead>
                <tr>
                  <th class="text-center whitespace-nowrap">#</th>
                  <th class="text-center whitespace-nowrap">Number</th>
                  <th class="text-center whitespace-nowrap">Weight</th>
                  <th class="text-center whitespace-nowrap">PWT</th>
                  <th class="text-center whitespace-nowrap">Shp</th>
                  <th class="text-center whitespace-nowrap">Clr</th>
                  <th class="text-center whitespace-nowrap">Prt</th>
                  <th class="text-center whitespace-nowrap">Cut</th>
                  <th class="text-center whitespace-nowrap">Pol</th>
                  <th class="text-center whitespace-nowrap">Sym</th>
                  <th class="text-center whitespace-nowrap">FL</th>
                  <th class="text-center whitespace-nowrap">TN</th>
                  <th class="text-center whitespace-nowrap">Stn</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(packet, index) in pagedata.delivery"
                  :key="index"
                  class="intro-x"
                >
                  <td class="py-2 text-center whitespace-nowrap">{{ index + 1 }}</td>
                  <td class="py-2 text-center whitespace-nowrap">{{ packet.number }}</td>
                  <td class="py-2 text-center whitespace-nowrap">{{ packet.weight }}</td>
                  <td class="py-2 text-center whitespace-nowrap">{{ packet.pwt > 0 }}</td>
                  <td class="py-2 text-center whitespace-nowrap">{{ packet.shape }}</td>
                  <td class="py-2 text-center whitespace-nowrap">{{ packet.color }}</td>
                  <td class="py-2 text-center whitespace-nowrap">{{ packet.purity }}</td>
                  <td class="py-2 text-center whitespace-nowrap">{{ packet.cut }}</td>
                  <td class="py-2 text-center whitespace-nowrap">{{ packet.polish }}</td>
                  <td class="py-2 text-center whitespace-nowrap">{{ packet.symmetry }}</td>
                  <td class="py-2 text-center whitespace-nowrap">{{ packet.fluerocent }}</td>
                  <td class="py-2 text-center whitespace-nowrap">{{ packet.tension }}</td>
                  <td class="py-2 text-center whitespace-nowrap">{{ packet.stone }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- BEGIN: Pagination -->
          <div
            class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center mt-3 mb-7"
          >
            <ul class="pagination">
              <li>
                <button class="pagination__link" :disabled="pagedata.current_page == 1" @click="PartyDashBoardurl(pagedata.first_page_url)">
                  <ChevronsLeftIcon class="w-4 h-4" />
                </button>
              </li>
              <li>
                <button class="pagination__link" :disabled="pagedata.prev_page_url == null" @click="PartyDashBoardurl(pagedata.prev_page_url)">
                  <ChevronLeftIcon class="w-4 h-4" />
                </button>
              </li>
              <li v-for="(pagelink,index) in pagelinks" :key="index">
                <button class="pagination__link" :class="{'pagination__link--active' : pagelink.active == true}" v-if="pagelinks[0] != pagelink && pagelinks[pagelinks.length - 1] != pagelink" @click='PartyDashBoardurl(pagelink.url)'>{{ pagelink.label }}</button>
              </li>
              <li>
                <button class="pagination__link" :disabled="pagedata.next_page_url == null" @click="PartyDashBoardurl(pagedata.next_page_url)">
                  <ChevronRightIcon class="w-4 h-4" />
                </button>
              </li>
              <li>
                <button class="pagination__link" :disabled="pagedata.current_page == pagedata.last_page" @click="PartyDashBoardurl(pagedata.last_page_url)">
                  <ChevronsRightIcon class="w-4 h-4" />
                </button>
              </li>
            </ul>
            <select class="w-20 form-select box mt-3 sm:mt-0" v-model="pagedata.size" @change="PartyDashBoard">
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
            </select>
          </div>
          <!-- END: Pagination -->
        </div>
        <!-- END: Weekly Top Products -->
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { defineComponent, ref, provide, onMounted, reactive, toRef, computed } from 'vue'
import ReportLineChart from '@/components/line-chart/Main.vue'
import ReportDonutChart from '@/components/report-donut-chart/Main.vue'
import ReportPieChart from '@/components/report-pie-chart/Main.vue'
import axios from 'axios'
import { BASE_URL } from '@/constants/config'
import store from '@/store'

export default defineComponent({
  components: {
    ReportLineChart,
    ReportDonutChart,
    ReportPieChart
  },
  setup() {
    const salesReportFilter = ref()
    const importantNotesRef = ref()

    provide('bind[importantNotesRef]', el => {
      importantNotesRef.value = el
    })

    const prevImportantNotes = () => {
      const el = importantNotesRef.value
      el.tns.goTo('prev')
    }
    const nextImportantNotes = () => {
      const el = importantNotesRef.value
      el.tns.goTo('next')
    }
    const pagedata = reactive({
      headings: [],
      size: 5,
      currerrent_month_packets: null,
      prev_month_packets: null,
      party_growth: [],
      last_year_party_growth: [],
      delivery: [],
      pagelinks: [],
      prev_page_url: null,
      next_page_url: null,
      first_page_url: null,
      last_page_url: null,
      current_page: 1,
      last_page: ''
    })
    const darkMode = computed(() => store.state.main.darkMode)
    const data = computed(() => {
      return {
        labels: ['Processing', 'In Purchase','Outward'],
        datasets: [
          {
            data: [pagedata.headings.processing, pagedata.headings.in_purchase, pagedata.headings.outward],
            backgroundColor: ['#FF8B26', '#FFC533', '#285FD3'],
            hoverBackgroundColor: ['#FF8B26', '#FFC533', '#285FD3'],
            borderWidth: 5,
            borderColor: darkMode.value ? '#303953' : '#fff'
          }
        ]
      }
    })
    const weight_data = computed(() => {
      return {
        labels: ['Processing Weight', 'In Purchase Weight','Outward Weight'],
        datasets: [
          {
            data: [pagedata.headings.processing_weight, pagedata.headings.in_purchase_weight, pagedata.headings.outward_weight],
            backgroundColor: ['#FF8B26', '#FFC533', '#285FD3'],
            hoverBackgroundColor: ['#FF8B26', '#FFC533', '#285FD3'],
            borderWidth: 5,
            borderColor: darkMode.value ? '#303953' : '#fff'
          }
        ]
      }
    })
    const options = computed(() => {
      return {
        legend: {
          display: false
        }
      }
    })
    const pagelinks = toRef(pagedata, 'pagelinks')
    onMounted(() => {
      PartyDashBoard()
    })
    function PartyDashBoardurl() {
        axios({
          url: url + `&size${pagedata.size}`,
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
           pagedata.headings = res.data.headings[0]
          pagedata.currerrent_month_packets = res.data.currerrent_month_packets
          pagedata.prev_month_packets = res.data.prev_month_packets
          pagedata.party_growth = res.data.party_growth
          pagedata.last_year_party_growth = res.data.last_year_party_growth
          pagedata.delivery = res.data.delivery_packets.data
          pagedata.pagelinks = res.data.delivery_packets.links
          pagedata.prev_page_url = res.data.delivery_packets.prev_page_url
          pagedata.next_page_url = res.data.delivery_packets.next_page_url
          pagedata.first_page_url = res.data.delivery_packets.first_page_url
          pagedata.last_page_url = res.data.delivery_packets.last_page_url
          pagedata.current_page = res.data.delivery_packets.current_page
          pagedata.last_page = res.data.delivery_packets.last_page
        })
    }
    function PartyDashBoard() {
        axios({
          url: `/dashboard/jober?size=${pagedata.size}`,
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.headings = res.data.headings[0]
          pagedata.currerrent_month_packets = res.data.currerrent_month_packets
          pagedata.prev_month_packets = res.data.prev_month_packets
          pagedata.party_growth = res.data.party_growth
          pagedata.last_year_party_growth = res.data.last_year_party_growth
          pagedata.delivery = res.data.delivery_packets.data
          pagedata.pagelinks = res.data.delivery_packets.links
          pagedata.prev_page_url = res.data.delivery_packets.prev_page_url
          pagedata.next_page_url = res.data.delivery_packets.next_page_url
          pagedata.first_page_url = res.data.delivery_packets.first_page_url
          pagedata.last_page_url = res.data.delivery_packets.last_page_url
          pagedata.current_page = res.data.delivery_packets.current_page
          pagedata.last_page = res.data.delivery_packets.last_page
        })
    }
    return {
      pagedata,
      pagelinks,
      data,
      weight_data,
      options,
      salesReportFilter,
      prevImportantNotes,
      nextImportantNotes,
      PartyDashBoard,
      PartyDashBoardurl
    }
  }
})
</script>

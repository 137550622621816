<template>
  <div>
    <div id="basic-non-sticky-notification-content" class="toastify-content hidden flex flex-col sm:flex-row">
      <div class="font-medium" v-if="pagedata.message">{{ pagedata.message }}</div>
    </div>
    <!-- BEgin: Process Report Start-->
    <div class="intro-y box p-5 pt-2 mt-5">
      <h2 class="intro-y text-lg font-medium mt-2">Alert Rates</h2>
      <div class="overflow-x-auto scrollbar-hidden">
        <div class="intro-y col-span-12 overflow-auto">
          <table class="mt-2 w-full border-2">
            <thead>
              <tr class="bg-theme-1 text-white">
                <th class="py-2 text-xs whitespace-nowrap">#</th>
                <th class="py-2 text-xs whitespace-nowrap">Main Type</th>
                <th class="py-2 text-xs whitespace-nowrap">Rate Type</th>
                <th class="py-2 text-xs whitespace-nowrap">Rate</th>
                <th class="py-2 text-xs whitespace-nowrap">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(rate, index) in pagedata.rates" :key="index" class="cursor-pointer">
                <td class="py-2 border-b text-xs text-center">{{ index + 1 }}</td>
                <td class="py-2 border-b text-xs text-center">{{ rate.main_type }}</td>
                <td class="py-2 border-b text-xs text-center">{{ rate.actions }}</td>
                <td class="py-2 border-b text-xs text-center">{{ rate.value }}</td>
                <td class="py-2 border-b text-xs text-center">
                  <div class="flex items-center justify-center">
                    <button class="flex items-center mr-3" @click="setUpdateData(rate.id, rate.value)">
                      <CheckSquareIcon class="w-4 h-4 mr-1" />
                      Edit
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- BEGIN: Create Color Modal -->
    <div id="new-order-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">Alert Rate</h2>
          </div>
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
            <div class="col-span-12">
              <h3 class="text-md text-primary-3 mb-2" v-if="pagedata.error">{{ pagedata.error }}</h3>
              <div class="flex flex-wrap mb-2">
                <input
                  id="pos-form-1"
                  type="text"
                  v-model="pagedata.rate"
                  class="form-control flex-1"
                  placeholder="Add Bonus Rates"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer text-right">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-24 mr-1"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-primary w-24" :disabled="pagedata.submit" @click="updateRates()">
              Update
              <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Create Color Modal -->
  </div>
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import axios from 'axios'
import cash from 'cash-dom'
import { defineComponent, inject, onMounted, reactive, ref, toRef } from 'vue'

export default defineComponent({
  setup() {
    const pagedata = reactive({
      rate: '',
      updateId: '',
      message: '',
      error: '',
      rates: [],
      submit: false,
    })
    // const swal = inject('$swal')
    onMounted(() => {
      getDetails()
    })
    function getDetails() {
      axios({
        url: 'api/majuri_costing/majuri_costing_details',
        method: 'post',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.rates = res.data.items
      })
    }
    function updateRates() {
      if(pagedata.rate == '') {
        pagedata.error = 'Rate is Required !'
      } else {

        pagedata.submit = true
        axios({
          url: 'api/majuri_costing/update_values',
          method: 'post',
          baseURL: BASE_URL,
          data: { value: window.btoa(pagedata.rate),id: window.btoa(pagedata.updateId) },
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.submit = false
          getDetails()
          cash('#new-order-modal').modal('hide')
        })
      }
    }
    function setUpdateData(id, rate) {
      pagedata.updateId = id
      pagedata.rate = rate
      cash('#new-order-modal').modal('show')
    }
    return {
      pagedata,
      setUpdateData,
      updateRates
    }
  }
})
</script>

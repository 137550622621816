<template>
  <div>
    <div class="pos intro-y grid">
      <h2 class="text-lg font-medium mr-auto">Process Report</h2>
      <!-- BEGIN: Item List -->
      <div class="intro-y col-span-12">
        <div class="grid grid-cols-12 gap-3 mt-1">
          <div class="col-span-4 sm:col-span-2 box text-center cursor-pointer zoom-in p-1">
              <div>
                <div class="text-xs whitespace-nowrap overflow-hidden">Admin</div>
                <div class="text-md">
                  <span class="text-md mr-2 px-2 rounded-lg text-white bg-primary-1">{{ pagedata.admin_total_packets }}</span>
                </div>
              </div>
          </div>
          <div class="col-span-4 sm:col-span-2 box text-center cursor-pointer zoom-in p-1" @click="getManagerPkt">
              <div>
                <div class="text-xs whitespace-nowrap overflow-hidden">Manager</div>
                <div class="text-md">
                  <span class="text-md mr-2 px-2 rounded-lg text-white bg-primary-4">{{ pagedata.manager_total_packets }}</span>
                </div>
              </div>
          </div>
          <div class="col-span-4 sm:col-span-2 box text-center cursor-pointer zoom-in p-1">
              <div>
                <div class="text-xs whitespace-nowrap overflow-hidden">External</div>
                <div class="text-md">
                  <span class="text-md mr-2 px-2 rounded-lg text-white bg-primary-3">{{ pagedata.external_packets }}</span>
                </div>
              </div>
          </div>
          <div class="col-span-4 sm:col-span-2 box text-center cursor-pointer zoom-in p-1">
              <div>
                <div class="text-xs whitespace-nowrap overflow-hidden">Admin Discuss</div>
                <div class="text-md">
                  <span class="text-md mr-2 px-2 rounded-lg text-white bg-primary-9">{{ pagedata.admin_discuss_packets }}</span>
                </div>
              </div>
          </div>
          <div class="col-span-4 sm:col-span-2 box text-center cursor-pointer zoom-in p-1">
              <div>
                <div class="text-xs whitespace-nowrap overflow-hidden">Party Discuss</div>
                <div class="text-md">
                  <span class="text-md mr-2 px-2 rounded-lg text-white bg-primary-6">{{ pagedata.party_discuss_packets }}</span>
                </div>
              </div>
          </div>
        </div>
      </div>
      <!-- END: Item List -->
    </div>
    <!-- BEgin: Process Report Start-->
    <div class="intro-y box p-5 mt-5">
      <div class="overflow-x-auto scrollbar-hidden">
        <div class="intro-y col-span-12 overflow-auto">
          <table class="mt-2 w-full border-2">
            <thead>
              <tr class="bg-theme-1 text-white">
                <th class="py-2 text-xs whitespace-nowrap">#</th>
                <th class="py-2 text-xs whitespace-nowrap">Process</th>
                <th class="py-2 text-xs whitespace-nowrap">Packets</th>
                <th class="py-2 text-xs whitespace-nowrap">Weight</th>
              </tr>
            </thead>
            <tbody>
              <tr class="bg-gray-200 text-gray-700">
                <td class="py-2 border-b text-xs text-center"></td>
                <td class="py-2 border-b text-xs text-center">{{ pagedata.total_processes }}</td>
                <td class="py-2 border-b text-xs text-center">{{ pagedata.total_packets }}</td>
                <td class="py-2 border-b text-xs text-center">{{ parseFloat(pagedata.total_weight).toFixed(2) }}</td>
              </tr>
              <tr v-for="(packet, index) in pagedata.packets" :key="index" @click="getEmployeePkt(packet.id)" class="cursor-pointer">
                <td class="py-2 border-b text-xs text-center">{{ index + 1 }}</td>
                <td class="py-2 border-b text-xs text-center">{{ packet.process_name }}</td>
                <td class="py-2 border-b text-xs text-center">{{ packet.packets }}</td>
                <td class="py-2 border-b text-xs text-center">{{ parseFloat(packet.total_issue_weight == null ? 0 : packet.total_issue_weight).toFixed(2) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- BEGIN: Compare Packet Plan Details Modal -->
    <div
      id="employee_process_modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <a data-dismiss="modal" href="javascript:;">
            <XIcon class="w-8 h-8 text-gray-500" />
          </a>
          <div class="modal-body py-5 text-center">
            <!-- BEGIN: HTML Table Data -->
            <!-- BEGIN: Data List -->
            <h2 class="text-lg font-medium mr-auto">Employee Packets</h2>
              <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
                <table class="mt-2 w-full border-2">
                  <thead>
                    <tr class="bg-theme-1 text-white">
                      <th class="py-2 text-xs whitespace-nowrap">#</th>
                      <th class="py-2 text-xs whitespace-nowrap">Employee</th>
                      <th class="py-2 text-xs whitespace-nowrap">Packets</th>
                      <th class="py-2 text-xs whitespace-nowrap">Weight</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="bg-gray-200 text-gray-700">
                      <td class="py-2 border-b text-xs text-center"></td>
                      <td class="py-2 border-b text-xs text-center"></td>
                      <td class="py-2 border-b text-xs text-center">{{ pagedata.employee_total_packets }}</td>
                      <td class="py-2 border-b text-xs text-center">{{ parseFloat(pagedata.employee_total_weight).toFixed(2) }}</td>
                    </tr>
                    <tr v-for="(employee, index) in pagedata.employees" :key="index" class="cursor-pointer">
                          <td class="py-2 border-b text-xs text-center">{{ index + 1 }}</td>
                          <td class="py-2 border-b text-xs text-center">{{ employee.emp_name }}</td>
                          <td class="py-2 border-b text-xs text-center">{{ employee.total_packets }}</td>
                          <td class="py-2 border-b text-xs text-center">{{ parseFloat(employee.total_weight).toFixed(2) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- END: Data List -->
            <!-- END: HTML Table Data -->
          </div>
        </div>
      </div>
    </div>
    <!-- END: Compare Packet Plan Details Modal -->
    <!-- BEGIN: Compare Packet Plan Details Modal -->
    <div
      id="manager_process_modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <a data-dismiss="modal" href="javascript:;">
            <XIcon class="w-8 h-8 text-gray-500" />
          </a>
          <div class="modal-body py-5 text-center">
            <!-- BEGIN: HTML Table Data -->
            <!-- BEGIN: Data List -->
            <h2 class="text-lg font-medium mr-auto">Manager Packets</h2>
              <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
                <table class="mt-2 w-full border-2">
                  <thead>
                    <tr class="bg-theme-1 text-white">
                      <th class="py-2 text-xs whitespace-nowrap">#</th>
                      <th class="py-2 text-xs whitespace-nowrap">manager</th>
                      <th class="py-2 text-xs whitespace-nowrap">Packets</th>
                      <th class="py-2 text-xs whitespace-nowrap">Weight</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="bg-gray-200 text-gray-700">
                      <td class="py-2 border-b text-xs text-center"></td>
                      <td class="py-2 border-b text-xs text-center"></td>
                      <td class="py-2 border-b text-xs text-center">{{ pagedata.manager_total_packets }}</td>
                      <td class="py-2 border-b text-xs text-center">{{ parseFloat(pagedata.manager_total_weight).toFixed(2) }}</td>
                    </tr>
                    <tr v-for="(manager, index) in pagedata.managers" :key="index" class="cursor-pointer">
                          <td class="py-2 border-b text-xs text-center">{{ index + 1 }}</td>
                          <td class="py-2 border-b text-xs text-center">{{ manager.manager }}</td>
                          <td class="py-2 border-b text-xs text-center">{{ manager.total_packets }}</td>
                          <td class="py-2 border-b text-xs text-center">{{ parseFloat(manager.total_weight).toFixed(2) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- END: Data List -->
            <!-- END: HTML Table Data -->
          </div>
        </div>
      </div>
    </div>
    <!-- END: Compare Packet Plan Details Modal -->
  </div>
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config';
import axios from 'axios';
import cash from 'cash-dom';
import { defineComponent, onMounted, reactive, toRef, watch } from 'vue';

export default defineComponent({
  setup() {
    const pagedata = reactive({
      packets: [],
      employees: [],
      managers: [],
      total_weight: null,
      total_packets: null,
      employee_total_weight: null,
      employee_total_packets: null,
      manager_total_weight: null,
      manager_total_packets: null,
      total_processes : null,
      manager_total_packets : null,
      admin_total_packets : null,
      external_packets : null,
      admin_discuss_packets : null,
      party_discuss_packets: null
    })
    onMounted(() => {
      getAvailPkt()
    })
    function getAvailPkt() {
       axios({
        url: `/report/process_report`,
        method: 'get',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.packets = res.data.processes
        pagedata.total_packets = res.data.total_packets
        pagedata.total_processes = res.data.total_processes
        pagedata.manager_total_packets = res.data.manager_total_packets
        pagedata.admin_total_packets = res.data.admin_total_packets
        pagedata.external_packets = res.data.external_packets
        pagedata.admin_discuss_packets = res.data.admin_discuss_packets
        pagedata.party_discuss_packets = res.data.party_discuss_packets
        pagedata.total_weight = res.data.total_weight
      })
    }
    function getEmployeePkt(id) {
       axios({
        url: `/report/process_report_employee_packets/${id}`,
        method: 'get',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.employees = res.data.employees
        pagedata.employee_total_packets = res.data.total[0].total_packets
        pagedata.employee_total_weight = res.data.total[0].total_weight
        cash('#employee_process_modal').modal('show')
      })
    }
    function getManagerPkt() {
       axios({
        url: '/report/process_report_manager_packets',
        method: 'get',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.managers = res.data.managers
        pagedata.manager_total_packets = res.data.total[0].total_packets
        pagedata.manager_total_weight = res.data.total[0].total_weight
        cash('#manager_process_modal').modal('show')
      })
    }
    return {
      pagedata,
      getEmployeePkt,
      getManagerPkt
    }
  }
})
</script>

<template>
  <div>
    <div id="basic-non-sticky-notification" class="toastify-content hidden flex flex-col sm:flex-row">
      <div class="flex items-center justify-around" v-if="pagedata.message">
        <CheckCircleIcon class="w-6 h-6 text-theme-9 mx-auto" />
        <div class="font-medium text-black">{{ pagedata.message }}</div>
      </div>
      <div class="flex items-center justify-around" v-if="pagedata.error">
        <CheckCircleIcon class="w-6 h-6 text-theme-6 mx-auto" />
        <div class="font-medium text-black">{{ pagedata.error }}</div>
      </div>
    </div>
    <h2 class="intro-y text-lg font-medium mt-10">Rate Requests</h2>
      <!-- BEGIN: Data List -->
      <div v-if="pagedata.loading == true" class="text-center text-md mt-5">Loading...</div>
      <div v-else>
        <div v-if="rates.length <= 0" class="text-center text-md mt-5">Requests Not Found</div>
        <div v-else class="intro-y col-span-12 overflow-auto lg:overflow-visible mt-5">
          <table class="table-report -mt-2 w-full">
            <thead>
              <tr>
                <th class="py-2 whitespace-nowrap">No</th>
                <th class="py-2 whitespace-nowrap">User</th>
                <th class="py-2 whitespace-nowrap">Packet</th>
                <th class="py-2 whitespace-nowrap">T_Id</th>
                <th class="py-2 whitespace-nowrap">Process</th>
                <th class="py-2 whitespace-nowrap">Labour</th>
                <th class="py-2 whitespace-nowrap">Weight</th>
                <th class="py-2 whitespace-nowrap">Shape</th>
                <th class="py-2 whitespace-nowrap">Color</th>
                <th class="py-2 whitespace-nowrap">Purity</th>
                <th class="py-2 whitespace-nowrap">Cut</th>
                <th class="py-2 whitespace-nowrap">Polish</th>
                <th class="py-2 whitespace-nowrap">Symm</th>
                <th class="py-2 whitespace-nowrap">Fluer</th>
                <th class="py-2 whitespace-nowrap">Tension</th>
                <th class="py-2 whitespace-nowrap">Stone</th>
                <th class="py-2 whitespace-nowrap">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(rate, index) in rates"
                :key="index"
                class="intro-x"
              >
                <td  class="text-center">{{ index + 1 }}</td>
                <td>
                  <div class="font-medium whitespace-nowrap">{{
                    rate.user_id
                  }}</div>
                </td>
                <td class="py-2 font-medium text-center">{{ rate.packet }}</td>
                <td class="py-2 font-medium text-center">{{ rate.t_id }}</td>
                <td class="py-2 font-medium text-center">{{ rate.process }}</td>
                <td class="py-2 font-medium text-center"><span v-if="rate.labour == 1">Issue Weight</span><span v-if="rate.labour == 2">Fixed Piece</span><span v-if="rate.labour == 3">Weight + Weight</span><span v-if="rate.labour == 4">Piece + Piece</span><span v-if="rate.labour == 5">Weight Loss</span></td>
                <td class="py-2 font-medium text-center">{{ rate.weight }}</td>
                <td class="py-2 font-medium text-center">{{ rate.shape }}</td>
                <td class="py-2 font-medium text-center">{{ rate.color }}</td>
                <td class="py-2 font-medium text-center">{{ rate.purity }}</td>
                <td class="py-2 font-medium text-center">{{ rate.cut }}</td>
                <td class="py-2 font-medium text-center">{{ rate.polish }}</td>
                <td class="py-2 font-medium text-center">{{ rate.symmetry }}</td>
                <td class="py-2 font-medium text-center">{{ rate.fluerocent }}</td>
                <td class="py-2 font-medium text-center">{{ rate.tension }}</td>
                <td class="py-2 font-medium text-center">{{ rate.stone }}</td>
                <td class="py-2 table-report__action">
                  <div class="flex justify-center items-center">
                    <a class="btn btn-success mr-1 p-1" href="javascript:;" @click="updateManager(rate.id,rate.type,rate.packet_type)">
                      <CheckSquareIcon class="w-5 h-5" />
                    </a>
                    <a class="btn btn-danger p-1" href="javascript:;" data-toggle="modal"
                      data-target="#delete-confirmation-modal" @click="pagedata.deleteId = rate.id">
                      <XSquareIcon class="w-5 h-5" />
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- END: Data List -->
    <!-- BEGIN: Delete Confirmation Modal -->
    <div
      id="delete-confirmation-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
              <div class="text-3xl mt-5">Are you sure?</div>
              <div class="text-gray-600 mt-2">
                Do you really want to delete these records? <br />This process
                cannot be undone.
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-24 mr-1"
              >
                Cancel
              </button>
              <button type="button" class="btn btn-danger w-24" @click="cancelRequest">Delete</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Delete Confirmation Modal -->
  </div>
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import router from '@/router'
import axios from 'axios'
import Toastify from 'toastify-js'
import cash from 'cash-dom'
import { defineComponent, onMounted, reactive, ref } from 'vue'

export default defineComponent({
  setup() {
    const rates = ref([])
    const pagedata = reactive({
      error: '',
      message: '',
      deleteId: '',
      loading: true
    })
    onMounted(() => {
      getRates()
    })
    function getRates() {
      pagedata.loading = true
      axios({
        url: '/packet/rate-requests?type=manager',
        method: 'get',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.loading = false
        rates.value = res.data.rates
      }).catch(() => {
        pagedata.loading = false
      })
    }
    function updateManager(id,type,pkt_type) {
      if(pkt_type == 'rough') {
        router.push({ name: 'create-rough-employee-rate-request', params: { id: id, type:type } })
      } else {
        router.push({ name: 'create-employee-rate-request', params: { id: id} })
      }
    }
    function cancelRequest() {
      axios({
        url: `/packet/rate-cancel-requests/${pagedata.deleteId}`,
        method: 'get',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.message = res.data.success
        cash('#delete-confirmation-modal').modal('hide')
        setTimeout(() => {
          basicNonStickyNotification()
        }, 100)
        setTimeout(() => {
          pagedata.message = ''
        }, 5000);
        getRates()
      }).catch((err) => {
        pagedata.error = res.data.error
        setTimeout(() => {
          pagedata.error = ''
        }, 5000);
      })
    }
    const basicNonStickyNotification = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification')
          .clone()
          .removeClass('hidden')[0],
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    return {
      pagedata,
      rates,
      updateManager,
      cancelRequest
    }
  }
})
</script>

<template>
  <div>
    <div id="basic-non-sticky-notification-packet" class="toastify-content hidden flex flex-col sm:flex-row">
      <div class="font-medium text-primary-3" v-if="pagedata.error">{{ pagedata.error }}</div>
      <div class="font-medium text-primary-4" v-if="pagedata.success">{{ pagedata.success }}</div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex items-center justify-between">
        <h2 class="text-lg font-bold">Processing Packets</h2>
        <div class="flex items-center">
          <button class="btn btn-primary ml-2" @click="comparePlans"> Compare</button>
          <button class="btn btn-danger ml-2" @click="showRemoveConfirmation" v-if="pagedata.status == 2" :disabled="!pagedata.editable">Cancel</button>
          <button class="btn btn-danger ml-2" @click="getProcessData" v-else :disabled="!pagedata.editable">RePlanning</button>
          <button class="btn btn-danger ml-2" @click="getProcessData" v-if="pagedata.status == 2" :disabled="!pagedata.editable"> LsBs >> </button>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <!-- BEGIN: Cancel Selected Plan -->
    <div id="cancel-confirmation-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-3">
            <div class="p-5 text-center">
              <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
              <div class="text-3xl mt-5">Are you sure?</div>
              <div class="text-gray-600 mt-2">
                Do you really want to Send Request?
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-24 mr-1"
              >
                Cancel
              </button>
              <button type="button" class="btn btn-danger w-24" :disabled="pagedata.submit" @click="cancelPlan">Remove
              <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!-- END: Cancrl Selected Plan -->
   <!-- BEGIN: Packet Plan Details Modal -->
  <div
    id="packet-plans-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <a data-dismiss="modal" href="javascript:;">
          <XIcon class="w-8 h-8 text-gray-500" />
        </a>
        <div class="modal-body py-5 text-center">
          <!-- BEGIN: HTML Table Data -->
          <div class="intro-y box p-5 mt-5">
            <div class="overflow-x-auto scrollbar-hidden">
              <table class="w-full">
                <thead>
                  <tr class="bg-gray-700 dark:bg-dark-1 text-white">
                    <th class="whitespace-nowrap p-1">#</th>
                    <th class="whitespace-nowrap p-1">Number</th>
                    <th class="whitespace-nowrap p-1">PWT</th>
                    <th class="whitespace-nowrap p-1">Shape</th>
                    <th class="whitespace-nowrap p-1">Color</th>
                    <th class="whitespace-nowrap p-1">Purity</th>
                    <th class="whitespace-nowrap p-1">Cut</th>
                    <th class="whitespace-nowrap p-1">Polish</th>
                    <th class="whitespace-nowrap p-1">Symm</th>
                    <th class="whitespace-nowrap p-1">Fluc</th>
                    <th class="whitespace-nowrap p-1">Tension</th>
                    <th class="whitespace-nowrap p-1">Stone</th>
                    <th class="whitespace-nowrap p-1">R Rate</th>
                    <th class="whitespace-nowrap p-1">S Rate</th>
                    <th class="whitespace-nowrap p-1">Tabl</th>
                    <th class="whitespace-nowrap p-1">Height</th>
                    <th class="whitespace-nowrap p-1">Discount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(plandet, i) in pagedata.plans"
                    :key="i" :class="{ 'text-primary-3' : plandet.status == 2}">
                    <td class="border-b dark:border-dark-5 p-1">{{ i + 1 }}</td>
                    <td class="border-b dark:border-dark-5 p-1">{{ plandet.number }}</td>
                    <td class="border-b dark:border-dark-5 p-1">{{ plandet.pwt }}</td>
                    <td class="border-b dark:border-dark-5 p-1">{{ plandet.shape }}</td>
                    <td class="border-b dark:border-dark-5 p-1">{{ plandet.color }}</td>
                    <td class="border-b dark:border-dark-5 p-1">{{ plandet.purity }}</td>
                    <td class="border-b dark:border-dark-5 p-1">{{ plandet.cut }}</td>
                    <td class="border-b dark:border-dark-5 p-1">{{ plandet.polish }}</td>
                    <td class="border-b dark:border-dark-5 p-1">{{ plandet.symmetry }}</td>
                    <td class="border-b dark:border-dark-5 p-1">{{ plandet.fluerocent }}</td>
                    <td class="border-b dark:border-dark-5 p-1">{{ plandet.tension }}</td>
                    <td class="border-b dark:border-dark-5 p-1">{{ plandet.stone }}</td>
                    <td class="border-b dark:border-dark-5 p-1">{{ plandet.r_rate }}</td>
                    <td class="border-b dark:border-dark-5 p-1">{{ plandet.s_rate }}</td>
                    <td class="border-b dark:border-dark-5 p-1">{{ plandet.tabl }}</td>
                    <td class="border-b dark:border-dark-5 p-1">{{ plandet.height }}</td>
                    <td class="border-b dark:border-dark-5 p-1">{{ plandet.discount }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- END: HTML Table Data -->
        </div>
      </div>
    </div>
  </div>
  <!-- END: Packet Plan Details Modal -->
   <!-- BEGIN: Compare Packet Plan Details Modal -->
  <div
    id="compare-plans-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <a data-dismiss="modal" href="javascript:;">
          <XIcon class="w-8 h-8 text-gray-500" />
        </a>
        <div class="modal-body py-5 text-center">
          <!-- BEGIN: HTML Table Data -->
          <!-- BEGIN: Data List -->
            <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
              <table class="table table-report -mt-2 hidden">
                <thead>
                  <tr>
                    <th class="whitespace-nowrap p-3">No</th>
                    <th class="whitespace-nowrap p-3">Packet No</th>
                    <th class="whitespace-nowrap p-3">PKT Wt</th>
                    <th class="text-center whitespace-nowrap p-3">Rates</th>
                    <th class="text-center whitespace-nowrap p-3">Piece</th>
                    <th class="text-center whitespace-nowrap p-3">Status</th>
                  </tr>
                </thead>
              </table>
              <div v-for="(plan, index) in pagedata.packet_plans"
                  :key="index">
                <table class="table table-report">
                  <thead>
                  <tr>
                    <th class="whitespace-nowrap p-3">No</th>
                    <th class="whitespace-nowrap p-3">Packet No</th>
                    <th class="whitespace-nowrap p-3">PKT Wt</th>
                    <th class="text-center whitespace-nowrap p-3">Rates</th>
                    <th class="text-center whitespace-nowrap p-3">Piece</th>
                    <th class="text-center whitespace-nowrap p-3">Status</th>
                  </tr>
                </thead>
                  <tbody>
                    <tr
                      class="intro-x"
                    >
                      <td  class="text-center">{{ index + 1 }}</td>
                      <td>
                        <div class="font-medium whitespace-nowrap">{{
                          plan.number
                        }}</div>
                      </td>
                      <td class="font-medium whitespace-nowrap">{{plan.total_weight }}</td>
                      <td class="text-center">{{ plan.total_pwt }}</td>
                      <td class="text-center">{{ plan.price }}</td>
                      <td class="text-center text-primary-4">{{ plan.status ? 'Active' : '' }}</td>
                    </tr>
                  </tbody>
                </table>
                <div>
                  <table class="w-full">
                    <thead>
                      <tr class="bg-gray-700 dark:bg-dark-1 text-white">
                        <th class="whitespace-nowrap px-1">#</th>
                        <th class="whitespace-nowrap px-1">Number</th>
                        <th class="whitespace-nowrap px-1">PWT</th>
                        <th class="whitespace-nowrap px-1">Shape</th>
                        <th class="whitespace-nowrap px-1">Color</th>
                        <th class="whitespace-nowrap px-1">Purity</th>
                        <th class="whitespace-nowrap px-1">Cut</th>
                        <th class="whitespace-nowrap px-1">Polish</th>
                        <th class="whitespace-nowrap px-1">Symm</th>
                        <th class="whitespace-nowrap px-1">Fluc</th>
                        <th class="whitespace-nowrap px-1">Tension</th>
                        <th class="whitespace-nowrap px-1">Stone</th>
                        <th class="whitespace-nowrap px-1">R Rate</th>
                        <th class="whitespace-nowrap px-1">S Rate</th>
                        <th class="whitespace-nowrap px-1">Tabl</th>
                        <th class="whitespace-nowrap px-1">Height</th>
                        <th class="whitespace-nowrap px-1">Discount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(plandet, i) in plan.plan_details"
                  :key="i">
                        <td class="border-b dark:border-dark-5 px-1">{{ i + 1 }}</td>
                        <td class="border-b dark:border-dark-5 px-1">{{ plandet.number }}</td>
                        <td class="border-b dark:border-dark-5 px-1">{{ plandet.pwt }}</td>
                        <td class="border-b dark:border-dark-5 px-1">{{ plandet.shape }}</td>
                        <td class="border-b dark:border-dark-5 px-1">{{ plandet.color }}</td>
                        <td class="border-b dark:border-dark-5 px-1">{{ plandet.purity }}</td>
                        <td class="border-b dark:border-dark-5 px-1">{{ plandet.cut }}</td>
                        <td class="border-b dark:border-dark-5 px-1">{{ plandet.polish }}</td>
                        <td class="border-b dark:border-dark-5 px-1">{{ plandet.symmetry }}</td>
                        <td class="border-b dark:border-dark-5 px-1">{{ plandet.fluerocent }}</td>
                        <td class="border-b dark:border-dark-5 px-1">{{ plandet.tension }}</td>
                        <td class="border-b dark:border-dark-5 px-1">{{ plandet.stone }}</td>
                        <td class="border-b dark:border-dark-5 px-1">{{ plandet.r_rate }}</td>
                        <td class="border-b dark:border-dark-5 px-1">{{ plandet.s_rate }}</td>
                        <td class="border-b dark:border-dark-5 px-1">{{ plandet.tabl }}</td>
                        <td class="border-b dark:border-dark-5 px-1">{{ plandet.height }}</td>
                        <td class="border-b dark:border-dark-5 px-1">{{ plandet.discount }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- END: Data List -->
          <!-- END: HTML Table Data -->
        </div>
      </div>
    </div>
  </div>
  <!-- END: Compare Packet Plan Details Modal -->
   <!-- BEGIN: Process Assign Plan  Modal -->
  <div
    id="process-assign-packet-plans-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <a data-dismiss="modal" href="javascript:;">
          <XIcon class="w-8 h-8 text-gray-500" />
        </a>
        <div class="modal-body p-10 text-center">
          <div class="intro-y box">
            <!-- <div id="vertical-form"> -->
            <div class="flex flex-col justify-between mt-5">
              <!-- BEGIN: Data List -->
              <div class="flex items-center mt-3" v-if="pagedata.status == 2">
                <div class="form-check">
                  <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer" value="0" type="radio" name="flexRadioDefault" id="flexRadioDefault1" v-model="process.eternals">
                  <label class="form-check-label inline-block text-black text-lg" for="flexRadioDefault1">
                    Internal
                  </label>
                </div>
                <div class="form-check ml-6" v-if="pagedata.status == 2">
                  <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer" value="1" type="radio" name="flexRadioDefault" id="flexRadioDefault2" v-model="process.eternals">
                  <label class="form-check-label inline-block text-black text-lg" for="flexRadioDefault2">
                    Enternal
                  </label>
                </div>
              </div>
              <div v-if="process.eternals == '0'" class="m-auto">
                  <div v-if="pagedata.status == 2">
                    <TomSelect
                    v-model="process.process"
                    @change="getEmployees(process.process)"
                      :options="{
                        placeholder: 'Select Process'
                      }"
                      class="w-60 my-3" disabled
                  >
                    <option value="0">Select Process</option>
                    <option v-for="(manager,ind) in pagedata.process" :key="ind" :value="manager.id">{{ manager.name }}</option>
                  </TomSelect>
                  </div>
                  <div v-else>
                    <TomSelect
                      v-model="process.process"
                      @change="getEmployees(process.process)"
                        :options="{
                          placeholder: 'Select Process'
                        }"
                        class="w-60 my-3"
                    >
                      <option value="0">Select Process</option>
                      <option v-for="(manager,ind) in pagedata.process" :key="ind" :value="manager.id">{{ manager.name }}</option>
                    </TomSelect>
                  </div>
                <span class="text-primary-3" v-if="pagedata.errors.process">{{ pagedata.errors.process[0] }}</span>
                  <TomSelect
                  v-model="process.employee"
                    :options="{
                      placeholder: 'Select Employee'
                    }"
                    class="w-60 mb-3"
                >
                  <option value="0">Select Employee</option>
                  <option v-for="(manager,ind) in pagedata.employees" :key="ind" :value="manager.id">{{ manager.name }}</option>
                </TomSelect>
                <span class="text-primary-3" v-if="pagedata.errors.employee">{{ pagedata.errors.employee[0] }}</span>
              </div>
              <div v-else-if="process.eternals == '1'" class="m-auto">
                  <TomSelect
                  v-model="process.parties"
                    :options="{
                      placeholder: 'Select Paery'
                    }"
                    class="w-60 my-3"
                >
                  <option value="0">Select Party</option>
                  <option v-for="(party,ind) in pagedata.parties" :key="ind" :value="party.id">{{ party.name }}  ({{ ind + 1 }})</option>
                </TomSelect>
                <span class="text-primary-3" v-if="pagedata.errors.parties">{{ pagedata.errors.parties[0] }}</span>
              </div>
              <button type="button" @click="assignProcess" :disabled="pagedata.submit" class="btn btn-primary w-24 m-auto mt-5">Submit
                <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Process Assign Plan  Modal -->
  </div>
  <!-- END: Profile Info -->
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import axios from 'axios'
import cash from 'cash-dom'
import { defineComponent, onMounted, computed, reactive, ref, watch } from 'vue'
import print from 'vue3-print-nb'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import Toastify from 'toastify-js'
import { useRoute } from 'vue-router'
import { stringify } from 'json5'
export default defineComponent({
  directives: {
    print
  },
  setup() {
    const pagedata = reactive({
      submit: false,
      editable: false,
      status: '',
      type: '',
      error: '',
      success: '',
      packet_plans: [],
      process: [],
      employees: [],
      parties: [],
      errors: [],
      plans: []
    })
    const route = useRoute()
    const process = reactive({
      eternals: '0',
      employee: '',
      process: '',
      parties: '',
      paketid: route.params.id
    })
    const tableRef = ref()
    const tabulator = ref()
    onMounted(() => {
      initTabulator()
      Editable()
    })
    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + `/rough/plans/${route.params.id}`,
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [
          {
            formatter: 'responsiveCollapse',
            width: 40,
            minWidth: 30,
            hozAlign: 'center',
            vertAlign: 'middle',
            resizable: false,
            headerSort: false
          },

          // For HTML table
          {
            title: 'Packet No',
            responsive: 0,

            field: 'number',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().number
                }</div>
              </div>`
            }
          },
          {
            title: 'Total Weight',
            responsive: 0,
            field: 'total_weight',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().total_weight
                }</div>
              </div>`
            }
          },
          {
            title: 'Total Pwt',
            responsive: 0,
            field: 'total_pwt',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().total_pwt
                }</div>
              </div>`
            }
          },
          {
            title: 'Rates',
            responsive: 0,
            field: 'price',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().price
                }</div>
              </div>`
            }
          },
          {
            title: 'Pieces',
            field: 'pieces',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Status',
            field: 'status',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().status == 1 ? '<div class="text-primary-4">Active</div>' : ''
                }</div>
              </div>`
            }
          },
          {
            title: 'Action',
            field: 'action',
            minWidth: 100,
            vertAlign: 'middle',
            print: false,
            download: false,
            columns:[
            {
              field:"action",
              width:60,
              formatter(cell) {
                const a = cash(`<div class="flex lg:justify-center items-center"><button class="btn btn-primary p-1 px-2 mr-1"> View </button></div>`)
                cash(a).on('click', function() {
                    showPacketPlans(cell.getData().id)
                })

                return a[0]
              }
            },
            {
              field:"action",
              width:60,
              formatter(cell) {
                const a = cash(`<div class="flex lg:justify-center items-center">
                  ${
                    cell.getData().packet_proc_status == 1 && cell.getData().packet_status == 1 ? '<button class="btn btn-danger p-1 px-2 mr-1">Select </button>': cell.getData().packet_status != 2 ? '<button class="btn btn-danger p-1 px-2 mr-1" disabled>Select </button>' : ''
                  }
                </div>`)
                cash(a).on('click', function() {
                  if(cell.getData().packet_proc_status == 1 && cell.getData().packet_status == 1) {
                    selectPlan(cell.getData().id)
                  }
                })

                return a[0]
              }
            }
            ],
          },
        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }
    function showPacketPlans(id) {
      axios({
        url: `/rough/show-packet-plans/${id}`,
        method: 'get',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.plans = res.data.data
        cash('#packet-plans-modal').modal('show')
      })
    }
    function getProcessData() {
      pagedata.parties = []
      pagedata.process = []
      pagedata.employees = []
      process.eternals = '0'
      process.process = '0'
      axios({
        url: '/rough-process-select',
        method: 'get',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.process = res.data.processes
        if(pagedata.status == 2) {
          process.process = stringify(pagedata.process[pagedata.process.length - 1].id)
        } else {
          pagedata.process.splice(pagedata.process.length - 1,pagedata.process.length)
        }
        cash('#process-assign-packet-plans-modal').modal('show')
      })
    }
    function assignProcess() {
      pagedata.submit = true
      axios({
        url: '/process/assignProc',
        method: 'post',
        baseURL: BASE_URL,
        data: process,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.success = res.data.success
        pagedata.submit = false
        initTabulator()
        Editable()
        cash('#process-assign-packet-plans-modal').modal('hide')
        setTimeout(() => {
          basicNonStickyNotificationPacket()
        }, 100);
        process.employee = ''
        process.process = ''
        process.parties = ''
        process.eternals = '0'
        setTimeout(() => {
          pagedata.success = ''
        },5000)
      }).catch((err) => {
        pagedata.submit = false
        pagedata.errors = err.response.data.errors
        setTimeout(() => {
          pagedata.errors = []
        }, 5000);
      })
    }
    function getEmployees(id) {
      if(id == 0) {
        pagedata.employees = []
      } else {
        axios({
          url: `/user/manager-employee?id=${id}&type=${pagedata.type}`,
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.employees = res.data.employees
        })
      }
    }
    function Editable() {
      axios({
          url: `/rough/plans-editable/${route.params.id}`,
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.editable = res.data.editable
          pagedata.status = res.data.packet
          pagedata.type = res.data.type
        })
    }
    function selectPlan(id) {
      pagedata.submit = true
      axios({
          url: `/rough/select-plan/${id}`,
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.success = res.data.success
          pagedata.submit = false
          setTimeout(() => {
            basicNonStickyNotificationPacket()
          },100)
          setTimeout(() => {
            pagedata.success = ''
          }, 5000);
          Editable()
          initTabulator()
        }).catch((err) => {
          pagedata.submit = false
          pagedata.error = err.response.data.error
          setTimeout(() => {
            basicNonStickyNotificationPacket()
          },100)
          setTimeout(() => {
            pagedata.error = ''
          }, 5000);
        })
    }
    function comparePlans() {
      axios({
          url: `/rough/compare-packet-plan/${route.params.id}`,
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.packet_plans = res.data.data
          cash('#compare-plans-modal').modal('show')
        })
    }
    function cancelPlan() {
      pagedata.submit = true
      axios({
          url: `/rough/cancel-plan/${route.params.id}`,
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.success = res.data.success
          pagedata.submit = false
          setTimeout(() => {
            basicNonStickyNotificationPacket()
          },100)
          setTimeout(() => {
            pagedata.success = ''
          }, 5000);
          cash('#cancel-confirmation-modal').modal('hide')
          Editable()
          initTabulator()
        }).catch((err) => {
          pagedata.error = err.response.data.error
          pagedata.submit = false
          setTimeout(() => {
            basicNonStickyNotificationPacket()
          },100)
          setTimeout(() => {
            pagedata.error = ''
          }, 5000);
        })
    }
    function showRemoveConfirmation() {
      cash('#cancel-confirmation-modal').modal('show')
    }
    function getLsbsParty() {
      axios({
          url: '/get-lsbs-external-parties',
          method: 'get',
          baseURL: BASE_URL,
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.parties = res.data.parties
        })
    }
    const basicNonStickyNotificationPacket = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification-packet')
          .clone()
          .removeClass('hidden')[0],
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    watch(() => process.eternals, (newValue) => {
      if(process.eternals == 1) {
        getLsbsParty()
      }
    })
    return {
      pagedata,
      tableRef,
      process,
      initTabulator,
      selectPlan,
      cancelPlan,
      showPacketPlans,
      comparePlans,
      showRemoveConfirmation,
      getProcessData,
      getEmployees,
      assignProcess
    }
  }
})
</script>


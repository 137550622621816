<template>
  <div>
    <div id="basic-non-sticky-notification-layout-pkts" class="toastify-content hidden flex flex-col sm:flex-row">
        <div class="flex items-center justify-around" v-if="pagedata.error">
          <CheckCircleIcon class="w-6 h-6 text-theme-6 mx-auto" />
          <div class="font-medium text-black">{{ pagedata.error }}</div>
        </div>
        <div class="flex items-center justify-around" v-if="pagedata.success">
          <CheckCircleIcon class="w-6 h-6 text-theme-9 mx-auto" />
          <div class="font-medium text-black">{{ pagedata.success }}</div>
        </div>
    </div>
    <div class="grid grid-cols-12 gap-5 mt-5">
      <div
        class="col-span-6 xl:col-span-2 md:col-span-3 sm:col-span-4 box p-5 cursor-pointer zoom-in" v-for="(add,index) in pagedata.addlist" :key="index"
      >
        <div class="flex justify-between">
          <div class="font-medium text-base">{{ add }}</div>
          <button class="btn btn-outline-danger p-1 pl-2 hover:text-white" @click="removeItem(index)"><Trash2Icon class="w-4 h-4 mr-1" /></button>
        </div>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
          <div class=" flex items-center mt-2 xl:mt-0">
            <h2 class="text-xl font-bold">Outward Packets</h2>
            <div class="mr-3 ml-3">
              <TomSelect
                v-model="filter.party"
                @change="initTabulator"
                :options="{
                  placeholder: 'Select your favorite actors'
                }"
                class="w-44"
              >
                <option value="0">Party</option>
                <option v-for="dept in pagedata.parties" :key="dept.id" :value="dept.id">{{ dept.name }}</option>
              </TomSelect>
            </div>
          </div>
        </form>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <!-- BEGIN: Delete Confirmation Modal -->
    <div
      id="delete-confirmation-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
              <div class="text-3xl mt-5">Are you sure?</div>
              <div class="text-gray-600 mt-2">
                Do you really want to delete these records? <br />This process
                cannot be undone.
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-24 mr-1"
              >
                Cancel
              </button>
              <button type="button" :disabled="pagedata.submit" @click="CancelPacket" class="btn btn-danger w-24">Delete
                <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Delete Confirmation Modal -->
  </div>
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import axios from 'axios'
import cash from 'cash-dom'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import Toastify from 'toastify-js'
import { defineComponent, onMounted, reactive, ref, watch } from 'vue'

export default defineComponent({
  setup() {
    onMounted(() => {
      initTabulator()
      setTimeout(() => {
        getParties()
      }, 500);
    })
    const pagedata = reactive({
      parties: [],
      error: '',
      success: '',
      cancelId: '',
      submit: false,
    })
    const tableRef = ref()
    const tabulator = ref()
    const filter = reactive({
      party: '0',
    })
    function getParties() {
      axios({
        url: '/parties',
        method: 'get',
        baseURL: BASE_URL,
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.parties = res.data.parties
      })
    }
    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + '/outword/outward-packets',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 10,
        paginationSizeSelector: [10, 20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [
          {
            formatter: 'responsiveCollapse',
            width: 40,
            minWidth: 30,
            hozAlign: 'center',
            resizable: false,
            headerSort: false
          },

          // For HTML table
          {
            title: 'Packet No',
            responsive: 0,

            field: 'number',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().number
                }</div>
              </div>`
            }
          },
          {
            title: 'Vepari No',
            responsive: 0,
            field: 'number',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().vepari_no
                }</div>
              </div>`
            }
          },
          {
            title: 'Weight',
            responsive: 0,
            field: 'weight',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().weight
                }</div>
              </div>`
            }
          },
          {
            title: 'Polish Wt',
            responsive: 0,
            field: 'polish_wt',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().admin_pwt
                }</div>
              </div>`
            }
          },
          {
            title: 'Party',
            responsive: 0,
            field: 'party',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
          },
          // {
          //   title: 'Department',
          //   field: 'department',
          //   hozAlign: 'center',
          //   vertAlign: 'middle',
          //   print: false,
          //   download: false
          // },
          {
            title: 'Shape',
            field: 'shape',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Color',
            field: 'color',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Purity',
            field: 'purity',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Cut',
            field: 'cut',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Polish',
            field: 'polish',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'symmetry',
            field: 'symmetry',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Fluer',
            field: 'fluerocent',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Tension',
            field: 'tension',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Stone',
            field: 'stone',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Outword',
            field: 'outword',
            minWidth: 100,
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
                  const a = cash(`<div class="flex lg:justify-center items-center">
                    ${
                      cell.getData().status == 5 ? '<button class="btn btn-danger p-1 px-2"> <i data-feather="trash" class="w-4 h-4"></i> </button>' : ''
                    }
                  </div>`)
                  cash(a).on('click', function() {
                    if(cell.getData().status == 5) {
                      pagedata.cancelId = cell.getData().id
                      cash('#delete-confirmation-modal').modal('show')
                    }
                  })

                  return a[0]
                }
          },
        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }
  // Redraw table onresize
      const reInitOnResizeWindow = () => {
        window.addEventListener('resize', () => {
          tabulator.value.redraw()
          feather.replace({
            'stroke-width': 1
          })
        })
      }
      function CancelPacket() {
      pagedata.submit = true
      axios({
          url: `/outword/cancel-packet/${pagedata.cancelId}`,
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.success = res.data.success
          pagedata.submit = false
          setTimeout(() => {
            basicNonStickyNotificationPack()
          }, 100);
          initTabulator()
          cash('#delete-confirmation-modal').modal('hide')
          setTimeout(() => {
            pagedata.success = ''
          }, 500);
        }).catch((err) => {
          pagedata.error = err.response.data.error
          pagedata.submit = false
          setTimeout(() => {
            basicNonStickyNotificationPack()
          }, 100);
          setTimeout(() => {
            pagedata.error = ''
          }, 500);
        })
    }
    const basicNonStickyNotificationPack = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification-layout-pkts')
          .clone()
          .removeClass('hidden')[0],
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    return {
      pagedata,
      filter,
      tableRef,
      reInitOnResizeWindow,
      initTabulator,
      getParties,
      CancelPacket,
      basicNonStickyNotificationPack
    }
  }
})
</script>

<template>
  <div>
    <div class="intro-y box mt-2">
      <h2 class="text-lg font-medium mr-auto ml-2 pt-2">Packet Report</h2>
      <div class="flex flex-col sm:items-start relative">
        <div class="flex sm:flex-wrap md:flex-row flex-col items-start " v-if="pagedata.filters == true">
          <div class="input-group m-1 my-2">
            <div id="input-group-3" class="input-group-text text-center w-20">Date</div>
            <Litepicker
              v-model="pagedata.date"
              :options="{
                autoApply: false,
                singleMode: false,
                numberOfColumns: 2,
                numberOfMonths: 2,
                showWeekNumbers: true,
                dropdowns: {
                  minYear: 1990,
                  maxYear: null,
                  months: true,
                  years: true
                }
              }"
              class="form-control w-48 block mx-auto"
            />
          </div>
          <div class="input-group m-1 my-2">
            <div id="input-group-3" class="input-group-text text-center w-20">Party</div>
            <TomSelect
            @change="getAvailPkt"
            v-model="filter.party"
              :options="{
                placeholder: 'Select your favorite actors'
              }"
              class="w-48"
            >
            <option value="0">Select Party</option>
              <option v-for="(party,index) in pagedata.parties" :key="index" :value="party.id">{{ party.name }}({{ index + 1 }})</option>
            </TomSelect>
          </div>
          <div class="input-group m-1 my-2">
            <div id="input-group-3" class="input-group-text text-center w-20">Process</div>
            <TomSelect
            v-model="filter.process"
            @change="getAvailPkt"
              :options="{
                placeholder: 'Select your favorite actors'
              }"
              class="w-48"
            >
            <option value="0">Select Process</option>
              <option v-for="(process,index) in pagedata.processes" :key="index" :value="process.id">{{ process.name }}</option>
            </TomSelect>
          </div>
          <div class="input-group m-1 my-2">
            <div id="input-group-3" class="input-group-text text-center w-20">Cancel</div>
            <select
            @change="getAvailPkt"
              class="form-select w-32"
              v-model="filter.cancel_type"
              aria-label="Default select example"
            >
              <option value="">All</option>
              <option value="1">Not Cancelled</option>
              <option value="2">Cancelled</option>
            </select>
          </div>
        </div>
        <div class="flex sm:flex-wrap md:flex-row flex-col items-start " v-if="pagedata.filters == true">
          <div class="input-group m-1 my-2">
            <div id="input-group-3" class="input-group-text text-center w-20">Dept</div>
            <TomSelect
            v-model="filter.type"
            @change="getChangesTypeWise"
              :options="{
                placeholder: 'Select your favorite actors'
              }"
              class="w-48"
            >
            <option value="0">Packet Type</option>
              <option value="makeable">Makeable</option>
              <option value="rough">Rough</option>
              <option value="video">Video</option>
              <option value="lab">Lab</option>
            </TomSelect>
          </div>
          <div class="input-group m-1 my-2">
            <div id="input-group-3" class="input-group-text text-center w-20">Dept</div>
            <TomSelect
            @change="getManager"
            v-model="filter.department"
              :options="{
                placeholder: 'Select your favorite actors'
              }"
              class="w-48"
            >
            <option value="0">Select Dept</option>
              <option v-for="(department,index) in pagedata.departments" :key="index" :value="department.id">{{ department.name }}</option>
            </TomSelect>
          </div>
          <div class="input-group m-1 my-2" v-if="filter.department != 0">
            <div id="input-group-3" class="input-group-text text-center w-20">Manager</div>
            <TomSelect
            @change="getEmployee"
            v-model="filter.manager"
              :options="{
                placeholder: 'Select your favorite actors'
              }"
              class="w-48"
            >
            <option value="0">Select Manager</option>
              <option v-for="(manager,index) in pagedata.managers" :key="index" :value="manager.id">{{ manager.name }}</option>
            </TomSelect>
          </div>
          <div class="input-group m-1 my-2" v-if="filter.manager != 0 && (filter.type == '0' || filter.type == 'makeable' || filter.type == 'rough')">
            <div id="input-group-3" class="input-group-text text-center w-12">Emp</div>
            <TomSelect
            v-model="filter.employee"
            @change="getAvailPkt"
              :options="{
                placeholder: 'Select your favorite actors'
              }"
              class="w-40"
            >
            <option value="0">Select Employee</option>
              <option v-for="(employee,index) in pagedata.employees" :key="index" :value="employee.id">{{ employee.name }}</option>
            </TomSelect>
          </div>
          <div class="flex flex-col mt-2 w-32">
            <div class="flex items-center ml-2">
              <div class="mr-2 flex items-center">
              <input
                id="remember-me"
                type="checkbox" v-model="pagedata.kapan"
                class="form-check-input border mr-2 bg-white"
              />
              <label for="remember-me" class="text-md font-medium">Kapan</label>
              </div>
              <div class="mx-2 flex items-center">
                <input
                  id="transfer-me"
                  type="checkbox" v-model="pagedata.f_date"
                  class="form-check-input border mr-2 bg-white"
                />
                <label for="transfer-me" class="text-md font-medium">Date</label>
              </div>
              <div class="mr-2 flex items-center">
                <input
                  id="remember-me"
                  @change="checkGetData"
                  type="checkbox" v-model="filter.labour"
                  class="form-check-input border mr-2 bg-white"
                />
                <label for="remember-me" class="text-md font-medium">Labour</label>
              </div>
            </div>
            <div class="flex items-center mt-1">
              <div class="mx-2 flex items-center" v-if="filter.labour == true">
                <input
                  id="transfer-me"
                  type="checkbox" v-model="pagedata.emrate"
                  class="form-check-input border mr-2 bg-white"
                />
                <label for="transfer-me" class="text-md font-medium">Rate</label>
              </div>
              <div class="mx-2 flex items-center" v-if="filter.labour == true">
                <input
                  id="transfer-me"
                  @change="checkGetData"
                  type="checkbox" v-model="filter.bonus"
                  class="form-check-input border mr-2 bg-white"
                />
                <label for="transfer-me" class="text-md font-medium">Bonus</label>
              </div>
              <div class="mx-2 flex items-center" v-if="filter.labour == true">
                <input
                  id="transfer-me"
                  @change="checkGetData"
                  type="checkbox" v-model="filter.panalty"
                  class="form-check-input border mr-2 bg-white"
                />
                <label for="transfer-me" class="text-md font-medium">Panalty</label>
              </div>
            </div>
          </div>
        </div>
        <div class="flex sm:flex-wrap md:flex-row flex-col items-start " v-if="pagedata.filters == true">
          <div class="input-group m-1 my-2">
            <div id="input-group-3" class="input-group-text text-center mt-1 w-20">Search</div>
            <div class="w-48 mt-1 relative text-gray-700 dark:text-gray-300">
              <input
                type="text"
                v-model="filter.number"
                autofocus
                ref="focusMe"
                class="form-control w-48 pr-10"
                placeholder="Search Packet"
              />
              <SearchIcon
                class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
              />
            </div>
          </div>
          <div class="input-group mr-2 my-2">
            <div id="input-group-3" class="input-group-text text-center mt-1 w-20">Issue</div>
            <div class="custom-number-input h-8 w-24 mr-1">
              <div class="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
                <button @click="decrement('issue','from')" :disabled="filter.issue_from == 0" class=" bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-14 rounded-l cursor-pointer outline-none">
                  <span class="m-auto text-2xl font-thin">−</span>
                </button>
                <input v-model="filter.issue_from" type="number" class="outline-none focus:outline-none text-center w-full bg-gray-300 font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none" name="custom-input-number">
                <button @click="increment('issue','from')" class="bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-14 rounded-r cursor-pointer">
                  <span class="m-auto text-2xl font-thin">+</span>
                </button>
              </div>
            </div>
            <div class="custom-number-input h-8 w-24">
              <div class="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
                <button @click="decrement('issue','to')" :disabled="filter.issue_to == 0" class=" bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-14 rounded-l cursor-pointer outline-none">
                  <span class="m-auto text-2xl font-thin">−</span>
                </button>
                <input v-model="filter.issue_to" type="number" class="outline-none focus:outline-none text-center w-full bg-gray-300 font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none" name="custom-input-number">
                <button @click="increment('issue','to')" class="bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-14 rounded-r cursor-pointer">
                  <span class="m-auto text-2xl font-thin">+</span>
                </button>
              </div>
            </div>
          </div>
          <div class="input-group mr-2 my-2">
            <div id="input-group-3" class="input-group-text text-center mt-1 w-20">Receive</div>
            <div class="custom-number-input h-8 w-24 mr-1">
              <div class="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
                <button @click="decrement('receive','from')" :disabled="filter.receive_from == 0" class=" bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-14 rounded-l cursor-pointer outline-none">
                  <span class="m-auto text-2xl font-thin">−</span>
                </button>
                <input v-model="filter.receive_from" type="number" class="outline-none focus:outline-none text-center w-full bg-gray-300 font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none" name="custom-input-number">
                <button @click="increment('receive','from')" class="bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-14 rounded-r cursor-pointer">
                  <span class="m-auto text-2xl font-thin">+</span>
                </button>
              </div>
            </div>
            <div class="custom-number-input h-8 w-24">
              <div class="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
                <button @click="decrement('receive','to')" :disabled="filter.receive_to == 0" class=" bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-14 rounded-l cursor-pointer outline-none">
                  <span class="m-auto text-2xl font-thin">−</span>
                </button>
                <input v-model="filter.receive_to" type="number" class="outline-none focus:outline-none text-center w-full bg-gray-300 font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none" name="custom-input-number">
                <button @click="increment('receive','to')" class="bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-14 rounded-r cursor-pointer">
                  <span class="m-auto text-2xl font-thin">+</span>
                </button>
              </div>
            </div>
          </div>
          <div class="input-group mr-2 my-2">
            <button class="btn btn-primary p-2 w-24 mt-1 shadow-md mr-2" @click="getAvailPkt">Search</button>
          </div>
        </div>
        <div class="bottom-0 right-0 absolute">
          <div @click="pagedata.filters = !pagedata.filters" class="btn btn-primary p-0 mr-3"><ChevronsUpIcon class="h-5 w-5" v-if="pagedata.filters == true" /><ChevronsDownIcon class="h-5 w-5" v-if="pagedata.filters == false" /></div>
        </div>
      </div>
      <!-- BEGIN: Data List -->
      <div class="overflow-x-auto scrollbar-hidden" v-if="pagedata.packets.length > 0">
        <div class="intro-y col-span-12 overflow-auto">
          <table class="mt-2 w-full border-2">
                <thead>
                  <tr class="bg-theme-1 text-white">
                    <th class="py-2 text-xs whitespace-nowrap">#</th>
                    <th class="py-2 text-xs whitespace-nowrap">Party</th>
                    <th class="py-2 text-xs whitespace-nowrap">Dept.</th>
                    <th class="py-2 text-xs whitespace-nowrap">Proc.</th>
                    <th class="py-2 text-xs whitespace-nowrap">Mgr.</th>
                    <th class="py-2 text-xs whitespace-nowrap">Emp.</th>
                    <th class="py-2 text-xs whitespace-nowrap" v-if="pagedata.kapan == true">Kapan</th>
                    <th class="py-2 text-xs whitespace-nowrap">Packet</th>
                    <th class="py-2 text-xs whitespace-nowrap" v-if="pagedata.f_date == true">Iss. Date</th>
                    <th class="py-2 text-xs whitespace-nowrap">SCPCPSTF</th>
                    <th class="py-2 text-xs whitespace-nowrap" v-if="pagedata.f_date == true">Rec. Date</th>
                    <th class="py-2 text-xs whitespace-nowrap">In. Wt</th>
                    <th class="py-2 text-xs whitespace-nowrap">Iss. Wt</th>
                    <th class="py-2 text-xs whitespace-nowrap">Rec. Wt</th>
                    <th class="py-2 text-xs whitespace-nowrap">Loss</th>
                    <th class="py-2 text-xs whitespace-nowrap" v-if="filter.labour == true && pagedata.emrate == true">Rate</th>
                    <th class="py-2 text-xs whitespace-nowrap" v-if="filter.labour == true && filter.bonus == true">B. Rate</th>
                    <th class="py-2 text-xs whitespace-nowrap" v-if="filter.labour == true && filter.bonus == true">Bonus</th>
                    <th class="py-2 text-xs whitespace-nowrap" v-if="filter.labour == true && pagedata.emrate == true">M. Rate</th>
                    <th class="py-2 text-xs whitespace-nowrap" v-if="filter.labour == true">M.M. Rate</th>
                    <th class="py-2 text-xs whitespace-nowrap" v-if="filter.labour == true && filter.panalty == true">E. Pnty</th>
                    <th class="py-2 text-xs whitespace-nowrap" v-if="filter.labour == true && filter.panalty == true">M.Pnty</th>
                    <th class="py-2 text-xs whitespace-nowrap" v-if="filter.labour == true">Labour</th>
                    <th class="py-2 text-xs whitespace-nowrap" v-if="filter.labour == true">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="bg-gray-200 text-gray-700">
                    <td class="py-2 border-b text-xs text-center"></td>
                    <td class="py-2 border-b text-xs text-center"></td>
                    <td class="py-2 border-b text-xs text-center"></td>
                    <td class="py-2 border-b text-xs text-center">{{ pagedata.heading.processes }}</td>
                    <td class="py-2 border-b text-xs text-center"></td>
                    <td class="py-2 border-b text-xs text-center"></td>
                    <td class="py-2 border-b text-xs text-center" v-if="pagedata.kapan == true"></td>
                    <td class="py-2 border-b text-xs text-center"></td>
                    <td class="py-2 border-b text-xs text-center w-20" v-if="pagedata.f_date == true"></td>
                    <td class="py-2 border-b text-xs text-center w-20"></td>
                    <td class="py-2 border-b text-xs text-center w-20" v-if="pagedata.f_date == true"></td>
                    <td class="py-2 border-b text-xs text-center">{{ parseFloat(pagedata.heading.total_weight).toFixed(2) }}</td>
                    <td class="py-2 border-b text-xs text-center">{{ parseFloat(pagedata.heading.total_issue_weight).toFixed(2) }}</td>
                    <td class="py-2 border-b text-xs text-center">{{ parseFloat(pagedata.heading.total_receive_weight).toFixed(2) }}</td>
                    <td class="py-2 border-b text-xs text-center">{{ parseFloat(pagedata.heading.total_loss_weight).toFixed(2) }}</td>
                    <td class="py-2 border-b text-xs text-center" v-if="filter.labour == true && pagedata.emrate == true">{{ parseFloat(pagedata.heading.total_rate).toFixed(2) }}</td>
                    <td class="py-2 border-b text-xs text-center" v-if="filter.labour == true && filter.bonus == true"></td>
                    <td class="py-2 border-b text-xs text-center" v-if="filter.labour == true && filter.bonus == true">{{ parseFloat(pagedata.heading.total_employee_bonus_rates).toFixed(2) }}</td>
                    <td class="py-2 border-b text-xs text-center" v-if="filter.labour == true && pagedata.emrate == true"></td>
                    <td class="py-2 border-b text-xs text-center" v-if="filter.labour == true">{{ parseFloat(pagedata.heading.total_manager_majuri_rate).toFixed(2) }}</td>
                    <td class="py-2 border-b text-xs text-center" v-if="filter.labour == true && filter.panalty == true">{{ parseFloat(pagedata.heading.total_employee_panalty).toFixed(2) }}</td>
                    <td class="py-2 border-b text-xs text-center" v-if="filter.labour == true && filter.panalty == true">{{ parseFloat(pagedata.heading.total_manager_panalty).toFixed(2) }}</td>
                    <td class="py-2 border-b text-xs text-center" v-if="filter.labour == true">{{ parseFloat(pagedata.heading.total_majuri_rate).toFixed(2) }}</td>
                    <td class="py-2 border-b text-xs text-center" v-if="filter.labour == true">{{ parseFloat(pagedata.heading.total_total).toFixed(2) }}</td>

                  </tr>
                  <tr v-for="(packet, index) in pagedata.packets" :key="index">
                    <td class="py-2 border-b text-xs text-center">{{ index + 1 }}</td>
                    <td class="py-2 border-b text-xs text-center">{{ packet.party }}</td>
                    <td class="py-2 border-b text-xs text-center">{{ packet.department }}</td>
                    <td class="py-2 border-b text-xs text-center">{{ packet.process }}</td>
                    <td class="py-2 border-b text-xs text-center">{{ packet.manager }}</td>
                    <td class="py-2 border-b text-xs text-center">{{ packet.employee }}</td>
                    <td class="py-2 border-b text-xs text-center" v-if="pagedata.kapan == true">{{ packet.lot_no }}</td>
                    <td class="py-2 border-b text-xs text-center">{{ packet.number }}</td>
                    <td class="py-2 border-b text-xs text-center w-20" v-if="pagedata.f_date == true">{{ packet.issue_date }}</td>
                    <td class="py-2 border-b text-xs text-center w-20">{{ packet.stone }},{{ packet.color }},{{ packet.purity }},
                    {{ packet.cut }},{{ packet.polish }},{{ packet.shape }},{{ packet.tension }},{{ packet.fluerocent }}</td>
                    <td class="py-2 border-b text-xs text-center w-20" v-if="pagedata.f_date == true">{{ packet.receive_date }}</td>
                    <td class="py-2 border-b text-xs text-center">{{ packet.weight }}</td>
                    <td class="py-2 border-b text-xs text-center">{{ packet.issue_weight }}</td>
                    <td class="py-2 border-b text-xs text-center">{{ packet.receive_weight }}</td>
                    <td class="py-2 border-b text-xs text-center">{{ packet.loss ? parseFloat(packet.loss).toFixed(2) : 0 }}</td>
                    <td class="py-2 border-b text-xs text-center" v-if="filter.labour == true && pagedata.emrate == true">{{ packet.rate }}</td>
                    <td class="py-2 border-b text-xs text-center" v-if="filter.labour == true && filter.bonus == true">{{ packet.bonus_rates }} {{ packet.bonus_rates ? '%': '' }}</td>
                    <td class="py-2 border-b text-xs text-center" v-if="filter.labour == true && filter.bonus == true">{{ packet.employee_bonus_rates }}</td>
                    <td class="py-2 border-b text-xs text-center" v-if="filter.labour == true && pagedata.emrate == true">{{ packet.m_rate }} {{ packet.m_rate_type == 3 ? ' %': '' }}</td>
                    <td class="py-2 border-b text-xs text-center" v-if="filter.labour == true">{{ packet.manager_majuri_rates }}</td>
                    <td class="py-2 border-b text-xs text-center" v-if="filter.labour == true && filter.panalty == true">{{ packet.employee_panalty }}</td>
                    <td class="py-2 border-b text-xs text-center" v-if="filter.labour == true && filter.panalty == true">{{ packet.manager_panalty }}</td>
                    <td class="py-2 border-b text-xs text-center" v-if="filter.labour == true">{{ packet.majuri_rate }}</td>
                    <td class="py-2 border-b text-xs text-center" v-if="filter.labour == true">{{ packet.total }}</td>

                  </tr>
                </tbody>
              </table>
        </div>
        <!-- END: Data List -->
        <!-- BEGIN: Pagination -->
        <div
          class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center mt-3 mb-7"
        >
          <ul class="pagination">
            <li>
              <button class="pagination__link" :disabled="pagedata.current_page == 1" @click="getAvailablePkt(pagedata.first_page_url)">
                <ChevronsLeftIcon class="w-4 h-4" />
              </button>
            </li>
            <li>
              <button class="pagination__link" :disabled="pagedata.prev_page_url == null" @click="getAvailablePkt(pagedata.prev_page_url)">
                <ChevronLeftIcon class="w-4 h-4" />
              </button>
            </li>
            <li v-for="(pagelink,index) in pagelinks" :key="index">
              <button class="pagination__link" :class="{'pagination__link--active' : pagelink.active == true}" v-if="pagelinks[0] != pagelink && pagelinks[pagelinks.length - 1] != pagelink" @click='getAvailablePkt(pagelink.url)'>{{ pagelink.label }}</button>
            </li>
            <li>
              <button class="pagination__link" :disabled="pagedata.next_page_url == null" @click="getAvailablePkt(pagedata.next_page_url)">
                <ChevronRightIcon class="w-4 h-4" />
              </button>
            </li>
            <li>
              <button class="pagination__link" :disabled="pagedata.current_page == pagedata.last_page" @click="getAvailablePkt(pagedata.last_page_url)">
                <ChevronsRightIcon class="w-4 h-4" />
              </button>
            </li>
          </ul>
          <select class="w-20 form-select box mt-3 sm:mt-0" v-model="filter.size" @change="getAvailPkt">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
          </select>
        </div>
        <!-- END: Pagination -->
      </div>
      <div v-else class="text-center">No Packet Found</div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config';
import axios from 'axios';
import moment from 'moment';
import { defineComponent, onMounted, reactive, toRef, watch } from 'vue';

export default defineComponent({
  setup() {
    const pagedata = reactive({
      packets: [],
      filters: true,
      labour: false,
      bonus: false,
      emrate: false,
      f_date: false,
      kapan: false,
      pagelinks: [],
      prev_page_url: null,
      next_page_url: null,
      first_page_url: null,
      last_page_url: null,
      current_page: 1,
      last_page: '',
      date: '',
      processes: [],
      parties: [],
      departments: [],
      managers: [],
      employees: [],
      heading: {
        processes: '',
        total_weight: '',
        total_issue_weight: '',
        total_receive_weight: '',
        total_loss_weight: '',
        total_rate: '',
        total_employee_bonus_rates: '',
        total_majuri_rate: '',
        total_total: '',
        total_manager_majuri_rate: '',
        total_employee_panalty: '',
        total_manager_panalty: ''
      }
    })
    const pagelinks = toRef(pagedata, 'pagelinks')
    const filter = reactive({
      size : 10,
      start_date : '',
      type : '0',
      end_date : '',
      party : '',
      process : '',
      department : '0',
      manager : '0',
      employee : '0',
      number : '',
      issue_from : 0,
      issue_to : 0,
      receive_from : 0,
      receive_to : 0,
      labour: false,
      bonus: false,
      panalty: false,
      cancel_type : ''
    })
    onMounted(() => {
      let start = moment().startOf('month').format('DD MMM, YYYY')
      let end = moment().endOf('month').format('DD MMM, YYYY')
      pagedata.date = start + '-' + end
      setTimeout(() => {
        getParties()
        getProcessData()
        getDepartment()
      },1500)
      // getAvailPkt()
      // getDataDate()
    })
    function getAvailablePkt(url) {
      axios({
        url: url + `&size=${filter.size}`,
        method: 'post',
        data: filter,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.packets = res.data.packets.data
        pagedata.pagelinks = res.data.packets.links
        pagedata.prev_page_url = res.data.packets.prev_page_url
        pagedata.next_page_url = res.data.packets.next_page_url
        pagedata.first_page_url = res.data.packets.first_page_url
        pagedata.last_page_url = res.data.packets.last_page_url
        pagedata.current_page = res.data.packets.current_page
        pagedata.last_page = res.data.packets.last_page
        pagedata.heading.total_weight = res.data.total[0].total_weight
        pagedata.heading.total_issue_weight = res.data.total[0].total_issue_weight
        pagedata.heading.total_receive_weight = res.data.total[0].total_receive_weight
        pagedata.heading.total_loss_weight = res.data.total[0].total_loss_weight
        pagedata.heading.total_rate = res.data.total[0].total_rate
        pagedata.heading.total_employee_bonus_rates = res.data.total[0].total_employee_bonus_rates
        pagedata.heading.total_majuri_rate = res.data.total[0].total_majuri_rate
        pagedata.heading.total_total = res.data.total[0].total_total
        pagedata.heading.total_manager_majuri_rate = res.data.total[0].total_manager_majuri_rate
        pagedata.heading.total_employee_panalty = res.data.total[0].total_employee_panalty
        pagedata.heading.total_manager_panalty = res.data.total[0].total_manager_panalty
        pagedata.heading.processes = res.data.total[0].total_processes
    })
    }
    function getAvailPkt() {
       axios({
        url: `/report/packet_report`,
        method: 'post',
        baseURL: BASE_URL,
        data: filter,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.packets = res.data.packets.data
        pagedata.pagelinks = res.data.packets.links
        pagedata.prev_page_url = res.data.packets.prev_page_url
        pagedata.next_page_url = res.data.packets.next_page_url
        pagedata.first_page_url = res.data.packets.first_page_url
        pagedata.last_page_url = res.data.packets.last_page_url
        pagedata.current_page = res.data.packets.current_page
        pagedata.last_page = res.data.packets.last_page
        pagedata.heading.total_weight = res.data.total[0].total_weight
        pagedata.heading.total_issue_weight = res.data.total[0].total_issue_weight
        pagedata.heading.total_receive_weight = res.data.total[0].total_receive_weight
        pagedata.heading.total_loss_weight = res.data.total[0].total_loss_weight
        pagedata.heading.total_rate = res.data.total[0].total_rate
        pagedata.heading.total_employee_bonus_rates = res.data.total[0].total_employee_bonus_rates
        pagedata.heading.total_majuri_rate = res.data.total[0].total_majuri_rate
        pagedata.heading.total_total = res.data.total[0].total_total
        pagedata.heading.total_manager_majuri_rate = res.data.total[0].total_manager_majuri_rate
        pagedata.heading.total_employee_panalty = res.data.total[0].total_employee_panalty
        pagedata.heading.total_manager_panalty = res.data.total[0].total_manager_panalty
        pagedata.heading.processes = res.data.total[0].total_processes
      })
    }
    function getParties() {
      axios({
        url: '/parties',
        method: 'get',
        baseURL: BASE_URL,
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.parties = res.data.parties
      })
    }
    function getDepartment() {
      axios({
        url: '/allDepartments',
        method: 'get',
        baseURL: BASE_URL,
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.departments = res.data.departments
      })
    }
    function getManager() {
      filter.manager = '0'
      filter.employee = '0'
      axios({
        url: `/user/allManager?dept_id=${filter.department}`,
        method: 'get',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.managers = res.data.managers
        getAvailPkt()
      })
    }
    function getEmployee() {
      filter.employee = '0'
      if(filter.type == 'makeable' || filter.type == '0') {
        axios({
          url: `/user/manager-employee/${filter.manager}`,
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.employees = res.data.employees
          getAvailPkt()
        })
      } else if(filter.type == 'rough') {
        axios({
          url: '/user/planner_checker_ls',
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.employees = res.data.users
          getAvailPkt()
        })
      }
    }
    function getProcessData() {
      axios({
        url: '/all-select',
        method: 'get',
        baseURL: BASE_URL,
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.processes = res.data.processes
      })
    }
    function getDataDate() {
      let divide = pagedata.date.split("-")
      filter.start_date = moment(divide[0]).format('YYYY-MM-DD');
      filter.end_date = moment(divide[1]).format('YYYY-MM-DD');
      getAvailPkt()
    }
    function increment(type,fromto) {
      if(type == 'issue') {
        if(fromto == 'from') {
          filter.issue_from =  parseFloat((filter.issue_from + 0.01).toFixed(2))
        } else if(fromto == 'to') {
          filter.issue_to =  parseFloat((filter.issue_to + 0.01).toFixed(2))
        }
      } else if(type == 'receive') {
        if(fromto == 'from') {
          filter.receive_from =  parseFloat((filter.receive_from + 0.01).toFixed(2))
        } else if(fromto == 'to') {
          filter.receive_to =  parseFloat((filter.receive_to + 0.01).toFixed(2))
        }
      }
    }
    function checkGetData() {
      if(filter.labour == false) {
        filter.bonus = false
        filter.panalty = false
      }
      getAvailPkt()
    }
    function getChangesTypeWise() {
      if(filter.type == 'makeable' || filter.type== 'rough' || filter.type == '0') {
        getEmployee()
      } else {
        pagedata.employees = []
        filter.employee = '0'
      }

    }
    function decrement(type, fromto) {
      if(type == 'issue') {
        if(fromto == 'from') {
          filter.issue_from =  parseFloat((filter.issue_from - 0.01).toFixed(2))
        } else if(fromto == 'to') {
          filter.issue_to =  parseFloat((filter.issue_to - 0.01).toFixed(2))
        }
      } else if(type == 'receive') {
        if(fromto == 'from') {
          filter.receive_from =  parseFloat((filter.receive_from - 0.01).toFixed(2))
        } else if(fromto == 'to') {
          filter.receive_to =  parseFloat((filter.receive_to - 0.01).toFixed(2))
        }
      }
    }
      watch(() => filter.issue_from, (currentVal) => {
        if(filter.issue_to < currentVal) {
          filter.issue_to = filter.issue_from
        }
      })
      watch(() => filter.receive_from, (currentVal) => {
        if(filter.receive_to < currentVal) {
          filter.receive_to = filter.receive_from
        }
      })
      watch(() => filter.issue_to, (oldVal, currentVal) => {
          if(currentVal < filter.issue_from) {
          filter.issue_to = oldVal
          }
      })
      watch(() => filter.receive_to, (oldVal, currentVal) => {
        if(currentVal < filter.receive_from) {
          filter.receive_to = oldVal
        }
      })
      watch(() => pagedata.date, (currentDate) => {
        if(currentDate) {
          let divide = pagedata.date.split("-")
          filter.start_date = moment(divide[0]).format('YYYY-MM-DD');
          filter.end_date = moment(divide[1]).format('YYYY-MM-DD');
          getAvailPkt()
        }
      })
      watch(() => filter.number, (current) => {
        if(current == '') {
          getAvailPkt()
        }
      })
    return {
      getAvailablePkt,
      getAvailPkt,
      getChangesTypeWise,
      getDataDate,
      getEmployee,
      getManager,
      increment,
      decrement,
      pagedata,
      filter,
      pagelinks,
      checkGetData
    }
  }
})
</script>
<style scoped>
input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .custom-number-input input:focus {
    outline: none !important;
  }

  .custom-number-input button:focus {
    outline: none !important;
  }
</style>

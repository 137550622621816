<template>
<div id="basic-non-sticky-notification-packet-error" class="toastify-content hidden flex flex-col sm:flex-row">
      <div class="flex items-center justify-around">
        <CheckCircleIcon class="w-6 h-6 text-theme-6 mx-auto" />
        <div class="font-medium text-black" v-if="pagedata.error">{{ pagedata.error }}</div>
      </div>
  </div>
      <div id="basic-non-sticky-notification-packet-receive" class="toastify-content hidden flex flex-col sm:flex-row">
          <div class="flex items-center justify-around">
            <CheckCircleIcon class="w-6 h-6 text-theme-9 mx-auto" />
            <div class="font-medium text-black" v-if="pagedata.success.success">{{ pagedata.success.success }}</div>
          </div>
      </div>
  <div class="my-8">
    <div class="xl:grid block xl:grid-cols-2 gap-10">
      <div>
        <div class="flex items-center">
          <div class="text-lg font-medium mr-2">Please Scan the Packet</div>
          <div>
            <input
                  id="remember-me"
                  type="checkbox" v-model="pagedata.check"
                  class="form-check-input border mr-2 bg-white"
                />
                <label for="remember-me" class="text-md font-medium">Menual</label>
          </div>
        </div>
        <div class="w-full flex sm:w-auto xl:mt-0 mt-3 sm:ml-auto md:ml-0">
          <div class="w-56 relative text-gray-700 dark:text-gray-300 mr-4">
            <input
              type="text"
              v-model="pagedata.search"
              autofocus
              ref="focusMe"
              class="form-control w-56 box pr-10 placeholder-theme-13"
              placeholder="Search..."
            />
            <SearchIcon
              class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
            />
          </div>
          <button class="btn btn-primary shadow-md mr-2" @click="getPaketDetail">Search</button>
        </div>
      </div>
      <div class="flex items-end mt-10 xl:mt-0">
        <div class="mx-5" v-if="paketDet.number != ''">
          <h2 class="intro-y text-lg font-medium">Packet No : {{ paketDet.number }}</h2>
          <table class="w-48">
            <tbody>
              <tr>
                <th class="py-2 font-bold border-2 text-md px-1 text-left">Shape</th>
                <th class="py-2 font-bold border-2 text-md px-1 text-left">Color</th>
                <th class="py-2 font-bold border-2 text-md px-1 text-left">Purity</th>
                <th class="py-2 font-bold border-2 text-md px-1 text-left">Cut</th>
                <th class="py-2 font-bold border-2 text-md px-1 text-left">Polish</th>
                <th class="py-2 font-bold border-2 text-md px-1 text-left">Symmetry</th>
                <th class="py-2 font-bold border-2 text-md px-1 text-left">Tension</th>
                <th class="py-2 font-bold border-2 text-md px-1 text-left whitespace-nowrap">Issue Wt</th>
                <th class="py-2 font-bold border-2 text-md px-1 text-left whitespace-nowrap">Oriznal Wt</th>
              </tr>
              <tr>
                <td class="font-bold text-md border-2 text-center">{{ paketDet.shape.name }}</td>
                <td class="font-bold text-md border-2 text-center">{{ paketDet.color.name }}</td>
                <td class="font-bold text-md border-2 text-center">{{ paketDet.purity.name }}</td>
                <td class="font-bold text-md border-2 text-center">{{ paketDet.cut.name }}</td>
                <td class="font-bold text-md border-2 text-center">{{ paketDet.polish.name }}</td>
                <td class="font-bold text-md border-2 text-center">{{ paketDet.symmetry.name }}</td>
                <td class="font-bold text-md border-2 text-center">{{ paketDet.tension.name }}</td>
                <td class="font-bold text-md border-2 text-center">{{ paketDet.issueWt }}</td>
                <td class="font-bold text-md border-2 text-center">{{ paketDet.oriznal_wt }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="hidden">
          <div id="paketDetails" class="mx-5">
          <table class="w-48 border-2 border-black">
            <tbody>
              <tr>
                <th class="p-2 font-bold text-lg text-left">
                  Packet No:
                </th>
                <th class="font-bold text-lg text-center">{{ paketDet.number }}</th>
              </tr>
              <tr>
                <td class="p-2 border-2 border-black font-bold text-md text-left">
                  Shape :
                </td>
                <td class="font-bold text-md border-2 border-black text-center">{{ paketDet.shape.name }}</td>
              </tr>
              <tr>
                <td class="p-2 border-2 border-black font-bold text-md text-left">
                  Color :
                </td>
                <td class="font-bold text-md border-2 border-black text-center">{{ paketDet.color.name }}</td>
              </tr>
              <tr>
                <td class="p-2 border-2 border-black font-bold text-md text-left">
                  Purity :
                </td>
                <td class="font-bold text-md border-2 border-black text-center">{{ paketDet.purity.name }}</td>
              </tr>
              <tr>
                <td class="p-2 border-2 border-black font-bold text-md text-left">
                  Cut :
                </td>
                <td class="font-bold text-md border-2 border-black text-center">{{ paketDet.cut.name }}</td>
              </tr>
              <tr>
                <td class="p-2 border-2 border-black font-bold text-md text-left">
                  Polish :
                </td>
                <td class="font-bold text-md border-2 border-black text-center">{{ paketDet.polish.name }}</td>
              </tr>
              <tr>
                <td class="p-2 border-2 border-black font-bold text-md text-left">
                  Symmetry :
                </td>
                <td class="font-bold text-md border-2 border-black text-center">{{ paketDet.symmetry.name }}</td>
              </tr>
              <tr>
                <td class="p-2 border-2 border-black font-bold text-md text-left">
                  Tension :
                </td>
                <td class="font-bold text-md border-2 border-black text-center">{{ paketDet.tension.name }}</td>
              </tr>
              <tr>
                <td class="p-2 border-2 border-black font-bold text-md text-left">
                  Iss. Wt :
                </td>
                <td class="font-bold text-md border-2 border-black text-center">{{ paketDet.issueWt }}</td>
              </tr>
              <tr>
                <td class="p-2 border-2 border-black font-bold text-md text-left">
                  Oriz. Wt :
                </td>
                <td class="font-bold text-md border-2 border-black text-center">{{ paketDet.oriznal_wt }}</td>
              </tr>
            </tbody>
          </table>
          </div>
        </div>
        <div>
          <div class="flex items-center">
          <button  v-if="paketDet.number != ''"
            type="button" v-print="'#paketDetails'"
            class="btn btn-primary w-16 mr-1 py-1"
          >
            Print
          </button>
          <RotateCwIcon  v-if="paketDet.number != ''" class="w-5 h-5 text-primary-6 mx-1 cursor-pointer" @click="getPaketDetail"></RotateCwIcon>
        </div>
        <a title="New Process Assign" data-toggle="modal" class="btn btn-primary shadow-md mt-1 py-1 w-28" href="javascript:;" v-if="paketDet.status == 1"
                    @click="getProcessesCheck" data-target="#new-process-modal">New Process</a>
        </div>
      </div>
      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 xl:mt-0 mt-5" v-if="paketDet.number != ''">
        <div class="intro-y col-span-12 overflow-auto" v-if="packets.length > 0">
            <table class="table-report -mt-2">
              <thead>
                <tr>
                  <th class=" lg:px-2 px-1 whitespace-nowrap">#</th>
                  <th class=" lg:px-2 px-1 whitespace-nowrap">T No</th>
                  <th class=" lg:px-2 px-1 whitespace-nowrap">Proc.</th>
                  <th class=" lg:px-2 px-1 whitespace-nowrap">Dept.</th>
                  <th class=" lg:px-2 px-1 whitespace-nowrap">Mgr.</th>
                  <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Emp.</th>
                  <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Issue Wt.</th>
                  <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Rec. Wt.</th>
                  <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Issue Time</th>
                  <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Rec. Time</th>
                  <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Shape</th>
                  <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Color</th>
                  <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Purity</th>
                  <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Cut</th>
                  <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Polish</th>
                  <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Symm</th>
                  <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Flurc</th>
                  <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Tension</th>
                  <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Stone</th>
                  <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Action</th>
                </tr>
              </thead>
              <tbody>
                <TableList v-for="(packet, index) in packets"
                  :key="index" :index="index" :packet="packet" :packetDet="paketDet" :diamond="diamond" @getData="getPaketDetail" @getDiamondDetail="getDiamondDetail" @cancelProcess="cancelProcess" @updatParameters="updateParameters"/>
              </tbody>
            </table>
        </div>
        <div v-else class="intro-y col-span-12 overflow-auto mt-5 text-center">No Packet Found</div>
      </div>
      <div v-else class="intro-y col-span-12 overflow-auto mt-5 text-center">Please Scan Packet</div>
      <!-- END: Data List -->
    </div>
  </div>
  <!-- BEGIN: new Process Modal -->
  <div
    id="new-process-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <a data-dismiss="modal" href="javascript:;">
          <XIcon class="w-8 h-8 text-gray-500" />
        </a>
        <div class="modal-body p-10 text-center">
          <div class="intro-y box">
            <!-- <div id="vertical-form"> -->
            <div class="flex flex-col justify-between mt-5">
              <!-- BEGIN: Data List -->
              <div class="intro-y overflow-auto">
                <div class="flex items-center">
                  <h2 class="intro-y text-lg font-medium mr-5">All Packets of Lot No :  <span class="ml-2 text-primary-1">{{ paketDet.number }}</span></h2>
                </div>
              </div>
              <div class="m-auto">
                  <TomSelect
                  v-model="pagedata.procs"
                    :options="{
                      placeholder: 'Select Process'
                    }"
                    class="w-60 my-3"
                >
                  <option value="0">Select Process</option>
                  <option v-for="(proc,ind) in pagedata.process" :key="ind" :value="proc.id">{{ proc.name }}</option>
                </TomSelect>
                <span class="text-primary-3" v-if="errors.process">{{ errors.process[0] }}</span>
              </div>
              <button type="button" @click="AssignProcess" :disabled="pagedata.submit" class="btn btn-primary w-24 m-auto mt-5">Submit
                <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: new Process Modal -->
  <!-- Start: Success Modal -->
  <div
    id="success-modal-preview"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body p-0">
          <div class="p-5 text-center">
            <CheckCircleIcon class="w-16 h-16 text-theme-9 mx-auto mt-3" />
            <div class="text-3xl mt-5" v-if="success.success">{{ success.success }}</div>
            <div class="text-gray-600 mt-2 box w-52 p-3 m-auto my-3">
              <div v-if="success.manager" class="text-black font-bold pb-2">Manager : <span class="text-primary-1">{{ success.manager }}</span></div>
              <div v-if="success.department" class="text-black font-bold m-auto pb-2">Department : <span class="text-primary-1">{{ success.department }}</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End: Success Modal -->
  <!-- BEGIN: Receive Packet Modal -->
  <div
    id="edit-confirmation-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <a data-dismiss="modal" href="javascript:;">
            <XIcon class="w-8 h-8 text-gray-500" />
        </a>
        <div class="modal-body p-10 text-center">
          <!-- BEGIN: Vertical Form -->
          <div class="intro-y box">
            <div id="vertical-form" class="p-5">
              <div class="text-lg font-medium mx-3">Packet No :- <span class="text-primary-1">{{ receiveDet.paket_no }}</span></div>
                <div class="grid grid-cols-12 gap-6 mt-5">
                  <div class="intro-y col-span-12 md:col-span-6">
                    <div class="mt-3">
                      <div class="input-group mt-2 sm:mt-0">
                        <div id="input-group-3" class="input-group-text w-36 text-center">Color</div>
                        <select
                          class="form-select"
                          v-model="receiveDet.color"
                          aria-label="Default select example"
                        >
                          <option v-for="col in diamond.colors" :key="col.id" :value="col.id">{{ col.name }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="mt-3">
                      <div class="input-group">
                        <div id="input-group-3" class="input-group-text w-36 text-center">Purity</div>
                        <select
                          class="form-select"
                          v-model="receiveDet.purity"
                          aria-label="Default select example"
                        >
                          <option v-for="col in diamond.purities" :key="col.id" :value="col.id">{{ col.name }}</option>
                      </select>
                      </div>
                    </div>
                    <div class="input-group mt-2 sm:mt-0">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Shape</div>
                      <select
                        class="form-select"
                        v-model="receiveDet.shape"
                        aria-label="Default select example"
                      >
                        <option v-for="col in diamond.shapes" :key="col.id" :value="col.id">{{ col.name }}</option>
                      </select>
                    </div>
                    <div class="mt-3">
                      <div class="input-group mt-2 sm:mt-0">
                        <div id="input-group-3" class="input-group-text w-36 text-center">Flurocent</div>
                        <select
                          class="form-select"
                          v-model="receiveDet.fluerocent"
                          aria-label="Default select example"
                        >
                          <option v-for="col in diamond.fluerocents" :key="col.id" :value="col.id">{{ col.name }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="mt-3">
                      <div class="input-group">
                        <div id="input-group-3" class="input-group-text w-36 text-center">Tension</div>
                        <select
                          class="form-select"
                          v-model="receiveDet.tension"
                          aria-label="Default select example"
                        >
                          <option v-for="col in diamond.tensions" :key="col.id" :value="col.id">{{ col.name }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="intro-y col-span-12 md:col-span-6">
                    <div class="mt-3">
                      <div class="input-group">
                        <div id="input-group-3" class="input-group-text w-36 text-center">Cut</div>
                        <select
                          class="form-select"
                          v-model="receiveDet.cut"
                          aria-label="Default select example"
                        >
                          <option v-for="col in diamond.cuts" :key="col.id" :value="col.id">{{ col.name }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="mt-3">
                      <div class="input-group mt-2 sm:mt-0">
                        <div id="input-group-3" class="input-group-text w-36 text-center">Polish</div>
                        <select
                          class="form-select"
                          v-model="receiveDet.polish"
                          aria-label="Default select example"
                        >
                          <option v-for="col in diamond.polish" :key="col.id" :value="col.id">{{ col.name }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="mt-3">
                      <div class="input-group">
                        <div id="input-group-3" class="input-group-text w-36 text-center">Symmerty</div>
                        <select
                          class="form-select"
                          v-model="receiveDet.symmetry"
                          aria-label="Default select example"
                        >
                          <option v-for="col in diamond.symmetry" :key="col.id" :value="col.id">{{ col.name }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="mt-3">
                      <div class="input-group mt-2 sm:mt-0">
                        <div id="input-group-3" class="input-group-text w-36 text-center">Stone</div>
                        <select
                          class="form-select"
                          v-model="receiveDet.stone"
                          aria-label="Default select example"
                        >
                          <option v-for="col in diamond.stones" :key="col.id" :value="col.id">{{ col.name }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="mt-3">
                      <div class="mt-2 sm:mt-0">
                        <div class="input-group">
                          <input
                          type="number"
                          v-model="receiveDet.pktwt"
                          class="form-control"
                          placeholder="Weight"
                          aria-describedby="input-group-4"
                        />
                        </div>
                        <span class="text-primary-3 text-left" v-if="errors.pktwt">{{ errors.pktwt[0] }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-3">
                  <div class="form-label w-full text-left">Remark</div>
                  <textarea
                    id="regular-form-4"
                    type="password"
                    class="form-control"
                    v-model="receiveDet.description"
                    placeholder="Remark"
                  ></textarea>
                </div>
                <div class="text-right mt-5">
                  <button type="button" class="btn btn-primary w-24" :disabled="pagedata.submit" @click="receivePKT">Receive
                  <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" /></button>
                </div>
            </div>
          </div>
        <!-- END: Vertical Form -->
        </div>
      </div>
    </div>
  </div>
  <!-- END: Receive Packet Modal -->
  <!-- BEGIN: Rate Not Found Process -->
  <div
    id="rate-show-confirmation-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body p-3">
          <div class="p-5 text-center">
            <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
            <div class="text-3xl mt-5" v-if="pagedata.errors.error">{{ pagedata.errors.error }}</div>
            <div class="text-gray-600 mt-2" v-if="pagedata.errors.message">
              {{ pagedata.errors.message }}
            </div>
            <div class="text-gray-600 mt-5" v-if="pagedata.errors.data">{{ pagedata.errors.data }}</div>
          </div>
          <div class="px-5 pb-8 text-center">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-24 mr-1"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-danger w-24" :disabled="pagedata.submit" v-if="pagedata.errors.error == 'Rate Not Found'" @click="createRateRequest">Send
              <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
            </button>
            <button type="button" class="btn btn-danger w-24" :disabled="pagedata.submit" v-if="pagedata.errors.error == 'Parameters are Changed'" @click="createParamRequest">Send
              <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Rate Not Found Process -->
  <!-- BEGIN: Parameter Change Packet Process -->
  <div
    id="middle-parameter-confirmation-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body p-3">
          <div class="p-5 text-center">
            <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
            <div class="text-3xl mt-5">Are you sure?</div>
            <div class="text-gray-600 mt-2">
              Do you really want to Send Request?
            </div>
          </div>
          <div class="px-5 pb-8 text-center">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-24 mr-1"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-danger w-24" :disabled="pagedata.submit" @click="sendRequestMdlParam">Send
            <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Parameter Change Packet Process -->
  <!-- BEGIN: Cancel Packet Process -->
  <div
    id="cancel-confirmation-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body p-3">
          <div class="p-5 text-center">
            <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
            <div class="text-3xl mt-5">Are you sure?</div>
            <div class="text-gray-600 mt-2">
              Do you really want to Cancel Packet? <br />This process
              cannot be undone.
            </div>
          </div>
          <div class="px-5 pb-8 text-center">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-24 mr-1"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-danger w-24">Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Cancel Packet Process -->
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import axios from 'axios'
import cash from 'cash-dom'
import { defineComponent, reactive, toRef, watch, onMounted } from 'vue'
import print from 'vue3-print-nb'
import Toastify from 'toastify-js'
import TableList from '@/views/JoberDashboard/tableList.vue'
export default defineComponent({
  directives: {
    print
  },
  components:{
    TableList
  },
  setup() {
    const pagedata = reactive({
      packets: [],
      search: '',
      check: false,
      waiting: false,
      procs: '',
      process: [],
      submit: false,
      errors: [],
      success: {
        success: '',
        manager: '',
        department: ''
      },
      error: '',
      changes: ''
    })
    const diamond = reactive({
      colors: [],
      cuts: [],
      purities: [],
      symmetry: [],
      polish: [],
      fluerocents: [],
      tensions: [],
      stones: [],
      shapes: [],
    })
    const packets = toRef(pagedata,'packets')
    const errors = toRef(pagedata,'errors')
    const managers = toRef(pagedata,'managers')
    const success = toRef(pagedata,'success')
    const paketDet = reactive({
      id: '',
      number: '',
      color: '',
      cut: '',
      purity: '',
      polish: '',
      shape: '',
      symmetry: '',
      fluerocent: '',
      stone: '',
      tension: '',
      issueWt: '',
      pwt: '',
      admin_pwt: '',
      oriznal_wt: '',
      status: ''
    })
    const receiveDet = reactive({
      id: '',
      paket_no: '',
      color: '',
      cut: '',
      purity: '',
      polish: '',
      shape: '',
      symmetry: '',
      tension: '',
      stone: '',
      fluerocent: '',
      pktwt: '',
      description: '',
      type: ''
    })
    onMounted(() => {
      getDiamontData()
    })
    function getPaketDetail() {
      if(pagedata.search != '' && pagedata.search != null){
        axios({
          url: `/process/manager-packet/${pagedata.search}`,
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          setTimeout(() => {
            pagedata.search = ''
          }, 1000);
          pagedata.packets = res.data.packets
          paketDet.id = res.data.paket.id
          paketDet.number = res.data.paket.number
          paketDet.color = res.data.paket.color
          paketDet.cut = res.data.paket.cut
          paketDet.purity = res.data.paket.purity
          paketDet.polish = res.data.paket.polish
          paketDet.shape = res.data.paket.shape
          paketDet.symmetry = res.data.paket.symmetry
          paketDet.fluerocent = res.data.paket.fluerocent
          paketDet.stone = res.data.paket.stone
          paketDet.tension = res.data.paket.tension
          paketDet.issueWt = res.data.paket.weight
          paketDet.pwt = res.data.paket.pwt
          paketDet.admin_pwt = res.data.paket.admin_pwt
          paketDet.oriznal_wt = res.data.paket.oriznal_wt
          paketDet.status = res.data.paket.packet_status
          // if(pagedata.packets.length == 0) {
          //   paketDet.inprocess = 0
          // }
        }).catch((err) => {
          pagedata.errors = err.response.data
          cash('#delete-confirmation-modal').modal('show')
          setTimeout(() => {
            pagedata.errors = []
            cash('#delete-confirmation-modal').modal('hide')
          }, 3000);
          pagedata.packets = []
          paketDet.id = ''
          paketDet.number = ''
          paketDet.color = []
          paketDet.cut = []
          paketDet.purity = []
          paketDet.polish = []
          paketDet.shape = []
          paketDet.stone = []
          paketDet.symmetry = []
          paketDet.tension = []
          paketDet.status = '',
          setTimeout(() => {
            pagedata.search = ''
          }, 1000);
        })
      }
    }
    function printData() {
      var print = this.$refs.paketDetails
      const newWin = window.open('')
      newWin.document.write(print.outerHTML)
      newWin.print()
    }
    function getProcessData() {
      axios({
        url: `/user/emp-process-by-packet-no/${paketDet.id}`,
        method: 'get',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.process = res.data.processes
      })
    }
    function getProcessesCheck() {
      getProcessData()
    }
    function AssignProcess() {
      pagedata.submit = true
      axios({
        url: '/process/assignProc',
        method: 'post',
        baseURL: BASE_URL,
        data: { process : pagedata.procs, pktnum : paketDet.id},
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.submit = false
        pagedata.success.success = res.data.success
        setTimeout(() => {
          basicNonStickyNotificationPacketReceive()
        },100)
        setTimeout(() => {
          pagedata.success.success = ''
        }, 4000);
        cash('#new-process-modal').modal('hide')
        getPaketDetail()
        process.employee = ''
        process.process = ''
        process.parties = ''
        process.expected_weight = ''
      }).catch((err) => {
        pagedata.submit = false
        pagedata.errors = err.response.data.errors
        setTimeout(() => {
          pagedata.errors = []
        }, 5000);
      })
    }
    function getDiamondDetail(id) {
      receiveDet.id = id
      receiveDet.paket_no = paketDet.number
      cash('#edit-confirmation-modal').modal('show')
      receiveDet.color = paketDet.color.id
      receiveDet.cut = paketDet.cut.id
      receiveDet.purity = paketDet.purity.id
      receiveDet.symmetry = paketDet.symmetry.id
      receiveDet.polish = paketDet.polish.id
      receiveDet.fluerocent = paketDet.fluerocent.id
      receiveDet.tension = paketDet.tension.id
      receiveDet.shape = paketDet.shape.id
      receiveDet.stone = paketDet.stone.id
    }
    function getDiamontData() {
      axios({
        url: '/GetAll',
        method: 'get',
        baseURL: BASE_URL,
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        diamond.colors = res.data.diamonds.colors
        diamond.cuts = res.data.diamonds.cut
        diamond.purities = res.data.diamonds.purity
        diamond.symmetry = res.data.diamonds.symmetry
        diamond.polish = res.data.diamonds.polish
        diamond.fluerocents = res.data.diamonds.fluerocent
        diamond.tensions = res.data.diamonds.tension
        diamond.shapes = res.data.diamonds.shape
        diamond.stones = res.data.diamonds.stones
      })
    }
    function createRateRequest() {
      pagedata.submit = true
      axios({
        url: '/packet/create-rate-request',
        method: 'post',
        baseURL: BASE_URL,
        data: receiveDet,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      }).then((res) => {
        pagedata.submit = false
        pagedata.success.success = res.data.success
        getPaketDetail()
        cash('#rate-show-confirmation-modal').modal('hide')
        cash('#edit-confirmation-modal').modal('hide')
        cash('#success-modal-preview').modal('show')
        setTimeout(() => {
          pagedata.success.success = ''
          cash('#success-modal-preview').modal('hide')
        }, 3000);
      }).catch(() => {
        pagedata.submit = false
      })
    }
    function createParamRequest() {
      pagedata.submit = true
      axios({
        url: '/packet/create-param-request',
        method: 'post',
        baseURL: BASE_URL,
        data: receiveDet,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      }).then((res) => {
        pagedata.submit = false
        pagedata.success.success = res.data.success
        getPaketDetail()
        cash('#rate-show-confirmation-modal').modal('hide')
        cash('#edit-confirmation-modal').modal('hide')
        cash('#success-modal-preview').modal('show')
        setTimeout(() => {
          pagedata.success.success = ''
          cash('#success-modal-preview').modal('hide')
        }, 3000);
      }).catch(() => {
        pagedata.submit = false
      })
    }
    function receivePKT() {
      pagedata.submit = true
      pagedata.errors = []
      axios({
        url: '/process/recpkt',
        method: 'post',
        baseURL: BASE_URL,
        data: receiveDet,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.submit = false
        pagedata.success.success = res.data.success
        setTimeout(() => {
          basicNonStickyNotificationPacketReceive()
        }, 100);
        setTimeout(() => {
          pagedata.success.success = ''
        }, 4000);
        cash('#edit-confirmation-modal').modal('hide')
        pagedata.search = paketDet.number
      }).catch((err) => {
          if(err.response.data.error) {
            pagedata.errors.error = err.response.data.error
            pagedata.errors.message = err.response.data.message
            pagedata.errors.data = err.response.data.data
            receiveDet.type = err.response.data.type
            cash('#rate-show-confirmation-modal').modal('show')
          } else {
            pagedata.errors = err.response.data.errors
            setTimeout(() => {
              pagedata.errors = []
            }, 4000);
          }
              pagedata.submit = false
      })
    }
    function cancelProcess() {
      cash('#cancel-confirmation-modal').modal('show')
    }
    function updateParameters(changes) {
      cash('#middle-parameter-confirmation-modal').modal('show')
      pagedata.changes = changes
    }
    function sendRequestMdlParam() {
      pagedata.submit = true
      axios({
        url: '/process/create-middle-proc-request',
        method: 'post',
        baseURL: BASE_URL,
        data: pagedata.changes,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      }).then((res) => {
        pagedata.submit = false
        pagedata.success.success = res.data.success
         cash('#middle-parameter-confirmation-modal').modal('hide')
        setTimeout(() => {
          cash('#success-modal-preview').modal('show')
        }, 100);
        pagedata.search = paketDet.number
        setTimeout(() => {
          cash('#success-modal-preview').modal('hide')
          pagedata.success.success = ''
        }, 4000);
      }).catch((err) => {
        pagedata.submit = false
        pagedata.error = err.response.data.error
        setTimeout(() => {
          basicNonStickyNotificationPacketError()
        }, 100);
        setTimeout(() => {
          pagedata.error = ''
        }, 4000);
      })
    }
    const basicNonStickyNotificationPacketReceive = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification-packet-receive')
          .clone()
          .removeClass('hidden')[0],
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    watch(() => pagedata.search, (currentValue) => {
      if(!pagedata.check) {
        if (!pagedata.waiting) {
          setTimeout(() => {
            getPaketDetail();
            pagedata.waiting = false;
          }, 100);
        }
        pagedata.waiting = true;
      }
    })
    const basicNonStickyNotificationPacketError = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification-packet-error')
          .clone()
          .removeClass('hidden')[0],
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    return {
      pagedata,
      packets,
      errors,
      paketDet,
      receiveDet,
      diamond,
      managers,
      success,
      basicNonStickyNotificationPacketError,
      basicNonStickyNotificationPacketReceive,
      getPaketDetail,
      printData,
      receivePKT,
      getDiamondDetail,
      createRateRequest,
      createParamRequest,
      cancelProcess,
      getProcessData,
      getProcessesCheck,
      AssignProcess,
      updateParameters,
      sendRequestMdlParam
    }
  },
  mounted() {
    this.$refs.focusMe.focus()
  }
})
</script>

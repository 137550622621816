<template>
  <!-- BEGIN: Profile Info -->
  <div id="basic-non-sticky-notification-packet" class="toastify-content hidden flex flex-col sm:flex-row">
      <div class="font-medium text-primary-3" v-if="pagedata.error">{{ pagedata.error }}</div>
      <div class="font-medium text-primary-4" v-if="pagedata.success">{{ pagedata.success }}</div>
  </div>
  <div>
    <div class="xl:grid block xl:grid-cols-2 gap-6">
      <div>
        <h2 class="intro-y text-lg font-medium mt-5 ml-2">Scan Packet</h2>
        <div class="flex items-center mt-2 ml-2">
          <div class="mr-2 flex items-center">
            <input
              id="remember-me"
              type="checkbox" v-model="pagedata.check"
              class="form-check-input border mr-2 bg-white"
            />
            <label for="remember-me" class="text-md font-medium">Menual</label>
          </div>
          <div class="mx-2 flex items-center">
            <input
              id="remember-me"
              type="checkbox" v-model="pagedata.multiple"
              class="form-check-input border mr-2 bg-white"
            />
            <label for="remember-me" class="text-md font-medium">Multiple Receive</label>
          </div>
        </div>
        <div class="w-full flex sm:w-auto mt-3 ml-2">
          <div class="w-56 relative text-gray-700 dark:text-gray-300 mr-4">
            <input
              type="text"
              v-model="pagedata.search"
              ref="focusMe"
              autofocus
              class="form-control w-56 box pr-10 placeholder-theme-13"
              placeholder="Search Packet"
            />
            <SearchIcon
              class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
            />
          </div>
          <button class="btn btn-primary shadow-md mr-2" @click="AddtoList">Add</button>
          <a class="btn btn-primary shadow-md mr-2" href="javascript:;" data-toggle="modal" @click="getProcessesCheck" data-target="#process-assign-packet-plans-modal" v-if="pagedata.addlist.length > 0 && pagedata.multiple == true">Assign Packets</a>
        </div>
      </div>
    </div>
    <div class="mt-5">
      <div class="grid grid-cols-12 gap-5" v-if="pagedata.multiple == true">
        <div
          class="col-span-6 xl:col-span-2 md:col-span-3 sm:col-span-4 box p-5 cursor-pointer zoom-in" v-for="(add,index) in pagedata.addlist" :key="index"
        >
          <div class="flex justify-between">
            <div class="font-medium text-base">{{ add }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: Data List -->
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex items-center">
        <h2 class="text-lg font-bold">Ls Packets</h2>
      </div>
      <div class="overflow-x-auto">
        <table class="w-full">
          <thead>
            <tr class="bg-gray-700 dark:bg-dark-1 text-white">
              <th class="whitespace-nowrap py-2">#</th>
              <th class="whitespace-nowrap py-2">Number</th>
              <th class="whitespace-nowrap py-2">Weight</th>
              <th class="whitespace-nowrap py-2">PWT</th>
              <th class="whitespace-nowrap py-2">Shape</th>
              <th class="whitespace-nowrap py-2">Color</th>
              <th class="whitespace-nowrap py-2">Purity</th>
              <th class="whitespace-nowrap py-2">Cut</th>
              <th class="whitespace-nowrap py-2">Polish</th>
              <th class="whitespace-nowrap py-2">Symm</th>
              <th class="whitespace-nowrap py-2">Fluc</th>
              <th class="whitespace-nowrap py-2">Tension</th>
              <th class="whitespace-nowrap py-2">Stone</th>
              <th class="whitespace-nowrap py-2">R Rate</th>
              <th class="whitespace-nowrap py-2">S Rate</th>
              <th class="whitespace-nowrap py-2">Tabl</th>
              <th class="whitespace-nowrap py-2">Height</th>
              <th class="whitespace-nowrap py-2">Discount</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(plandet, i) in pagedata.packets"
        :key="i">
              <td class="border-b dark:border-dark-5 text-center py-2">{{ i + 1 }}</td>
              <td class="border-b dark:border-dark-5 text-center py-2">{{ plandet.number }}</td>
              <td class="border-b dark:border-dark-5 text-center py-2">{{ plandet.weight }}</td>
              <td class="border-b dark:border-dark-5 text-center py-2">{{ plandet.pwt }}</td>
              <td class="border-b dark:border-dark-5 text-center py-2">{{ plandet.shape }}</td>
              <td class="border-b dark:border-dark-5 text-center py-2">{{ plandet.color }}</td>
              <td class="border-b dark:border-dark-5 text-center py-2">{{ plandet.purity }}</td>
              <td class="border-b dark:border-dark-5 text-center py-2">{{ plandet.cut }}</td>
              <td class="border-b dark:border-dark-5 text-center py-2">{{ plandet.polish }}</td>
              <td class="border-b dark:border-dark-5 text-center py-2">{{ plandet.symmetry }}</td>
              <td class="border-b dark:border-dark-5 text-center py-2">{{ plandet.fluerocent }}</td>
              <td class="border-b dark:border-dark-5 text-center py-2">{{ plandet.tension }}</td>
              <td class="border-b dark:border-dark-5 text-center py-2">{{ plandet.stone }}</td>
              <td class="border-b dark:border-dark-5 text-center py-2">{{ plandet.r_rate }}</td>
              <td class="border-b dark:border-dark-5 text-center py-2">{{ plandet.s_rate }}</td>
              <td class="border-b dark:border-dark-5 text-center py-2">{{ plandet.tabl }}</td>
              <td class="border-b dark:border-dark-5 text-center py-2">{{ plandet.height }}</td>
              <td class="border-b dark:border-dark-5 text-center py-2">{{ plandet.discount }}</td>
            </tr>
          </tbody>
        </table>
        <div v-if="pagedata.packets.length <= 0" class="text-center mt-3">No Matching Records Found</div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
  <!-- BEGIN: Process Assign Plan  Modal -->
  <div
    id="process-assign-packet-plans-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <a data-dismiss="modal" href="javascript:;">
          <XIcon class="w-8 h-8 text-gray-500" />
        </a>
        <div class="modal-body p-10 text-center">
          <div class="intro-y box">
            <!-- <div id="vertical-form"> -->
            <div class="flex flex-col justify-between mt-5">
              <!-- BEGIN: Data List -->
              <div class="mt-3" v-if="pagedata.multiple == true">
                <h2 class="text-left intro-y text-lg font-medium mt-5 mb-2 ml-2">Transfer To</h2>
                <TomSelect
                    v-model="process.transfer"
                    id="crud-form-2"
                    :options="{
                      placeholder: 'Select your Labour'
                    }"
                    class="w-full"
                  >
                    <option value="makeable">Makeable</option>
                    <option value="rough">Rough</option>
                  </TomSelect>
              </div>
              <div class="flex items-center mt-3" v-if="pagedata.multiple == false">
                <div class="form-check">
                  <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer" value="0" type="radio" name="flexRadioDefault" id="flexRadioDefault1" v-model="single_process.individual">
                  <label class="form-check-label inline-block text-black text-lg" for="flexRadioDefault1">
                    All
                  </label>
                </div>
                <div class="form-check ml-6">
                  <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer" value="1" type="radio" name="flexRadioDefault" id="flexRadioDefault2" v-model="single_process.individual">
                  <label class="form-check-label inline-block text-black text-lg" for="flexRadioDefault2">
                    Individual
                  </label>
                </div>
              </div>
              <div class="mt-3" v-if="single_process.individual == 0 && pagedata.multiple == false">
                <h2 class="text-left intro-y text-lg font-medium mt-5 mb-2 ml-2">Transfer To</h2>
                <TomSelect
                    v-model="single_process.transfer"
                    id="crud-form-2"
                    :options="{
                      placeholder: 'Select your Labour'
                    }"
                    class="w-full"
                  >
                    <option value="makeable">Makeable</option>
                    <option value="rough">Rough</option>
                  </TomSelect>
              </div>
              <div class="mt-3" v-else-if="single_process.individual == 1 && pagedata.multiple == false">
                <div v-for="(process, index) in single_process.allpackets" :key="index">
                  <div class="mt-3">
                    <div class="sm:grid grid-cols-2 gap-2 items-center text-center">
                      <div>{{ process }}</div>
                      <div>
                        <TomSelect
                          v-model="single_process.multiple_transfer[index]"
                          id="crud-form-2"
                          :options="{
                            placeholder: 'Select your Labour'
                          }"
                          class="w-full"
                        >
                          <option value="makeable">Makeable</option>
                          <option value="rough">Rough</option>
                        </TomSelect>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button type="button" @click="AssignProcess" :disabled="pagedata.submit" class="btn btn-primary w-24 m-auto mt-5">Submit
                <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Process Assign Plan  Modal -->
  <!-- END: Profile Info -->
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import axios from 'axios'
import cash from 'cash-dom'
import { defineComponent, onMounted, reactive, ref, toRef, watch } from 'vue'
import print from 'vue3-print-nb'
import Toastify from 'toastify-js'
export default defineComponent({
  directives: {
    print
  },
  setup() {
    const pagedata = reactive({
      search: '',
      check: false,
      multiple: false,
      waiting: false,
      packets: [],
      addlist: [],
      single_addlist: [],
      error: '',
      addornot: false,
      submit: false,
      success: '',
      error_employee: ''
    })
    const process = reactive({
      multiple: 1,
      transfer: 'makeable',
      allpackets: pagedata.addlist,
    })
    const single_process = reactive({
      individual: 0,
      transfer: 'makeable',
      multiple_transfer: [],
      allpackets: pagedata.single_addlist,
    })
    onMounted(() => {
      getPackets()
    })
    function getPackets() {
      axios({
        url: '/lsbs/manager-packets',
        method: 'get',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.packets = res.data.packets
      })
    }
    function AddtoList() {
      if(pagedata.search != '') {
        axios({
          url: `/lsbs/packet_lot_assign/${pagedata.search}`,
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          let lsbs_packets = res.data.lsbs
          let packet = res.data.packet
          if(pagedata.multiple == true) {
            for (let i = 0; i < lsbs_packets.length; i++) {
              if(pagedata.addlist.includes(lsbs_packets[i])) {
                let index = pagedata.addlist.indexOf(lsbs_packets[i])
                pagedata.addlist.splice(index, 1);
              } else {
                pagedata.addlist[pagedata.addlist.length] = lsbs_packets[i]
              }
            }
          } else {
            for (let i = 0; i < lsbs_packets.length; i++) {
                pagedata.single_addlist[i] = lsbs_packets[i]
            }
            cash('#process-assign-packet-plans-modal').modal('show')
          }
          pagedata.search = ''
          // pagedata.checklist[pagedata.addlist[index.value].id] = true
        }).catch((err) => {
          pagedata.error = err.response.data.error
          setTimeout(() => {
            basicNonStickyNotificationPacket()
          },100)
          setTimeout(() => {
            pagedata.search = ''
          }, 1000);
          setTimeout(() => {
            pagedata.error = ''
          }, 5000);

        })
      }
    }
    function AssignProcess() {
      pagedata.submit = true
      axios({
        url: '/lsbs/assign-process',
        method: 'post',
        baseURL: BASE_URL,
        data: pagedata.multiple == true ? process : single_process,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.success = res.data.success
        pagedata.submit = false
        getPackets()
        setTimeout(() => {
          basicNonStickyNotificationPacket()
        }, 100);
        cash('#process-assign-packet-plans-modal').modal('hide')
        if(pagedata.multiple == true) {
          pagedata.addlist = []
          process.packets = []
          process.transfer = 'makeable'
        } else {
          pagedata.single_addlist = []
          single_process.transfer = 'makeable'
          single_process.individual = 0
          single_process.packet = ''
          single_process.multiple_transfer = []
        }
        setTimeout(() => {
          pagedata.success = ''
        },5000)
      }).catch((err) => {
        pagedata.submit = false
        pagedata.error = err.response.data.error
        setTimeout(() => {
          basicNonStickyNotificationPacket()
        }, 100);
        setTimeout(() => {
          pagedata.error = ''
        }, 5000);
      })
    }
    watch(() => pagedata.search, (currentValue) => {
      if(!pagedata.check) {
        if (!pagedata.waiting) {
          setTimeout(() => {
            AddtoList()
            pagedata.waiting = false;
          }, 100); // 100 m-sec delay
        }
        pagedata.waiting = true;
      }
    })
    watch(() => single_process.individual, (currentValue) => {
      if(currentValue == 1) {
        for(let i = 0; i < pagedata.single_addlist.length; i++) {
          single_process.multiple_transfer[i] = 'makeable'
        }
      }
    })
    const basicNonStickyNotificationPacket = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification-packet')
          .clone()
          .removeClass('hidden')[0],
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    return {
      pagedata,
      process,
      single_process,
      AddtoList,
      AssignProcess,
      basicNonStickyNotificationPacket,
    }
  },
  mounted() {
    this.$refs.focusMe.focus()
  }
})
</script>


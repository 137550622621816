<template>
  <tr :class="{'text-theme-6' : packet.blocked == 1 }">
    <td class="text-center text-sm py-2">{{ index + 1 }}</td>
    <td class="text-center text-sm py-2">{{ packet.transaction_code }}</td>
    <td class="text-center text-sm py-2">{{ packet.process_id }}</td>
    <td class="text-center text-sm py-2">{{ packet.manager_id }}</td>
    <td class="text-center text-sm py-2">{{ packet.employee_id }}</td>
    <td class="text-center text-sm py-2">{{ packet.issue_weight }}</td>
    <td class="text-center text-sm py-2"><input v-if="pagedata.editable" type="number" class="form-control w-16 text-center" v-model="changes.weight"><div v-else>{{ packet.receive_weight }}</div></td>
    <td class="text-center text-sm py-2 w-6">{{ packet.issue_date }}</td>
    <td class="text-center text-sm py-2 w-6">{{ packet.receive_date }}</td>
    <td class="text-center text-sm py-2">
      <div v-if="packet.shape">
        <div class="input-group mt-2 sm:mt-0" v-if="pagedata.editable">
          <select
            class="form-select w-14"
            v-model="changes.shape"
            aria-label="Default select example"
          >
            <option v-for="col in diamond.shapes" :key="col.id" :value="col.id">{{ col.shape_name }}</option>
          </select>
        </div>
        <div v-else>
          {{ packet.shape.shape_name }}
        </div>
      </div>
    </td>
    <td class="text-center text-sm py-2">
      <div v-if="packet.color">
        <div class="input-group mt-2 sm:mt-0" v-if="pagedata.editable">
          <select
            class="form-select w-14"
            v-model="changes.color"
            aria-label="Default select example"
          >
            <option v-for="col in diamond.colors" :key="col.id" :value="col.id">{{ col.color_name }}</option>
          </select>
        </div>
        <div v-else>
          {{ packet.color.color_name }}
        </div>
      </div>
    </td>
    <td class="text-center text-sm py-2">
      <div v-if="packet.purity">
        <div class="input-group mt-2 sm:mt-0" v-if="pagedata.editable">
          <select
            class="form-select w-14"
            v-model="changes.purity"
            aria-label="Default select example"
          >
            <option v-for="col in diamond.purities" :key="col.id" :value="col.id">{{ col.purity_name }}</option>
          </select>
        </div>
        <div v-else>
          {{ packet.purity.purity_name }}
        </div>
      </div>
    </td>
    <td class="text-center text-sm py-2">
      <div v-if="packet.cut">
        <div class="input-group mt-2 sm:mt-0" v-if="pagedata.editable">
          <select
            class="form-select w-14"
            v-model="changes.cut"
            aria-label="Default select example"
          >
            <option v-for="col in diamond.cuts" :key="col.id" :value="col.id">{{ col.cut_name }}</option>
          </select>
        </div>
        <div v-else>
          {{ packet.cut.cut_name }}
        </div>
      </div>
    </td>
    <td class="text-center text-sm py-2">
      <div v-if="packet.polish">
        <div class="input-group mt-2 sm:mt-0" v-if="pagedata.editable">
          <select
            class="form-select w-14"
            v-model="changes.polish"
            aria-label="Default select example"
          >
            <option v-for="col in diamond.polish" :key="col.id" :value="col.id">{{ col.polish_name }}</option>
          </select>
        </div>
        <div v-else>
          {{ packet.polish.polish_name }}
        </div>
      </div>
    </td>
    <td class="text-center text-sm py-2">
      <div v-if="packet.symmetry">
        <div class="input-group mt-2 sm:mt-0" v-if="pagedata.editable">
          <select
            class="form-select w-14"
            v-model="changes.symmetry"
            aria-label="Default select example"
          >
            <option v-for="col in diamond.symmetry" :key="col.id" :value="col.id">{{ col.symmetry_name }}</option>
          </select>
        </div>
        <div v-else>
          {{ packet.symmetry.symmetry_name }}
        </div>
      </div>
    </td>
    <td class="text-center text-sm py-2">
      <div v-if="packet.fluerocent">
        <div class="input-group mt-2 sm:mt-0" v-if="pagedata.editable">
          <select
            class="form-select w-14"
            v-model="changes.fluerocent"
            aria-label="Default select example"
          >
            <option v-for="col in diamond.fluerocents" :key="col.id" :value="col.id">{{ col.fluerocent_name }}</option>
          </select>
        </div>
        <div v-else>
          {{ packet.fluerocent.fluerocent_name }}
        </div>
      </div>
    </td>
    <td class="text-center text-sm py-2">
      <div v-if="packet.tension">
        <div class="input-group mt-2 sm:mt-0" v-if="pagedata.editable">
          <select
            class="form-select w-14"
            v-model="changes.tension"
            aria-label="Default select example"
          >
            <option v-for="col in diamond.tensions" :key="col.id" :value="col.id">{{ col.tension_name }}</option>
          </select>
        </div>
        <div v-else>
          {{ packet.tension.tension_name }}
        </div>
      </div>
    </td>
    <td class="text-center text-sm py-2">
      <div v-if="packet.stone">
        <div class="input-group mt-2 sm:mt-0" v-if="pagedata.editable">
          <select
            class="form-select w-14"
            v-model="changes.stone" disabled
            aria-label="Default select example"
          >
            <option v-for="col in diamond.stones" :key="col.id" :value="col.id">{{ col.stone_name }}</option>
          </select>
        </div>
        <div v-else>
          {{ packet.stone.stone_name }}
        </div>
      </div>
    </td>
    <td class="text-center text-sm py-2">
      <div v-if="packet.transaction_type == 2">
        <button
          class="btn btn-primary mr-1 p-2" @click="pagedata.editable = !pagedata.editable" v-if="pagedata.editable == false"
        >
          <ExternalLinkIcon class="h-4 w-4" />
        </button>
        <div v-else>
          <button class="btn bg-primary-9 text-white mr-1 p-2"><SaveIcon class="w-4 h-4" @click="updateParameters"/></button>
          <button class="btn btn-danger mr-1 p-2" @click="pagedata.editable = !pagedata.editable" v-if="pagedata.editable == true"><XIcon class="h-4 w-4" /></button>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
/* eslint-disable */
import { computed, defineComponent, inject, reactive, watch} from 'vue'
import Toastify from 'toastify-js'
import axios from 'axios'
import { BASE_URL } from '@/constants/config'
import store from '@/store'
import { useRoute } from 'vue-router'
export default defineComponent({
  props: ['packet', 'index', 'diamond','packetDet'],
  emits: ['getData','updateParams'],
  setup(props, {emit}) {
    const changes = reactive({
      id: props.packet.id,
      weight: props.packet.receive_weight,
      color: props.packet.color ? props.packet.color.id : '',
      cut: props.packet.cut ? props.packet.cut.id : '',
      purity: props.packet.purity ? props.packet.purity.id : '',
      polish: props.packet.polish ? props.packet.polish.id : '',
      shape: props.packet.shape ? props.packet.shape.id : '',
      symmetry: props.packet.symmetry ? props.packet.symmetry.id : '',
      fluerocent: props.packet.fluerocent ? props.packet.fluerocent.id : '',
      stone: props.packet.stone ? props.packet.stone.id : '',
      tension: props.packet.tension ? props.packet.tension.id : '',
      weight_issue: false,
      param_issue: false,
    })
    const user_details = computed(() => store.state.user.currentUser)
    const issue = computed(() => props.packet.issue_weight)
    const receive = computed(() => props.packet.receive_weight)
    const pagedata = reactive({
      editable: props.packet.issue_weight < props.packet.receive_weight ? true: false
    })
    const swal = inject('$swal')
    const route = useRoute()
    function updateParameters() {
      if(props.packet.type == 'rough') {
        if(parseFloat(changes.weight) != parseFloat(props.packet.receive_weight)) {
          if(parseFloat(props.packet.issue_weight) < parseFloat(changes.weight)) {
            swal("Warning!", "Receive Weight Must Be Less then Issue Weight!", "warning");
            return
          }
          UpdateWeight()
          return
        } else {
          swal("Warning!", "Please Change Parameters !", "warning");
          return
        }
      } else {
        if(changes.color == props.packet.color.id && changes.cut == props.packet.cut.id && changes.purity == props.packet.purity.id && changes.polish == props.packet.polish.id &&
        changes.shape == props.packet.shape.id && changes.symmetry == props.packet.symmetry.id && changes.fluerocent == props.packet.fluerocent.id && changes.stone == props.packet.stone.id &&
        changes.tension == props.packet.tension.id) {
          changes.param_issue = false
          if(parseFloat(changes.weight) != parseFloat(props.packet.receive_weight)) {
            if(parseFloat(props.packet.issue_weight) < parseFloat(changes.weight)) {
              swal("Warning!", "Receive Weight Must Be Less then Issue Weight!", "warning");
              return
            } else if(parseFloat(props.packetDet.cr_polish_weight) > parseFloat(changes.weight)) {
              if(props.packetDetupdateParams.cr_polish_weight > changes.weight) {
                changes.weight_issue = true
              } else {
                changes.weight_issue = false
              }
              emit('updateParams',changes)
              // return
              console.warn(changes)
            } else {
              UpdateWeight()
              return
            }
          } else {
            swal("Warning!", "Please Change Parameters !", "warning");
            return
          }
        } else {
          changes.param_issue = true
          emit('updateParams',changes)
          console.warn(changes)
          // return
        }
      }
    }
    function UpdateWeight() {
      //screenData.return_progress = true;
      swal({
        title: "Are you sure?",
        text: "You want to Update Weight ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {

          let promise = axios({
            url: 'api/request/update_mdl_process_weight',
            method: "post",
            data: { weight: changes.weight, request_id: changes.id},
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data)
            .then(data => {
              pagedata.editable = false
              swal("Success!", "Transaction Weight is Updated !", "success");
              emit('getData')
            })
            .catch(_error => {
              swal("Warning!", "Somthing went Wrong !", "warning");
            });
        }
      })
    }
    watch(() => issue.value, (newValue,OldValue) => {
      if(receive.value > issue.value) {
        pagedata.editable = true
      }
    })
    return {
      changes,
      pagedata,
      user_details,
      updateParameters,
    }
  }
})
</script>

<style scoped>
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type=number] {
            -moz-appearance: textfield;
        }

        .input-group .input-group-text-custom {
            border-width: 1px;
            --tw-border-opacity: 1;
            border-color: rgba(226, 232, 240, var(--tw-border-opacity));
            --tw-bg-opacity: 1;
            background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
            padding-top: 0.1rem;
            padding-bottom: 0.1rem;
            padding-left: 1rem;
            padding-right: 1rem;
            --tw-text-opacity: 1;
            color: rgba(113, 128, 150, var(--tw-text-opacity));
            --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
            box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
        }
    </style>

<template>
  <div>
    <h2 class="intro-y text-lg font-medium mt-10">Parameter Requests</h2>
      <!-- BEGIN: Data List -->
      <div v-if="pagedata.loading == true" class="text-center text-md mt-5">Loading...</div>
      <div v-else>
        <div v-if="pagedata.params.length <= 0" class="text-center text-md mt-5">Requests Not Found</div>
        <div v-else class="intro-y col-span-12 overflow-auto lg:overflow-visible mt-5">
          <table class="table-report -mt-2 w-full">
            <thead>
              <tr>
                <th class="py-2 whitespace-nowrap">No</th>
                <th class="py-2 whitespace-nowrap">User</th>
                <th class="py-2 whitespace-nowrap">Packet</th>
                <th class="py-2 whitespace-nowrap">Stock No</th>
                <th class="py-2 whitespace-nowrap">T_Id</th>
                <th class="py-2 whitespace-nowrap">Process</th>
                <th class="py-2 whitespace-nowrap">Weight</th>
                <th class="py-2 whitespace-nowrap">Polish WT</th>
                <th class="py-2 whitespace-nowrap">Shape</th>
                <th class="py-2 whitespace-nowrap">Color</th>
                <th class="py-2 whitespace-nowrap">Purity</th>
                <th class="py-2 whitespace-nowrap">Cut</th>
                <th class="py-2 whitespace-nowrap">Polish</th>
                <th class="py-2 whitespace-nowrap">Symm</th>
                <th class="py-2 whitespace-nowrap">Fluer</th>
                <th class="py-2 whitespace-nowrap">Tension</th>
                <th class="py-2 whitespace-nowrap">Stone</th>
                <th class="py-2 whitespace-nowrap">remark</th>
                <th class="py-2 whitespace-nowrap">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(rate, index) in pagedata.params"
                :key="index"
                class="intro-x"
              >
                <td  class="text-center">{{ index + 1 }}</td>
                <td>
                  <div class="font-medium whitespace-nowrap">{{
                    rate.manager_short_name
                  }}</div>
                </td>
                <td class="py-2 font-medium text-center">{{ rate.packet_code }}</td>
                <td class="py-2 font-medium text-center">{{ rate.vepari_no }}</td>
                <td class="py-2 font-medium text-center">{{ rate.transaction_code }}</td>
                <td class="py-2 font-medium text-center">{{ rate.process_name }}</td>
                <td class="py-2 font-medium text-center"><div v-if="rate.cr_polish_weight" :class="{ 'text-primary-3': rate.weight < rate.cr_polish_weight }">{{ rate.weight }}</div></td>
                <td class="py-2 font-medium text-center"><div v-if="rate.cr_polish_weight">{{ rate.cr_polish_weight }}</div></td>
                <td class="py-2 font-medium text-center"><span :class="{ 'text-primary-3': rate.shape_name != rate.prev_shape_name }">{{ rate.shape_name }}</span> / {{ rate.prev_shape_name }}</td>
                <td class="py-2 font-medium text-center"><span :class="{ 'text-primary-3': rate.color_name != rate.prev_color_name }">{{ rate.color_name }}</span> / {{ rate.prev_color_name }}</td>
                <td class="py-2 font-medium text-center"><span :class="{ 'text-primary-3': rate.purity_name != rate.prev_purity_name }">{{ rate.purity_name }}</span> / {{ rate.prev_purity_name }}</td>
                <td class="py-2 font-medium text-center"><span :class="{ 'text-primary-3': rate.cut_name != rate.prev_cut_name }">{{ rate.cut_name }}</span> / {{ rate.prev_cut_name }}</td>
                <td class="py-2 font-medium text-center"><span :class="{ 'text-primary-3': rate.polish_name != rate.prev_polish_name }">{{ rate.polish_name }}</span> / {{ rate.prev_polish_name }}</td>
                <td class="py-2 font-medium text-center"><span :class="{ 'text-primary-3': rate.symmetry_name != rate.prev_symmetry_name }">{{ rate.symmetry_name }}</span> / {{ rate.prev_symmetry_name }}</td>
                <td class="py-2 font-medium text-center"><span :class="{ 'text-primary-3': rate.fluerocent_name != rate.prev_fluerocent_name }">{{ rate.fluerocent_name }}</span> / {{ rate.prev_fluerocent_name }}</td>
                <td class="py-2 font-medium text-center"><span :class="{ 'text-primary-3': rate.tension_name != rate.prev_tension_name }">{{ rate.tension_name }}</span> / {{ rate.prev_tension_name }}</td>
                <td class="py-2 font-medium text-center"><span :class="{ 'text-primary-3': rate.stone_name != rate.prev_stone_name }">{{ rate.stone_name }}</span> / {{ rate.prev_stone_name }}</td>
                <td class="py-2 font-medium text-center">{{ rate.remark }}</td>
                <td class="py-2 table-report__action">
                  <div class="flex justify-center items-center">
                    <button class="btn btn-success mr-1 p-1" @click="AcceptRequest(rate.id,index)">
                      <CheckSquareIcon class="w-5 h-5" /><span v-if="user_details.user_role != 1"> Accept</span>
                    </button>
                    <button class="btn btn-danger p-1" v-if="user_details.user_role == 1" @click="cancelRequest(rate.id)"><XSquareIcon class="w-5 h-5" /></button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- END: Data List -->
    <!-- BEGIN: Receive Packet Modal -->
    <div
      id="edit-confirmation-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <a data-dismiss="modal" href="javascript:;">
              <XIcon class="w-8 h-8 text-gray-500" />
          </a>
          <div class="modal-body p-10 text-center">
            <!-- BEGIN: Vertical Form -->
            <div class="intro-y box">
              <div id="vertical-form" class="p-5">
                <div class="text-lg font-medium mx-3">Accept Request</div>
                  <div class="mt-3" v-if="pagedata.weight_issue">
                    <div class="input-group">
                      <div id="input-group-3" class="input-group-text w-22">New PWT</div>
                      <input
                        type="number"
                        class="form-control w-40"
                        v-model="formData.newpwt"
                        placeholder="New Polish Weight"
                        aria-describedby="input-group-3"
                      />
                    </div>
                    <!-- <span v-if="errors.newpwt" class="text-primary-3">{{ errors.newpwt[0] }}</span> -->
                  </div>
                  <div class="mt-3">
                    <div class="text-lg font-medium text-left m-3">Panalty</div>
                    <div class="flex items-center mt-3">
                      <div class="form-check">
                        <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer" value="0" type="radio" name="flexRadioDefault" id="flexRadioDefault1" v-model="formData.panalty">
                        <label class="form-check-label inline-block text-black text-md" for="flexRadioDefault1">
                          None
                        </label>
                      </div>
                      <div class="form-check ml-6">
                        <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer" value="1" type="radio" name="flexRadioDefault" id="flexRadioDefault2" v-model="formData.panalty">
                        <label class="form-check-label inline-block text-black text-md" for="flexRadioDefault2">
                          Fixed
                        </label>
                      </div>
                      <div class="form-check ml-6">
                        <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer" value="2" type="radio" name="flexRadioDefault" id="flexRadioDefault2" v-model="formData.panalty">
                        <label class="form-check-label inline-block text-black text-md" for="flexRadioDefault2">
                          Percent
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="mt-3" v-if="formData.panalty == 1 || formData.panalty == 2">
                    <div class="sm:grid grid-cols-2 gap-2">
                      <div>
                        <div class="input-group">
                          <div id="input-group-3" class="input-group-text">Manager</div>
                          <div>
                              <input
                                type="text"
                                class="form-control"
                                v-model="formData.manager_panalty"
                                placeholder="Panalty"
                                aria-describedby="input-group-3"
                              />
                          </div>
                        </div>
                        <!-- <div class="text-primary-3" v-if="pagedata.errors.manager_panalty">{{ pagedata.errors.manager_panalty[0] }}</div> -->
                      </div>
                      <div>
                        <div class="input-group mt-2 sm:mt-0">
                          <div id="input-group-4" class="input-group-text">Employee</div>
                          <div>
                              <input
                                type="text"
                                class="form-control"
                                v-model="formData.employee_panalty"
                                placeholder="Panalty"
                                aria-describedby="input-group-4"
                              />
                          </div>
                        </div>
                        <!-- <div class="text-primary-3" v-if="pagedata.errors.employee_panalty">{{ pagedata.errors.employee_panalty[0] }}</div> -->
                      </div>
                    </div>
                  </div>
                  <div class="mt-3">
                    <div class="form-label w-full text-left">Remark</div>
                    <textarea
                      id="regular-form-4"
                      type="password"
                      class="form-control"
                      v-model="formData.description"
                      placeholder="Remark"
                    ></textarea>
                  </div>
                  <div class="text-right mt-5">
                    <button
                      type="button"
                      data-dismiss="modal"
                      class="btn btn-outline-secondary w-24 mr-1"
                    >
                      Cancel
                    </button>
                    <button type="button" class="btn btn-primary w-24" :disabled="pagedata.submit" @click="accept_request">Accept
                    <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" /></button>
                  </div>
              </div>
            </div>
          <!-- END: Vertical Form -->
          </div>
        </div>
      </div>
    </div>
    <!-- END: Receive Packet Modal -->
  </div>
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import axios from 'axios'
import Toastify from 'toastify-js'
import cash from 'cash-dom'
import { computed, defineComponent, inject, onMounted, reactive, ref, toRef, watch } from 'vue'
import store from '@/store'

export default defineComponent({
  setup() {
    const swal = inject('$swal')
    const user_details = computed(() => store.state.user.currentUser)
    const pagedata = reactive({
      params: [],
      loading: false,
      weight_issue: false,
      submit: false,
    })
    const formData = reactive({
      manager_panalty: 0,
      employee_panalty: 0,
      newpwt: 0,
      panalty: 0,
      description: '',
      request_id: '',
    })
    onMounted(() => {
      getParams()
    })
    function getParams() {
      pagedata.loading = true
      axios({
        url: 'api/request/get_parameter_change_requests',
        method: 'post',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.loading = false
        pagedata.params = res.data.params
      }).catch(() => {
        pagedata.loading = false
      })
    }
    function cancelRequest(id) {

      swal({
        title: "Are you sure?",
        text: "You Want to Cancel Request!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
          if(result.isConfirmed){
            axios({
              url: 'api/request/cancel_parameter_request',
              method: 'post',
              baseURL: BASE_URL,
              data: {id : id},
              headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
              config: { headers: { 'Accept':'application/json' } }
            }).then((res) => {
              cash('#delete-confirmation-modal').modal('hide')
              swal("Success!", "Request Cancelled.", "success");
              getParams()
            }).catch(() => {
              swal("Warning!", "Request Not Cancelled!", "warning");
            })
          }
      });
    }
    function AcceptRequest(id,index) {
      if(user_details.value.user_role == 1) {
        formData.request_id = id
        if(pagedata.params[index].cr_polish_weight > pagedata.params[index].weight) {
          pagedata.weight_issue = true
        } else {
          pagedata.weight_issue = false
        }
        cash('#edit-confirmation-modal').modal('show')
      } else {
        swal({
        title: "Are you sure?",
        text: "You Want to Accept Request!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Accept",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
          if(result.isConfirmed){
            axios({
              url: 'api/request/accept_parameter_request_manager',
              method: 'post',
              baseURL: BASE_URL,
              data: {id : id},
              headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
              config: { headers: { 'Accept':'application/json' } }
            }).then((res) => {
              swal("Success!", "Request Accepted.", "success");
              getParams()
            }).catch(() => {
              swal("Warning!", "Request Not Accepted!", "warning");
            })
          }
      });
      }
    }
    function accept_request() {
      pagedata.submit = true
      axios({
        url: 'api/request/accept_parameter_request',
        method: 'post',
        baseURL: BASE_URL,
        data:formData,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.submit = false
        cash('#edit-confirmation-modal').modal('hide')
        formData.manager_panalty = 0
        formData.employee_panalty = 0
        formData.newpwt = 0
        formData.panalty = 0
        formData.description = ''
        formData.request_id = ''
        getParams()
      }).catch(() => {
        pagedata.submit = false
      })
    }
    watch(() => formData.panalty, (currentValue,oldValue) => {
      formData.manager_panalty = '0'
      formData.employee_panalty = '0'
    })
    return {
      pagedata,
      formData,
      user_details,
      cancelRequest,
      AcceptRequest,
      accept_request
    }
  }
})
</script>
<style scoped>
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type=number] {
            -moz-appearance: textfield;
        }

        .input-group .input-group-text-custom {
            border-width: 1px;
            --tw-border-opacity: 1;
            border-color: rgba(226, 232, 240, var(--tw-border-opacity));
            --tw-bg-opacity: 1;
            background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
            padding-top: 0.1rem;
            padding-bottom: 0.1rem;
            padding-left: 1rem;
            padding-right: 1rem;
            --tw-text-opacity: 1;
            color: rgba(113, 128, 150, var(--tw-text-opacity));
            --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
            box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
        }
    </style>


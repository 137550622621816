<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Back Details</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
          <router-link
          :to="{ name: 'create-back-discount' }"
          tag="button"
           class="btn btn-primary shadow-md mr-2">

          New Back
          </router-link>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="flex mt-5 sm:mt-0">

          <div class="dropdown w-1/2 sm:w-auto">
            <button
              class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto"
              aria-expanded="false"
            >
              <FileTextIcon class="w-4 h-4 mr-2" /> Export
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-menu w-40">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                <a
                  id="tabulator-export-csv"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportCsv"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export CSV
                </a>
                <a
                  id="tabulator-export-json"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportJson"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export JSON
                </a>
                <a
                  id="tabulator-export-xlsx"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportXlsx"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                </a>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>

</template>

<script>
/* eslint-disable */
import { defineComponent, ref,onMounted, reactive } from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'

export default defineComponent({
  setup() {
    const router = useRouter()
    const route = useRoute()
    const tableRef = ref()
    const tabulator = ref()

    const formData = reactive({
      // process_type : '0',
      // process_id : '0',
      // processes: []
    })
    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + 'api/master/get_back_details',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams: {
          // process_id : formData.process_id == '0' ? '' : formData.process_id,
          // process_type : formData.process_type == '0' ? '' : formData.process_type
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [10, 20, 30, 40],
        layout: 'fitColumns',
        headerSort: false,
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [
          // For HTML table
          {
            title: 'From',
            responsive: 0,
            minWidth: 70,
            field: 'from',
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'To',
            responsive: 0,
            field: 'to',
            minWidth: 70,
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'Cl',
            responsive: 0,
            field: 'color',
            minWidth: 70,
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'Pt',
            responsive: 0,
            field: 'purity',
            minWidth: 70,
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'Sp',
            responsive: 0,
            field: 'shape',
            minWidth: 70,
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'Ct',
            responsive: 0,
            field: 'cut',
            minWidth: 70,
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'Pl',
            responsive: 0,
            field: 'polish',
            minWidth: 70,
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'Sm',
            responsive: 0,
            field: 'symmetry',
            minWidth: 70,
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'Fl',
            responsive: 0,
            field: 'fluerocent',
            minWidth: 70,
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'St',
            responsive: 0,
            field: 'stone',
            minWidth: 70,
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'Ratio',
            responsive: 0,
            field: 'ratio',
            minWidth: 100,
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'tabl',
            responsive: 0,
            field: 'tabl',
            minWidth: 100,
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'Height',
            responsive: 0,
            field: 'height',
            minWidth: 100,
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'Back',
            responsive: 0,
            field: 'back',
            minWidth: 100,
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'Status',
            minWidth: 100,
            field: 'active_status',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div class="flex lg:justify-center ${
                cell.getData().active_status == 1 ? 'text-theme-9' : 'text-theme-6'
              }">
                <i data-feather="check-square" class="w-4 h-4 mr-2"></i> ${
                  cell.getData().active_status == 1 ? 'Active' : 'Inactive'
                }
              </div>`
            }
          },
          {
            title: 'Action',
            // minWidth: 50,
            field: 'actions',
            responsive: 1,
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              const a = cash(`<div class="flex lg:justify-center items-center">
                <a class="flex items-center mr-3" href="javascript:;">
                  <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Edit
                </a>

              </div>`)
              cash(a).on('click', function() {
                //getDetails(cell.getData().id);
                router.push("/master/back/update/" + window.btoa(cell.getData().id));
              })

              return a[0]
            }
          },

          {
            title: 'From',
            visible: false,
            field: 'from',
            vertAlign: 'middle',
            print: false,
            download: true,

          },
          {
            title: 'To',
            visible: false,
            field: 'to',
            vertAlign: 'middle',
            print: false,
            download: true,

          },
          {
            title: 'Color',
            visible: false,
            field: 'color',
            vertAlign: 'middle',
            print: false,
            download: true,

          },
          {
            title: 'Purity',
            visible: false,
            field: 'purity',
            vertAlign: 'middle',
            print: false,
            download: true,

          },
          {
            title: 'Shape',
            visible: false,
            field: 'shape',
            vertAlign: 'middle',
            print: false,
            download: true,

          },
          {
            title: 'Cut',
            visible: false,
            field: 'cut',
            vertAlign: 'middle',
            print: false,
            download: true,

          },
          {
            title: 'Polish',
            visible: false,
            field: 'polish',
            vertAlign: 'middle',
            print: false,
            download: true,

          },
          {
            title: 'Symmetry',
            visible: false,
            field: 'symmetry',
            vertAlign: 'middle',
            print: false,
            download: true,

          },
          {
            title: 'Fluc',
            visible: false,
            field: 'fluerocent',
            vertAlign: 'middle',
            print: false,
            download: true,

          },
          {
            title: 'Stone',
            responsive: 0,
            visible: false,
            field: 'stone',
            vertAlign: 'middle',
            print: false,
            download: true,

          },
          {
            title: 'Ratio',
            responsive: 0,
            field: 'ratio',
            vertAlign: 'middle',
            visible: false,
            print: false,
            download: true,

          },
          {
            title: 'tabl',
            responsive: 0,
            field: 'tabl',
            vertAlign: 'middle',
            visible: false,
            print: false,
            download: true,

          },
          {
            title: 'Height',
            responsive: 0,
            field: 'height',
            vertAlign: 'middle',
            visible: false,
            print: false,
            download: true,

          },
          {
            title: 'Back',
            responsive: 0,
            field: 'back',
            vertAlign: 'middle',
            visible: false,
            print: false,
            download: true,

          },
          {
            title: 'Status',
            field: 'active_status',
            visible: false,
            print: true,
            download: true,
            formatterPrint(cell) {
              return cell.getValue() ? 'Active' : 'Inactive'
            }
          },

        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }


    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'data.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'data.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'data.xlsx', {
        sheetName: 'Products'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }

    onMounted(() => {
      initTabulator()
      reInitOnResizeWindow()
    })


    return {
      tableRef,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      formData
    }
  }
})
</script>

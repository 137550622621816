<template>
  <div>
    <div id="basic-non-sticky-notification" class="toastify-content hidden flex flex-col sm:flex-row">
      <div class="flex items-center justify-around" v-if="pagedata.message">
        <CheckCircleIcon class="w-6 h-6 text-theme-9 mx-auto" />
        <div class="font-medium text-black">{{ pagedata.message }}</div>
      </div>
      <div class="flex items-center justify-around" v-if="pagedata.error">
        <CheckCircleIcon class="w-6 h-6 text-theme-6 mx-auto" />
        <div class="font-medium text-black">{{ pagedata.error }}</div>
      </div>
    </div>
    <h2 class="intro-y text-lg font-medium mt-10">Edit Requests</h2>
      <!-- BEGIN: Data List -->
      <div v-if="pagedata.loading == true" class="text-center text-md mt-5">Loading...</div>
      <div v-else>
        <div v-if="params.length <= 0" class="text-center text-md mt-5">Requests Not Found</div>
        <div v-else class="intro-y col-span-12 overflow-auto lg:overflow-visible mt-5">
          <table class="table-report -mt-2 w-full">
            <thead>
              <tr>
                <th class="py-2 whitespace-nowrap">No</th>
                <th class="py-2 whitespace-nowrap">User</th>
                <th class="py-2 whitespace-nowrap">Packet</th>
                <th class="py-2 whitespace-nowrap">T_Id</th>
                <th class="py-2 whitespace-nowrap">Process</th>
                <th class="py-2 whitespace-nowrap">I-weight</th>
                <th class="py-2 whitespace-nowrap">R-weight</th>
                <th class="py-2 whitespace-nowrap">Polish WT</th>
                <th class="py-2 whitespace-nowrap">Shape</th>
                <th class="py-2 whitespace-nowrap">Color</th>
                <th class="py-2 whitespace-nowrap">Purity</th>
                <th class="py-2 whitespace-nowrap">Cut</th>
                <th class="py-2 whitespace-nowrap">Polish</th>
                <th class="py-2 whitespace-nowrap">Symm</th>
                <th class="py-2 whitespace-nowrap">Fluer</th>
                <th class="py-2 whitespace-nowrap">Tension</th>
                <th class="py-2 whitespace-nowrap">Stone</th>
                <th class="py-2 whitespace-nowrap">remark</th>
                <th class="py-2 whitespace-nowrap">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(rate, index) in params"
                :key="index"
                class="intro-x"
              >
                <td  class="text-center">{{ index + 1 }}</td>
                <td>
                  <div class="font-medium whitespace-nowrap">{{
                    rate.user_id
                  }}</div>
                </td>
                <td class="py-2 font-medium text-center">{{ rate.packet }}</td>
                <td class="py-2 font-medium text-center">{{ rate.t_id }}</td>
                <td class="py-2 font-medium text-center">{{ rate.process }}</td>
                <td class="py-2 font-medium text-center">{{ packets[index].issue_weight }}</td>
                <td class="py-2 font-medium text-center"><div v-if="packets[index].adminpwt" :class="{ 'text-primary-3': rate.weight < packets[index].adminpwt }">{{ rate.weight }}</div><div v-else :class="{ 'text-primary-3': rate.weight < packets[index].polishwt }">{{ rate.weight }}</div></td>
                <td class="py-2 font-medium text-center"><div v-if="packets[index].adminpwt">{{ packets[index].adminpwt }}</div><div v-else>{{ packets[index].polishwt }}</div></td>
                <td class="py-2 font-medium text-center"><span :class="{ 'text-primary-3': rate.shape != packets[index].shape }">{{ rate.shape }}</span> / {{ packets[index].shape }}</td>
                <td class="py-2 font-medium text-center"><span :class="{ 'text-primary-3': rate.color != packets[index].color }">{{ rate.color }}</span> / {{ packets[index].color }}</td>
                <td class="py-2 font-medium text-center"><span :class="{ 'text-primary-3': rate.purity != packets[index].purity }">{{ rate.purity }}</span> / {{ packets[index].purity }}</td>
                <td class="py-2 font-medium text-center"><span :class="{ 'text-primary-3': rate.cut != packets[index].cut }">{{ rate.cut }}</span> / {{ packets[index].cut }}</td>
                <td class="py-2 font-medium text-center"><span :class="{ 'text-primary-3': rate.polish != packets[index].polish }">{{ rate.polish }}</span> / {{ packets[index].polish }}</td>
                <td class="py-2 font-medium text-center"><span :class="{ 'text-primary-3': rate.symmetry != packets[index].symmetry }">{{ rate.symmetry }}</span> / {{ packets[index].symmetry }}</td>
                <td class="py-2 font-medium text-center"><span :class="{ 'text-primary-3': rate.fluerocent != packets[index].fluerocent }">{{ rate.fluerocent }}</span> / {{ packets[index].fluerocent }}</td>
                <td class="py-2 font-medium text-center"><span :class="{ 'text-primary-3': rate.tension != packets[index].tension }">{{ rate.tension }}</span> / {{ packets[index].tension }}</td>
                <td class="py-2 font-medium text-center"><span :class="{ 'text-primary-3': rate.stone != packets[index].stone }">{{ rate.stone }}</span> / {{ packets[index].stone }}</td>
                <td class="py-2 font-medium text-center">{{ rate.remark }}</td>
                <td class="py-2 table-report__action">
                  <div class="flex justify-center items-center">
                    <a class="btn btn-success mr-1 p-1" href="javascript:;" data-toggle="modal"
                      data-target="#edit-confirmation-modal" @click="AcceptRequest(rate.packet)">
                      <CheckSquareIcon class="w-5 h-5" />
                    </a>
                    <a class="btn btn-danger p-1" href="javascript:;" data-toggle="modal"
                      data-target="#delete-confirmation-modal" @click="pagedata.deleteId = rate.id">
                      <XSquareIcon class="w-5 h-5" />
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- END: Data List -->
    <!-- BEGIN: Delete Confirmation Modal -->
    <div
      id="delete-confirmation-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
              <div class="text-3xl mt-5">Are you sure?</div>
              <div class="text-gray-600 mt-2">
                Do you really want to delete these records? <br />This process
                cannot be undone.
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-24 mr-1"
              >
                Cancel
              </button>
              <button type="button" class="btn btn-danger w-24" :disabled="pagedata.submit" @click="cancelRequest">Delete
              <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" /></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Delete Confirmation Modal -->
  </div>
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import axios from 'axios'
import Toastify from 'toastify-js'
import cash from 'cash-dom'
import { defineComponent, onMounted, reactive, ref, toRef, watch } from 'vue'
import router from '@/router'

export default defineComponent({
  setup() {
    const params = ref([])
    const packets = ref([])
    const pagedata = reactive({
      error: '',
      message: '',
      deleteId: '',
      loading: true,
      weight_issue: false,
      acceptId: '',
      ind: '',
      submit: false,
      panalty: 0,
      manager_panalty: '',
      employee_panalty: '',
      newpwt: '',
      errors: [],
      description: []
    })
    const errors = toRef(pagedata,'errors')
    onMounted(() => {
      getParams()
    })
    function getParams() {
      pagedata.loading = true
      axios({
        url: '/packet/mdl-param-requests',
        method: 'get',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.loading = false
        params.value = res.data.params
        packets.value = res.data.packets
      }).catch(() => {
        pagedata.loading = false
      })
    }
    function cancelRequest() {
      pagedata.submit = true
      axios({
        url: `/packet/mdl-param-cancel-requests/${pagedata.deleteId}`,
        method: 'get',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.submit = false
        pagedata.message = res.data.success
        cash('#delete-confirmation-modal').modal('hide')
        setTimeout(() => {
          basicNonStickyNotification()
        }, 100)
        setTimeout(() => {
          pagedata.message = ''
        }, 5000);
        getParams()
      }).catch((err) => {
        pagedata.submit = false
        pagedata.error = res.data.error
        setTimeout(() => {
          pagedata.error = ''
        }, 5000);
      })
    }
    function AcceptRequest(id) {
      router.push({ name: 'param-mdl-request', params: { id: id } })
    }
    const basicNonStickyNotification = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification')
          .clone()
          .removeClass('hidden')[0],
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    watch(() => pagedata.panalty, (currentValue,oldValue) => {
      pagedata.manager_panalty = ''
      pagedata.employee_panalty = ''
    })
    return {
      pagedata,
      packets,
      params,
      errors,
      cancelRequest,
      AcceptRequest,
    }
  }
})
</script>

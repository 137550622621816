<template>
  <!-- BEGIN: Profile Info -->
  <div id="basic-non-sticky-notification-packet" class="toastify-content hidden flex flex-col sm:flex-row">
      <div class="font-medium text-primary-3" v-if="errors.error">{{ errors.error }}</div>
  </div>
  <div id="basic-non-sticky-notification-packet-receive" class="toastify-content hidden flex flex-col sm:flex-row">
      <div class="flex items-center justify-around">
        <CheckCircleIcon class="w-6 h-6 text-theme-9 mx-auto" />
        <div class="font-medium text-black">Packets Received</div>
      </div>
  </div>
  <div>
    <div class="xl:grid block xl:grid-cols-2 gap-6">
      <div>
        <h2 class="intro-y text-lg font-medium mt-5 ml-2">Scan Packet</h2>
        <div class="flex items-center mt-2 ml-2">
          <div class="mr-2 flex items-center">
            <input
              id="remember-me"
              type="checkbox" v-model="pagedata.check"
              class="form-check-input border mr-2 bg-white"
            />
            <label for="remember-me" class="text-md font-medium">Menual</label>
          </div>
        </div>
        <div class="w-full flex sm:w-auto mt-3 ml-2">
          <div class="w-56 relative text-gray-700 dark:text-gray-300 mr-4">
            <input
              type="text"
              v-model="pagedata.search"
              autofocus
              ref="focusMe"
              class="form-control w-56 box pr-10 placeholder-theme-13"
              placeholder="Search Packet"
            />
            <SearchIcon
              class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
            />
          </div>
          <button class="btn btn-primary shadow-md mr-2" @click="AddtoList">Add</button>
          <button class="btn btn-primary shadow-md mr-2" @click="getDiamondDetail" v-if="addlist.length > 0">Receive Packets</button>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-5 mt-5">
          <div
            class="col-span-6 xl:col-span-2 md:col-span-3 sm:col-span-4 box p-5 cursor-pointer zoom-in" v-for="(add,index) in addlist" :key="index"
          >
            <div class="flex justify-between">
              <div class="font-medium text-base">{{ add }}</div>
              <button class="btn btn-outline-danger p-1 pl-2 hover:text-white" @click="removeItem(index)"><Trash2Icon class="w-4 h-4 mr-1" /></button>
            </div>
          </div>
        </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex items-center">
        <h2 class="text-lg font-bold">Processing Packets</h2>
        <div class="w-48 relative text-gray-700 dark:text-gray-300 ml-4">
            <select
                class="form-select"
                v-model="pagedata.type"
                @change="initTabulator"
                aria-label="Default select example"
              >
                <option value="">Select Type</option>
                <option value="makeable">Makeable</option>
                <option value="rough">Rough</option>
                <option value="video">Video</option>
                <option value="lab">Lab</option>
              </select>
          </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
  <!-- BEGIN: Receive Packet Modal -->
    <div
      id="edit-confirmation-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <a data-dismiss="modal" href="javascript:;">
              <XIcon class="w-8 h-8 text-gray-500" />
          </a>
          <div class="modal-body p-10 text-center">
            <!-- BEGIN: Vertical Form -->
            <div class="intro-y box">
              <div id="vertical-form" class="p-5">
                <div class="text-lg font-medium mx-3">Packet No :- <span class="text-primary-1">{{ receiveDet.number[pagedata.paket_num] }}</span></div>
                  <div class="grid grid-cols-12 gap-6 mt-5">
                    <div class="intro-y col-span-12 md:col-span-6">
                      <div class="mt-3">
                        <div class="input-group mt-2 sm:mt-0">
                          <div id="input-group-3" class="input-group-text w-36 text-center">Color</div>
                          <select
                            class="form-select"
                            v-model="receiveDet.color[pagedata.paket_num]"
                            aria-label="Default select example"
                          >
                            <option v-for="col in pagedata.colors" :key="col.id" :value="col.id">{{ col.name }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="mt-3">
                        <div class="input-group">
                          <div id="input-group-3" class="input-group-text w-36 text-center">Purity</div>
                          <select
                            class="form-select"
                            v-model="receiveDet.purity[pagedata.paket_num]"
                            aria-label="Default select example"
                          >
                            <option v-for="col in pagedata.purities" :key="col.id" :value="col.id">{{ col.name }}</option>
                        </select>
                        </div>
                      </div>
                      <div class="mt-3">
                        <div class="input-group mt-2 sm:mt-0">
                          <div id="input-group-3" class="input-group-text w-36 text-center">Shape</div>
                          <select
                            class="form-select"
                            v-model="receiveDet.shape[pagedata.paket_num]"
                            aria-label="Default select example"
                          >
                            <option v-for="col in pagedata.shapes" :key="col.id" :value="col.id">{{ col.name }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="mt-3">
                        <div class="input-group sm:mt-0">
                          <div id="input-group-3" class="input-group-text w-36 text-center">Flurocent</div>
                          <select
                            class="form-select"
                            v-model="receiveDet.fluerocent[pagedata.paket_num]"
                            aria-label="Default select example"
                          >
                            <option v-for="col in pagedata.fluerocents" :key="col.id" :value="col.id">{{ col.name }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="mt-3">
                        <div class="input-group">
                          <div id="input-group-3" class="input-group-text w-36 text-center">Tension</div>
                          <select
                            class="form-select"
                            v-model="receiveDet.tension[pagedata.paket_num]"
                            aria-label="Default select example"
                          >
                            <option v-for="col in pagedata.tensions" :key="col.id" :value="col.id">{{ col.name }}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="intro-y col-span-12 md:col-span-6">
                      <div class="mt-3">
                        <div class="input-group">
                          <div id="input-group-3" class="input-group-text w-36 text-center">Cut</div>
                          <select
                            class="form-select"
                            v-model="receiveDet.cut[pagedata.paket_num]"
                            aria-label="Default select example"
                          >
                            <option v-for="col in pagedata.cuts" :key="col.id" :value="col.id">{{ col.name }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="mt-3">
                        <div class="input-group mt-2 sm:mt-0">
                          <div id="input-group-3" class="input-group-text w-36 text-center">Polish</div>
                          <select
                            class="form-select"
                            v-model="receiveDet.polish[pagedata.paket_num]"
                            aria-label="Default select example"
                          >
                            <option v-for="col in pagedata.polish" :key="col.id" :value="col.id">{{ col.name }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="mt-3">
                        <div class="input-group">
                          <div id="input-group-3" class="input-group-text w-36 text-center">Symmerty</div>
                          <select
                            class="form-select"
                            v-model="receiveDet.symmetry[pagedata.paket_num]"
                            aria-label="Default select example"
                          >
                            <option v-for="col in pagedata.symmetry" :key="col.id" :value="col.id">{{ col.name }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="mt-3">
                        <div class="input-group">
                          <div id="input-group-3" class="input-group-text w-36 text-center">Stone</div>
                          <select
                            class="form-select"
                            v-model="receiveDet.stone[pagedata.paket_num]"
                            aria-label="Default select example"
                          >
                            <option v-for="col in pagedata.stones" :key="col.id" :value="col.id">{{ col.name }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="mt-3">
                        <div class="mt-2 sm:mt-0">
                          <div class="input-group">
                            <input
                            type="number"
                            v-model="receiveDet.pktwt[pagedata.paket_num]"
                            class="form-control"
                            placeholder="Weight"
                            aria-describedby="input-group-4"
                          />
                          </div>
                          <span class="text-primary-3 text-left" v-if="errors[pagedata.paket_num]">{{ errors[pagedata.paket_num] }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-3">
                    <div class="form-label w-full text-left">Remark</div>
                    <textarea
                      id="regular-form-4"
                      type="password"
                      class="form-control"
                      v-model="receiveDet.description[pagedata.paket_num]"
                      placeholder="Remark"
                    ></textarea>
                  </div>
                  <div class="text-right">
                    <button class="btn btn-outline-secondary w-24 mr-1" @click="prevnext('prev')" :disabled="pagedata.btnPrev">
                      Previous
                    </button>
                    <button type="button" :disabled="pagedata.btnDis" @click="prevnext('next')" class="btn btn-primary w-24">Next</button>
                  </div>
                  <div class="text-right mt-5">
                    <button type="button" class="btn btn-primary w-24" :disabled="pagedata.submit" @click="receivePKT">Receive
                    <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" /></button>
                  </div>
              </div>
            </div>
          <!-- END: Vertical Form -->
          </div>
        </div>
      </div>
    </div>
    <!-- END: Receive Packet Modal -->
  <!-- END: Profile Info -->
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import axios from 'axios'
import cash from 'cash-dom'
import { computed, defineComponent, onMounted, reactive, ref, toRef, watch } from 'vue'
import print from 'vue3-print-nb'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import Toastify from 'toastify-js'
export default defineComponent({
  directives: {
    print
  },
  setup() {
    const pagedata = reactive({
      search: '',
      search_type: 'makeable',
      check: false,
      waiting: false,
      type: 'makeable',
      colors: [],
      cuts: [],
      purities: [],
      symmetry: [],
      polish: [],
      fluerocents: [],
      tensions: [],
      stones: [],
      shapes: [],
      processingPkts: [],
      addlist: [],
      errors: [],
      receivePkts: [],
      addornot: false,
      btnDis: false,
      btnPrev: true,
      paket_num: 0,
      lenPkt: 1,
      submit: false,
    })
    const receiveDet = reactive({
      id: [],
      number: [],
      color: [],
      cut: [],
      purity: [],
      polish: [],
      shape: [],
      symmetry: [],
      tension: [],
      stone: [],
      fluerocent: [],
      pktwt: [],
      description: []
    })
    const tableRef = ref()
    const tabulator = ref()
    const errors = toRef(pagedata, 'errors')
    const addlist = toRef(pagedata, 'addlist')
    onMounted(() => {
      initTabulator()
    })
    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + `/packet/packetsap/2?type=${pagedata.type}`,
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 10,
        paginationSizeSelector: [10, 20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [
          {
            formatter: 'responsiveCollapse',
            width: 40,
            minWidth: 30,
            hozAlign: 'center',
            resizable: false,
            headerSort: false
          },

          // For HTML table
          {
            title: 'Packet No',
            responsive: 0,

            field: 'number',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().number
                }</div>
              </div>`
            }
          },
          {
            title: 'Type',
            responsive: 0,
            field: 'type',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().type
                }</div>
              </div>`
            }
          },
          {
            title: 'Parent',
            responsive: 0,
            field: 'parent',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().parent
                }</div>
              </div>`
            }
          },
          {
            title: 'Weight',
            responsive: 0,
            field: 'weight',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().weight
                }</div>
              </div>`
            }
          },
          {
            title: 'Exp. Wt',
            responsive: 0,
            field: 'pwt',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().admin_pwt > 0 ? cell.getData().admin_pwt : cell.getData().pwt
                }</div>
              </div>`
            }
          },
          {
            title: 'Shape',
            field: 'shape',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Color',
            field: 'color',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Purity',
            field: 'purity',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Cut',
            field: 'cut',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Polish',
            field: 'polish',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'symmetry',
            field: 'symmetry',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Fluer',
            field: 'fluerocent',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Tension',
            field: 'tension',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Stone',
            field: 'stone',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }
    function AddtoList() {
      if(pagedata.search != '') {
        if(pagedata.addlist.length <= 0) {
          checkadd()
        } else {
          for (let i = 0; i < pagedata.addlist.length; i++) {
            if(pagedata.addlist[i] == pagedata.search) {
              // pagedata.checklist.splice(pagedata.addlist[i].id,1)
              pagedata.addlist.splice(i, 1);
              pagedata.addornot = true
              pagedata.search = ''
              break
            } else {
              pagedata.addornot = false
            }
          }
          if(pagedata.addornot == false) {
              checkadd()
            }
        }
      }
    }
    function checkadd() {
      axios({
        url: `/purchase/alltoAssign/${pagedata.search}?assrec=2&type=${pagedata.search_type}`,
        method: 'get',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        const index = ref(pagedata.addlist.length)
        pagedata.addlist[index.value] = res.data.packet.number
        pagedata.search_type = res.data.packet.type
        pagedata.search = ''
        // pagedata.checklist[pagedata.addlist[index.value].id] = true
      }).catch((err) => {
        pagedata.errors = err.response.data
        console.warn(pagedata.errors)
        setTimeout(() => {
          basicNonStickyNotificationPacket()
        },100)
        setTimeout(() => {
          pagedata.search = ''
        }, 1000);
        setTimeout(() => {
          pagedata.errors = []
        }, 5000);

      })
    }
    function removeItem(id) {
      console.warn(id)
      pagedata.addlist.splice(id, 1);
      if(pagedata.addlist.length <= 0) {
        // pagedata.search_type = ''
      }
    }
    function getPaketDetail() {
      if(pagedata.paket_num == 0 && pagedata.lenPkt == 1) {
        pagedata.btnDis = true
        pagedata.btnPrev = true
      } else if(pagedata.paket_num == 0 && pagedata.lenPkt > 1) {
        pagedata.btnDis = false
        pagedata.btnPrev = true
      } else if(pagedata.paket_num != 0 && pagedata.lenPkt == (pagedata.paket_num +1)) {
        pagedata.btnPrev = false
        pagedata.btnDis = true
      } else {
        pagedata.btnDis = false
        pagedata.btnPrev = false
      }
    }
    function prevnext(type) {
      if(type == 'next') {
        pagedata.paket_num = pagedata.paket_num + 1;
      } else if(type == 'prev') {
        pagedata.paket_num = pagedata.paket_num - 1;
      }
      getPaketDetail()
    }
    watch(() => pagedata.search, (currentValue) => {
      if(!pagedata.check) {
        if (!pagedata.waiting) {
          setTimeout(() => {
            AddtoList()
            pagedata.waiting = false;
          }, 100); // 1 sec delay
        }
        pagedata.waiting = true;
      }
    })
    const basicNonStickyNotificationPacket = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification-packet')
          .clone()
          .removeClass('hidden')[0],
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    function getDiamondDetail() {
      getDiamondDet()
      axios({
        url: '/packet/selPkt',
        method: 'post',
        baseURL: BASE_URL,
        data: {packets: pagedata.addlist},
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.receivePkts = res.data.packets
        pagedata.lenPkt = pagedata.receivePkts.length
        getPaketDetail()
        for (let i = 0; i < pagedata.receivePkts.length; i++) {
          receiveDet.id[i] = pagedata.receivePkts[i].id
          receiveDet.number[i] = pagedata.receivePkts[i].number
          receiveDet.color[i] = pagedata.receivePkts[i].color
          receiveDet.cut[i] = pagedata.receivePkts[i].cut
          receiveDet.purity[i] = pagedata.receivePkts[i].purity
          receiveDet.polish[i] = pagedata.receivePkts[i].polish
          receiveDet.shape[i] = pagedata.receivePkts[i].shape
          receiveDet.stone[i] = pagedata.receivePkts[i].stone
          receiveDet.symmetry[i] = pagedata.receivePkts[i].symmetry
          receiveDet.fluerocent[i] = pagedata.receivePkts[i].fluerocent
          receiveDet.tension[i] = pagedata.receivePkts[i].tension
          receiveDet.pktwt[i] = ''
          receiveDet.description[i] = ''
        }
        cash('#edit-confirmation-modal').modal('show')
      })
    }
    function getDiamondDet() {
      axios({
        url: '/GetAll',
        method: 'get',
        baseURL: BASE_URL,
        config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.colors = res.data.diamonds.colors
          pagedata.cuts = res.data.diamonds.cut
          pagedata.purities = res.data.diamonds.purity
          pagedata.symmetry = res.data.diamonds.symmetry
          pagedata.polish = res.data.diamonds.polish
          pagedata.fluerocents = res.data.diamonds.fluerocent
          pagedata.tensions = res.data.diamonds.tension
          pagedata.shapes = res.data.diamonds.shape
          pagedata.stones = res.data.diamonds.stones
        })
    }
    function receivePKT() {
      pagedata.submit = true
      axios({
        url: '/process/recmulpkt',
        method: 'post',
        baseURL: BASE_URL,
        data: receiveDet,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.submit = false
        pagedata.addlist = []
        // pagedata.search_type = ''
        basicNonStickyNotificationPacketReceive()
        cash('#edit-confirmation-modal').modal('hide')
      }).catch((err) => {
        pagedata.errors = err.response.data
        pagedata.submit = false
        setTimeout(() => {
          pagedata.errors = []
        }, 10000);
        initTabulator()
        setTimeout(() => {
          basicNonStickyNotificationPacket()
        }, 100);
      })
    }
    const basicNonStickyNotificationPacketReceive = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification-packet-receive')
          .clone()
          .removeClass('hidden')[0],
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    return {
      pagedata,
      errors,
      addlist,
      receiveDet,
      AddtoList,
      basicNonStickyNotificationPacketReceive,
      prevnext,
      removeItem,
      basicNonStickyNotificationPacket,
      getDiamondDetail,
      receivePKT,
      initTabulator,
      tableRef

    }
  },
  mounted() {
    this.$refs.focusMe.focus()
  }
})
</script>


<template>
  <div>
    <!-- Headings -->
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Stock Report</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

      <div class="grid grid-cols-12 gap-x-2">
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
            <label for="update-profile-form-6" class="form-label py-1"
              >Packet / Lot</label
            >
            <input
              id="tabulator-html-filter-value"
              v-model="filter.packet_no"
              type="text"
              class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
              placeholder="Search Packet / Kapan"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Process</label
              >
              <TomSelect
                v-model="filter.process_id"
                :options="{
                  placeholder: 'Select Process',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Process'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Process</option>
              <option v-for="process in pagedata.processes" :key="process.id" :value="process.id">{{process.process_name}} ( {{ process.process_short_code ? process.process_short_code : '-' }} )</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Type</label
              >
              <TomSelect
                v-model="filter.type"
                :options="{
                  placeholder: 'Select Type',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Type'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Type</option>
              <option value="makeable">Makeable</option>
              <option value="rough">Rough</option>
              <option value="video">Video</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">
          <div class="mt-5 flex pt-4">

            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2"
              @click="onResetFilter"
            >
              Reset
            </button>
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">
          <div class="mt-5 flex pt-4">

            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              tabindex="5"
              @click="generateReport()"
            >
            Generate
            </button>
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1" v-if="pagedata.items.length > 0">
          <div class="mt-5 flex pt-4">

            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              tabindex="5"
              @click="printReportDetails"
            >
            Print
            </button>
          </div>
        </div>
      </div>

      <div>
          <!-- BEgin: Process Report Start-->
          <!-- <div id="responsive-table" class="p-5" v-if="pagedata.loading">
            <div class="preview">
              <div class="overflow-x-auto">
                <div
                  class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
                >
                  <LoadingIcon icon="bars" class="w-8 h-8" />
                  <div class="text-center text-xs mt-2">Getting Latest Price</div>
              </div>
              </div>
            </div>
          </div> -->

          <div>
            <div class="scrollbar-hidden">
              <div class="intro-y col-span-12 overflow-auto">
                <table class="w-full mt-3 border-2">
                  <thead>
                    <tr class="border-b bg-gray-300 dark:bg-dark-1">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">#</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">Packet</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">Vepari No</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">Proc</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">Type</th>
                      <!-- <th class="py-2 text-xs lg:text-md whitespace-nowrap">Dept</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">Mgr</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">Emp</th> -->
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">R Wt.</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">PL Wt.</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">Iss Wt.</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap"><MultipleSelect :options="pagedata.parameters.shape" :name="'shape'" @onChecked="onShapeCheck"></MultipleSelect></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap"><MultipleSelect :options="pagedata.parameters.color" :name="'color'" @onChecked="onColorCheck"></MultipleSelect></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap"><MultipleSelect :options="pagedata.parameters.purity" :name="'purity'" @onChecked="onPurityCheck"></MultipleSelect></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap"><MultipleSelect :options="pagedata.parameters.cut" :name="'cut'" @onChecked="onCutCheck"></MultipleSelect></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap"><MultipleSelect :options="pagedata.parameters.polish" :name="'polish'" @onChecked="onPolishCheck"></MultipleSelect></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap"><MultipleSelect :options="pagedata.parameters.symmetry" :name="'symmetry'" @onChecked="onSymmetryCheck"></MultipleSelect></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap"><MultipleSelect :options="pagedata.parameters.fluerocent" :name="'fluerocent'" @onChecked="onFluerocentCheck"></MultipleSelect></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap"><MultipleSelect :options="pagedata.parameters.tension" :name="'tension'" @onChecked="onTensionCheck"></MultipleSelect></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap"><MultipleSelect :options="pagedata.parameters.stone" :name="'stone'" @onChecked="onStoneCheck"></MultipleSelect></th>
                      <th class="py-2 text-xs lg:text-md">Iss Date</th>
                      <th class="py-2 text-xs lg:text-md">Days</th>
                      <!-- <th class="py-2 text-xs lg:text-md">Late</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.total_packets ? pagedata.total.total_packets : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td> -->
                      <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td> -->
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.rough_weight ? $h.myFloat(pagedata.total.rough_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.polish_weight ? $h.myFloat(pagedata.total.polish_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.issue_weight ? $h.myFloat(pagedata.total.issue_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td> -->
                    </tr>
                    <tr class="border-b" v-for="(single_item,ind) in pagedata.items" :key="ind" :class="{'bg-gray-100': (ind % 2) == 0}">
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ ind + 1 }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.number }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.vepari_no }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.process_code ? single_item.process_code : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.packet_type ? single_item.packet_type : '-' }}</td>
                      <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.department_short_code ? single_item.department_short_code : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.manager_short_name ? single_item.manager_short_name : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.employee_short_name ? single_item.employee_short_name : '-' }}</td> -->
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.weight ? $h.myFloat(single_item.weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.cr_polish_weight ? $h.myFloat(single_item.cr_polish_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.issue_weight ? $h.myFloat(single_item.issue_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.shape ? single_item.shape : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.color ? single_item.color : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.purity ? single_item.purity : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.cut ? single_item.cut : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.polish ? single_item.polish : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.symmetry ? single_item.symmetry : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.fluerocent ? single_item.fluerocent : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.tension ? single_item.tension : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.stone ? single_item.stone : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.issue_date ? single_item.issue_date : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.days }}</td>
                      <!-- <td class="border-b text-xs lg:text-md text-center border-r" :class="{'bg-primary-3 text-white': ((single_item.packet_delay > 0 && (single_item.packet_delay < single_item.late) && (single_item.transaction_type == 1 || single_item.transaction_type == 3 || single_item.transaction_type == 4)) || (single_item.process_duration > 0  && (single_item.process_duration < single_item.late) && !(single_item.transaction_type == 1 || single_item.transaction_type == 3 || single_item.transaction_type == 4)))}">{{ single_item.late }}</td> -->
                    </tr>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.total_packets ? pagedata.total.total_packets : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td> -->
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.rough_weight ? $h.myFloat(pagedata.total.rough_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.polish_weight ? $h.myFloat(pagedata.total.polish_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.issue_weight ? $h.myFloat(pagedata.total.issue_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td> -->
                    </tr>
                  </tbody>

                </table>
                <!-- BEGIN: Pagination -->
                  <div v-if="pagedata.items.length > 0"
                      class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center mt-3 mb-7"
                    >
                      <ul class="pagination">
                        <li>
                          <button class="pagination__link" :disabled="pagedata.current_page == 1" @click="getReportDetailPage(pagedata.first_page_url)">
                            <ChevronsLeftIcon class="w-4 h-4" />
                          </button>
                        </li>
                        <!-- <li>
                          <button class="pagination__link" :disabled="pagedata.prev_page_url == null" @click="getReportDetailPage(pagedata.prev_page_url)">
                            <ChevronLeftIcon class="w-4 h-4" />
                          </button>
                        </li> -->
                        <li v-for="(pagelink,index) in pagedata.pagelinks" :key="index">
                          <button class="pagination__link" :class="{'pagination__link--active' : pagelink.active == true}" :disabled="pagelink.url == null" @click='getReportDetailPage(pagelink.url)'><span v-if="index == 0"><ChevronLeftIcon class="w-4 h-4" /></span><span v-else-if="index == pagedata.pagelinks.length - 1"><ChevronRightIcon class="w-4 h-4" /></span><span v-else>{{ pagelink.label }}</span></button>
                        </li>
                        <!-- <li>
                          <button class="pagination__link" :disabled="pagedata.next_page_url == null" @click="getReportDetailPage(pagedata.next_page_url)">
                            <ChevronRightIcon class="w-4 h-4" />
                          </button>
                        </li> -->
                        <li>
                          <button class="pagination__link" :disabled="pagedata.current_page == pagedata.last_page" @click="getReportDetailPage(pagedata.last_page_url)">
                            <ChevronsRightIcon class="w-4 h-4" />
                          </button>
                        </li>
                      </ul>
                      <select class="w-20 form-select box mt-3 sm:mt-0" v-model="filter.size" @change="getAvailPkt">
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                      </select>
                  </div>
                  <!-- END: Pagination -->
              </div>
            </div>
          </div>
        </div>
    </div>
      <!-- END: HTML Table Data -->

  </div>
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config';
import axios from 'axios';
import moment from 'moment';
import { defineComponent, onMounted, reactive, toRef, watch, computed } from 'vue';
import MultipleSelect from '@/components/MultipleSelect/Main.vue'
import store from '@/store'

export default defineComponent({
  components:{
    MultipleSelect
  },
  setup() {
    const user_details = computed(() => store.state.user.currentUser)
    const pagedata = reactive({
      total : [],
      items : [],
      parties : [],
      managers : [],
      processes : [],
      departments : [],
      employees : [],
      loading : false,
      pagelinks: [],
      prev_page_url: null,
      next_page_url: null,
      first_page_url: null,
      last_page_url: null,
      current_page: 1,
      last_page: '',
      parameters: {
        color: [],
        cut: [],
        purity: [],
        polish: [],
        shape: [],
        symmetry: [],
        fluerocent: [],
        tension: [],
        stone: []
      }
    })
    const filter = reactive({
      packet_no : '',
      process_id : '0',
      type : '0',
      size : '20',
      color: [],
      cut: [],
      purity: [],
      polish: [],
      shape: [],
      symmetry: [],
      fluerocent: [],
      tension: [],
      stone: [],
    })
    const api_filter = reactive({
      packet_no : '',
      process_id : '0',
      type : '0',
      size : '20',
      color: [],
      cut: [],
      purity: [],
      polish: [],
      shape: [],
      symmetry: [],
      fluerocent: [],
      tension: [],
      stone: [],
    })
    function getReportDetails() {
      api_filter.packet_no = filter.packet_no
      api_filter.process_id = filter.process_id
      api_filter.type = filter.type
      api_filter.size = filter.size
      api_filter.color = filter.color
      api_filter.cut = filter.cut
      api_filter.purity = filter.purity
      api_filter.polish = filter.polish
      api_filter.shape = filter.shape
      api_filter.symmetry = filter.symmetry
      api_filter.fluerocent = filter.fluerocent
      api_filter.tension = filter.tension
      api_filter.stone = filter.stone
      getReportDetail()
      getReportDetailsTotal()
    }
    function getDiamontData() {
      axios({
        url: 'api/master/get_all_parameters',
        method: 'post',
        baseURL: BASE_URL,
        data: filter,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.parameters.color = res.data.color
        pagedata.parameters.cut = res.data.cut
        pagedata.parameters.purity = res.data.purity
        pagedata.parameters.symmetry = res.data.symmetry
        pagedata.parameters.polish = res.data.polish
        pagedata.parameters.fluerocent = res.data.fluerocent
        pagedata.parameters.tension = res.data.tension
        pagedata.parameters.shape = res.data.shape
        pagedata.parameters.stone = res.data.stone
      })
    }
    const getReportDetail = () => {
      pagedata.loading = true

      let params = {
        packet_no : window.btoa(api_filter.packet_no),
        process_id : api_filter.process_id == '0' ? '' : window.btoa(api_filter.process_id) ,
        type : api_filter.type == '0' ? '' : window.btoa(api_filter.type),
        size : api_filter.size,
        color : api_filter.color,
        cut : api_filter.cut,
        purity : api_filter.purity,
        polish : api_filter.polish,
        shape : api_filter.shape,
        symmetry : api_filter.symmetry,
        fluerocent : api_filter.fluerocent,
        tension : api_filter.tension,
        stone : api_filter.stone,
      }

      let promise = axios({
        url: 'api/report/get_stock_report_details_party',
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          pagedata.items = data.data
          pagedata.pagelinks = data.links
          pagedata.prev_page_url = data.prev_page_url
          pagedata.next_page_url = data.next_page_url
          pagedata.first_page_url = data.first_page_url
          pagedata.last_page_url = data.last_page_url
          pagedata.current_page = data.current_page
          pagedata.last_page = data.last_page
          pagedata.loading = false
        })
        .catch(_error => {
          // pagedata.items = []
          pagedata.loading = false
        });
    }
    const getReportDetailPage = (url) => {
      pagedata.loading = true

      let params = {
        packet_no : window.btoa(api_filter.packet_no),
        process_id : api_filter.process_id == '0' ? '' : window.btoa(api_filter.process_id) ,
        type : api_filter.type == '0' ? '' : window.btoa(api_filter.type),
        size : api_filter.size,
        color : api_filter.color,
        cut : api_filter.cut,
        purity : api_filter.purity,
        polish : api_filter.polish,
        shape : api_filter.shape,
        symmetry : api_filter.symmetry,
        fluerocent : api_filter.fluerocent,
        tension : api_filter.tension,
        stone : api_filter.stone,
      }

      let promise = axios({
        url: url,
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          pagedata.items = data.data
          pagedata.pagelinks = data.links
          pagedata.prev_page_url = data.prev_page_url
          pagedata.next_page_url = data.next_page_url
          pagedata.first_page_url = data.first_page_url
          pagedata.last_page_url = data.last_page_url
          pagedata.current_page = data.current_page
          pagedata.last_page = data.last_page
          pagedata.loading = false
        })
        .catch(_error => {
          pagedata.items = []
          pagedata.loading = false
        });
    }
    const getReportDetailsTotal = () => {
      let params = {
        packet_no : window.btoa(api_filter.packet_no),
        process_id : api_filter.process_id == '0' ? '' : window.btoa(api_filter.process_id) ,
        type : api_filter.type == '0' ? '' : window.btoa(api_filter.type),
        color : api_filter.color,
        cut : api_filter.cut,
        purity : api_filter.purity,
        polish : api_filter.polish,
        shape : api_filter.shape,
        symmetry : api_filter.symmetry,
        fluerocent : api_filter.fluerocent,
        tension : api_filter.tension,
        stone : api_filter.stone,
      }

      let promise = axios({
        url: 'api/report/get_stock_report_details_total_party',
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          pagedata.total = data.total
        })
        .catch(_error => {
          pagedata.total = []
        });
    }
    const printReportDetails = () => {
      let params = {
        packet_no : window.btoa(api_filter.packet_no),
        process_id : api_filter.process_id == '0' ? '' : window.btoa(api_filter.process_id) ,
        type : api_filter.type == '0' ? '' : window.btoa(api_filter.type),
        color : api_filter.color,
        cut : api_filter.cut,
        purity : api_filter.purity,
        polish : api_filter.polish,
        shape : api_filter.shape,
        symmetry : api_filter.symmetry,
        fluerocent : api_filter.fluerocent,
        tension : api_filter.tension,
        stone : api_filter.stone,
        filters : api_filter,
        report_name : 'stock-report-details-party',
      }

      let promise = axios({
        url: 'api/print/set_stock_report_details_party',
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          let id = data.report_id

          let url = BASE_URL + data.report_name + '?report_id=' + window.btoa(id)
            window.open(url, '_blank');
        })
        .catch(_error => {
          pagedata.total = []
        });
    }
    const getProcessDetails = () => {
      let params = {
        // id : window.btoa(filter.manager_id)
      }
      let api_url = ''

      api_url = 'api/master/dp_process'
      let promise = axios({
        url: api_url,
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          filter.process_id = '0'
          pagedata.processes = data.items
        })
        .catch(_error => {
          filter.process_id = '0'
          pagedata.processes = []
        });
    }
    function onColorCheck(val) {
      filter.color = val;
      getReportDetails()
    }
    function onPurityCheck(val) {
      filter.purity = val;
      getReportDetails()
    }
    function onCutCheck(val) {
      filter.cut = val;
      getReportDetails()
    }
    function onPolishCheck(val) {
      filter.polish = val;
      getReportDetails()
    }
    function onShapeCheck(val) {
      filter.shape = val;
      getReportDetails()
    }
    function onSymmetryCheck(val) {
      filter.symmetry = val;
      getReportDetails()
    }
    function onStoneCheck(val) {
      filter.stone = val;
      getReportDetails()
    }
    function onFluerocentCheck(val) {
      filter.fluerocent = val;
      getReportDetails()
    }
    function onTensionCheck(val) {
      filter.tension = val;
      getReportDetails()
    }
    function onResetFilter() {
      filter.packet_no = ''
      filter.process_id = '0'
      filter.type = '0'
      filter.color = []
      filter.cut = []
      filter.purity = []
      filter.polish = []
      filter.shape = []
      filter.symmetry = []
      filter.fluerocent = []
      filter.tension = []
      filter.stone = []
    }
    function generateReport() {
      getReportDetails()
    }
    onMounted(() => {
      getProcessDetails()
      getDiamontData()
      generateReport()
    })
    return {
      pagedata,
      filter,api_filter,user_details,generateReport,
      getProcessDetails,
      onResetFilter,
      getReportDetailPage,
      onColorCheck,
      onPurityCheck,
      onCutCheck,
      onPolishCheck,
      onShapeCheck,
      onSymmetryCheck,
      onFluerocentCheck,
      onStoneCheck,
      onTensionCheck,
      printReportDetails
    }
  }
})
</script>

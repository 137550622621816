<template>
  <div>
    <div id="basic-non-sticky-notification-packet" class="toastify-content hidden flex flex-col sm:flex-row">
      <div class="font-medium text-primary-3" v-if="pagedata.error">{{ pagedata.error }}</div>
      <div class="font-medium text-primary-4" v-if="pagedata.success">{{ pagedata.success }}</div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex items-center">
        <h2 class="text-lg font-bold">Plan Packets</h2>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
  <!-- BEGIN: Rate Not Found Process -->
  <div
    id="rate-show-confirmation-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body p-3">
          <div class="p-5 text-center">
            <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
            <div class="text-3xl mt-5" v-if="pagedata.errors.error">{{ pagedata.errors.error }}</div>
            <div class="text-gray-600 mt-2" v-if="pagedata.errors.message">
              {{ pagedata.errors.message }}
            </div>
          </div>
          <div class="px-5 pb-8 text-center">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-24 mr-1"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-danger w-24" :disabled="pagedata.submit" v-if="pagedata.errors.error == 'Rate Not Found' || pagedata.errors.error == 'Manager Rate Not Found'" @click="createRateRequest">Send
              <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Rate Not Found Process -->
  <!-- END: Profile Info -->
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import axios from 'axios'
import cash from 'cash-dom'
import { defineComponent, onMounted, reactive, ref, watch } from 'vue'
import print from 'vue3-print-nb'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import Toastify from 'toastify-js'
import router from '@/router'
export default defineComponent({
  directives: {
    print
  },
  setup() {
    const pagedata = reactive({
      submit: false,
      error: '',
      type: '',
      errors: [],
      success: '',
      packet_id: ''
    })
    const tableRef = ref()
    const tabulator = ref()
    onMounted(() => {
      initTabulator()
    })
    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + '/rough/get_manager_rough_packets',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 10,
        paginationSizeSelector: [10, 20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [
          {
            formatter: 'responsiveCollapse',
            width: 40,
            minWidth: 30,
            hozAlign: 'center',
            vertAlign: 'middle',
            resizable: false,
            headerSort: false
          },

          // For HTML table
          {
            title: 'Packet No',
            responsive: 0,

            field: 'number',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().number
                }</div>
              </div>`
            }
          },
          {
            title: 'Parent',
            responsive: 0,
            field: 'parent',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().parent
                }</div>
              </div>`
            }
          },
          {
            title: 'Weight',
            responsive: 0,
            field: 'weight',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().weight
                }</div>
              </div>`
            }
          },
          {
            title: 'Color',
            field: 'color',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Purity',
            field: 'purity',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Stone',
            field: 'stone',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Action',
            field: 'action',
            minWidth: 100,
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              const a = cash(`<div class="flex lg:justify-center items-center">
                ${
                  cell.getData().rough_transfers_status == 0 ? (cell.getData().rate_status == 0 ?'<div><button class="btn btn-primary p-1 px-2 mr-1" disabled> <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Accept </button> <div class="text-primary-3 mt-1">Rate Not Found</div></div>' : '<button class="btn btn-primary p-1 px-2 mr-1"> <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Accept </button>') : '<button class="btn btn-danger p-1 px-2 mr-1"> <i data-feather="check-square" class="w-4 h-4 mr-1"></i> View Plan </button>'
                }
              </div>`)
              cash(a).on('click', function() {
                if(cell.getData().rough_transfers_status == 0 && cell.getData().rate_status != 0) {
                  acceptPacket(cell.getData().id)
                } else if(cell.getData().rate_status != 0){
                  router.push({ name: 'manager-packet-plans', params: { id: cell.getData().id } })
                }
              })

              return a[0]
            }
          },
        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }
    function acceptPacket(id) {
      axios({
          url: `/rough/accept_manager_rough_packet/${id}`,
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.success = res.data.success
          setTimeout(() => {
            basicNonStickyNotificationPacket()
          },100)
          setTimeout(() => {
            pagedata.success = ''
          }, 5000);
          initTabulator()
        }).catch((err) => {
          if(err.response.data.message) {
            pagedata.errors.message = err.response.data.message
            pagedata.errors.error = err.response.data.error
            pagedata.type = err.response.data.type
            cash('#rate-show-confirmation-modal').modal('show')
            pagedata.packet_id = id
          } else {
            pagedata.error = err.response.data.error
            setTimeout(() => {
              basicNonStickyNotificationPacket()
            },100)
            setTimeout(() => {
              pagedata.error = ''
            }, 5000);
          }

        })
    }
    function createRateRequest() {
      pagedata.submit = true
      axios({
        url: `/packet/create-rough-rate-request/${pagedata.packet_id}?type=${pagedata.type}`,
        method: 'get',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      }).then((res) => {
        pagedata.submit = false
        pagedata.success = res.data.success
        initTabulator()
        setTimeout(() => {
          basicNonStickyNotificationPacket()
        }, 100);
        cash('#rate-show-confirmation-modal').modal('hide')
      }).catch((err) => {
        pagedata.error = err.response.data.error
        pagedata.submit = false
        setTimeout(() => {
          basicNonStickyNotificationPacket()
        }, 100);
      })
    }
    const basicNonStickyNotificationPacket = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification-packet')
          .clone()
          .removeClass('hidden')[0],
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    return {
      pagedata,
      tableRef,
      initTabulator,
      createRateRequest
    }
  }
})
</script>


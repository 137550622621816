<template>
  <div class="my-8">
    <div class="xl:grid block xl:grid-cols-2 gap-4">
      <div class="mx-5">
        <h2 class="intro-y text-lg font-medium">Packet No : {{ paketDet.number }}</h2>
        <table class="w-48">
          <tbody>
            <tr>
              <th class="py-2 font-bold border-2 text-md px-1 text-left">Shape</th>
              <th class="py-2 font-bold border-2 text-md px-1 text-left">Color</th>
              <th class="py-2 font-bold border-2 text-md px-1 text-left">Purity</th>
              <th class="py-2 font-bold border-2 text-md px-1 text-left">Cut</th>
              <th class="py-2 font-bold border-2 text-md px-1 text-left">Polish</th>
              <th class="py-2 font-bold border-2 text-md px-1 text-left">Symmetry</th>
              <th class="py-2 font-bold border-2 text-md px-1 text-left">Tension</th>
              <th class="py-2 font-bold border-2 text-md px-1 text-left whitespace-nowrap">Issue Wt</th>
              <th class="py-2 font-bold border-2 text-md px-1 text-left whitespace-nowrap">Oriznal Wt</th>
            </tr>
            <tr>
              <td class="font-bold text-md border-2 text-center">{{ paketDet.shape.name }}</td>
              <td class="font-bold text-md border-2 text-center">{{ paketDet.color.name }}</td>
              <td class="font-bold text-md border-2 text-center">{{ paketDet.purity.name }}</td>
              <td class="font-bold text-md border-2 text-center">{{ paketDet.cut.name }}</td>
              <td class="font-bold text-md border-2 text-center">{{ paketDet.polish.name }}</td>
              <td class="font-bold text-md border-2 text-center">{{ paketDet.symmetry.name }}</td>
              <td class="font-bold text-md border-2 text-center">{{ paketDet.tension.name }}</td>
              <td class="font-bold text-md border-2 text-center">{{ paketDet.issueWt }}</td>
              <td class="font-bold text-md border-2 text-center">{{ paketDet.oriznal_wt }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto mt-6" v-if="packets.length > 0">
          <table class="table-report -mt-2">
            <thead>
              <tr>
                <th class=" lg:px-2 px-1 whitespace-nowrap">#</th>
                <th class=" lg:px-2 px-1 whitespace-nowrap">T No</th>
                <th class=" lg:px-2 px-1 whitespace-nowrap">Proc.</th>
                <th class=" lg:px-2 px-1 whitespace-nowrap">Dept.</th>
                <th class=" lg:px-2 px-1 whitespace-nowrap">Mgr.</th>
                <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Emp.</th>
                <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Issue Wt.</th>
                <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Rec. Wt.</th>
                <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Issue Time</th>
                <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Rec. Time</th>
                <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Shape</th>
                <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Color</th>
                <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Purity</th>
                <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Cut</th>
                <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Polish</th>
                <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Symm</th>
                <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Flurc</th>
                <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Tension</th>
                <th class=" lg:px-2 px-1 text-center whitespace-nowrap">Stone</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(packet, index) in packets" :key="index">
                <td class="text-center text-sm py-2">{{ index + 1 }}</td>
                <td class="text-center text-sm py-2">{{ packet.t_id }}</td>
                <td class="text-center text-sm py-2">{{ packet.process }}</td>
                <td class="text-center text-sm py-2">{{ packet.department }}</td>
                <td class="text-center text-sm py-2">{{ packet.user_id }}</td>
                <td class="text-center text-sm py-2">{{ packet.employee }}</td>
                <td class="text-center text-sm py-2">{{ packet.issue_weight }}</td>
                <td class="text-center text-sm py-2">{{ packet.receive_weight }}</td>
                <td class="text-center text-sm py-2 w-6">{{ packet.issue_date }}</td>
                <td class="text-center text-sm py-2 w-6">{{ packet.receive_date }}</td>
                <td class="text-center text-sm py-2">{{ packet.shape.name }}</td>
                <td class="text-center text-sm py-2">{{ packet.color.name }}</td>
                <td class="text-center text-sm py-2">{{ packet.purity.name }}</td>
                <td class="text-center text-sm py-2">{{ packet.cut.name }}</td>
                <td class="text-center text-sm py-2">{{ packet.polish.name }}</td>
                <td class="text-center text-sm py-2">{{ packet.symmetry.name }}</td>
                <td class="text-center text-sm py-2">{{ packet.fluerocent.name }}</td>
                <td class="text-center text-sm py-2">{{ packet.tension.name }}</td>
                <td class="text-center text-sm py-2">{{ packet.stone.name }}</td>
              </tr>
            </tbody>
          </table>
      </div>
      <div v-else class="intro-y col-span-12 overflow-auto mt-5 text-center">No Transection Found</div>
      <!-- END: Data List -->
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import axios from 'axios'
import { useRoute } from 'vue-router'
import { defineComponent, reactive, toRef, onMounted } from 'vue'
import print from 'vue3-print-nb'
export default defineComponent({
  setup() {
    const route = useRoute()
    const pagedata = reactive({
      packets: [],
      search: route.params.id,
    })
    const packets = toRef(pagedata,'packets')
    const newParams = toRef(pagedata,'newParams')
    const paketDet = reactive({
      id: '',
      number: '',
      color: '',
      cut: '',
      purity: '',
      polish: '',
      shape: '',
      symmetry: '',
      fluerocent: '',
      stone: '',
      tension: '',
      issueWt: '',
      pwt: '',
      admin_pwt: '',
      inprocess: 1,
      oriznal_wt: '',
      blocked: ''
    })
    onMounted(() => {
        getPaketDetail()
    })
    function getPaketDetail() {
        axios({
          url: `/process/admin-party-packet/${route.params.id}`,
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.packets = res.data.packets
          paketDet.id = res.data.paket.id
          paketDet.number = res.data.paket.number
          paketDet.inprocess = res.data.paket.inProcess
          paketDet.color = res.data.paket.color
          paketDet.cut = res.data.paket.cut
          paketDet.purity = res.data.paket.purity
          paketDet.polish = res.data.paket.polish
          paketDet.shape = res.data.paket.shape
          paketDet.symmetry = res.data.paket.symmetry
          paketDet.fluerocent = res.data.paket.fluerocent
          paketDet.stone = res.data.paket.stone
          paketDet.tension = res.data.paket.tension
          paketDet.issueWt = res.data.paket.weight
          paketDet.pwt = res.data.paket.pwt
          paketDet.admin_pwt = res.data.paket.admin_pwt
          paketDet.oriznal_wt = res.data.paket.oriznal_wt
          paketDet.blocked = res.data.paket.blocked
          if(pagedata.packets.length == 0) {
            paketDet.inprocess = 0
          }
        })
    }
    return {
      pagedata,
      packets,
      paketDet,
      getPaketDetail
    }
  }
})
</script>

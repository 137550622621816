<template>
  <tr>
    <td class="text-center text-sm py-2">{{ index + 1 }}</td>
    <td class="text-center text-sm py-2">{{ packet.t_id }}</td>
    <td class="text-center text-sm py-2">{{ packet.process }}</td>
    <td class="text-center text-sm py-2">{{ packet.department }}</td>
    <td class="text-center text-sm py-2">{{ packet.manager }}</td>
    <td class="text-center text-sm py-2">{{ packet.employee }}</td>
    <td class="text-center text-sm py-2">{{ packet.issue_weight }}</td>
    <td class="text-center text-sm py-2"><input v-if="pagedata.editable" type="number" class="form-control w-16 text-center" v-model="changes.weight"><div v-else>{{ packet.receive_weight }}</div></td>
    <td class="text-center text-sm py-2 w-6">{{ packet.issue_date }}</td>
    <td class="text-center text-sm py-2 w-6">{{ packet.receive_date }}</td>
    <td class="text-center text-sm py-2">
      <div class="input-group mt-2 sm:mt-0" v-if="pagedata.editable">
        <select
          class="form-select w-14"
          v-model="changes.shape"
          aria-label="Default select example"
        >
          <option v-for="col in diamond.shapes" :key="col.id" :value="col.id">{{ col.name }}</option>
        </select>
      </div>
      <div v-else>
        {{ packet.shape.name }}
      </div>
    </td>
    <td class="text-center text-sm py-2">
      <div class="input-group mt-2 sm:mt-0" v-if="pagedata.editable">
        <select
          class="form-select w-14"
          v-model="changes.color"
          aria-label="Default select example"
        >
          <option v-for="col in diamond.colors" :key="col.id" :value="col.id">{{ col.name }}</option>
        </select>
      </div>
      <div v-else>
        {{ packet.color.name }}
      </div>
    </td>
    <td class="text-center text-sm py-2">
      <div class="input-group mt-2 sm:mt-0" v-if="pagedata.editable">
        <select
          class="form-select w-14"
          v-model="changes.purity"
          aria-label="Default select example"
        >
          <option v-for="col in diamond.purities" :key="col.id" :value="col.id">{{ col.name }}</option>
        </select>
      </div>
      <div v-else>
        {{ packet.purity.name }}
      </div>
    </td>
    <td class="text-center text-sm py-2">
      <div class="input-group mt-2 sm:mt-0" v-if="pagedata.editable">
        <select
          class="form-select w-14"
          v-model="changes.cut"
          aria-label="Default select example"
        >
          <option v-for="col in diamond.cuts" :key="col.id" :value="col.id">{{ col.name }}</option>
        </select>
      </div>
      <div v-else>
        {{ packet.cut.name }}
      </div>
    </td>
    <td class="text-center text-sm py-2">
      <div class="input-group mt-2 sm:mt-0" v-if="pagedata.editable">
        <select
          class="form-select w-14"
          v-model="changes.polish"
          aria-label="Default select example"
        >
          <option v-for="col in diamond.polish" :key="col.id" :value="col.id">{{ col.name }}</option>
        </select>
      </div>
      <div v-else>
        {{ packet.polish.name }}
      </div>
    </td>
    <td class="text-center text-sm py-2">
      <div class="input-group mt-2 sm:mt-0" v-if="pagedata.editable">
        <select
          class="form-select w-14"
          v-model="changes.symmetry"
          aria-label="Default select example"
        >
          <option v-for="col in diamond.symmetry" :key="col.id" :value="col.id">{{ col.name }}</option>
        </select>
      </div>
      <div v-else>
        {{ packet.symmetry.name }}
      </div>
    </td>
    <td class="text-center text-sm py-2">
      <div class="input-group mt-2 sm:mt-0" v-if="pagedata.editable">
        <select
          class="form-select w-14"
          v-model="changes.fluerocent"
          aria-label="Default select example"
        >
          <option v-for="col in diamond.fluerocents" :key="col.id" :value="col.id">{{ col.name }}</option>
        </select>
      </div>
      <div v-else>
        {{ packet.fluerocent.name }}
      </div>
    </td>
    <td class="text-center text-sm py-2">
      <div class="input-group mt-2 sm:mt-0" v-if="pagedata.editable">
        <select
          class="form-select w-14"
          v-model="changes.tension"
          aria-label="Default select example"
        >
          <option v-for="col in diamond.tensions" :key="col.id" :value="col.id">{{ col.name }}</option>
        </select>
      </div>
      <div v-else>
        {{ packet.tension.name }}
      </div>
    </td>
    <td class="text-center text-sm py-2">
      <div class="input-group mt-2 sm:mt-0" v-if="pagedata.editable">
        <select
          class="form-select w-14"
          v-model="changes.stone"
          aria-label="Default select example"
        >
          <option v-for="col in diamond.stones" :key="col.id" :value="col.id">{{ col.name }}</option>
        </select>
      </div>
      <div v-else>
        {{ packet.stone.name }}
      </div>
    </td>
    <td class="text-center text-sm py-2">
      <div v-if="packet.status != 0">
        <button
          class="btn btn-primary mr-1 p-2" @click="pagedata.editable = !pagedata.editable" v-if="pagedata.editable == false" :disabled="packet.status == 3"
        >
          <ExternalLinkIcon class="h-4 w-4" />
        </button>
        <div v-else>
          <button class="btn bg-primary-9 text-white mr-1 p-2"><SaveIcon class="w-4 h-4" @click="updateParameters"/></button>
          <button class="btn btn-danger mr-1 p-2" @click="pagedata.editable = !pagedata.editable" v-if="pagedata.editable == true" :disabled="packet.status == 3"><XIcon class="h-4 w-4" /></button>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
/* eslint-disable */
import { computed, defineComponent, reactive, ref, watch} from 'vue'
import axios from 'axios'
import { BASE_URL } from '@/constants/config'
export default defineComponent({
  props: ['packet', 'index', 'diamond','packetDet','newParams'],
  emits: ['getData','updatParameters','showNotification'],
  setup(props, {emit}) {
    const changes = reactive({
      id: props.packet.id,
      weight: props.packet.receive_weight,
      color: props.packet.color.id,
      cut: props.packet.cut.id,
      purity: props.packet.purity.id,
      polish: props.packet.polish.id,
      shape: props.packet.shape.id,
      symmetry: props.packet.symmetry.id,
      fluerocent: props.packet.fluerocent.id,
      stone: props.packet.stone.id,
      tension: props.packet.tension.id,
    })
    const issue = computed(() => props.packet.issue_weight)
    const receive = computed(() => props.packet.receive_weight)

    const pagedata = reactive({
      editable: props.packet.issue_weight < props.packet.receive_weight ? true : props.newParams.packet_detail == props.packet.id ? (props.newParams.status == 0 ? true:false):false,
      // editable: props.packet.issue_weight < props.packet.receive_weight ? true : props.newParams.packet_detail == props.packet.id ? (props.newParams.status == 0 ? true:false):false,
      error: '',
      success: ''
    })
    function updateParameters() {
      if(changes.color == props.packet.color.id) {
        if(changes.cut == props.packet.cut.id) {
          if(changes.purity == props.packet.purity.id) {
            if(changes.polish == props.packet.polish.id) {
              if(changes.shape == props.packet.shape.id) {
                if(changes.symmetry == props.packet.symmetry.id) {
                  if(changes.fluerocent == props.packet.fluerocent.id) {
                    if(changes.stone == props.packet.stone.id) {
                      if(changes.tension == props.packet.tension.id) {
                        if(parseFloat(changes.weight) == parseFloat(props.packet.receive_weight)) {
                          const message = ref('Please Change Parameters')
                          emit('showNotification',message.value)
                              return
                        } else if(parseFloat(changes.weight) < parseFloat(props.packet.issue_weight)) {
                          if(props.packetDet.admin_pwt > 0) {
                            if(props.packetDet.admin_pwt > changes.weight) {
                              emit('updatParameters',changes)
                              pagedata.editable = false
                              return
                            } else {
                              UpdateWeight()
                              return
                            }
                          } else {
                            if(parseFloat(props.packetDet.pwt) > parseFloat(changes.weight)) {
                              emit('updatParameters',changes)
                              pagedata.editable = false
                              return
                            } else {
                              UpdateWeight()
                              return
                            }
                          }
                        } else {
                          const message = ref('Please Change Parameters')
                          emit('showNotification',message.value)
                              return
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      emit('updatParameters',changes)
      pagedata.editable = false
    }
    function UpdateWeight() {
      console.warn(changes.weight)
      axios({
        url: `/process/update-middle-proc-weight/${changes.id}`,
        method: 'post',
        baseURL: BASE_URL,
        data: { weight: changes.weight},
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      }).then((res) => {
        pagedata.editable = false
        emit('showNotification',res.data.success)
        emit('getData')

      }).catch(() => {
      })
    }
    watch(() => issue.value, (newValue,OldValue) => {
      if(receive.value > issue.value) {
        pagedata.editable = true
      }
    })
    return {
      changes,
      pagedata,
      updateParameters
    }
  }
})
</script>

<style scoped>
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type=number] {
            -moz-appearance: textfield;
        }

        .input-group .input-group-text-custom {
            border-width: 1px;
            --tw-border-opacity: 1;
            border-color: rgba(226, 232, 240, var(--tw-border-opacity));
            --tw-bg-opacity: 1;
            background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
            padding-top: 0.1rem;
            padding-bottom: 0.1rem;
            padding-left: 1rem;
            padding-right: 1rem;
            --tw-text-opacity: 1;
            color: rgba(113, 128, 150, var(--tw-text-opacity));
            --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
            box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
        }
    </style>
